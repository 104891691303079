import React, { useState } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";

export interface IInputTextComponentProps {
  name: string;
  error?: string;
  label?: string;
  defaultValue?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
}

export function InputTextComponent({
  inputObject,
}: { inputObject: any } | any) {
  const {
    label,
    name,
    defaultValue,
    maxLength,
    minLength,
    required,
    disabled,
  } = inputObject.getRawInput();
  const [error, setError] = useState(null);
  const ref: any = React.createRef();

  inputObject.setError = setError;
  inputObject.setRef(ref);

  return (
    <FormGroup
      onBlur={(event: any) => {
        const { value } = event.target;
        event.currentTarget.classList.add("was-validated");
        if (required && !value) {
          return inputObject.setCurrentError("missing");
        }
        if (
          (minLength && value.length < minLength) ||
          (maxLength && value.length > maxLength)
        ) {
          return inputObject.setCurrentError("invalid");
        }
        inputObject.setCurrentError(null);
      }}
      onChange={(event: any) => {
        inputObject.setValue(event.target.value);
        event.currentTarget.classList.remove("was-validated");
      }}
    >
      <Form.Label>
        {label}{" "}
        {required && <span className={"text-danger fv_required"}>*</span>}
      </Form.Label>
      <FormControl
        disabled={disabled}
        ref={ref}
        isValid={!error && inputObject.value}
        isInvalid={!!error}
        name={name}
        type={"text"}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
      />
      {error && (
        <Form.Control.Feedback
          id={`error_${name}`}
          className="font-weight-bold"
          type="invalid"
          role="alert"
        >
          {error}
        </Form.Control.Feedback>
      )}
    </FormGroup>
  );
}
