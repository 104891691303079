import React, { useCallback, useEffect, useState } from "react";
import "./CustomOffer.css";
import { connect } from "react-redux";
import {
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import {
  BannerComponent,
  CustomOfferCardComponent,
  CustomOfferFormComponent,
  FooterComponent,
  LoaderComponent,
} from "../../Components";
import {
  compareShallowObject,
  getClosestNumber,
  getPredefinedPermissions,
  i18n,
  isDefaultOffer,
  matomo,
} from "../../utils";
import { appAction, offerAction } from "../../Store";
import { defaultPermissions, Go, offerLabelsAndColors } from "../../Constants";
import { offerApi } from "../../Api";
import { getOfferRegisterFormConfig } from "../../Services/Form";
import { useLocation } from "react-router-dom";

export type IPeriod = "month" | "year" | undefined;

function CustomOfferHeader({
  minimal,
  currentPeriod,
  setPeriod,
}: {
  minimal?: boolean;
  currentPeriod: IPeriod;
  setPeriod?: any;
}) {
  return (
    <MDBContainer className={"text-center"}>
      <MDBRow>
        <MDBCol size={"12"}>
          <p className="t2 text-white fv_offer_slogan_adjust">
            {i18n._("custom_offer_co2_saved_conclustion")}
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size={"12"} className={"my-3"}>
          {!minimal && (
            <MDBRow>
              <MDBCol size={"12"} className={"my-5"}>
                <BannerComponent />
              </MDBCol>
            </MDBRow>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size={"6"} className={"text-right mb-2 pl-5"}>
          <a
            href={"#vision"}
            onClick={() => setPeriod("year")}
            className={`offer-header-period-cta text-white p-2 fv_offer_header_period_cta ${
              currentPeriod === "year" && "active"
            }`}
          >
            {i18n._("offer_per_year_label")}{" "}
            <span className={"period-giving"}>
              {i18n._("offer_per_year_giving_label")}
            </span>
          </a>
        </MDBCol>
        <MDBCol size={"6"} className={"text-left mb-2 pl-5"}>
          <a
            href={"#vision"}
            onClick={() => setPeriod("month")}
            className={`offer-header-period-cta text-white p-2 fv_offer_header_period_cta ${
              currentPeriod !== "year" && "active"
            }`}
          >
            {i18n._("offer_per_month_label")}
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

function CustomOfferScreen({
  minimal,
  userCurrentPrice,
  userCurrentPeriod,
  backgroundImage,
  user_permissions,
  showLightHeader,
  setLightHeader,
  currentPeriod,
  setCurrentPeriod,
  currentOffer,
  setCurrentOffer,
  currentPrice,
  setCurrentPrice,
  cb,
  isNewUser,
}: any) {
  const [permissions, setPermissions]: any = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [payload, setPayload] = useState({});
  const location = useLocation();
  const [type, setType] = useState<number>(0);

  // setCurrentPrice = useCallback(setCurrentPrice, [setCurrentPrice]);
  // setLightHeader = useCallback(setLightHeader, [setLightHeader]);

  const onSubmit = async (formValues: any) => {
    const payload = Object.keys(formValues).reduce(
      (acc: any, cur: any) => {
        if (formValues[cur].value) {
          acc[cur] = {
            value: formValues[cur].value,
          };
        }
        return acc;
      },
      { ...currentOffer, interval: currentPeriod }
    );

    //TODO: Check if user log before call stripe true = put false = post
    // console.log(payload, currentPeriod)

    if (user_permissions && !isNewUser) {
      setPayload(payload);
      try {
        const checkoutSession: any =
          await offerApi.createCheckoutSessionForUser({ products: payload });
        // return console.log(checkoutSession)

        if (checkoutSession && cb) {
          return cb(checkoutSession);
        }

        if (checkoutSession?.url)
          return (document.location = checkoutSession.url);
      } catch (e) {}
    } else {
      setModalOpened(true);
      setPayload(payload);
    }
  };
  const onSubmitRegister = async (values: any) => {
    try {
      values.role = currentOffer.team_size !== false ? 3 : 2;
      const newCustomer: any = await offerApi.createCustomer(values);
      if (newCustomer.error) {
        return newCustomer.error;
      }
      const checkoutSession: any = await offerApi.createCheckoutSession(
        {
          products: payload,
          customer_id: newCustomer.customer_id,
        },
        localStorage.getItem("by")
      );
      if (checkoutSession?.url) document.location = checkoutSession.url;
    } catch (e) {
      return e;
    }
  };
  const updatePriceCB = useCallback(
    (currentOffer: any) => {
      setCurrentPrice({ currentOffer, currentPeriod });
    },
    [currentPeriod, setCurrentPrice]
  );

  const updatePricePeriodCB = (currentPeriod: any) => {
    setCurrentPrice({ currentOffer, currentPeriod });
  };

  const initCurrentOffer = useCallback(() => {
    setCurrentOffer(
      user_permissions
        ? Object.keys(user_permissions).reduce(
            (acc: any, cur) => ({
              ...acc,
              [cur]: {
                ...acc[cur],
                value:
                  cur !== "team_size" || user_permissions[cur].value === false
                    ? user_permissions[cur].value
                    : getClosestNumber(
                        user_permissions[cur].value,
                        currentOffer.team_size.options
                      ),
              },
            }),
            currentOffer
          )
        : currentOffer,
      updatePriceCB
    );
  }, [currentOffer, setCurrentOffer, updatePriceCB, user_permissions]);

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(location.search);

      if (params.get("studio") !== null)
        return setCurrentOffer(
          getPredefinedPermissions("offer_studio_label"),
          updatePriceCB
        );
      if (params.get("pro") !== null)
        return setCurrentOffer(
          getPredefinedPermissions("offer_pro_label"),
          updatePriceCB
        );

      if (!user_permissions || isNewUser)
        setCurrentOffer(
          getPredefinedPermissions("offer_solo_label"),
          updatePriceCB
        );
    }, 10);
  }, [location.search]);

  useEffect(() => {
    const storedReferer = localStorage.getItem("by");
    const referer = new URLSearchParams(document.location.search).get("by");
    if (storedReferer !== referer && referer) {
      localStorage.setItem("by", referer);
    }

    if (!showLightHeader && !minimal) {
      setTimeout(setLightHeader, 500);
    }

    if (!isMounted) initCurrentOffer();

    setIsMounted(true);

    matomo._paq.push(["setDocumentTitle", `Offers`]);
    matomo._paq.push(["trackPageView"]);
  }, [minimal, showLightHeader, setLightHeader, isMounted, initCurrentOffer]);

  useEffect(() => {
    if (
      compareShallowObject(
        getPredefinedPermissions(offerLabelsAndColors[1].label),
        currentOffer
      )
    )
      setType(1);

    if (
      compareShallowObject(
        getPredefinedPermissions(offerLabelsAndColors[2].label),
        currentOffer
      )
    )
      setType(2);
  }, [currentOffer]);

  if (!showLightHeader && !minimal) {
    return null;
  }

  if (!permissions) {
    setTimeout(() => setPermissions(defaultPermissions), 1000);
    return <LoaderComponent />;
  }

  return (
    <>
      <MDBContainer
        id={"main"}
        fluid
        className={"px-0 m-0"}
        style={{
          minHeight: "100vh",
          background: minimal
            ? "transparent"
            : "url(" + backgroundImage + ") no-repeat fixed",
          backgroundSize: "100vw 100vh",
          paddingTop: 45,
        }}
      >
        <MDBContainer
          fluid
          className={`bg-${
            minimal ? "transparent" : "aqua"
          } p-0 m-0 py-3 fv_offer_screen section adjust`}
          style={{ minHeight: "inherit" }}
        >
          {!minimal && (
            <>
              <div className={"clearfix"} />

              <MDBRow>
                <MDBCol size={"12"}>
                  <CustomOfferHeader
                    minimal={minimal}
                    currentPeriod={currentPeriod}
                    setPeriod={(p: any) =>
                      setCurrentPeriod(p, updatePricePeriodCB)
                    }
                  />
                </MDBCol>
              </MDBRow>
            </>
          )}

          <MDBRow>
            <MDBCol size={"12"}>
              <CustomOfferCardComponent
                isNewUser={isNewUser}
                userCurrentPrice={userCurrentPrice / 100}
                userCurrentPeriod={userCurrentPeriod}
                user_permissions={user_permissions}
                currentOffer={currentOffer}
                setCurrentOffer={(data: any) =>
                  setCurrentOffer(data, updatePriceCB)
                }
                currentPrice={currentPrice}
                setCurrentPrice={setCurrentPrice}
                currentPeriod={currentPeriod}
                onSubmit={onSubmit}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>

      <MDBModal
        centered
        toggle={() => setModalOpened(false)}
        isOpen={modalOpened}
      >
        <MDBModalHeader toggle={() => setModalOpened(false)}>
          <span className={"mr-2"}>{i18n._("fv_title")}</span>
          <span style={{ color: i18n._(offerLabelsAndColors[type].color) }}>
            {i18n._(offerLabelsAndColors[type].label).toUpperCase()}
          </span>
        </MDBModalHeader>
        <CustomOfferFormComponent
          {...getOfferRegisterFormConfig()}
          onSubmit={onSubmitRegister}
        />
      </MDBModal>

      {!minimal && <FooterComponent />}
    </>
  );
}

function getUserCurrentPrice(data: any) {
  const getNewPrice = (acc: number, item: any) => {
    return acc + item?.plan?.amount * item?.quantity;
  };

  return data.reduce(
    (acc: number, sub: any) =>
      acc +
      (sub?.plan?.amount * sub.quantity ||
        sub?.items?.data.reduce(getNewPrice, 0)),
    0
  );
}

const mapStateToProps = ({ app, user, offer }: any) => {
  try {
    user = user?.user;
  } catch (e) {}
  const isNewUser = !user?.subscriptions || !user?.subscriptions?.length;
  return {
    isNewUser,
    backgroundImage: app.BACKGROUND,
    user_permissions: !isNewUser ? user?.permissions : defaultPermissions,
    showLightHeader: app[appAction.SHOW_LIGHT_HEADER]
      ? app[appAction.SHOW_LIGHT_HEADER][appAction.SHOW_LIGHT_HEADER]
      : false,
    currentPeriod: offer.currentPeriod,
    currentOffer: offer.currentOffer,
    currentPrice: offer.currentPrice,
    userCurrentPrice: !isNewUser
      ? getUserCurrentPrice(user?.subscriptions)
      : undefined,
    userCurrentPeriod: user?.subscriptions?.[0]?.plan?.interval,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setLightHeader: () => {
    dispatch({
      type: appAction.SHOW_LIGHT_HEADER,
      value: { [appAction.SHOW_LIGHT_HEADER]: true },
    });
  },
  setCurrentPeriod: (period: any, cb: Function) => {
    dispatch(offerAction.setCurrentPeriod(period));
    cb(period);
  },
  setCurrentOffer: (currentOffer: any, cb: Function) => {
    dispatch(offerAction.setCurrentOffer(currentOffer));
    cb(currentOffer);
  },
  setCurrentPrice: ({
    currentOffer,
    currentPeriod,
  }: {
    currentOffer: any;
    currentPeriod: "year" | "month";
  }) => {
    dispatch(
      offerAction.setCurrentPrice(calcPrice(currentOffer, currentPeriod))
    );
  },
});

function calcPrice(formValues: any, currentPeriod: any) {
  if (isDefaultOffer(formValues)) {
    return 0;
  }
  const roundPrice = (p: number) => Math.round(p * 100) / 100;
  let ratioPerUser = 1;
  let qty = 1;
  const calculated: any = Object.keys(formValues).reduce(
    (acc: any, k: string) => {
      const { value } = formValues[k];
      switch (k) {
        case "upload_ttl":
          acc[k] = Number(value) + (Number(value) === 7 ? 2 : 5);
          break;
        case "upload_size":
          const sizeInGo: number = Number(value) / Go;
          switch (sizeInGo) {
            case 2:
              acc[k] = 0.2;
              break;
            case 5:
              acc[k] = 0.25;
              break;
            case 10:
              acc[k] = 0.3;
              break;
            case 20:
              acc[k] = 0.4;
              break;
            case 50:
              acc[k] = 0.6;
              break;
            case 100:
              acc[k] = 0.9;
              break;
            case 200:
              acc[k] = 1.7;
              break;
            default:
              break;
          }
          break;
        case "transfer_notification":
          if (value) acc[k] = 0.5;
          break;
        case "transfer_authentication":
          if (value) acc[k] = 0.65;
          break;
        case "transfer_tracking":
          if (value) acc[k] = 1;
          break;
        case "consumption_export":
          if (value) acc[k] = 0.5;
          break;
        case "customization":
          if (value) acc[k] = 0.9;
          break;
        case "outlook_addin":
          if (value) acc[k] = 0.95;
          break;
        case "studio":
          if (value) acc[k] = 6.45;
          break;
        case "team_size":
          const ratios: any = {
            "0": 1,
            "5": 0.25,
            "10": 0.22,
            "20": 0.19,
            "30": 0.16,
            "50": 0.13,
            "100": 0.1,
            // '200': .065
          };
          ratioPerUser = ratios[value] || 1;
          qty = Number(value !== true ? value : "0");
          break;
        default:
          break;
      }
      return acc;
    },
    {}
  );

  calculated.upload_ttl = roundPrice(
    calculated.upload_ttl * calculated.upload_size - calculated.upload_size
  );
  const lines = Object.keys(calculated).reduce((acc: any, k) => {
    acc[k] = (calculated[k] * 12) / (currentPeriod === "year" ? 12 : 10);
    return acc;
  }, {});

  const perUser = Object.keys(lines).reduce(
    (total: number, k: any): number => total + lines[k],
    0
  );

  return roundPrice(perUser + perUser * qty * ratioPerUser);
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomOfferScreen);
