import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { MDBBtn } from "mdbreact";

export default function App({
  input,
  onLoadCb,
  ratios,
  originImg,
  canvasId,
  automatic,
}: any) {
  if (!ratios) ratios = [{ aspect: 16 / 9, label: "16/9" }];
  const [upImg, setUpImg] = useState(originImg);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop]: any = useState({
    unit: "%",
    width: 50,
    aspect: ratios[0].aspect,
  });
  const [completedCrop, setCompletedCrop]: any = useState(null);

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        if (!automatic) return setUpImg(reader.result);

        const canvas: HTMLCanvasElement | any = previewCanvasRef.current;
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight;

          if (ratio >= 1.4) {
            const nH = img.naturalWidth / 1.4;
            canvas.width = img.naturalWidth;
            // prettier-ignore
            //@ts-ignore
            canvas.height = nH;
            ctx.drawImage(img, 0, (nH - img.naturalHeight) / 2);
          } else {
            const nW = img.naturalHeight;
            canvas.height = img.naturalHeight;
            // prettier-ignore
            //@ts-ignore
            canvas.width = nW;
            ctx.drawImage(img, (nW - img.naturalWidth) / 2, 0);
          }

          setUpImg(canvas.toDataURL());
        };
        img.src = reader.result;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback(
    (img) => {
      imgRef.current = img;

      if (onLoadCb) onLoadCb();

      if (!completedCrop)
        setTimeout(() => {
          const ratio = img.width / img.height;
          let aspect = ratios[0].aspect;
          if (automatic) aspect = ratio > 1.4 ? 16 / 9 : 1;
          setCrop({ ...crop, aspect });
        }, 250);
    },
    [automatic, completedCrop, crop, onLoadCb, ratios]
  );

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    const maxH = 1080,
      maxW = 1920;

    if (crop.width > maxW) {
      crop.width = maxW;
    }

    if (crop.height > maxH) {
      crop.height = maxH;
    }

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop?.x * scaleX,
      crop?.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  });

  return (
    <div className="App" style={{ textAlign: "center" }}>
      <div>
        {input ? (
          <input {...input} onChange={onSelectFile} />
        ) : (
          <input type="file" accept="image/*" onChange={onSelectFile} />
        )}
      </div>

      <ReactCrop
        src={upImg || ""}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c: any) => setCrop(c)}
        onComplete={(c: any) => setCompletedCrop(c)}
        style={{ maxHeight: "70vh" }}
        imageStyle={{ maxHeight: "70vh" }}
      />

      <div>
        {ratios.length > 1 &&
          ratios.map((ratio: any) => (
            <MDBBtn
              key={`btn_logo_${ratio.aspect}`}
              onClick={() => {
                if (crop.aspect === ratio.aspect) return;
                const mult = ratio.aspect === 1 ? crop.aspect : ratio.aspect;
                const newCrop = {
                  ...crop,
                  aspect: ratio.aspect,
                  height:
                    ratio.aspect > crop.aspect
                      ? crop.height / mult
                      : crop.height * mult,
                };
                setCrop(newCrop);
                setCompletedCrop(newCrop);
              }}
            >
              {ratio.label}
            </MDBBtn>
          ))}
      </div>

      <div style={{ margin: "auto", display: "none" }}>
        <canvas
          id={canvasId}
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            borderColor: "black",
            borderWidth: 2,
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
    </div>
  );
}
