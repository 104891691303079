export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export function loginUser(value: any) {
  return { type: LOGIN_USER, value };
}

export function logoutUser(value: any) {
  setTimeout(() => (document.location.href = "/"), 500);
  return { type: LOGOUT_USER, value };
}
