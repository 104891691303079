import { userAction } from "../Actions";
import { AnyAction } from "redux";

const initialState = {};

export default function userReducer(state: any, action: AnyAction) {
  let nextState;

  switch (action.type) {
    case userAction.LOGIN_USER:
      const { user, token } = action.value;
      nextState = { user, token };
      break;

    case userAction.LOGOUT_USER:
      nextState = {};
      break;

    default:
      nextState = state;
      break;
  }

  return nextState || initialState;
}

export { userAction };
