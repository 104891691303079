export const SHOW_LIGHT_HEADER = "SHOW_LIGHT_HEADER";
export const SHOW_CUSTOM_HEADER = "SHOW_CUSTOM_HEADER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SAVE_PASSWORD = "SAVE_PASSWORD";
export const LANG = "LANG";
export const BACKGROUND = "BACKGROUND";
export const COOKIES = "COOKIES";
export const I18N_READY = "I18N_READY";
export const USER_UPLOAD_FAVORITE_METHOD = "USER_UPLOAD_FAVORITE_METHOD";
export const APP_ERROR = "APP_ERROR";
export const HIDE_HEADER = "HIDE_HEADER";

export function setAppError(value: any) {
  return { type: APP_ERROR, value };
}

export function hideHeader(value: boolean) {
  return { type: HIDE_HEADER, value };
}
export function showLightHeader(value: boolean) {
  return { type: SHOW_LIGHT_HEADER, value };
}

export function showCustomHeader(value: string | null | undefined) {
  return { type: SHOW_CUSTOM_HEADER, value };
}

export function resetPassword(value: boolean) {
  return { type: RESET_PASSWORD, value };
}

export function savePassword(value: boolean) {
  return { type: SAVE_PASSWORD, value };
}

export function changeLang(value: string) {
  return { type: LANG, value };
}

export function setBackground(value: string) {
  return { type: BACKGROUND, value };
}

export function acceptCookies(value: string) {
  return { type: COOKIES, value };
}

export function setI18nStatus(value: boolean) {
  return { type: I18N_READY, value };
}

export function setUserUploadFavoriteMethod(value: number) {
  return { type: USER_UPLOAD_FAVORITE_METHOD, value };
}
