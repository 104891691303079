import "./Space.css";
import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import { i18n } from "../../../../../../utils";
import { userApi } from "../../../../../../Api";
import * as userAction from "../../../../../../Store/Actions/userAction";
import { images } from "../../../../../../assets";
import { isMobile } from "../../../../../../utils";
import CropperComponent from "../../../../../Cropper";
import { gradients } from "../../../../../../Constants";

const initialUser = {
  config: {
    website: "",
    title: "",
    message: "",
    theme: "",
    logo: false,
    background: false,
  },
  logo: false,
  background: false,
};

interface Props {
  dispatch: Function;
  handleChangeSpace: Function;
  user: any;
  isDisabled: boolean;
}

interface State {
  user: {
    config: {
      website: string;
      title: string;
      message: string;
      theme: string;
    } & any;
    logo: any;
    background: any;
  };
  isModalOpen: boolean;
  hasChanged: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  isCroppieOpen: boolean;
  isCroppieLoaded: boolean;
  croppieType: string;
}

class SpaceComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { config } = this.props.user;

    this.state = {
      user: {
        config: {
          rss_feed: config?.rss_feed ? config.rss_feed : "",
          website: config ? config.url || config.website : "",
          title: config ? config.title : "",
          message: config ? config.message : "",
          theme: config ? config.theme : "",
          logo: config.logo || null,
          background: config.background || null,
        },
        logo: config.logo || null,
        background: config.background || null,
      },
      hasChanged: false,
      isModalOpen: false,
      isLoading: false,
      isDisabled: true,
      isSuccess: false,
      isCroppieOpen: false,
      isCroppieLoaded: false,
      croppieType: "",
    };
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    const { config, logo } = nextProps.user;
    return config !== this.state.user.config || logo !== this.state.user.logo;
  }

  handleChangeTheme = (theme: string | undefined) => {
    const { user } = this.state;
    const { handleChangeSpace } = this.props;

    this.setState(
      {
        ...this.state,
        hasChanged:
          !user.config ||
          !user.config.theme ||
          (user.config && user.config.theme !== theme),
        isDisabled: !(
          !user.config ||
          !user.config.theme ||
          (user.config && user.config.theme !== theme)
        ),
        isSuccess: false,
        user: {
          ...this.state.user,
          config: {
            ...this.state.user.config,
            theme,
          },
        },
      },
      () => handleChangeSpace(this.state.user.config)
    );
  };

  handleChangeThemeBg = (theme: string | undefined) => {
    const { user } = this.state;
    const { handleChangeSpace } = this.props;

    this.setState(
      {
        ...this.state,
        hasChanged:
          !user.config ||
          !user.config.theme ||
          (user.config && user.config.theme !== theme),
        isDisabled: !(
          !user.config ||
          !user.config.theme ||
          (user.config && user.config.theme !== theme)
        ),
        isSuccess: false,
        user: {
          ...this.state.user,
          config: {
            ...this.state.user.config,
            theme,
          },
        },
      },
      () => handleChangeSpace(this.state.user.config)
    );
  };

  openModal = () =>
    this.setState({ ...this.state, isModalOpen: true, isDisabled: false });
  closeModal = () => this.setState({ ...this.state, isModalOpen: false });

  closeCroppieModal = () =>
    this.setState({
      ...this.state,
      isCroppieOpen: false,
      isCroppieLoaded: false,
      user: { ...this.state.user, logo: this.props.user.logo },
    });
  openCroppieModal = (croppieType: string) =>
    this.setState({ ...this.state, isCroppieOpen: true, croppieType }, () => {
      document.body.onfocus = this.listenIfCancel;
    });
  onCroppieLoaded = () =>
    this.setState({ ...this.state, isCroppieLoaded: true });

  listenIfCancel = () => {
    setTimeout(() => {
      if (this.state.isCroppieLoaded) return;
      this.closeCroppieModal();
    }, 500);
  };

  onResult = (evt: SyntheticEvent<HTMLButtonElement, Event> & any) => {
    const canvasId = evt.target.attributes["data-crop"].value;
    const canvasDom: HTMLCanvasElement | null = document.querySelector(
      `#${canvasId}`
    );
    const inputLogoDom: HTMLInputElement | null =
      document.querySelector(`#logo`);
    const base64 = canvasDom?.toDataURL();
    const logoName: string | null =
      inputLogoDom &&
      inputLogoDom?.files &&
      inputLogoDom?.files[0] &&
      inputLogoDom?.files[0].name;

    this.setState(
      {
        ...this.state,
        isCroppieOpen: false,
        isCroppieLoaded: false,
        hasChanged: true,
        user: {
          ...this.state.user,
          logo: { ...this.state.user.logo, file: base64, name: logoName },
        },
      },
      () =>
        this.props.handleChangeSpace(
          this.state.user.config,
          this.state.user.logo
        )
    );
  };

  onResultBg = (evt: SyntheticEvent<HTMLButtonElement, Event> & any) => {
    const canvasId = evt.target.attributes["data-crop"].value;
    const canvasDom: HTMLCanvasElement | null = document.querySelector(
      `#${canvasId}`
    );
    const inputLogoDom: HTMLInputElement | null =
      document.querySelector(`#background`);
    const base64 = canvasDom?.toDataURL();
    const logoName: string | null =
      inputLogoDom &&
      inputLogoDom?.files &&
      inputLogoDom?.files[0] &&
      inputLogoDom?.files[0].name;

    this.setState(
      {
        ...this.state,
        isCroppieOpen: false,
        isCroppieLoaded: false,
        hasChanged: true,
        user: {
          ...this.state.user,
          background: {
            ...this.state.user.background,
            file: base64,
            name: logoName,
          },
        },
      },
      () => {
        this.props.handleChangeSpace(
          this.state.user.config,
          this.state.user.background,
          "background"
        );
      }
    );
  };

  handleChange = (evt: any) => {
    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user: {
          ...this.state.user,
          config: {
            ...this.state.user.config,
            [evt.target.name]: evt.target.value,
          },
        },
      },
      () => this.props.handleChangeSpace(this.state.user.config)
    );
  };

  handleRemoveLogo = (evt: Event & any) => {
    evt.preventDefault();
    evt.stopPropagation();
    let { user } = this.state;
    user.config.logo = {};
    user.logo = {};

    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user,
      },
      () => this.props.handleChangeSpace(this.state.user.config, false)
    );
  };

  handleRemoveBg = (evt: Event & any) => {
    evt.preventDefault();
    evt.stopPropagation();
    let { user } = this.state;
    user.config.background = {};
    user.background = {};
    user.config.theme = "";

    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user,
      },
      () =>
        this.props.handleChangeSpace(
          this.state.user.config,
          false,
          "background"
        )
    );
  };

  resetSpace = (evt: SyntheticEvent<HTMLButtonElement, Event> | any): void => {
    evt.preventDefault();
    evt.stopPropagation();
    const user = initialUser;

    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user,
      },
      () => {
        this.props.handleChangeSpace(null, false, "logo");
        this.props.handleChangeSpace(null, false, "background");
        this.props.handleChangeSpace("RESET");
      }
    );
  };

  handleSubmit = async () => {
    let updatedInfos: any = {};

    Object.keys(this.state.user.config).forEach((key: string, i: number) => {
      if (key === "logo") {
        if (JSON.stringify(this.state.user[key]) === this.props.user[key])
          return;
        updatedInfos[key] = this.state.user[key];
      }
      if (key === "background") {
        if (JSON.stringify(this.state.user[key]) === this.props.user[key])
          return;
        updatedInfos[key] = this.state.user[key];
      }

      if (
        !this.props.user[key] ||
        this.props.user.config[key] !== this.state.user.config[key]
      ) {
        updatedInfos[key] = this.state.user.config[key];
      }
    });

    if (Object.keys(updatedInfos).length) {
      this.setState({ isLoading: true, hasChanged: false });
      const updatedUser = await userApi.update(
        { config: updatedInfos },
        this.props.user.id
      );
      this.props.dispatch(userAction.loginUser(updatedUser));
      this.setState({
        isLoading: false,
        hasChanged: false,
        isDisabled: true,
        isSuccess: true,
      });
    }
  };

  wrapLogoName = (logoName: string) =>
    logoName.length > 33 ? `${logoName.substr(0, 30)}...` : logoName;

  render() {
    const { isDisabled } = this.props;
    const { isCroppieOpen, isCroppieLoaded, croppieType } = this.state;

    let { logo, background } = this.state.user;
    if (typeof logo === "string" && logo.length > 10) {
      logo = JSON.parse(logo);
    }
    if (typeof background === "string" && background.length > 10) {
      background = JSON.parse(background);
    }

    let logoSrc = logo?.file || logo?.url;
    logoSrc += !logoSrc || logoSrc.includes("base64") ? "" : `?${Date.now()}`;

    let backgroundSrc = background?.file || background?.url;
    backgroundSrc +=
      !backgroundSrc || backgroundSrc.includes("base64")
        ? ""
        : `?${Date.now()}`;

    return (
      <MDBContainer>
        <MDBModal
          backdropStyles={{ zIndex: 1000 }}
          isOpen={isCroppieOpen && croppieType === "logo"}
          className={`${!isMobile && "croppie-modal"} fv_croppie_modal ${
            isCroppieLoaded && croppieType === "logo" ? "" : "d-none"
          }`}
        >
          <MDBModalHeader toggle={this.closeCroppieModal} />
          <MDBModalBody>
            <CropperComponent
              canvasId={"logo_canvas"}
              automatic={true}
              onLoadCb={this.onCroppieLoaded}
              originImg={this.state.user?.logo?.file}
              input={{
                className: "position-absolute",
                disabled: isDisabled,
                accept: ".png, .jpg, .jpeg",
                style: { visibility: "hidden" },
                id: "logo",
                type: "file",
                autoComplete: "logo",
                name: "logo",
              }}
            />
          </MDBModalBody>
          <MDBModalFooter className={"justify-content-center text-center"}>
            <MDBBtn onClick={this.onResult} data-crop={"logo_canvas"}>
              {i18n._(`btn_crop_${croppieType}_label`)}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          backdropStyles={{ zIndex: 1000 }}
          isOpen={isCroppieOpen && croppieType === "background"}
          className={`${!isMobile && "croppie-modal"} fv_croppie_modal ${
            isCroppieLoaded && croppieType === "background" ? "" : "d-none"
          }`}
        >
          <MDBModalHeader toggle={this.closeCroppieModal} />
          <MDBModalBody>
            <CropperComponent
              canvasId={"background_canvas"}
              ratios={[{ aspect: 16 / 9, label: "16/9" }]}
              onLoadCb={this.onCroppieLoaded}
              originImg={this.state.user?.background?.file}
              input={{
                className: "position-absolute",
                disabled: isDisabled,
                accept: ".png, .jpg, .jpeg",
                style: { visibility: "hidden" },
                id: "background",
                type: "file",
                autoComplete: "background",
                name: "background",
              }}
            />
          </MDBModalBody>
          <MDBModalFooter className={"justify-content-center text-center"}>
            <MDBBtn onClick={this.onResultBg} data-crop={"background_canvas"}>
              {i18n._(`btn_crop_${croppieType}_label`)}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBRow>
          <MDBCol size={"12"}>
            <h5
              className={
                "text-left my-5 font-weight-bold  profil-infos-panel fv_profil_infos_panel"
              }
            >
              {i18n._("account_space_title")}

              <span
                className={`ml-4 ${isDisabled ? "disabled" : "hover-pointer"}`}
                style={{ width: "auto", height: 25 }}
                onClick={isDisabled ? undefined : this.resetSpace}
                title={i18n._("btn_reset_label")}
              >
                <img
                  alt={"icon-reset"}
                  style={{ height: "inherit", marginTop: -5 }}
                  src={images.ResetIcon}
                />
              </span>
            </h5>

            <MDBContainer className={"pl-0"}>
              <MDBRow className={"form-group"}>
                <MDBCol size={"8"}>
                  <label
                    htmlFor="logo"
                    onClick={() => {
                      if (!isDisabled) this.openCroppieModal("logo");
                    }}
                    className={
                      "float-left w-100 text-white logo-label fv_logo_label"
                    }
                  >
                    {logo && logo.name && (
                      <div className={"logo-preview fv_logo_preview"}>
                        <img alt={"logo-space"} height={120} src={logoSrc} />
                      </div>
                    )}

                    <span
                      style={{
                        lineHeight: "20px",
                        borderRadius: logo && logo.name ? "20px" : undefined,
                      }}
                      onClick={
                        logo && logo.name ? this.handleRemoveLogo : undefined
                      }
                      className={`btn btn-sm btn${
                        logo && logo.name ? "-outline" : ""
                      }-default ${
                        logo && logo.name && "w-100 input-image"
                      } text-left justify-content-between w-100 px-2 ${
                        isDisabled ? "disabled" : "hover-pointer"
                      } ${isMobile && "d-flex"}`}
                    >
                      {!logo || !logo.name ? (
                        i18n._("form_profil_logo_label")
                      ) : (
                        <span
                          className={`logo-wrapper float-left py-2 w-100 fv_logo_wrapper`}
                        >
                          {this.wrapLogoName(logo.name)}

                          {logo && logo.name ? (
                            <MDBIcon
                              icon={"trash-alt"}
                              far
                              size={"2x"}
                              onClick={this.handleRemoveLogo}
                              className={"my-auto position-absolute delete"}
                            />
                          ) : (
                            <MDBIcon
                              icon={"images"}
                              far
                              size={"2x"}
                              className={`mx-0 float-right text-center`}
                            />
                          )}
                        </span>
                      )}

                      {(!logo || !logo.name) && (
                        <MDBIcon
                          icon={"images"}
                          far
                          size={"2x"}
                          className={`mx-0 float-right text-center`}
                        />
                      )}
                    </span>
                  </label>

                  <small className={"font-italic"}>
                    {i18n._("logo_size_recommendations")}
                  </small>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"8"}>
                  <label
                    htmlFor="background"
                    onClick={() => {
                      if (!isDisabled) this.openCroppieModal("background");
                    }}
                    className={
                      "float-left w-100 text-white background-label fv_background_label"
                    }
                  >
                    {background && background.name && (
                      <div
                        className={"background-preview fv_background_preview"}
                      >
                        <img
                          alt={"background-space"}
                          height={120}
                          src={backgroundSrc}
                        />
                      </div>
                    )}

                    <span
                      style={{
                        lineHeight: "20px",
                        borderRadius:
                          background && background.name ? "20px" : undefined,
                      }}
                      onClick={
                        background && background.name
                          ? this.handleRemoveBg
                          : undefined
                      }
                      className={`btn btn-sm btn${
                        background && background.name ? "-outline" : ""
                      }-default ${
                        background && background.name && "w-100 input-image"
                      } text-left justify-content-between w-100 px-2 ${
                        isDisabled ? "disabled" : "hover-pointer"
                      } ${isMobile && "d-flex"}`}
                    >
                      {!background || !background.name ? (
                        i18n._("form_profil_background_label")
                      ) : (
                        <span
                          className={`background-wrapper float-left py-2 w-100 fv_background_wrapper`}
                        >
                          {this.wrapLogoName(background.name)}

                          {background && background.name ? (
                            <MDBIcon
                              icon={"trash-alt"}
                              far
                              size={"2x"}
                              onClick={this.handleRemoveBg}
                              className={"my-auto position-absolute delete"}
                            />
                          ) : (
                            <MDBIcon
                              icon={"images"}
                              far
                              size={"2x"}
                              className={`mx-0 float-right text-center`}
                            />
                          )}
                        </span>
                      )}

                      {(!background || !background.name) && (
                        <MDBIcon
                          icon={"images"}
                          far
                          size={"2x"}
                          className={`mx-0 float-right text-center`}
                        />
                      )}
                    </span>
                  </label>

                  <small className={"font-italic"}>
                    {i18n._("background_size_recommendations")}
                  </small>
                </MDBCol>

                {(!background || !background?.name) && (
                  <MDBCol size={"4"} className={"my-1"}>
                    <MDBIcon
                      onClick={isDisabled ? () => null : this.openModal}
                      icon={"palette"}
                      size={"2x"}
                      className={`my-1 blue-text ${
                        isDisabled ? "disabled" : "hover-pointer"
                      }`}
                    />
                  </MDBCol>
                )}

                {background && background?.name && (
                  <MDBCol size={"4"} className={"d-flex"}>
                    <MDBSwitch
                      checked={
                        !this.state.user?.config?.theme ||
                        this.state.user?.config?.theme === "custom-0"
                      }
                      onChange={
                        isDisabled
                          ? () => null
                          : (
                              evt: SyntheticEvent<HTMLButtonElement, Event> &
                                any
                            ) => {
                              const backgroundTextColor =
                                this.state.user?.config?.theme === "custom-0"
                                  ? "custom-1"
                                  : "custom-0";
                              this.handleChangeThemeBg(backgroundTextColor);
                            }
                      }
                    />
                  </MDBCol>
                )}

                <MDBModal
                  isOpen={this.state.isModalOpen}
                  toggle={this.closeModal}
                >
                  <MDBContainer className={"py-3"}>
                    <MDBRow className={"color-select-block mx-auto h-100"}>
                      <MDBCol size={"6"} className={"clair px-0 mb-3"}>
                        <div
                          className={
                            "head-color mx-auto white-text text-center grey"
                          }
                        >
                          <span>{i18n._("theme_text_white_label")}</span>
                        </div>
                        {gradients.clair.map((gradient: any) => (
                          <div
                            key={gradient.id}
                            onClick={() => this.handleChangeTheme(gradient.id)}
                            className={`whitebox text-center hover-pointer bg-white ${
                              gradient.id === this.state.user?.config?.theme
                                ? " btn-outline-primary"
                                : ""
                            }`}
                            id={gradient.id}
                          >
                            <div
                              className={`${gradient.className} color-block-5 mb-1 mx-auto z-depth-1`}
                            ></div>
                            <span className={"mb-0 text-black"}>
                              {gradient.text}
                            </span>
                          </div>
                        ))}
                      </MDBCol>

                      <MDBCol size={"6"} className={"sombre px-0 mb-3"}>
                        <div className={"head-color mx-auto white text-center"}>
                          <span>{i18n._("theme_text_black_label")}</span>
                        </div>
                        {gradients.sombre.map((gradient: any) => (
                          <div
                            key={gradient.id}
                            onClick={() => this.handleChangeTheme(gradient.id)}
                            className={`whitebox text-center hover-pointer bg-white ${
                              gradient.id === this.state.user?.config?.theme
                                ? " btn-outline-primary"
                                : ""
                            }`}
                            id={gradient.id}
                          >
                            <div
                              className={`${gradient.className} color-block-5 mb-1 mx-auto z-depth-1`}
                            ></div>
                            <span className={"mb-0 text-black"}>
                              {gradient.text}
                            </span>
                          </div>
                        ))}
                      </MDBCol>

                      <MDBCol size={"6"}>
                        <MDBBtn
                          onClick={() => this.handleChangeTheme(undefined)}
                          color={"default"}
                          className={"w-100"}
                        >
                          {i18n._("btn_reset_label")}
                        </MDBBtn>
                      </MDBCol>

                      <MDBCol size={"6"}>
                        <MDBBtn
                          onClick={this.closeModal}
                          color={"default"}
                          className={"w-100"}
                        >
                          {i18n._("btn_valid_label")}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBModal>
              </MDBRow>
              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_title_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"text"}
                    name={"title"}
                    value={this.state.user?.config?.title || ""}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_title_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_title_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    disabled={isDisabled}
                    outline
                    className={"float-left w-100"}
                    label={i18n._("form_profil_message_label")}
                    type={"textarea"}
                    rows={7}
                    onChange={this.handleChange}
                    name={"message"}
                    value={this.state.user?.config?.message || ""}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_message_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_message_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_site_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"url"}
                    name={"website"}
                    value={this.state.user?.config?.website || ""}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_site_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_site_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_rss_feed_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"rss_feed"}
                    name={"rss_feed"}
                    value={this.state.user?.config?.rss_feed || ""}
                  >
                    <div className={"fv_rss_input_warn"}>
                      <small className={"text-left"}>
                        {i18n._("form_profil_rss_feed_warn")}
                      </small>
                    </div>
                    <div className="invalid-feedback">
                      {i18n._("form_profil_rss_feed_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_rss_feed_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

const MDBSwitch = ({ checked, onChange }: any) => (
  <label
    className="switch-theme hover-pointer"
    onClick={onChange}
    htmlFor="customSwitches"
  >
    <input
      onChange={() => null}
      checked={!checked}
      type="checkbox"
      className="hover-pointer"
    />
    <div
      className={`btn slider-theme round ${i18n.lang.substr(0, 2)} mx-0`}
    ></div>
  </label>
);

//<label class="switch">
// <input type="checkbox" id="togBtn">
// <div class="slider round"></div>
// </label>

export default connect(mapStateToProps)(SpaceComponent);
