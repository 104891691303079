import Logo from "./logo.svg";
import LogoLight from "./logo-light.svg";
import Slide2 from "./slide-2.jpg";
import Add from "./add.svg";
import About from "./about.svg";
import GreenMountain from "./mountain-green.svg";
import Business1 from "./business-1.jpg";
import Business2 from "./business-2.jpg";
import Business3 from "./business-3.jpg";
import Fake from "./fake.jpg";
import BandFeatures from "./business";
import MailIcon from "./mail.svg";
import FileIcon from "./icon-file.svg";
import DirectoryIcon from "./icon-directory.svg";
import LegaleShieldIcon from "./shield.svg";
import LegalePdfIcon from "./pdf.svg";
import LegaleFileIcon from "./file.svg";
import LogoBahaus from "./logo-bahaus.png";
import ResetIcon from "./reset.svg";
import OrbImage from "./orb.png";
import CerfTransition from "./fv/cerf-filevert-transition.png";
import FilevertGreen from "./fv/filevert-green.png";
import CerfBrousse from "./fv/cerf-brousse.png";
import ODD6 from "./odd/06.png";
import ODD7 from "./odd/07.png";
import ODD8 from "./odd/08.png";
import ODD9 from "./odd/09.png";
import ODD12 from "./odd/12.png";
import ODD13 from "./odd/13.png";
// import Background1 from "./background/bg-1.avif";
import audioAltImage from "./audio.png";

const images = {
  audioAltImage,
  Logo,
  LogoLight,
  Slide2,
  Add,
  About,
  GreenMountain,
  Business1,
  Business2,
  Business3,
  Fake,
  BandFeatures,
  MailIcon,
  FileIcon,
  DirectoryIcon,
  LegaleShieldIcon,
  LegalePdfIcon,
  LegaleFileIcon,
  LogoBahaus,
  ResetIcon,
  OrbImage,
  CerfTransition,
  FilevertGreen,
  CerfBrousse,
  ODD6,
  ODD7,
  ODD8,
  ODD9,
  ODD12,
  ODD13,
  // Background: { Background1 },
};

export default images;
