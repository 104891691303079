import { AnyAction } from "redux";
import { depositAction } from "../Actions";

const initialState: any = {
  deposits: {},
};

export default function depositReducer(state: any, action: AnyAction) {
  let nextState;

  switch (action.type) {
    case depositAction.ACCEPT_DEPOSIT:
      nextState = {
        ...state,
        deposits: {
          ...state.deposits,
          [action.value.transfer_id]: {
            accepted: action.value.accepted,
            uploader_email: action.value.uploader_email,
          },
        },
      };
      break;

    default:
      nextState = state;
      break;
  }

  return nextState || initialState;
}

export { depositAction };
