import React from "react";
import "./Banner.css";

import { MDBContainer, MDBRow } from "mdbreact";

import { BannerItemComponent } from "./Components";

interface Item {
  faClassName: string;
  i18nKey: string;
}

interface Props {}

interface State {}

class BannerComponent extends React.Component<Props, State> {
  items: Item[];

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.items = [
      {
        faClassName: "fas fa-map-marker-alt fa-3x white-text mb-4",
        i18nKey: "banner_item_map",
      },
      {
        faClassName: "fa fa-hdd white-text fa-3x mb-4",
        i18nKey: "banner_item_hdd",
      },
      {
        faClassName: "fas fa-lock white-text fa-3x amber-text mb-4",
        i18nKey: "banner_item_lock",
      },
      {
        faClassName: "fas fa-user-shield white-text fa-3x green-text mb-4",
        i18nKey: "banner_item_shield",
      },
      {
        faClassName: "fas fa-recycle white-text fa-3x green-text mb-4",
        i18nKey: "banner_item_recycle",
      },
      {
        faClassName: "fas fa-globe-africa white-text fa-3x indigo-text mb-4",
        i18nKey: "banner_item_globe",
      },
    ];
  }

  render = () => {
    return (
      <MDBContainer
        className={"text-white text-lg-left px-md-5 fv_banner_container"}
      >
        <MDBRow>
          {this.items.map((item: Item) => (
            <BannerItemComponent item={item} key={this.items.indexOf(item)} />
          ))}
        </MDBRow>
      </MDBContainer>
    );
  };
}

export default BannerComponent;
