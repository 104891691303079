import React from "react";
import { MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { i18n } from "../../utils";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import convertSize from "../../utils/convertSize";
import { LoaderComponent } from "../index";

export interface IFileLightDetail {
  file_name: string;
  file_size: number;
  downloaded: number;
  is_mine?: boolean;
  is_new?: boolean;
}

function printFileLine(fileObj: any, k: any, fIndex: number): any {
  if (fileObj?.dir_name) {
    let displayedDirName = fileObj?.dir_name;
    try {
      displayedDirName = unescape(displayedDirName);
    } catch (e) {}

    return (
      <div key={`file_${k}`}>
        <ExpansionPanel
          className={`row w-100 px-0 d-block my-1 ${k} ${
            fileObj?.is_mine && "fv-deposit-my-folder fv_deposit_my_folder"
          }`}
          id={`file_${k}`}
        >
          <ExpansionPanelSummary
            className={"p-0"}
            style={{
              height: 40,
              minHeight: 40,
            }}
            expandIcon={<MDBIcon icon={"angle-down"} size={"1x"} />}
          >
            <Typography>
              <MDBIcon icon={"folder"} size={"1x"} />
              <span
                className={
                  "fv-transferdetails-foldername fv_transferdetails_foldername"
                }
              >
                {displayedDirName}
              </span>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={"w-100 p-0"}>
            <div className={"container full fluid w-100 pr-0 pl-4"}>
              {Object.keys(fileObj)
                .sort((k1, k2) =>
                  fileObj[k1] &&
                  fileObj[k1]?.dir_name &&
                  !(fileObj[k2] || !fileObj[k2]?.dir_name)
                    ? -1
                    : 1
                )
                .map((fKey, i) =>
                  printFileLine(fileObj[fKey], `${k}--${fKey}`, i)
                )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }

  if (!fileObj?.file_name || !fileObj?.file_size) return null;

  if (!fileObj?.dir_name) {
    let displayedFileName = fileObj?.file_name;
    try {
      displayedFileName = unescape(displayedFileName);
    } catch (e) {}

    return (
      <div
        className={`row w-100 ${k} ${fIndex % 2 ? "even" : "odd"} ${
          fileObj?.is_mine && "fv-deposit-my-file fv_deposit_my_file"
        } `}
        key={`file_${k}`}
      >
        <div className={"fv-filedetails-filename"}>
          {/*<MDBIcon icon={"file"} size={"1x"}/>*/}
          <span className={"fv-transferdetails-filename filename"}>
            {displayedFileName}
          </span>
        </div>

        <div className={"fv-filedetails-fileinfos"}>
          <span className={"fv-filedetails-fileinfos-filesize"}>
            {convertSize(fileObj.file_size, 2, true)}
          </span>

          <span className={"fv-filedetails-fileinfos-downloaded"}>
            {fileObj.downloaded}
          </span>
        </div>
      </div>
    );
  }
}
function printTransferDetails(files: IFileLightDetail[]) {
  let obj: any = {};

  files.forEach((f) => {
    let item: string = f.file_name;
    let splitItems = item.split("/");
    let current: any = obj;
    let stop = splitItems.length - 1;
    splitItems.forEach((splitItem, i) => {
      if (!current[splitItem]) {
        // if (stop !== i) console.log(splitItem)
        current[splitItem] =
          stop === i
            ? { ...f, file_name: splitItem }
            : {
                is_dir: true,
                is_mine: f?.is_mine,
                dir_name: splitItem,
              };
      }
      current = current[splitItem];
    });
  });

  let keysFromFiles = Object.keys(obj);

  try {
    const mine = keysFromFiles.find((k) => obj[k]?.is_mine);
    keysFromFiles = keysFromFiles.sort((d1, d2) => (d1 === mine ? -1 : 0));
  } catch (e) {
    console.log("Cannot sort this list");
  }

  return keysFromFiles.map((objKey, i) =>
    printFileLine(obj[objKey], objKey, i)
  );
}

export function FVTransferDetailsModal({
  onClose,
  isOpen,
  transfer,
  isLoading,
}: {
  onClose: any;
  isOpen: boolean;
  transfer: any;
  isLoading?: boolean;
}) {
  return (
    <MDBModal
      centered
      className={"text-black fv-transferdetails-modal fv_transferdetails_modal"}
      size={"lg"}
      isOpen={isOpen}
      toggle={onClose}
    >
      <MDBModalHeader
        toggle={onClose}
        className={
          "text-black fv-transferdetails-modal-header fv_transferdetails_modal_header"
        }
      >
        {isLoading && <LoaderComponent size={"small"} type={"Dot"} />}
        {transfer && (
          <>
            {transfer?.transfer_name}
            <br />
            {i18n._("transfer_details_downloaded_label", [
              transfer?.downloaded || 0,
            ])}
          </>
        )}
      </MDBModalHeader>
      <MDBModalBody
        className={
          "fv-transferdetails-modal-body fv_transferdetails_modal_body"
        }
      >
        <div className={"container w-100 px-0"}>
          <div className={"row w-100 px-0"}>
            <div className={"fv-filedetails-filename-header"}>
              {i18n._("transfer_details_header_filename_label")}
            </div>
            <div className={"fv-filedetails-fileinfos-header"}>
              <span className={"fv-filedetails-fileinfos-filesize-header"}>
                {i18n._("transfer_details_header_filesize_label")}
              </span>

              <span className={"fv-filedetails-fileinfos-downloaded-header"}>
                {i18n._("transfer_details_header_filedownloaded_label")}
              </span>
            </div>
          </div>
        </div>

        <div className={"col-12"}></div>

        {isLoading && <LoaderComponent size={"small"} type={"Dot"} />}
        {transfer &&
          transfer?.files?.length &&
          printTransferDetails(transfer?.files)}
      </MDBModalBody>
    </MDBModal>
  );
}
