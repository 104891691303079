import React, { useState } from "react";
import { defaultPermissions } from "../../Constants";
import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCol,
  MDBIcon,
  MDBLink,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from "mdbreact";
import { SliderNumberComponent } from "../SliderNumberComponent";
import {
  convertSize,
  getCurrentUserPriceByPeriod,
  getFinalPrice,
  i18n,
  isOfferDifferent,
} from "../../utils";
import { SwitchButtonComponent } from "../SwitchButtonComponent";
import { CustomOfferCardHeaderComponent } from "./CustomOfferCardHeaderComponent";

import "./CustomOfferStyle.css";
import { UserPermissionDetailComponent } from "../UserPermissionDetailComponent";
import { isMobile } from "../../utils";

const UpdatedComponent = function () {
  const statusKey = "SUCCESS";
  const [waitfor, setWaitfor]: any = useState(5);

  if (waitfor) setTimeout(() => setWaitfor(waitfor - 1), 1000);
  if (waitfor === 0) {
    document.location = "/profil";
    return null;
  }

  return (
    <MDBRow>
      <MDBCol size={"12"}>
        <MDBCard
          style={{ maxWidth: 500 }}
          className={`card-wrapper mx-auto m-5 fv_ordercard`}
        >
          <MDBCardHeader
            className={`font-weight-bold justify-content-center d-flex centered text-center text-green`}
          >
            <MDBIcon
              size={"3x"}
              icon={"check-circle"}
              className={"green-text align"}
            />
            <span className={"p-3"}>
              {i18n._(`CARD_HEADER.ORDER_${statusKey}.LABEL`)}
            </span>
          </MDBCardHeader>
          <MDBCardBody
            className={"p-5 d-flex flex-column justify-content-center"}
            style={{ minHeight: 400 }}
          >
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.INTRO`)}</p>
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.CONTENT`, waitfor)}</p>
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.OUTRO`)}</p>
          </MDBCardBody>
          <MDBCardFooter className={"text-center"}>
            <MDBBtn
              onClick={() => {
                return (document.location = "/profil");
              }}
            >
              {i18n._(`BUTTONS.RELOAD.LABEL`)}
            </MDBBtn>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export function CustomOfferCardComponent({
  isNewUser,
  userCurrentPrice,
  userCurrentPeriod,
  currentPeriod,
  onSubmit,
  currentPrice,
  setCurrentOffer,
  currentOffer,
  user_permissions,
}: {
  onSubmit?: Function;
  currentPrice: number;
  currentPeriod: "year" | "month";
  [rest: string]: any;
  userCurrentPrice?: number;
  isNewUser?: boolean;
}) {
  const [orderStatus, setOrderStatus]: [{ updated: boolean }, any] = useState({
    updated: false,
  });
  const allChecked = !Object.values(currentOffer).some(
    ({ value, label }: any) => !value && label !== "team_size"
  );
  const checkAll = (checked: boolean) =>
    setCurrentOffer(
      Object.keys(currentOffer).reduce(
        (acc: any, k: any) => ({
          ...acc,
          [k]: {
            ...acc[k],
            value:
              typeof defaultPermissions[k].value === "boolean"
                ? checked
                : acc[k].value,
          },
        }),
        currentOffer
      )
    );

  const isTeamSizeDisabled = (upSize?: any, upTtl?: any) =>
    (upSize || currentOffer.upload_size.value) ===
    defaultPermissions.upload_size.value;
  // (upTtl || currentOffer.upload_ttl.value) === defaultPermissions.upload_ttl.value

  const isTeamSizeExcluded = !!(
    user_permissions?.team_size?.value &&
    !defaultPermissions.team_size.options?.includes(
      user_permissions?.team_size?.value
    )
  );

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const beforeSubmit = () => {
    if (confirmationModalOpen || !userCurrentPrice)
      return onSubmit && onSubmit(currentOffer);
    setConfirmationModalOpen(true);
  };

  return (
    <MDBCard className={`card-wrapper mx-auto mb-5 mb-lg-0 fv_offercard`}>
      <MDBCardBody
        style={{ justifyContent: "center" }}
        className={`fv_offer_card_boody ${isMobile && "mobile"}`}
      >
        <div
          className={`fv-offer-main-wrapper fv_offer_main_wrapper ${
            isMobile && "mobile"
          }`}
        >
          <CustomOfferCardHeaderComponent
            setCurrentOffer={setCurrentOffer}
            currentOffer={currentOffer}
            price={currentPrice}
            slogan={"TOTO"}
            currentPeriod={currentPeriod}
          />

          {user_permissions ? (
            <>
              {isTeamSizeExcluded && (
                <MDBRow>
                  <MDBCol size={"12"}>
                    <MDBAlert className={"d-flex"} dismiss color={"warning"}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: i18n._("OLD_OFFER_NOT_AVAILABLE"),
                        }}
                      />
                    </MDBAlert>
                  </MDBCol>
                </MDBRow>
              )}
              <MDBRow
                className={`${
                  (!isOfferDifferent([user_permissions, currentOffer]) || isNewUser) &&
                  "d-none"
                }`}
              >
                {orderStatus?.updated && (
                  <MDBModal isOpen>
                    <MDBModalBody>
                      <UpdatedComponent />
                    </MDBModalBody>
                  </MDBModal>
                )}

                <MDBCol size={"5"}>
                  <hr />
                </MDBCol>
                <MDBCol size={"2"} className={"text-center text-primary"}>
                  {!orderStatus?.updated && (
                    <>
                      {confirmationModalOpen ? (
                        <UserPermissionDetailComponent
                          onClose={() => setConfirmationModalOpen(false)}
                          opened={confirmationModalOpen}
                          confirmation={confirmationModalOpen}
                          title={i18n._("MODAL_HEADER.OFFER_COMPARISON.LABEL")}
                          onSubmit={
                            onSubmit
                              ? async () => {
                                  try {
                                    const result = await onSubmit(currentOffer);
                                    setConfirmationModalOpen(false);
                                    setOrderStatus({ updated: true });
                                    return result;
                                  } catch (e) {}
                                }
                              : undefined
                          }
                          info={
                            !!userCurrentPrice && {
                              color: "primary",
                              message: i18n._(
                                currentPrice > userCurrentPrice
                                  ? "MODAL_HEADER.OFFER_COMPARISON.AVAILABILITY.NOW.LABEL"
                                  : "MODAL_HEADER.OFFER_COMPARISON.AVAILABILITY.NEXT_INVOICE.LABEL"
                              ),
                            }
                          }
                          offers={[
                            getOfferPayload(
                              currentOffer,
                              1,
                              getFinalPrice(currentPeriod, currentPrice),
                              i18n._("CARD_HEADER.NEW_OFFER.LABEL")
                            ),
                          ]}
                        />
                      ) : (
                        <UserPermissionDetailComponent
                          onClose={() => setConfirmationModalOpen(false)}
                          title={i18n._("MODAL_HEADER.OFFER_COMPARISON.LABEL")}
                          onSubmit={
                            onSubmit
                              ? async () => {
                                  try {
                                    const result = await onSubmit(currentOffer);
                                    setConfirmationModalOpen(false);
                                    setOrderStatus({ updated: true });
                                    return result;
                                  } catch (e) {}
                                }
                              : undefined
                          }
                          offers={
                            [
                                  getOfferPayload(
                                    user_permissions,
                                    0,
                                    getCurrentUserPriceByPeriod(
                                      userCurrentPeriod,
                                      currentPeriod,
                                      userCurrentPrice
                                    ),
                                    i18n._("CARD_HEADER.CURRENT_OFFER.LABEL")
                                  ),
                                  getOfferPayload(
                                    currentOffer,
                                    1,
                                    getFinalPrice(currentPeriod, currentPrice),
                                    i18n._("CARD_HEADER.NEW_OFFER.LABEL")
                                  ),
                                ]
                          }
                        />
                      )}
                    </>
                  )}
                </MDBCol>
                <MDBCol size={"5"}>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow
                className={`${
                  isOfferDifferent([user_permissions, currentOffer]) && "d-none"
                }`}
              >
                <MDBCol size={"12"}>
                  <hr />
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            <MDBRow>
              <MDBCol size={"12"}>
                <hr />
              </MDBCol>
            </MDBRow>
          )}

          <MDBRow>
            <MDBCol size={"6"} className={"text-center"}>
              <SliderNumberComponent
                key={`${currentOffer.upload_size.label}_upload_size`}
                options={currentOffer.upload_size.options}
                id={`${currentOffer.upload_size.label}`}
                label={convertSize(currentOffer.upload_size.value, 0)}
                setValue={(value) => {
                  setCurrentOffer({
                    ...currentOffer,
                    upload_size: {
                      ...currentOffer.upload_size,
                      value,
                    },
                    team_size: {
                      ...currentOffer.team_size,
                      value: isTeamSizeDisabled(value, null)
                        ? 0
                        : currentOffer.team_size.value,
                    },
                  });
                }}
                value={currentOffer.upload_size.value}
              />
              <div className={"mt-2"}>
                <small className={"font-weight-bold mt-1"}>
                  {i18n._("offer_transfer_limitation_label_1")}
                </small>
              </div>
            </MDBCol>
            <MDBCol size={"6"} className={"text-center"}>
              <SliderNumberComponent
                key={`${currentOffer.upload_ttl.label}_upload_ttl`}
                options={currentOffer.upload_ttl.options}
                id={`${currentOffer.upload_ttl.label}`}
                label={i18n._("qty_upload_ttl_label", [
                  currentOffer.upload_ttl.value,
                ])}
                setValue={(value) => {
                  setCurrentOffer({
                    ...currentOffer,
                    upload_ttl: {
                      ...currentOffer.upload_ttl,
                      value,
                    },
                    team_size: {
                      ...currentOffer.team_size,
                      value: isTeamSizeDisabled(null, value)
                        ? 0
                        : currentOffer.team_size.value,
                    },
                  });
                }}
                value={currentOffer.upload_ttl.value}
              />
              <div className={`mt-2`}>
                <small className={"font-weight-bold"}>
                  {i18n._("offer_transfer_limitation_label_3")}
                </small>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <SliderNumberComponent
                disabled={isTeamSizeDisabled()}
                options={currentOffer.team_size.options}
                id={`${currentOffer.team_size.label}`}
                label={
                  currentOffer.team_size.value > 0
                    ? i18n._(`qty_team_size_label`, [
                        Number(currentOffer.team_size.value),
                      ])
                    : `${i18n._("how_many_label")} ${i18n
                        ._("subscription_cell_users")
                        .toLocaleLowerCase()}`
                }
                setValue={(value) => {
                  setCurrentOffer({
                    ...currentOffer,
                    team_size: {
                      ...currentOffer.team_size,
                      value,
                    },
                  });
                }}
                value={currentOffer.team_size.value}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className={"mt-2"}>
            <MDBCol size={"12"} className={"text-center"}>
              <small
                className={`${
                  isTeamSizeDisabled() && "hidden"
                } font-weight-bold`}
              >
                {/*{i18n._(*/}
                {/*  "offer_transfer_limitation_label_2",*/}
                {/*  getCumulatedSize(currentOffer)*/}
                {/*)}*/}

                {currentOffer.team_size.value > 0 && (
                  <>
                    <br />+ {i18n._("download_team_locked_title")}
                  </>
                )}
              </small>
            </MDBCol>
          </MDBRow>
          <hr />
        </div>
        <div
          className={`fv-offer-options-wrapper fv_offer_options_wrapper ${
            isMobile && "mobile"
          }`}
        >
          <SwitchButtonComponent
            id={"all"}
            label={`${i18n._("select_all_label")}`}
            onSwitch={checkAll}
            checked={allChecked}
          />
          <hr />
          {Object.keys(currentOffer).map((k) =>
            !hasSwitch(currentOffer[k]) || k === "team_size" ? null : (
              <SwitchButtonComponent
                key={`${currentOffer[k].label}_${k}`}
                id={k}
                label={i18n._(`select_${k}_label`)}
                onSwitch={(checked: boolean) =>
                  setCurrentOffer({
                    ...currentOffer,
                    [k]: { ...currentOffer[k], value: checked },
                  })
                }
                checked={allChecked || currentOffer[k].value !== false}
                infos={i18n._(`select_${k}_tooltip_label`)}
              >
                {currentOffer[k].optional &&
                  currentOffer[k].options &&
                  currentOffer[k].value !== false && (
                    <>
                      <hr className={"my-4"} />
                      <SliderNumberComponent
                        options={currentOffer[k].options}
                        id={`${currentOffer[k].label}`}
                        label={i18n._(`qty_${k}_label`, [
                          Number(
                            currentOffer[k].value === true
                              ? 0
                              : currentOffer[k].value
                          ) || i18n._("how_many_label"),
                        ])}
                        setValue={(value) => {
                          setCurrentOffer({
                            ...currentOffer,
                            [k]: {
                              ...currentOffer[k],
                              value,
                            },
                          });
                        }}
                        value={currentOffer[k].value}
                      />
                      <hr className={"my-4"} />
                    </>
                  )}
              </SwitchButtonComponent>
            )
          )}

          {currentPrice ? (
            <MDBBtn
              disabled={!isOfferDifferent([user_permissions, currentOffer])}
              onClick={onSubmit ? () => beforeSubmit() : undefined}
              className={`btn btn-block btn-primary text-uppercase waves-effect waves-light my-3 pricing-cta fv_pricing_cta_btn ${
                !isOfferDifferent([user_permissions, currentOffer]) &&
                "disabled hover-disabled"
              }`}
            >
              {!userCurrentPrice && (
                <div className={"fv_btn_text_wrapper"}>
                  {i18n._(`offer_cta_try_label`)}
                  <br />
                  <small className={"text-lowercase fv_price_try_label"}>
                    {i18n._("offer_cta_try_price_label", [
                      getFinalPrice(currentPeriod, currentPrice),
                    ])}
                    €{i18n._(`offer_${currentPeriod}lyPricePeriod_label`)}
                    &nbsp;
                    {i18n._("price_ht_label")}
                    &nbsp;
                    {i18n._("cta_try_members_label", [
                      Number(currentOffer.team_size?.value) + 1,
                    ])}
                  </small>
                </div>
              )}

              {!!userCurrentPrice && (
                <div className={"fv_btn_text_wrapper"}>
                  <small className={"text-lowercase fv_price_try_label"}>
                    {getFinalPrice(currentPeriod, currentPrice)}€
                    {i18n._(`offer_${currentPeriod}lyPricePeriod_label`)}
                    &nbsp;
                    {i18n._("price_ht_label")}
                    &nbsp;
                    {i18n._("cta_try_members_label", [
                      Number(currentOffer.team_size?.value) + 1,
                    ])}
                  </small>
                </div>
              )}
            </MDBBtn>
          ) : (
            <MDBLink
              to={`/`}
              className={
                "btn btn-block btn-primary text-uppercase waves-effect waves-light my-3 pricing-cta"
              }
            >
              {i18n._(`footer_services_transfer_link_label`)}
            </MDBLink>
          )}
        </div>
      </MDBCardBody>
    </MDBCard>
  );
}

const getOfferPayload = (
  offer: any,
  order: number,
  price: number,
  label?: string
) => ({ ...offer, order, price, label });

function hasSwitch(item: any): boolean {
  return typeof item.value === "boolean" || item.optional;
}
