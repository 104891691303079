import React from "react";
import "./Tab.css";

import {
  AccountComponent,
  ActivityComponent,
  CollabComponent,
  ReceiveComponent,
  SendComponent,
  SubscriptionComponent,
} from "./Components";
import { i18n } from "../../utils";

interface Props {
  type: string;
  changeTab?: any;
}

interface State {}

const ACTIVITY = "activity";
const SEND = "send";
const RECEIVE = "receive";
const COLLAB = "collab";
const SUBSCRIPTION = "subscription";
const ACCOUNT = "account";

class TabComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.tab = null;
    this.initializeTab();
  }

  initializeTab = () => {
    switch (this.props.type) {
      case ACTIVITY:
        this.tab = (
          <ActivityComponent
            changeTab={this.props.changeTab}
            title={i18n._(`profil_${ACTIVITY}_title`)}
          />
        );
        break;

      case ACCOUNT:
        this.tab = (
          <AccountComponent title={i18n._(`profil_${ACCOUNT}_title`)} />
        );
        break;

      case RECEIVE:
        this.tab = (
          <ReceiveComponent title={i18n._(`profil_${RECEIVE}_title`)} />
        );
        break;

      case SEND:
        this.tab = <SendComponent title={i18n._(`profil_${SEND}_title`)} />;
        break;

      case COLLAB:
        this.tab = <CollabComponent title={i18n._(`profil_${COLLAB}_title`)} />;
        break;

      case SUBSCRIPTION:
        this.tab = (
          <SubscriptionComponent
            title={i18n._(`profil_${SUBSCRIPTION}_title`)}
          />
        );
        break;

      default:
        break;
    }
  };

  render = () => this.tab;
}

export default TabComponent;
