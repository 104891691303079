import React from "react";
import "./Reason.css";
import { i18n } from "../../utils";
import { MDBCol, MDBCollapse, MDBLink, MDBRow } from "mdbreact";
import { isMobile } from "../../utils";

interface Props {
  nb: number;
}

interface State {
  manifest_1: string;
  manifest_2: string;
  manifest_3: string;
  manifest_4: string;
  manifest_5: string;
  manifest_6: string;
}

class ReasonComponent extends React.Component<Props, State & any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      manifest_1: "",
      manifest_2: "",
      manifest_3: "",
      manifest_4: "",
      manifest_5: "",
      manifest_6: "",
    };
  }

  toggleCollapse = (collapseID: string) => {
    this.setState((prevState: any) => ({
      [collapseID]: prevState[collapseID] !== collapseID ? collapseID : "",
    }));
  };

  renderLink = () => {
    if (!i18n._(`about_reasons_link_text_${this.props.nb}`)) {
      return null;
    }

    return (
      <MDBLink
        className={
          "waves-effect waves-light bold js-scroll-trigger blue-text collapsed manifest link"
        }
        to={i18n._(`about_reasons_link_url_${this.props.nb}`)}
      >
        {i18n._(`about_reasons_link_text_${this.props.nb}`)}
      </MDBLink>
    );
  };

  renderAnchor = () => {
    if (!i18n._(`about_reasons_manifest_anchor_text_${this.props.nb}`))
      return null;

    return (
      <MDBLink
        className={`waves-effect waves-light bold blue-text manifest link ${
          isMobile ? "mx-auto px-0" : ""
        }`}
        onClick={() => this.toggleCollapse(`manifest_${this.props.nb}`)}
        to={`#manifest_${this.props.nb}`}
      >
        {i18n._(`about_reasons_manifest_anchor_text_${this.props.nb}`)}
      </MDBLink>
    );
  };

  renderManifest = () => {
    if (!i18n._(`about_reasons_manifest_${this.props.nb}`)) return null;

    return (
      <MDBCollapse
        isOpen={this.state[`manifest_${this.props.nb}`]}
        id={`manifest_${this.props.nb}`}
        className={"manifest reason wrapper card mt-4"}
        dangerouslySetInnerHTML={{
          __html: i18n._(`about_reasons_manifest_${this.props.nb}`),
        }}
      />
    );
  };

  render = () => {
    const textAlign = isMobile ? "text-center" : "text-left";

    return (
      <MDBRow className={`mb-5 ${textAlign} px-2 fv_reason_row`}>
        <MDBCol
          xs={"12"}
          sm={"12"}
          md={"12"}
          lg={"2"}
          xl={"2"}
          className={"nb-big-green"}
        >
          {this.props.nb}
        </MDBCol>
        <MDBCol xs={"12"} sm={"12"} md={"12"} lg={"10"} xl={"10"}>
          <h3 className={"font-weight-bold mb-3"}>
            {i18n._(`about_reasons_title_${this.props.nb}`)}
          </h3>
          <p className={`black-text ${textAlign} no-padding`}>
            {i18n._(`about_reasons_text_${this.props.nb}`)}
            <br />
          </p>

          <div className={isMobile ? "text-center" : "flex"}>
            {this.renderAnchor()}
            {this.renderLink()}
          </div>

          {this.renderManifest()}
        </MDBCol>
      </MDBRow>
    );
  };
}

export default ReasonComponent;
