import React, { useState } from "react";
import "./History.css";

import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";

import { config } from "../../../../../config";

import { transferApi } from "../../../../../Api";

import { i18n, wrapStr } from "../../../../../utils";
import convertSize from "../../../../../utils/convertSize";
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTooltip } from "mdbreact";
import LoaderComponent from "../../../../Loader/Loader";
import TooltipComponent from "../../../../Tooltip/Tooltip";
import { CopyButtonComponent } from "../../../../Button";
import { FVTransferDetailsModal } from "../../../../FVModal";

interface Props {
  type: "upload" | "receive" | "collab";
  user: any;
}
interface State {
  history: any[];
  isLoading: boolean;
  page: number;
  rowsPerPage: number;
  numSelected: number;
}
interface History {
  name: string;
  date: string;
  time: string;
  transfer_status: number;
}

/*
 * status
 * -1: Waiting invitation
 * 0: Uploaded file
 * 1: Downloaded file
 * 2: Expired / Removed file but still visible
 * 3: Invisible
 * */

const maxLength = 30;

const statusKey: any = {
  "-2": "aborted",
  "-1": "waiting",
  "0": "uploaded",
  "1": "downloaded",
  "2": "expired",
};

const statusCollabKey: any = {
  "-1": "wip",
  "0": "validated",
  "1": "downloaded",
  "2": "expired",
};

const historyTab = [
  "tab_history_sender_label",
  "tab_history_recipients_label",
  "tab_history_name_label",
  "tab_history_transfer_expiration_label",
  "tab_history_transfer_date_label",
  "tab_history_status_label",
  "tab_history_copy_label",
];

const historyCollabTab = [
  "tab_history_sender_label",
  "tab_history_contributors_label",
  "tab_history_name_label",
  "tab_history_transfer_expiration_label",
  "tab_history_transfer_date_label",
  "tab_history_status_label",
  "tab_history_copy_label",
];

const TableLine = ({ item, onSelect, lineNumber }: any) => {
  let isFromOutlook: boolean = false;
  let isCollab: boolean = false;
  const [transferDetails, setTransferDetails]: any = useState({});
  const [transferModalOpen, setTransferModalOpen]: any = useState(false);
  const [displayedTransfer, setDisplayedTransfer]: any = useState("");
  const [transferDetailsLoading, setTransferDetailsLoading]: any =
    useState(false);

  const formatDate = (date: Date) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();

    return `${d < 10 ? "0" + d : d}/${m + 1 < 10 ? "0" + (m + 1) : m + 1}/${y}`;
  };
  const formatTime = (date: Date) => {
    const h = date.getHours();
    const m = date.getMinutes();
    const s = date.getSeconds();

    return `${h < 10 ? "0" + h : h}:${m + 1 < 10 ? "0" + (m + 1) : m + 1}:${
      s + 1 < 10 ? "0" + (s + 1) : s + 1
    }`;
  };

  let {
    transfer_name,
    transfer_id,
    date_transfer,
    expiration_transfer,
    transfer_status,
    isSelected,
    transferLocked,
    recipients,
    transfer_provider,
    for_team,
    downloaded,
    nb_files,
    transfer_type,
  } = item;

  try {
    transfer_name = unescape(transfer_name);
  } catch (e) {}

  const now = Date.now();

  if (transfer_provider && transfer_provider === "FV_OUTLOOK") {
    isFromOutlook = true;
  }
  if (transfer_provider && transfer_provider === "FV_STUDIO") {
    isCollab = true;
  }

  if (recipients) {
    try {
      recipients = recipients?.join ? recipients : JSON.parse(recipients);
      if (recipients && recipients.join) recipients = recipients.join(", ");
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.warn(e);
    }
  }

  const dT = new Date(date_transfer);
  const eT = expiration_transfer ? new Date(expiration_transfer) : null;

  transfer_status = eT && eT.getTime() < now ? 2 : transfer_status;

  const isTransferDisabled =
    (transfer_status === -1 && !isCollab) || transfer_status === -2;

  const getTransferLink = () => {
    let path = "u";
    const fNb = Number(nb_files);
    if (eT || fNb) path = "d";
    if (transfer_type === 3 && !fNb) path = "deposit";
    if (isCollab && transfer_status <= 1) path = "collab";

    return `${config.ihmUrl}/${path}/${transfer_id}`;
  };

  return (
    <>
      <TableRow
        className={`${
          lineNumber % 2
            ? "odd fv_history_line_odd"
            : "even fv_history_line_even"
        } ${isTransferDisabled && "disabled"}`}
      >
        <TableCell padding={"checkbox"}>
          <FVTransferDetailsModal
            isLoading={transferDetailsLoading}
            onClose={() => {
              setDisplayedTransfer("");
              setTransferModalOpen(false);
            }}
            isOpen={transferModalOpen}
            transfer={transferDetails[displayedTransfer]}
          />
          <Checkbox
            disabled={transfer_status > 2}
            checked={isSelected && transfer_status < 3}
            onChange={() => onSelect(!isSelected, item)}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        <TableCell padding={"none"} className={"text-left"}>
          {transfer_type === 3 && (
            <MDBTooltip domElement placement={"top"}>
              <i className="fa-sharp fa-solid fa-circle-d"></i>
              <span>Ceci est un dépôt</span>
            </MDBTooltip>
          )}

          {item?.transfer_sender}
        </TableCell>
        <TableCell padding={"none"} className={"text-left"}>
          {typeof recipients === "string" && wrapStr(recipients, maxLength)}
          {typeof recipients === "string" && recipients.length > maxLength && (
            <TooltipComponent
              className={"hover-pointer d-inline"}
              placement={"right"}
              text={recipients}
            />
          )}
        </TableCell>
        <TableCell className={"text-left"} padding={"none"}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <a rel={"noreferrer"} href={getTransferLink()} target={"_blank"}>
                {isFromOutlook && (
                  <MDBIcon icon={"envelope"} size={"1x"} className={"mr-2"} />
                )}

                {for_team && (
                  <MDBIcon icon={"user-shield"} className={"mr-2"} />
                )}

                <span className={"mr-2"}>
                  {transfer_type === 3
                    ? `D - ${transfer_id}`
                    : wrapStr(transfer_name, maxLength)}
                </span>

                {nb_files && (
                  <>
                    <br />
                    <span>
                      ({nb_files}{" "}
                      {i18n._(
                        isCollab
                          ? "history_versions_label"
                          : "history_files_label"
                      )}{" "}
                      {convertSize(item.transfer_size)})
                    </span>
                  </>
                )}
              </a>
              {transferLocked && (
                <MDBIcon icon={"lock"} className={"float-right mx-auto"} />
              )}
            </div>
            <div
              className={"hover-pointer"}
              onClick={async () => {
                setDisplayedTransfer(transfer_id);
                setTransferModalOpen(true);
                if (!transferDetails[transfer_id]) {
                  try {
                    setTransferDetailsLoading(true);
                    const transferDetailsResult = await transferApi.getTransfer(
                      transfer_id,
                      undefined,
                      undefined,
                      true
                    );
                    setTransferDetails({
                      ...transferDetails,
                      [transfer_id]: transferDetailsResult,
                    });
                  } catch (e) {
                  } finally {
                    setTransferDetailsLoading(false);
                  }
                }
              }}
            >
              <TooltipComponent
                className={"d-inline"}
                placement={"right"}
                text={transfer_name}
              />
            </div>
          </div>
        </TableCell>
        <TableCell className={"text-left"}>
          {formatDate(dT)} - {formatTime(new Date(dT))}
        </TableCell>
        <TableCell className={"text-left"}>
          {eT && formatDate(eT)} - {eT && formatTime(new Date(eT))} {!eT && ""}
        </TableCell>
        <TableCell className={"text-left"}>
          {i18n._(
            `status_${(() => {
              const sKeys = isCollab ? statusCollabKey : statusKey;
              return sKeys[transfer_status]
                ? sKeys[transfer_status]
                : "expired";
            })()}_label`
          )}
          {!isCollab && <>&nbsp; ({`${downloaded || 0}`})</>}
        </TableCell>

        <TableCell className={"text-center"}>
          <CopyButtonComponent dataToCopy={getTransferLink()} />
        </TableCell>
      </TableRow>
    </>
  );
};

class HistoryComponent extends React.Component<Props, State> {
  isCollab: boolean = false;

  constructor(props: Props) {
    super(props);
    this.isCollab = this.props.type === "collab";
    this.state = {
      history: [],
      isLoading: false,
      page: 0,
      rowsPerPage: 10,
      numSelected: 0,
    };
  }

  componentDidMount = async () => {
    if (this.props.type === "upload") {
      return this.getUploadHistory();
    }

    if (this.props.type === "collab") {
      return this.getCollabHistory();
    }

    return this.getReceiveHistory();
  };

  getCollabHistory = async () => {
    this.setState({ isLoading: true });
    let history: any[] = await transferApi.getAllUserTransfer(
      this.props.user.id
    );
    history.forEach((line: any) => {
      line.isSelected = false;
    });

    history = history.filter(
      (line: any) => line.transfer_provider === "FV_STUDIO"
    );

    this.setState({ history, isLoading: false, numSelected: 0 });
  };

  getUploadHistory = async () => {
    this.setState({ isLoading: true });
    let history: any[] = await transferApi.getAllUserTransfer(
      this.props.user.id
    );
    history.forEach((line: any) => {
      line.isSelected = false;
    });

    history = history.filter(
      (line: any) =>
        line.transfer_type === 0 && line.transfer_provider !== "FV_STUDIO"
    );

    this.setState({ history, isLoading: false, numSelected: 0 });
  };
  getReceiveHistory = async () => {
    this.setState({ isLoading: true });
    let history: any[] = await transferApi.getAllUserTransfer(
      this.props.user.id
    );
    history.forEach((line: any) => {
      line.isSelected = false;
    });

    history = history.filter((line: any) =>
      [1, 3].includes(line.transfer_type)
    );
    this.setState({ history, isLoading: false, numSelected: 0 });
  };

  handleChangePage = (event: unknown, page: number) => {
    this.setState({ ...this.state, page });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleSelect = (isSelected: boolean, item: any) => {
    let { history, numSelected } = this.state;

    numSelected = isSelected ? numSelected + 1 : numSelected - 1;

    history.forEach((line: any, i: number) => {
      if (line === item) history[i].isSelected = isSelected;
    });

    this.setState({ ...this.state, numSelected, history });
  };

  getActiveHistory = () =>
    this.state.history.filter((el: any) => el.transfer_status < 3);

  handleSelectAll = () => {
    let { numSelected, history } = this.state;

    numSelected =
      numSelected < this.getActiveHistory().length
        ? this.getActiveHistory().length
        : 0;

    history.forEach(
      (line: any) =>
        (line.isSelected = !!numSelected && line.transfer_status < 3)
    );

    this.setState({ ...this.state, numSelected, history });
  };

  handleDelete = async () => {
    let selectedItems = this.state.history.filter(
      (item: any) => item.isSelected
    );
    await transferApi.deleteManyTransfer(
      selectedItems.map(({ transfer_id }) => transfer_id)
    );
    this.componentDidMount();
  };

  renderTableBody = (history: any[]) => {
    if (this.state.isLoading) {
      return (
        <TableBody className={"w-100 h-100 position-absolute bg-transparent"}>
          <TableRow
            className={
              "text-center w-100 h-100 position-absolute pb-5 bg-transparent"
            }
          >
            <TableCell
              className={"text-center w-100 h-100 position-absolute pb-3"}
            >
              <LoaderComponent size={"small"} />
              <p>{i18n._("loading_label")}</p>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (!history.length) {
      return (
        <TableBody className={"w-100 h-100 position-absolute bg-transparent"}>
          <TableRow
            className={
              "text-center w-100 h-100 position-absolute pb-5 bg-transparent"
            }
          >
            <TableCell
              className={"text-center w-100 h-100 position-absolute pb-3"}
            >
              <p>{i18n._("no_transfer_label")}</p>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {history.map((el: History, k: number) => (
          <TableLine
            key={k}
            item={el}
            lineNumber={k}
            onSelect={this.handleSelect}
          />
        ))}
      </TableBody>
    );
  };

  render = () => {
    const { history, page, rowsPerPage, numSelected } = this.state;
    const rowsPerPageOptions = [10, 25, 50, 100, 250];

    return (
      <MDBContainer className={"px-0 fv_history_table_container"}>
        <MDBRow>
          <MDBCol size={"12"}>
            <Table className={"mt-3"}>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding={"checkbox"}
                    style={{ borderBottom: "none" }}
                    className={"pl-0 position-absolute"}
                  >
                    {numSelected > 0 && (
                      <MDBIcon
                        onClick={this.handleDelete}
                        icon={"trash"}
                        size={"2x"}
                        className={
                          "d-flex float-left m-3 ml-4 hover-pointer MuiCheckbox-colorSecondary-124 MuiCheckbox-checked-120"
                        }
                      />
                    )}
                    {numSelected > 0 && (
                      <span
                        className={
                          "mt-4 float-left d-flex MuiCheckbox-colorSecondary-124 MuiCheckbox-checked-120"
                        }
                        onClick={() => false}
                      >
                        {i18n._("transfer_deletion_label", numSelected)}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding={"checkbox"}>
                    <Checkbox
                      indeterminate={
                        numSelected > 0 &&
                        numSelected < this.getActiveHistory().length
                      }
                      checked={
                        this.getActiveHistory().length > 0 &&
                        numSelected === this.getActiveHistory().length
                      }
                      onChange={this.handleSelectAll}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {(this.isCollab ? historyCollabTab : historyTab).map(
                    (tab: string, k: number) => (
                      <TableCell
                        key={k}
                        className={"text-left font-weight-bold"}
                      >
                        {i18n._(tab)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>

              {this.renderTableBody(
                history.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              )}
            </Table>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size={"12"}>
            {history.length > 0 && (
              <TablePagination
                labelRowsPerPage={i18n._("rows_per_page_label")}
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={history.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(HistoryComponent);
