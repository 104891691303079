import { appAction } from "../Actions";
import { AnyAction } from "redux";

const initialState: any = {};

export default function appReducer(state: any, action: AnyAction & any): any {
  let nextState: any;

  switch (action.type) {
    case appAction.SHOW_LIGHT_HEADER:
    case appAction.SHOW_CUSTOM_HEADER:
    case appAction.HIDE_HEADER:
    case appAction.RESET_PASSWORD:
    case appAction.SAVE_PASSWORD:
    case appAction.LANG:
    case appAction.BACKGROUND:
    case appAction.COOKIES:
    case appAction.I18N_READY:
    case appAction.USER_UPLOAD_FAVORITE_METHOD:
    case appAction.APP_ERROR:
      nextState = { ...state, [(appAction as any)[action.type]]: action.value };
      break;

    default:
      nextState = state;
      break;
  }

  return nextState || initialState;
}

export { appAction };
