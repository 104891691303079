export interface IOfferLabelAndColor {
  label: string;
  color: string;
  bg_color: string;
  slogan: string;
}

export const offerLabelsAndColors: IOfferLabelAndColor[] = [
  "custom",
  "solo",
  "pro",
  "studio",
].map((k: string) => ({
  label: `offer_${k}_label`,
  bg_color: `offer_${k}_bg_color`,
  color: `offer_${k}_color`,
  slogan: `offer_${k}_slogan`,
}));
