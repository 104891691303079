import React, { useEffect, useState } from "react";
import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { convertSize, i18n } from "../utils";
import { defaultPermissions } from "../Constants";

export function UserPermissionDetailComponent({
  offers,
  onSubmit,
  title,
  opened,
  onClose,
  info,
}: {
  offers: any[];
  onSubmit?: any;
  title?: string;
  opened?: boolean;
  confirmation?: boolean;
  onClose?: Function;
  info?: { message?: string; color?: string | any } | false;
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isOneOffer = offers.length === 1;

  useEffect(() => {
    if (opened && !modalOpened) setModalOpened(opened);
    if (!mounted) setMounted(true);
  }, [opened, modalOpened, mounted]);

  const triggerModal = (state: boolean) => {
    setModalOpened(!modalOpened);
    onClose && onClose();
  };

  return (
    <>
      <MDBIcon
        onClick={() => triggerModal(!modalOpened)}
        className={`hover-pointer fv_info_offer_icon`}
        icon={"info-circle"}
        size={"2x"}
      />
      <MDBModal
        size={"lg"}
        modalClassName={"w-100"}
        className={"text-dark fv_info_offer_comparison_modal"}
        toggle={() => triggerModal(false)}
        isOpen={modalOpened}
      >
        <MDBModalHeader
          className={"fv_info_offer_comparison_modal_header"}
          toggle={() => triggerModal(false)}
        >
          {title}
        </MDBModalHeader>
        <MDBModalBody className={"fv_info_offer_comparison_modal_body"}>
          {!!info && (
            <MDBAlert className={"m-3"} dismiss color={info?.color}>
              {info?.message}
            </MDBAlert>
          )}
          <div className={"d-flex fv_info_offer_comparison_modal_body_offers"}>
            {offers.map((offer: any, k: number) => (
              <div
                onScroll={(e) => {}}
                className={"w-100 m-3"}
                key={`offer_comparison_${k}`}
              >
                <MDBCard>
                  <MDBCardHeader>{offer.label}</MDBCardHeader>
                  <MDBCardBody className={"px-0"}>
                    {Object.keys(defaultPermissions).map((perm, i) => {
                      const prefixLabel = defaultPermissions[perm]?.options
                        ? "qty_"
                        : "select_";

                      const isDifferent =
                        (!isOneOffer &&
                          !!k &&
                          offer[perm]?.value !== offers[0][perm]?.value) ||
                        (!isOneOffer &&
                          !k &&
                          offers[1] &&
                          offers[1][perm] &&
                          offer[perm]?.value !== offers[1][perm]?.value);
                      const moreClass =
                        !isOneOffer &&
                        offers[1][perm]?.value !== false &&
                        offers[1][perm]?.value > offers[0][perm]?.value
                          ? "more"
                          : "less";
                      const displayedValue =
                        perm === "upload_size"
                          ? convertSize(offer[perm]?.value, 0, false)
                          : offer[perm]?.value;

                      const labelKey = `${prefixLabel}${defaultPermissions[perm]?.label}_label`;

                      return (
                        <span key={`comparison_hr_${i}`}>
                          {!!i && <hr className={"my-0"} />}
                          <div
                            className={`p-2 text-left ${
                              isDifferent && "fv-bg-different fv_bg_different "
                            } ${moreClass} ${
                              offer[perm]?.value === false && "not-available"
                            }`}
                          >
                            <span>
                              {i18n._(labelKey, `${displayedValue || 0}`)}
                            </span>
                          </div>
                        </span>
                      );
                    })}
                  </MDBCardBody>
                  <MDBCardFooter>{offer.price}€</MDBCardFooter>
                </MDBCard>
              </div>
            ))}
          </div>
        </MDBModalBody>
        <MDBModalFooter
          className={
            "justify-content-center fv_info_offer_comparison_modal_footer"
          }
        >
          {onSubmit && (
            <MDBBtn onClick={onSubmit}>{i18n._("btn_valid_label")}</MDBBtn>
          )}
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
