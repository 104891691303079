import React from "react";

export interface ISliderNumberComponentProps {
  value?: number;
  label?: string;
  options: number[];
  setValue?: (value: number) => void;
  id: string;
  min?: number;
  max?: number;
  displayValue?: boolean;
  [rest: string]: any;
}

const valueStyle = {
  fontWeight: 600,
  fontSize: "1.5em",
  width: "auto",
  height: "50px",
  lineHeight: "60px",
  margin: "15px 0px",
  letterSpacing: "-.07em",
  textShadow: "white 2px 2px 2px",
  marginTop: 0,
};

export function SliderNumberComponent({
  displayValue,
  label,
  value,
  options,
  setValue,
  id,
  min,
  max,
  ...rest
}: ISliderNumberComponentProps) {
  const rangeValues = options.reduce(
    (acc: any, opt, i) => ({ ...acc, [opt]: i }),
    {}
  );
  const rangeMap = options.reduce(
    (acc: any, opt, i) => ({ ...acc, [i]: opt }),
    {}
  );

  return (
    <>
      <label
        aria-disabled={rest?.disabled}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }}
        className={"d-block font-weight-important text-center"}
        style={valueStyle}
        htmlFor={id}
      >
        {displayValue && value}
        {label}
      </label>
      <input
        disabled={rest?.disabled}
        onChange={({ currentTarget: { valueAsNumber } }) =>
          setValue && setValue(rangeMap[valueAsNumber])
        }
        value={value ? rangeValues[value] || 0 : 0}
        type="range"
        className="custom-range"
        id={id}
        min={min || 0}
        max={max || options.length - 1}
        {...rest}
      />
    </>
  );
}
