import { gradients } from "../Constants";

export function getThemeClass(id: string) {
  let themeClass: any;
  if (id === "custom-0" || id === "custom-1") return id;
  if ("0" === id[0])
    themeClass = gradients.clair.find((gradient) => gradient.id === id);
  else themeClass = gradients.sombre.find((gradient) => gradient.id === id);

  return themeClass?.className || "default";
}

export function getClassName(transferConfig: any) {
  const className =
    !transferConfig && !transferConfig?.config && !transferConfig?.config?.theme
      ? ""
      : getThemeClass(transferConfig?.config?.theme);
  return className;
}

export function getStyle(transferConfig: any, userConfig: any, app: any) {
  let style = {
    backgroundImage:
      !transferConfig ||
      !transferConfig?.config ||
      !transferConfig?.config?.theme
        ? "url(" + app.BACKGROUND + ")"
        : null,
    maxHeight: "100vh",
    overflow: "hidden",
    position: "fixed",
    top: 0,
  };

  if (
    transferConfig?.config?.background &&
    transferConfig?.config?.background?.url
  ) {
    style.backgroundImage =
      "url(" + transferConfig?.config?.background?.url + ")";
  }

  if (userConfig?.background) {
    try {
      const bgUrl = userConfig?.background?.url;
      if (bgUrl && bgUrl.length) style.backgroundImage = `url(${bgUrl})`;
    } catch (e) {}
  }

  return style;
}
