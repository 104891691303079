import React from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTooltip,
} from "mdbreact";
import ChipInput from "material-ui-chip-input";
import { i18n } from "../../../../utils";
import { isEmail } from "react-multi-email";
import { connect } from "react-redux";
import "react-multi-email/style.css";

interface TransferInfosModel {
  transfer_name: string;
  transfer_message: string;
  recipients: string[];
  for_team: boolean;
  auto_remove: boolean;
  auto_send: boolean;
}

interface EmailFormProps {
  dispatch: Function;
  user: any;
  handleChangeEmailForm: any;
  transferInfos: TransferInfosModel;
  disabled?: boolean;
}
interface EmailFormState {
  isFullscreen: boolean;
  recipients: string[];
  isPasswordModalOpen: boolean;
  transfer_name?: string;
  transfer_message?: string;
}

const defaultLimitRecipients: number = 10;

class EmailForm extends React.Component<EmailFormProps, EmailFormState> {
  constructor(props: EmailFormProps) {
    super(props);
    const { transferInfos } = this.props;
    this.state = {
      isFullscreen: false,
      recipients: transferInfos.recipients,
      isPasswordModalOpen: false,
      transfer_name: transferInfos.transfer_name,
      transfer_message: transferInfos.transfer_message,
    };
  }

  closePasswordModal = () => {
    this.setState({ isPasswordModalOpen: false });
  };

  openPasswordModal = () => {
    this.setState({ isPasswordModalOpen: true });
  };

  handleChange = (evt: Event & any) => {
    this.props.handleChangeEmailForm(evt.target.name, evt.target.value);
  };

  submitHandler = (event: any) => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  handleIconClick = () => {
    this.setState({ isFullscreen: !this.state.isFullscreen });
  };

  render = () => {
    const { transferInfos, disabled } = this.props;

    const inputEmail = (
      <MDBRow className={"mx-0"}>
        <MDBCol size={"11"} className={"px-0"}>
          <ChipInput
            className={"fv-multi-email fv_multi_emails no-border-hover"}
            newChipKeyCodes={[32]}
            variant={"outlined"}
            label={
              transferInfos.auto_send
                ? i18n._("form_upload_email_label")
                : `${i18n._("form_upload_recipients_label")}`
            }
            disabled={
              disabled ||
              transferInfos.for_team ||
              transferInfos.auto_send ||
              defaultLimitRecipients <= transferInfos.recipients.length
            }
            value={transferInfos.recipients}
            fullWidth
            onDelete={(email: any) => {
              const recipients = transferInfos.recipients.splice(
                transferInfos.recipients.indexOf(email, 1)
              );
              this.props.handleChangeEmailForm("recipients", recipients);
            }}
            onChange={(recipients: string[]) => {
              this.props.handleChangeEmailForm("recipients", recipients);
            }}
            onBeforeAdd={(value: string): boolean => {
              const isValid = isEmail(value);
              const recipientsField =
                document.querySelector("fieldset + input");

              if (!isValid && recipientsField) {
                // prettier-ignore
                //@ts-ignore
                recipientsField.setCustomValidity('invalid');
                // prettier-ignore
                //@ts-ignore
                recipientsField.classList.add('is-invalid');
                // prettier-ignore
                //@ts-ignore
                recipientsField.classList.remove('is-valid');
              } else if (recipientsField) {
                // prettier-ignore
                //@ts-ignore
                recipientsField.setCustomValidity('');
                // prettier-ignore
                //@ts-ignore
                recipientsField.classList.remove('is-invalid');
                // prettier-ignore
                //@ts-ignore
                recipientsField.classList.add('is-valid');
              }

              return isValid;
            }}
          >
            <div className="valid-feedback">
              {i18n._("form_upload_recipients_valid")}
            </div>
            <div className="invalid-feedback">
              {i18n._("form_upload_recipients_invalid")}
            </div>
          </ChipInput>
        </MDBCol>

        <MDBCol size={"1"} className={"fv_icon_recipients px-0"}>
          <MDBTooltip domElement tag={"span"} placement={"top"}>
            <i
              aria-disabled={disabled || transferInfos.for_team}
              className={`fa fas ${
                transferInfos.auto_send
                  ? "icon_auto_send_green"
                  : "icon_auto_send"
              } ${
                disabled || transferInfos.for_team
                  ? "disabled"
                  : "hover-pointer"
              }`}
              onClick={
                disabled || transferInfos.for_team
                  ? undefined
                  : () => {
                      const { transferInfos } = this.props;
                      const auto_send = !transferInfos.auto_send;
                      const recipients = auto_send
                        ? [this.props.user.email]
                        : [];

                      this.props.handleChangeEmailForm(
                        "auto_send",
                        auto_send,
                        recipients
                      );
                    }
              }
            />
            <span>{i18n._("auto_send_explanation")}</span>
          </MDBTooltip>
        </MDBCol>
      </MDBRow>
    );

    return (
      <MDBContainer className={"fv_uploader_form_email_form_container"}>
        <MDBRow>
          <MDBCol size={"12"}>
            <form
              className="needs-validation fv_uploader_form_email_form_form"
              onSubmit={this.submitHandler}
              noValidate
            >
              {inputEmail}
            </form>
          </MDBCol>
        </MDBRow>

        <MDBRow className={"form-group m-inside"}>
          <MDBCol size={"12"} className={"px-0"}>
            <MDBInput
              disabled={disabled}
              outline
              label={i18n._("form_upload_transfer_name_label")}
              onChange={this.handleChange}
              type={"text"}
              name={"transfer_name"}
              value={transferInfos.transfer_name}
            >
              <div className="invalid-feedback">
                {i18n._("form_upload_transfer_name_invalid")}
              </div>
              <div className="valid-feedback">
                {i18n._("form_upload_transfer_name_valid")}
              </div>
            </MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow className={"form-group m-inside"}>
          <MDBCol size={"12"} className={"px-0"}>
            <MDBInput
              disabled={disabled}
              outline
              type={"textarea"}
              className={"ml-0 mr-0 w-100"}
              label={i18n._("form_upload_transfer_message_label")}
              labelClass={"ml-0"}
              onChange={this.handleChange}
              rows={"3"}
              name={"transfer_message"}
              icon={"expand-arrows-alt"}
              iconSize={"1x"}
              iconClass={
                "position-absolute p-top p-right hover-pointer dezoom pl-4 pb-4 pr-1 pt-0 prefix fv_expand_icon"
              }
              onIconClick={this.handleIconClick}
              value={transferInfos.transfer_message}
            >
              <div className="invalid-feedback">
                {i18n._("form_upload_transfer_message_invalid")}
              </div>
              <div className="valid-feedback">
                {i18n._("form_upload_transfer_message_valid")}
              </div>
            </MDBInput>
          </MDBCol>
        </MDBRow>

        <MDBModal
          centered
          isOpen={this.state.isFullscreen}
          className={"full-screen-input fv_uploader_form_modal"}
        >
          <MDBModalHeader toggle={this.handleIconClick}>
            {i18n._("form_upload_transfer_message_title")}
          </MDBModalHeader>
          <MDBModalBody className={"no-padding full-screen-input p-2"}>
            <MDBInput
              outline
              type={"textarea"}
              label={i18n._("form_upload_transfer_message_label")}
              className={"ml-0 mr-0 w-100 full-screen-input m-auto"}
              labelClass={"ml-0"}
              onChange={this.handleChange}
              rows={"15"}
              name={"transfer_message"}
              icon={"compress-alt"}
              iconSize={"1x"}
              iconClass={
                "position-absolute p-bottom p-right hover-pointer dezoom pr-2 pb-2 pl-5 pt-5"
              }
              onIconClick={this.handleIconClick}
              value={transferInfos.transfer_message}
            >
              <div className="invalid-feedback">
                {i18n._("form_upload_transfer_message_invalid")}
              </div>
              <div className="valid-feedback">
                {i18n._("form_upload_transfer_message_valid")}
              </div>
            </MDBInput>

            <MDBCol size={"12"} className={"text-center"}>
              <MDBBtn onClick={this.handleIconClick} color={"default"}>
                {i18n._("btn_valid_label")}
              </MDBBtn>
            </MDBCol>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  };
}

const mapEmailFormStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapEmailFormStateToProps)(EmailForm);
