const convertTime = (time: number, text: string = ""): string => {
  let res: number = 0;
  let unit: string = "";
  let plurial = "";

  switch (true) {
    case time > 3600:
      res = Math.round(time / 3600);
      unit = "heure";
      break;

    case time > 60:
      res = Math.round(time / 60);
      unit = "minute";
      break;

    case time < 60:
      res = Math.round(time);
      unit = "seconde";
      break;
  }
  plurial = res > 1 ? "s" : "";

  const suffix = text && text.length ? ` ${text}${plurial}` : "";
  return `${res} ${unit}${plurial}${suffix}`;
};
export default convertTime;
