import React, { Suspense } from "react";
import { HeaderV2, PasswordModalsComponent } from "./Components";
import { Route } from "react-router";
import { isMobile } from "./utils";

import { LoaderComponent } from "./Components";

import { BrowserRouter, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { userApi, assetsApi } from "./Api";
import {
  Home,
  Download,
  About,
  Business,
  Offer,
  Profil,
  ResetPassword,
  ConfirmMember,
  NotFound,
  Legale,
  FVStatistics,
  CustomOffer,
  OrderScreen,
  InviteScreen,
  InviteConfirmationScreen,
  Deposit,
  Receipt,
  CreateDepositScreen,
  StudioScreen,
} from "./Screens";
import { connect } from "react-redux";
import { appAction, userAction } from "./Store";
import { hashLinkScroll, i18n } from "./utils";
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";

class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prevProps: any) {
    const { hash } = window.location;
    if (hash) return setTimeout(hashLinkScroll, 250);

    if (this.props.location.pathname !== prevProps.location.pathname)
      window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export const ScrollTop = withRouter(ScrollToTop);

class Main extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.updateI18nStatus();

    this.state = {
      isLoading: false,
      isLoggedOut: document.location.href.includes("?force_logout=1") || false,
    };

    let root = document.documentElement;

    root.addEventListener("mousemove", () => {
      root.style.setProperty("--light-text-content", "UPDATED");
      root.style.setProperty("--dark-text-content", "UPDATED 223");
    });
  }

  updateI18nStatus = () => {
    setTimeout(async () => {
      setTimeout(() => {
        if (!i18n.isReady) return this.updateI18nStatus();

        if (this.props.user?.id) {
          // if user not connected
          userApi
            .userVerification() // check if exist and asign new token
            .then(({ user, token }) => {
              if (token && typeof token === "string") {
                this.props.dispatch(userAction.loginUser({ user, token }));
              } else {
                this.props.dispatch(userAction.logoutUser({}));
                this.setState({ ...this.state, isLoggedOut: true });
              }
            })
            .catch(() => {
              this.props.dispatch(userAction.logoutUser({}));
              this.setState({ ...this.state, isLoggedOut: true });
            });
        }
        return this.props.dispatch(appAction.setI18nStatus(i18n.isReady));
      }, 250);
    }, 250);
  };

  async componentDidMount() {
    try {
      // @ts-ignore
      if (!window.connexionInfos)
        userApi
          .getConnexionInfos() // @ts-ignore
          .then((cI) => (window.connexionInfos = cI))
          .catch(() => {});
    } catch (e) {}

    if (isMobile) {
      return this.props.dispatch(appAction.setBackground(""));
    }

    const background = await assetsApi.getBackground();
    this.props.dispatch(appAction.setBackground(background));
  }

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    if (
      (nextProps?.app?.BACKGROUND !== this.props?.app?.BACKGROUND,
      nextProps?.user !== this.props?.user &&
        nextProps?.user?.status !== this.props?.user?.status)
    )
      this.componentDidMount().then(() => null);

    return true;
  }

  render() {
    if (isMobile) {
      // prettier-ignore
      //@ts-ignore
      document.getElementById('root').classList.add('mobile');
    }

    if (!this.props?.app?.BACKGROUND && !isMobile) {
      return <MainLoaderComponent />;
    }

    if (this.props?.transfer?.purging) {
      this.componentDidMount();
      return <MainLoaderComponent />;
    }

    const modalContent = {
      header: i18n._("fv_modal_header_user_expired"),
      body: i18n._("fv_modal_body_user_expired"),
      footer: i18n._("fv_modal_footer_user_expired"),
    };

    return (
      <BrowserRouter>
        <Suspense fallback={<MainLoaderComponent />}>
          <PasswordModalsComponent
            userFromParent={this.props.user}
            app={this.props.app}
            dispatch={this.props.dispatch}
          />

          {/*<HeaderComponent />*/}
          <HeaderV2 isLogged={!!this.props.user?.id} />

          <MDBModal
            className={"fv-expireduser-modal fv_expireduser_modal"}
            centered
            isOpen={this.state.isLoggedOut}
            toggle={() => {
              this.setState({ ...this.state, isLoggedOut: false });
            }}
          >
            {modalContent.header && (
              <MDBModalHeader
                className={
                  "fv-modal-expired-session-header fv_modal_expired_session_header"
                }
                toggle={() => {
                  this.setState({ ...this.state, isLoggedOut: false });
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: modalContent.header }}
                />
              </MDBModalHeader>
            )}

            {modalContent.body && (
              <MDBModalBody
                className={
                  "fv-modal-expired-session-body fv_modal_expired_session_body"
                }
              >
                <div dangerouslySetInnerHTML={{ __html: modalContent.body }} />
              </MDBModalBody>
            )}

            {modalContent.footer && (
              <MDBModalFooter
                className={
                  "fv-modal-expired-session-footer fv_modal_expired_session_footer"
                }
              >
                <div className={"w-100 text-center"}>{modalContent.footer}</div>
              </MDBModalFooter>
            )}
          </MDBModal>

          <main
            style={{
              minHeight: "100vh",
              background: this.props.app[appAction.SHOW_LIGHT_HEADER]
                ? "#FFF"
                : "#000",
              paddingTop: this.props.app[appAction.HIDE_HEADER] ? "unset" : 45,
              paddingBottom: this.props.app[appAction.HIDE_HEADER]
                ? "unset"
                : 45,
            }}
          >
            <Switch>
              <Route path={"/u/:id"} component={Receipt} />
              <Route path={"/deposit/:id"} component={Deposit} />
              <Route path={"/pro/:id"} component={CreateDepositScreen} />

              <Route path={"/d/:id"} component={Download} />

              <Route path={"/collab"} component={StudioScreen} />
              {/*<Route path={"/studio/*"} component={StudioScreen} />*/}

              <Route path={"/"} component={Home} exact />

              <Route path={"/impact"} component={About} exact />
              <Route path={"/impact/pro"} component={Business} exact />
              <Route path={"/offers_old"} component={Offer} exact />

              <Route path={"/offers"} component={CustomOffer} exact />

              <Route
                path={"/reset-password/:token"}
                component={ResetPassword}
                exact
              />
              <Route
                path={"/member/:member_token"}
                component={ConfirmMember}
                exact
              />

              <Route
                path={"/invite/confirmation/:token"}
                component={InviteConfirmationScreen}
                exact
              />
              <Route
                path={"/invite/:invitation_link"}
                component={InviteScreen}
                exact
              />

              <Route path={"/profil"} component={Profil} />

              <Route path={"/legal-terms"} exact>
                <Legale type={"legal-terms"} />
              </Route>
              <Route path={"/cgu"} exact>
                <Legale type={"cgu"} />
              </Route>
              <Route path={"/privacy"} exact>
                <Legale type={"privacy"} />
              </Route>

              <Route path={"/order/old/:status/:order_id"}>
                <OrderScreen isOld />
              </Route>
              <Route
                path={"/order/:status/:order_id"}
                component={OrderScreen}
              />
              <Route path={"/fv-statistics"} component={FVStatistics} />

              {/*<Route path={`/${lang}/u/:id`} component={Upload}/>*/}
              {/*<Route path={`/${lang}/d/:id`} component={Download} />*/}
              {/*<Route path={`/${lang}/`} component={Home} exact />*/}

              {/*<Route path={`/${lang}/u/:id`} component={Upload}/>*/}
              {/*<Route path={`/${lang}/d/:id`} component={Download} />*/}
              {/*<Route path={`/${lang}/`} component={Home} exact />*/}

              {/*<Route path={`/${lang}/about`} component={About} exact />*/}
              {/*<Route path={`/${lang}/about/pro`} component={Business} exact />*/}
              {/*<Route path={`/${lang}/offers`} component={Offer} exact/>*/}

              {/*<Route path={`/${lang}/reset-password/:token`} component={ResetPassword} exact />*/}
              {/*<Route path={`/${lang}/member/:member_token`} component={ConfirmMember} exact />*/}
              {/*<Route path={`/${lang}/profil`} component={Profil}/>*/}

              {/*<Route path={`/${lang}/legal-terms`} exact ><Legale type={'legal-terms'}/></Route>*/}
              {/*<Route path={`/${lang}/cgu`} exact ><Legale type={'cgu'}/></Route>*/}
              {/*<Route path={`/${lang}/privacy`} exact ><Legale type={'privacy'}/></Route>*/}

              <Route path={"*"} component={NotFound} exact />
            </Switch>
          </main>
        </Suspense>
      </BrowserRouter>
    );
  }
}

const MainLoaderComponent = () => {
  return (
    <div
      className={"main-loader-wrapper fv_main_loader_wrapper"}
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        textAlign: "center",
        paddingTop: "50vh",
      }}
    >
      <LoaderComponent size={"small"} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user.user,
});

export default connect(mapStateToProps)(Main);
