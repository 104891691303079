const kWhPerByteDataCenter = 0.000000000059;
const kWhPerByteNetwork = 0.000000000152;
const kWhPerMinuteDevice = 0.00021;
const GESgCO2ForOneKmByCar = 220;

const defaultResolution = 1366 * 768;
const nbServer = 1;
/*
 *
 * a_kWh = (1 * 1024 * 1024 * 1024) * 0.000000000152   // .163208757248    // kWhPerByteNetwork
 * b_kWh = (1 * 0.000000000072) * 3                    // .000000000216    // kWhPerByteDataCenter
 * c_kWh = (1 * 0.000000000072) * 24                   // .000000001728    // kWhPerByteDataCenter * temps en heure de stockage
 * d_kWh = (10 * 0.00021) * 1.1                        // .00231           // minutes * kWhPerMinuteDevice * facteur taille (24 pouces ...)
 *
 * a_gCO2 = a * 90                                     // 14.688788152320 // carbonIntensityFactorIngCO2PerKWh
 * b_gCO2 = b * 90                                     // .000000019440   // carbonIntensityFactorIngCO2PerKWh
 * c_gCO2 = c * 90                                     // .000000155520   // carbonIntensityFactorIngCO2PerKWh
 * d_gCO2 = d * 90                                     // .20790          // carbonIntensityFactorIngCO2PerKWh
 *
 * ------------------------------------------------------------------------------------------------------------------------------
 *
 * stockage_kWh =  (1 * .016) * 2                     // .032    // kWhPerByteDataCenter * durée en heure de stockage
 * stockage_gCO2 = stockage_kWh * 90                  // 2.880   // carbonIntensityFactorIngCO2PerKWh
 *
 * upload_kWh = (a_kWh + b_kWh + c_kWh + d_kWh)        // .165518759192 // 16 000 Wh
 * upload_gCO2 = (a_gCO2 + b_gCO2 + c_gCO2 + d_gCO2)   // 14.896688327280 // 1489.6688327280
 *
 *
 *                                             //-----------------------------------------------------------------------
 * */ // TOTAL UPLOAD + STOCKAGE = 0.197518759192 kWh soit 17.776688327280 gCO2

// prettier-ignore
//@ts-ignore
const getNetworkRatio: () => number = () => (window?.connexionInfos?.connexion && 'WIFI' === window?.connexionInfos?.connexion) ? 1 : 23
// prettier-ignore
//@ts-ignore
const getC02Ratio = () => window?.connexionInfos?.CO2Ratio || 67.781;

const forUpload: (
  sizeInO: number,
  timingInMs: number
) => { kWh: number; gCO2: number; kms: number } = (
  sizeInO: number,
  timingInMs: number
) => {
  let kWh = 0;
  let gCO2 = 0;
  let kms = 0;

  const timingInMinutes = timingInMs / (1000 * 60);
  const resolution = window.screen
    ? window.screen.height * window.screen.width
    : defaultResolution;
  const screenRatio = resolution / defaultResolution;
  const networkRatio = getNetworkRatio();

  let screen_kWh = timingInMinutes * kWhPerMinuteDevice * screenRatio; // .00231 // minutes * kWhPerMinuteDevice * facteur taille (24 pouces ...)
  let networkClient_kWh = sizeInO * kWhPerByteNetwork * networkRatio; // .163208757248 // kWhPerByteNetwork
  let networkServer_kWh = sizeInO * kWhPerByteDataCenter * nbServer;

  kWh = screen_kWh + networkClient_kWh + networkServer_kWh;
  gCO2 = kWh * getC02Ratio();
  kms = Math.round((1000 * gCO2) / GESgCO2ForOneKmByCar) / 1000;

  return {
    kWh,
    gCO2,
    kms,
  };
};

const calcConsumption = {
  forUpload,
};

export default calcConsumption;
