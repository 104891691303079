import axios from "axios";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 1000,
  retryCondition: (error: AxiosError) => {
    const jsonError: any = error.toJSON();
    return (
      (!jsonError || !jsonError.status) &&
      jsonError.status < 400 &&
      jsonError.status > 400 &&
      jsonError.status !== 404 &&
      jsonError.status !== 409
    );
  },
  retryDelay: (c: number) => {
    let delay = c * 1000 * (2 * Number(!navigator.onLine));
    if (delay > 20_000) delay = 20_000;
    console.warn(`No connexion to Bucket, retrying in : ${delay / 1000}s`);
    // prettier-ignore
    //@ts-ignore
    window.retryDelay = delay;
    return delay;
  },
});

const transferApi = {
  async getStatistics({ start_date, end_date }: any): Promise<any> {
    try {
      let stats = await axios.get(`/transfer/statistics`, {
        params: {
          start_date,
          end_date,
        },
      });

      if (stats.data) {
        Object.keys(stats.data?.consumption).forEach((itemKey: string) => {
          stats.data.consumption[itemKey] = Number(
            stats.data.consumption[itemKey]
          );
        });
        return stats.data;
      }
      return {};
    } catch (e) {
      console.error(e);
      return null;
    }
  },

  async getTransferComments(transfer_id: string) {
    try {
      return await axios.get(`/transfer/${transfer_id}/comment`);
    } catch (e) {
      return { error: "Unable to get transfer comment" };
    }
  },

  async getValidations(transfer_id: string, file_id: string | number) {
    try {
      return await axios.get(
        `/transfer/${transfer_id}/file/${file_id}/validations`
      );
    } catch (e) {
      return { error: "Unable to get transfer validations" };
    }
  },

  async getLightTransfer(fields: string[], transfer_id: string) {
    try {
      return await axios.get(`/transfer/${transfer_id}`, {
        params: {
          fields,
          collab: true,
        },
      });
    } catch (e) {
      return { error: "Unable to get transfer light" };
    }
  },

  async addTransferComment(comment: any, transfer_id: string) {
    try {
      return await axios.post(`/transfer/${transfer_id}/comment`, comment);
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async updateTransferComment(
    transfer_id: string,
    comment_id: number,
    payload: { file_id?: number; status?: number }
  ) {
    try {
      return await axios.put(
        `/transfer/${transfer_id}/comments/${comment_id}`,
        payload
      );
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async addTransferValidation({ file_id, transfer_id }: any) {
    try {
      return await axios.post(
        `/transfer/${transfer_id}/file/${file_id}/validations`
      );
    } catch (e) {
      return { error: "Unable to post transfer" };
    }
  },

  async updateStatus(transfer_status: any, transfer_id: string): Promise<any> {
    try {
      const transfer = await axios.put(`/transfer/${transfer_id}`, {
        data: { transfer_status },
      });
      return transfer.data;
    } catch (e) {
      console.error(e);
      return { error: "Unable to update transfer" };
    }
  },

  async updateExpiration(
    transfer_expiration: any,
    transfer_id: string
  ): Promise<any> {
    try {
      const transfer = await axios.put(`/transfer/${transfer_id}`, {
        data: { transfer_expiration },
      });
      return transfer.data;
    } catch (e) {
      console.error(e);
      return { error: "Unable to update transfer" };
    }
  },

  async patchTransfer(transfer_infos: any, transfer_id: string): Promise<any> {
    try {
      const transfer = await axios.patch(
        `/transfer/${transfer_id}/upload`,
        transfer_infos
      );
      return transfer.data;
    } catch (e) {
      console.error(e);
      return { error: "Unable to update transfer" };
    }
  },

  async createTransfer(transfer_infos: any): Promise<any> {
    try {
      if (transfer_infos?.transfer_name) {
        transfer_infos.transfer_name = unescape(transfer_infos.transfer_name);
      }
      const transfer = await axios.post(`/transfer/upload`, transfer_infos);
      return transfer.data;
    } catch (e) {
      console.error(e);
      return { error: "Unable to initialize transfer" };
    }
  },
  async getPresignedPostData(
    selectedFile: any,
    transfer: any,
    userType: string = "free"
  ) {
    try {
      const axiosSign: any = Axios.create({
        withCredentials: false,
      });

      axiosRetry(axiosSign, {
        retries: 2,
        retryCondition: (error: any) => true,
        retryDelay: (c) => c * 1_000,
      });

      const signedRes = await axiosSign.post(
        `/transfer/${transfer.transfer_id}/upload/file/sign/${transfer.bucket_id}`,
        selectedFile
      );

      return signedRes.data;
    } catch (e) {
      console.error(e);
      return { error: "Presigning request failed" };
    }
  },
  async uploadFileToS3(
    presignedPostData: any,
    file: any,
    onProgress: any,
    { transfer_id }: any,
    max: number = 25
  ) {
    return new Promise(async (resolve, reject) => {
      const axiosComplete: any = Axios.create({
        withCredentials: false,
      });

      axiosRetry(axiosComplete, {
        retries: 3,
        retryCondition: (error: any) => true,
        retryDelay: (c) => c * 1_000,
      });

      const generatedParts = presignedPostData.multiPart;
      const partSize = presignedPostData.multiPartSize;
      const presignedParts = await uploadParts(
        file,
        partSize,
        generatedParts,
        onProgress,
        max
      );
      const final = {
        uploadId: presignedPostData.multiPartInfos.UploadId,
        Parts: presignedParts,
        Key: presignedPostData.multiPartInfos.Key,
      };

      axiosComplete
        .put(
          `/transfer/${transfer_id}/upload/file/complete/${final.uploadId}`,
          {
            Parts: presignedParts,
            Key: final.Key,
          }
        )
        .then(resolve)
        .catch(reject);
    });
  },
  async finalizeTransfer(
    transfer_infos: any,
    transfer: any,
    uploader_email?: string,
    is_received?: boolean
  ): Promise<any> {
    axiosRetry(axios, {
      retries: 2,
      retryCondition: (error: any) => true,
      retryDelay: (c) => c * 1_000,
    });
    const result = await axios.patch(
      `/transfer/${transfer.transfer_id}/upload/finalize/${transfer.bucket_id}`,
      transfer_infos,
      {
        params: {
          uploader_email,
          is_received,
        },
      }
    );
    return result?.data || result || null;
  },

  async getApiVersion(): Promise<any> {
    return axios.get(`http://localhost:3003/api/v1/version`);
  },
  async getTransfer(
    id: string,
    transfer_password: string | undefined = undefined,
    auth?: boolean,
    is_history?: any
  ): Promise<any> {
    try {
      let transfer: AxiosResponse & any = await axios.get(`/transfer/${id}`, {
        params: {
          transfer_password,
          is_history,
        },
      });

      if (transfer && transfer.error && transfer.error.code === "UNAUTHORIZED")
        transfer = { data: { transferLocked: true } };

      return transfer && transfer.data ? transfer.data : [];
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getReceive(id: string, uploader_email?: string): Promise<any> {
    try {
      let transferConfig: AxiosResponse & any = await axios.get(
        `/transfer/${id}`,
        {
          params: { is_received: true, uploader_email },
        }
      );

      if (
        transferConfig &&
        transferConfig.error &&
        transferConfig.error.code === "UNAUTHORIZED"
      )
        transferConfig = { data: { transferLocked: true } };

      return transferConfig && transferConfig.data ? transferConfig.data : {};
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async downloadFile(
    { transfer_id, file_id }: any,
    onDownloadProgress: (
      progressEvent: any,
      file_id: number
    ) => void = console.log
  ): Promise<AxiosResponse<any>> {
    const config: AxiosRequestConfig = {
      responseType: "blob",
      onDownloadProgress: (progressEvent) =>
        onDownloadProgress(progressEvent, file_id),
    };
    const file = await axios.get(
      `/transfer/${transfer_id}/file/${file_id}`,
      config
    );
    return file && file.data ? file.data : null;
  },
  async downlaodTransfer(
    transfer_id: any,
    onDownloadProgress: (
      progressEvent: any,
      file_id: number
    ) => void = console.log
  ): Promise<AxiosResponse<any>> {
    const config: AxiosRequestConfig = {
      responseType: "blob",
      onDownloadProgress: (progressEvent) =>
        onDownloadProgress(progressEvent, transfer_id),
    };

    const transferArchive = await axios.get(
      `/transfer/${transfer_id}/zip`,
      config
    );
    return transferArchive && transferArchive.data
      ? transferArchive.data
      : null;
  },
  async getAllUserTransfer(user_id: number): Promise<any[]> {
    const transfer = await axios.get(`/user/${user_id}/transfer`);
    return transfer && transfer.data ? transfer.data : [];
  },
  async setTransferConsumption(
    path: string,
    consumption: any
  ): Promise<AxiosResponse<any>> {
    const result = await axios.put(path, consumption);

    return result && result.data ? result.data : null;
  },
  async deleteManyTransfer(transfers: any[]): Promise<any> {
    const result = await axios.delete(`/transfer`, {
      params: { transfers },
    });

    return result.data || null;
  },
  async createNewReceive(transfer_infos: any): Promise<any> {
    try {
      const transfer = await axios.post(`/transfer/receive`, transfer_infos);
      return transfer.data;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async createNewDeposit(hub_id?: any): Promise<any> {
    try {
      const transfer = await axios.post(`/transfer/deposit`, {
        hub_id,
      });
      return transfer.data;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },
};

async function uploadParts(
  file: Buffer,
  partSize: number,
  urls: Record<string, string>,
  onProgress: any,
  max: number = 25
) {
  const axiosS3 = Axios.create({
    withCredentials: false,
  });
  axiosS3.defaults.timeout = 0;
  delete axiosS3.defaults.headers.put["Content-Type"];

  axiosRetry(axiosS3, {
    retries: 10,
    retryCondition: (error: AxiosError | any) => {
      if (document.location.href.includes("debug=1"))
        console.log(error, error.toJSON());
      return !(error?.response?.status === 403);
    },
    retryDelay: (c: number) => {
      let delay = 5_000;
      console.warn(`No connexion to Bucket, retrying in : ${delay / 1000}s`);
      // prettier-ignore
      //@ts-ignore
      window.retryDelay = delay;
      return delay;
    },
  });

  const keys = Object.keys(urls);
  const promises: any = [];

  for (const indexStr of keys) {
    const index = parseInt(indexStr) - 1;
    const start = index * partSize;
    const end = (index + 1) * partSize;
    const blob =
      index < keys.length ? file.slice(start, end) : file.slice(start);
    promises.push(() =>
      axiosS3.put(urls[indexStr], blob, {
        onUploadProgress: (temp1: any) => onProgress(temp1, indexStr),
      })
    );
  }

  try {
    return runBatchUpload(promises, max, undefined);
  } catch (e) {
    if (document.location.href.includes("debug=1")) console.log(e);
  }
}

const runBatchUpload = async (
  promisesArr: any[],
  max: number = 10,
  cb?: any,
) => {
  const results: any = [];

  let PartNumber = 0;
  while (promisesArr.length) {
    // prettier-ignore
    //@ts-ignore
    window.myPromises = promisesArr;
    (
      await Promise.all(promisesArr.splice(0, max).map((promise) => promise()))
    ).forEach((part: any, i: number) => {
      let ETag = part.headers.etag;
      try {
        ETag = JSON.parse(part.headers.etag);
      } catch (e) {}
      results.push({
        ETag,
        PartNumber: ++PartNumber,
      });
    });
  }
  return results.sort((a: any, b: any) =>
    a.PartNumber < b.PartNumber ? -1 : 1
  );
};

export default transferApi;
