import { Go, Ko, Mo, To } from "../Constants";

const convertSizeInTo = (size: number, decimal: number): string =>
  (size / To).toFixed(decimal) + " To";
const convertSizeInGo = (size: number, decimal: number): string =>
  (size / Go).toFixed(decimal) + " Go";
const convertSizeInMo = (size: number, decimal: number): string =>
  (size / Mo).toFixed(decimal) + " Mo";
const convertSizeInKo = (size: number, decimal: number): string =>
  (size / Ko).toFixed(decimal) + " Ko";

const convertGCO2 = (gco2: number): string => {
  if (gco2 > 1_000_000) return `${(gco2 / 1_000_000).toFixed(2)} Tonnes de CO2`;
  if (gco2 > 1_000) return `${(gco2 / 1_000).toFixed(2)} kg de CO2`;

  return `${gco2.toFixed(2)} g de CO2`;
};

const convertSize = (
  size: number,
  decimal?: number,
  withLabel: boolean = true
): string => {
  decimal = decimal !== undefined ? decimal : 2;
  const result =
    Math.round(size / To) >= 1
      ? convertSizeInTo(size, decimal)
      : Math.round(size / Go) >= 1
      ? convertSizeInGo(size, decimal)
      : Math.round(size / Mo) >= 1
      ? convertSizeInMo(size, decimal)
      : convertSizeInKo(size, decimal);
  return withLabel ? result : `${parseFloat(result)}`;
};

export default convertSize;
export { convertSizeInMo, convertSizeInGo, convertSizeInKo, convertGCO2 };
