import React, { ChangeEvent } from "react";
import { i18n } from "../utils";
import { appAction } from "../Store/Actions";

import { Select, MenuItem } from "@material-ui/core";

const LangComponent = ({
  dispatch,
  textClass = "text-white",
}: {
  dispatch: Function;
  textClass?: string;
}) => {
  const langChoices = i18n.getLangChoices();

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const lang = evt.target.value;
    i18n.changeLang(lang);
    dispatch(appAction.changeLang(lang));
  };

  return (
    <div className={`my-auto`}>
      <Select
        variant={"outlined"}
        onChange={handleChange as any}
        value={i18n.getLang()}
        className={`bg-transparent ${textClass} custom-select-sm px-0 fv_select_lang text-white`}
        style={{ border: "none", color: "#FFF" }}
        IconComponent={() => (
          <svg
            style={{ position: "absolute", right: "20%", top: "10%" }}
            fill="#FFF"
            width={24}
            height={24}
            className="MuiSvgIcon-root-38 MuiSelect-icon-7"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
        )}
      >
        {langChoices.map((lang: string, key: number) => (
          <MenuItem value={lang} key={key}>
            {lang.split("_")[1]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default LangComponent;
