import React, { useEffect, useState } from "react";

import "./LandingCard.css";
import { i18n } from "../../utils";

export function LandingCard() {
  const [cards, setCards] = useState(i18n._("collab_landing.cards"))
  
  useEffect(() => {
    setCards(i18n._("collab_landing.cards"))
  }, [i18n?.isReady])

  return (
    <div className="fv-landing-card-container text-white">
      <div className="d-flex justify-content-center">
        {cards?.map(({ title, content }: any, index: number) => (
          <div
            key={`LandingCard_${index}`}
            className="d-flex flex-1 flex-column fv-landing-bg-linear"
          >
            <h4 className="text-left text-white">
              {index + 1} - {title}
            </h4>
            <p
              dangerouslySetInnerHTML={{ __html: content }}
              className="text-left"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
