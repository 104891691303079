import React from "react";
import { getQRCodeLink } from "../utils";

export function QRCodeComponent({
  link,
  size = 600,
  imgClass,
}: {
  link: string;
  size?: number;
  imgClass?: string;
}) {
  return (
    <img
      alt={"hub-qrcode"}
      onClick={() => {
        window.open(getQRCodeLink(link, 300), "_blank")?.focus();
      }}
      width={size}
      height={size}
      className={`hover-pointer fv-qr-code-img ${imgClass || ""}`}
      src={getQRCodeLink(link, size)}
    />
  );
}
