export const subscriptionsCells = [
  "subscription_cell_name",
  "subscription_cell_users",
  "subscription_cell_date",
  "subscription_cell_status",
  // "subscription_cell_update",
  "subscription_cell_action",
];

export const membersTableHeadersCells = [
  "members_cell_name",
  "members_cell_email",
  "members_cell_status",
  // "members_cell_action",
  "members_cell_delete",
];
