import React from "react";

const Home = React.lazy(() => import("./Home/Home"));
const Receipt = React.lazy(() => import("./Receipt/Receipt"));
const Deposit = React.lazy(() => import("./Deposit/Deposit"));

const Download = React.lazy(() => import("./Download/Download"));
const About = React.lazy(() => import("./About/About"));
const Business = React.lazy(() => import("./Business/Business"));
const Offer = React.lazy(() => import("./Offer/Offer"));
const Profil = React.lazy(() => import("./Profil/Profil"));
const ResetPassword = React.lazy(() => import("./Home/Home"));
const ConfirmMember = React.lazy(() => import("./Profil/Profil"));
const NotFound = React.lazy(() => import("./NotFound/NotFound"));
const Legale = React.lazy(() => import("./Legale/Legale"));
const FVStatistics = React.lazy(() => import("./FVStatistics/FVStatistics"));
const CustomOffer = React.lazy(() => import("./CustomOffer/CustomOfferScreen"));
const OrderScreen = React.lazy(() => import("./Order/OrderScreen"));
const InviteScreen = React.lazy(() => import("./Invite/Invite"));

const StudioScreen = React.lazy(() => import("./Studio/Studio"));

const InviteConfirmationScreen = React.lazy(
  () => import("./InviteConfirmation/InviteConfirmation")
);
const CreateDepositScreen = React.lazy(
  () => import("./CreateDeposit/CreateDeposit")
);

export {
  Home,
  Receipt,
  Deposit,
  Download,
  About,
  Business,
  Offer,
  Profil,
  ResetPassword,
  ConfirmMember,
  NotFound,
  Legale,
  FVStatistics,
  CustomOffer,
  OrderScreen,
  InviteScreen,
  InviteConfirmationScreen,
  CreateDepositScreen,
  StudioScreen,
};
