import React from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";

export interface IInputCheckboxComponentProps {
  id: string;
  name: string;
  error?: string;
  label?: string;
  defaultChecked?: boolean;
  required?: boolean;
  [key: string]: any;
}

export function InputCheckboxComponent({
  id,
  label,
  error,
  name,
  defaultChecked,
  required,
  ...rest
}: IInputCheckboxComponentProps) {
  return (
    <FormGroup className={"custom-control custom-switch"}>
      <FormControl
        id={id}
        className="custom-control-input hover-pointer"
        name={name}
        type={"checkbox"}
        defaultChecked={defaultChecked}
        required={required}
        {...rest}
      />
      <Form.Label className="custom-control-label hover-pointer" htmlFor={id}>
        {label}{" "}
        {required && <span className={"text-danger fv_required"}>*</span>}
      </Form.Label>
      {error && (
        <Form.Control.Feedback
          id={`error_${name}`}
          className="font-weight-bold"
          type="invalid"
          role="alert"
        >
          {error}
        </Form.Control.Feedback>
      )}
    </FormGroup>
  );
}

// export function InputCheckboxComponent ({label, error, name, defaultChecked, required}: IInputCheckboxComponentProps) {
//   return (
//     <FormGroup
//       onBlur={(event: any) => {event.currentTarget.classList.add('was-validated');}}
//       onChange={(event: any) => {event.currentTarget.classList.remove('was-validated');}}
//     >
//       <div className={'d-flex'}>
//         <SwitchButtonComponent
//           id={'cgu'}
//           label={'cgu'}
//         />
//         <FormControl
//           style={{
//             width: 20,
//             height: 25,
//             marginRight: 10,
//           }}
//           size={'sm'}
//           name={name}
//           type={'checkbox'}
//           defaultChecked={defaultChecked}
//           required={required}
//         />
//         <Form.Label>{label}</Form.Label>
//       </div>
//
//       { error &&
//       <Form.Control.Feedback
//         className="font-weight-bold"
//         type="invalid"
//         role="alert"
//       >
//         {error}
//       </Form.Control.Feedback>
//       }
//     </FormGroup>
//   );
// }
