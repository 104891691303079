import React from "react";
import "./Header.css";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavLink,
  MDBNavItem,
  MDBCollapse,
  MDBNavbarToggler,
  MDBIcon,
} from "mdbreact";

import { BrandComponent, LoginButtonComponent } from "./Components";

import { connect } from "react-redux";

import { appAction } from "../../Store";
import { i18n } from "../../utils";
import { isMobile } from "../../utils";
import LangComponent from "../LangComponent";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { FooterComponent } from "..";
import { hashLinkScrollProps } from "../../utils/hashLinkScroll";
import { gradients } from "../../Constants";

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user.user,
  transfer: state.transfer.transfers,
  file: state.file,
});

interface Props {
  dispatch: Function;
  app: any;
  user: any;
  file: IFileWithMeta[];
}

function getSerializedSlogan(str: string): string {
  return (str = str.replace(/{{(\w+)}}/g, function (e, t) {
    return '<img alt="Drapeau français" src="/flag.png" />';
  }));
}

const HeaderComponent = connect(mapStateToProps)(
  class HeaderComponentNormal extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);
      this.state = { collapseID: "" };
    }

    componentDidMount() {
      if (this.props.app.SAVE_PASSWORD || this.props.app.RESET_PASSWORD)
        setTimeout(() => {
          const tog = document.getElementById("navbar-toggler");
          if (tog) tog.click();
        }, 250);
    }

    getThemeClass(id: string) {
      if (id === "custom-0" || id === "custom-1") return id;

      const themeClass: any =
        "0" === id[0]
          ? gradients.clair.find((gradient) => gradient.id === id)
          : gradients.sombre.find((gradient) => gradient.id === id);

      return themeClass?.className || "";
    }

    getClassName = () => {
      const { app } = this.props;

      const className = !app[appAction.SHOW_CUSTOM_HEADER]
        ? "normal"
        : this.getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

      return className;
    };

    getClassId = () => {
      const { app } = this.props;

      const classId = !app[appAction.SHOW_CUSTOM_HEADER]
        ? ""
        : app[appAction.SHOW_CUSTOM_HEADER];

      return classId;
    };

    toggleCollapse = (collapseID: string) => () => {
      this.setState((prevState: any) => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      }));
    };

    handleOpenNavbar(): void {
      window.onclick = () => this.handleCloseNavbar();
      window.ontouchend = () => this.handleCloseNavbar();
    }

    handleCloseNavbar = () => {
      setTimeout(() => {
        // prettier-ignore
        //@ts-ignore
        if (this.state.isModalOpen)  return;

        window.onclick = null;
        window.ontouchend = null;
        this.toggleCollapse("")();
      }, 100);
    };

    onModalChangeCB = (isOpen: boolean) => {
      this.setState({ ...this.state, isModalOpen: isOpen });
    };

    render = () => {
      if (this.props.app[appAction.SHOW_LIGHT_HEADER]) return null;

      const themeClass = this.getClassName(),
        themeId = this.getClassId();

      let btnClassName = "btn-outline-default",
        textColorName = "text-default",
        isLight = isMobile,
        isWhite = isMobile;

      if (themeId && themeId.length) {
        isLight = themeId[0] === "0" || themeId === "custom-0";
        isWhite = (() => {
          return (
            gradients.clair.some(({ id }) => themeId === id) ||
            themeId === "custom-0"
          );
        })();
        btnClassName = isWhite ? "btn-outline-white" : "btn-outline-black";
        textColorName = isWhite ? "text-white" : "text-black";
      }

      return (
        <header
          id={"fv-header"}
          className={`default-header w-100 position-fixed ${themeClass}-header pt-0 fv_${themeClass}_header fv_header_theme_${
            isLight ? "clair" : "sombre"
          } theme-${isLight ? "clair" : "sombre"} ${isMobile ? "py-0" : ""} ${
            this.props.app.SAVE_PASSWORD || this.props.app.RESET_PASSWORD
              ? "no-hover"
              : ""
          } fv_header_screen_${
            document.location?.pathname?.split("/")[1] || "home"
          }`}
        >
          <MDBNavbar className={isMobile ? "p-0" : ""}>
            <MDBContainer fluid>
              <MDBRow between center className={"w-100 d-flex d-md-none"}>
                {isMobile && (
                  <MDBCol
                    size={"2"}
                    className={"position-absolute"}
                    style={{ top: 10, left: 10, zIndex: 10000 }}
                  >
                    <LangComponent
                      textClass={themeId ? textColorName : "text-white"}
                      dispatch={this.props.dispatch}
                    />
                  </MDBCol>
                )}

                <MDBCol
                  md={"8"}
                  lg={"10"}
                  className={isMobile ? "text-center" : ""}
                >
                  <BrandComponent
                    mode={!isLight || !isWhite ? "light" : "normal"}
                    dispatch={this.props.dispatch}
                    file={this.props.file}
                  />
                </MDBCol>

                <MDBCol size={"2"} className={"my-auto"}>
                  <MDBNavbarToggler
                    id={"navbar-toggler"}
                    children={
                      <MDBIcon
                        className={"white-text"}
                        icon={this.state.collapseID ? "times" : "bars"}
                      />
                    }
                    className={"white-text"}
                    onClick={this.toggleCollapse("navbar-normal")}
                  />
                </MDBCol>

                <MDBCol
                  size={"12"}
                  className={
                    "main-menubar d-flex align-items-center offset-xl-2 offset-lg-0"
                  }
                >
                  <MDBCollapse
                    className={"bg-transparent"}
                    id={"navbar-normal"}
                    isOpen={!!this.state.collapseID}
                    onOpened={this.handleOpenNavbar.bind(this)}
                    onClosed={this.handleCloseNavbar.bind(this)}
                    navbar
                  >
                    <MDBNavbarNav left>
                      <MDBNavItem className={"my-auto"}>
                        <MDBNavLink
                          link
                          to={"/impact"}
                          className={
                            "link light text-uppercase font-weight-bold text-white"
                          }
                          onClick={this.toggleCollapse("navbar-normal")}
                        >
                          {i18n._("link_more_label")}
                        </MDBNavLink>
                      </MDBNavItem>

                      <MDBNavItem className={"my-auto"}>
                        <MDBNavLink
                          link
                          to={"/offers"}
                          className={
                            "link light text-uppercase font-weight-bold text-white"
                          }
                          onClick={this.toggleCollapse("navbar-normal")}
                        >
                          {i18n._("link_subscribe_price_label")}
                        </MDBNavLink>
                      </MDBNavItem>

                      <MDBNavItem className={"my-auto"}>
                        <FooterComponent
                          contactOnly={true}
                          contactClassName={
                            "link light text-uppercase font-weight-bold text-white"
                          }
                        />
                      </MDBNavItem>

                      {/*ONLY ON MOBILE START*/}
                      <MDBNavItem className={"my-auto"}>
                        <MDBNavLink
                          link
                          to={"/legal-terms"}
                          className={"link light text-white"}
                        >
                          {i18n._("footer_basics_legales_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem className={"my-auto d-md-none"}>
                        <MDBNavLink
                          link
                          to={"/cgu"}
                          className={"link light text-white"}
                        >
                          {i18n._("footer_basics_cgu_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem className={"my-auto d-md-none"}>
                        <MDBNavLink
                          link
                          to={"/privacy"}
                          className={"link light text-white"}
                        >
                          {i18n._("footer_basics_privacy_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      {/*ONLY ON MOBILE END*/}

                      <MDBNavItem className={"my-auto"}>
                        <LoginButtonComponent
                          onModalChangeCB={this.onModalChangeCB}
                          textColorName={textColorName}
                          btnClassName={btnClassName}
                        />
                      </MDBNavItem>
                    </MDBNavbarNav>
                  </MDBCollapse>
                </MDBCol>
              </MDBRow>
              <MDBRow between center className={"w-100 d-none d-md-flex"}>
                <MDBCol xl={"2"} lg={"3"} md={"3"}>
                  <BrandComponent
                    file={this.props.file}
                    dispatch={this.props.dispatch}
                    mode={
                      themeId[0] === "1" || themeId[themeId.length - 1] === "1"
                        ? "light"
                        : "normal"
                    }
                  />
                </MDBCol>
                <MDBCol middle xl={"4"} lg={"4"} md={"4"}>
                  <h1
                    className={"py-0 my-0 header slogan text-white"}
                    dangerouslySetInnerHTML={{
                      __html: getSerializedSlogan(i18n._("fv_slogan")),
                    }}
                  />
                </MDBCol>
                <MDBCol
                  xl={"5"}
                  lg={"5"}
                  md={"5"}
                  className={
                    "main-menubar d-flex align-items-center offset-xl-1 offset-lg-0 px-0"
                  }
                >
                  <MDBNavbarNav className={"flex-row"}>
                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/impact"}
                        className={
                          "link light text-uppercase font-weight-bold text-white"
                        }
                      >
                        {i18n._("link_more_label")}
                      </MDBNavLink>
                    </MDBNavItem>
                    <span className={"header separator text-white my-auto"}>
                      |
                    </span>

                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/offers"}
                        className={
                          "link light text-uppercase font-weight-bold text-white"
                        }
                      >
                        {i18n._("link_subscribe_price_label")}
                      </MDBNavLink>
                    </MDBNavItem>
                    <span className={"header separator text-white my-auto"}>
                      |
                    </span>

                    <MDBNavItem className={"my-auto"}>
                      <FooterComponent
                        contactOnly={true}
                        contactClassName={
                          "link light text-uppercase font-weight-bold text-white"
                        }
                      />
                    </MDBNavItem>
                    <span className={"header separator text-white my-auto"}>
                      |
                    </span>

                    <MDBNavItem className={"my-auto"}>
                      <LoginButtonComponent
                        onModalChangeCB={this.onModalChangeCB}
                        textColorName={textColorName}
                        btnClassName={btnClassName}
                      />
                    </MDBNavItem>
                  </MDBNavbarNav>
                </MDBCol>
                {"normal" !== themeClass && (
                  <MDBCol className={"text-center"} size={"12"}>
                    <MDBIcon
                      icon={"angle-down"}
                      size={"2x"}
                      className={"text-white arrow-header hover-pointer"}
                    ></MDBIcon>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBContainer>
          </MDBNavbar>
        </header>
      );
    };
  }
);

const HeaderLightComponent = connect(mapStateToProps)(
  class HeaderComponentLight extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);

      this.state = {
        collapseID: "",
        isModalOpen: false,
      };
    }

    componentDidMount() {
      if (this.props.app.SAVE_PASSWORD || this.props.app.RESET_PASSWORD)
        setTimeout(() => {
          const tog = document.getElementById("navbar-toggler");
          if (tog) tog.click();
        }, 250);
    }

    handleOpenNavbar(): void {
      const d = document.getElementById("main");
      if (d) {
        d.onclick = this.handleCloseNavbar;
        d.ontouchend = this.handleCloseNavbar;
      }
    }

    handleCloseNavbar = (evt?: any) => {
      const d = document.getElementById("main");
      if (d) {
        d.onclick = null;
        d.ontouchend = null;
      }
      this.toggleCollapse("")();
    };

    toggleCollapse = (collapseID: string) => () => {
      this.setState((prevState: any) => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      }));
    };

    onModalChangeCB = (isOpen: boolean) => {
      this.setState({ ...this.state, isModalOpen: isOpen });
    };

    render = () => {
      if (!this.props.app[appAction.SHOW_LIGHT_HEADER]) return null;

      const offerLinkLabel = document.location.pathname.includes("/profil")
        ? i18n._("offer_cta_upgrade_label")
        : i18n._("link_subscription_label");

      return (
        <header
          className={`default-header-2 bg-white w-100 position-fixed fv_light_header ${
            isMobile ? "py-0" : ""
          } fv_light_header_screen_${
            document.location?.pathname?.split("/")[1] || "home"
          }`}
        >
          <MDBNavbar className={isMobile ? "p-0" : ""}>
            <MDBContainer fluid>
              <MDBRow between center className={"w-100 d-flex d-md-none h-100"}>
                {isMobile && (
                  <MDBCol
                    size={"2"}
                    className={"position-absolute"}
                    style={{ top: 10, left: 10, zIndex: 10000 }}
                  >
                    <LangComponent
                      textClass={"text-black"}
                      dispatch={this.props.dispatch}
                    />
                  </MDBCol>
                )}

                <MDBCol
                  md={"8"}
                  lg={"10"}
                  className={isMobile ? "text-center" : ""}
                >
                  <BrandComponent
                    file={this.props.file}
                    dispatch={this.props.dispatch}
                    mode={"light"}
                  />
                </MDBCol>

                <MDBCol
                  size={"2"}
                  className={
                    "my-auto main-menubar d-flex align-items-center offset-xl-1"
                  }
                >
                  <MDBNavbarToggler
                    id={"navbar-toggler"}
                    children={
                      <MDBIcon
                        icon={this.state.collapseID ? "times" : "bars"}
                      />
                    }
                    className={"black-text"}
                    onClick={this.toggleCollapse("navbar-light")}
                  />
                </MDBCol>

                <MDBCol size={"12"}>
                  <MDBCollapse
                    id={"navbar-light"}
                    isOpen={!!this.state.collapseID}
                    onOpened={this.handleOpenNavbar.bind(this)}
                    navbar
                  >
                    <MDBNavbarNav left>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to={"/impact"}
                          {...hashLinkScrollProps}
                          className={
                            "link light text-uppercase font-weight-bold"
                          }
                          onClick={this.toggleCollapse("navbar-light")}
                        >
                          {i18n._("link_filevertV2_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to={"/impact#metrics"}
                          {...hashLinkScrollProps}
                          className={
                            "link light text-uppercase font-weight-bold"
                          }
                          onClick={this.toggleCollapse("navbar-light")}
                        >
                          {i18n._("link_metrics_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to={"/impact#scope"}
                          {...hashLinkScrollProps}
                          className={
                            "link light text-uppercase font-weight-bold"
                          }
                          onClick={this.toggleCollapse("navbar-light")}
                        >
                          {i18n._("link_scope_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to={"/impact/pro"}
                          className={
                            "link light text-uppercase font-weight-bold"
                          }
                          onClick={this.toggleCollapse("navbar-light")}
                        >
                          {i18n._("link_business_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to={"/offers"}
                          className={
                            "link light text-uppercase font-weight-bold"
                          }
                          onClick={this.toggleCollapse("navbar-light")}
                        >
                          {offerLinkLabel}
                        </MDBNavLink>
                      </MDBNavItem>

                      {/*ONLY ON MOBILE START*/}
                      <MDBNavItem className={"my-auto"}>
                        <MDBNavLink
                          link
                          to={"/legal-terms"}
                          className={"link light text-black"}
                        >
                          {i18n._("footer_basics_legales_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem className={"my-auto d-md-none"}>
                        <MDBNavLink
                          link
                          to={"/cgu"}
                          className={"link light text-black"}
                        >
                          {i18n._("footer_basics_cgu_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem className={"my-auto d-md-none"}>
                        <MDBNavLink
                          link
                          to={"/privacy"}
                          className={"link light text-black"}
                        >
                          {i18n._("footer_basics_privacy_link_label")}
                        </MDBNavLink>
                      </MDBNavItem>
                      {/*ONLY ON MOBILE END*/}

                      <MDBNavItem>
                        <LoginButtonComponent
                          onModalChangeCB={this.onModalChangeCB}
                          mod={"light"}
                        />
                      </MDBNavItem>
                    </MDBNavbarNav>
                  </MDBCollapse>
                </MDBCol>
              </MDBRow>

              <MDBRow between center className={"w-100 d-none d-md-flex"}>
                <MDBCol lg={"2"} md={"3"}>
                  <BrandComponent
                    file={this.props.file}
                    dispatch={this.props.dispatch}
                    mode={"light"}
                  />
                </MDBCol>

                <MDBCol
                  xl={"6"}
                  lg={"7"}
                  md={"7"}
                  sm={"5"}
                  className={
                    "main-menubar d-flex align-items-center offset-xl-1"
                  }
                >
                  <MDBNavbarNav className={"flex-row"}>
                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/impact#annonce"}
                        {...hashLinkScrollProps}
                        className={"link light text-uppercase font-weight-bold"}
                      >
                        {i18n._("link_filevertV2_label")}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/impact#metrics"}
                        {...hashLinkScrollProps}
                        className={"link light text-uppercase font-weight-bold"}
                      >
                        {i18n._("link_metrics_label")}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/impact#scope"}
                        {...hashLinkScrollProps}
                        className={"link light text-uppercase font-weight-bold"}
                      >
                        {i18n._("link_scope_label")}
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/impact/pro"}
                        className={"link light text-uppercase font-weight-bold"}
                      >
                        {i18n._("link_business_label")}
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem className={"my-auto"}>
                      <MDBNavLink
                        link
                        to={"/offers"}
                        className={"link light text-uppercase font-weight-bold"}
                      >
                        {offerLinkLabel}
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem className={"my-auto"}>
                      <LoginButtonComponent
                        onModalChangeCB={this.onModalChangeCB}
                        mod={"light"}
                      />
                    </MDBNavItem>
                  </MDBNavbarNav>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBNavbar>
        </header>
      );
    };
  }
);

const FVHeader = () => {
  if (isMobile)
    // prettier-ignore
    //@ts-ignore
    document.getElementById('root').classList.add('mobile');

  return (
    <div
      style={{ height: !isMobile ? 88 : 50 }}
      className={!isMobile ? "" : "mobile-header"}
    >
      <HeaderComponent />
      <HeaderLightComponent />
    </div>
  );
};

export default FVHeader;
