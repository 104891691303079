import axios, { AxiosResponse } from "axios";

const invoiceApi = {
  async getInvoices(user_id: string): Promise<any[]> {
    const invoices = await axios.get(`/user/${user_id}/invoice`);
    return invoices?.data?.data;
  },

  async getSubscriptions(cId: string): Promise<AxiosResponse<any>[]> {
    const subscriptions = await axios.get(`/subscriptions/${cId}`);
    return subscriptions.data;
  },

  async getCustomer(user_id: string): Promise<any> {
    const customer = await axios.get(`/user/${user_id}/customer`);
    return customer.data;
  },
};

export default invoiceApi;
