import { AnyAction } from "redux";
import { offerAction } from "../Actions";
import { defaultOffer } from "../../Constants";

const initialState = {
  currentPrice: 6.25,
  currentPeriod: "year",
  currentOffer: defaultOffer,
};

export default function offerReducer(
  state: any = initialState,
  { type, value }: AnyAction
) {
  const nextState = state ? { ...initialState, ...state } : initialState;

  switch (type) {
    case offerAction.CURRENT_OFFER:
      nextState.currentOffer = value;
      break;
    case offerAction.CURRENT_PRICE:
      nextState.currentPrice = value;
      break;
    case offerAction.CURRENT_PERIOD:
      nextState.currentPeriod = value;
      break;
    default:
      break;
  }
  return nextState;
}

export { offerAction };
