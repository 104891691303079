import React from "react";
import "./Layout.css";

import { connect } from "react-redux";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBCardText,
  MDBCardTitle,
  MDBNotification,
  MDBIcon,
  MDBLink,
} from "mdbreact";
import {
  convertDate,
  convertSize,
  convertTime,
  fixeVal,
} from "../../../../utils";
import UploaderFormComponent from "../UploaderForm/UploaderForm";
import i18n from "../../../../utils/i18n";
import LoaderComponent from "../../../Loader/Loader";
import { config } from "../../../../config";
import { isMobile, isTablet } from "react-device-detect";
import { fileAction, transferAction } from "../../../../Store";
import { ActionInputComponent } from "../../../Button";

interface Props {
  resetTransfer?: any;
  dispatch: Function;
  role: number;
  transfer: any;
  file: any[];
  resetCb?: any;
  submitCb: any;
  app: any;
  maximum_size?: number;
}

interface State {
  isDetailOpen: boolean;
  successCopyLink: boolean;
}

const ttlTransfer = [2, 7, 15];

let isDetailOpen: boolean = false;

const mapDispatchToProps = (dispatch: any) => ({
  resetTransfer: (fWmeta: any) => {
    if (!fWmeta || !fWmeta.length) return;
    fWmeta.forEach(({ cancel }: any) => setTimeout(cancel));
    dispatch(fileAction.purgeFile(() => dispatch(transferAction.purgeInfos())));
    dispatch(transferAction.purgeInfos({ purging: true }));
  },
});

class LayoutComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDetailOpen: isDetailOpen,
      successCopyLink: false,
    };
  }

  getPercent = (): number => {
    const loaded = this.props.transfer.loaded;
    const total = this.props.transfer.totalSize;

    const percent = (loaded * 100) / total;

    return percent > 100 ? 100 : percent;
  };

  handleClick = () =>
    this.setState(
      { isDetailOpen: !this.state.isDetailOpen },
      () => (isDetailOpen = this.state.isDetailOpen)
    );

  getSpeed = (formatted: boolean = true): string | number => {
    const { transfer } = this.props;

    let timing =
      (transfer.progress.current.timestamp - transfer.progress.last.timestamp) /
      1000;
    let amount =
      transfer.progress.current.loaded - transfer.progress.last.loaded;

    if (
      !transfer.progress.current.timestamp ||
      !transfer.progress.last.timestamp ||
      amount / timing < 0
    ) {
      if (!transfer.loaded) {
        return "";
      }
    }

    timing = (Date.now() - transfer.startTime) / 1000;
    amount = transfer.loaded;

    return formatted ? `${convertSize(amount / timing)}/s` : amount / timing;
  };

  getRemainingTime = (): string => {
    const { transfer } = this.props;
    const speed: any = this.getSpeed(false);
    const remainingSize = transfer.totalSize - transfer.loaded;

    const remainingTimeInSeconds = Math.round(remainingSize / speed);

    return convertTime(remainingTimeInSeconds, i18n._("remaining_label"));
  };

  cancelTransfer = async () => {
    const confirmation = window.confirm(
      i18n._("confirm_upload_cancelation_label")
    );

    if (confirmation) {
      this.props.resetTransfer(this.props.file);
    }
  };

  handleLinkCopy = () => {
    const dataDom = document.getElementById("data-to-copy");
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    if (document.execCommand("copy")) {
      this.setState({ ...this.state, successCopyLink: true }, () =>
        setTimeout(
          () => this.setState({ ...this.state, successCopyLink: false }),
          1500
        )
      );
    }
  };

  handleMailTo = () => {
    const mailToLink = document.createElement("a"),
      subject: string = i18n._("link_copy_subject", [
        i18n._("fv_title"),
        config.ihmUrl,
      ]),
      body: string = i18n
        ._("link_copy_message", [
          i18n._("fv_title"),
          convertDate(
            new Date(this.props.transfer.expiration_date),
            i18n.getLang(),
            true
          ),
          parseCurly(this.props.transfer.link),
        ])
        .split("<br />")
        .join("%0D%0A")
        .split("<br />")
        .join("%0D%0A");

    // if (config.env === 'dev')
    //   return alert(body);

    mailToLink.setAttribute("target", "_blank");
    mailToLink.href = `mailto:?subject=${subject}&body=${body}`;

    mailToLink.click();
  };

  getTtlDate = (): string => {
    const ttl = ttlTransfer[this.props.role || 0];
    const d = new Date();
    d.setDate(d.getDate() + ttl);

    return convertDate(
      this.props?.transfer?.expiration_date || d,
      i18n.getLang()
    );
  };

  handleResend = () => {
    document.location.reload();
  };

  render = () => {
    const {
      input,
      previews,
      submitButton,
      dropzoneProps,
      file,
      transfer,
    }: any = this.props;
    let mpB = window.innerHeight > 960 ? "my-4" : "my-1";
    let mb4 = window.innerHeight > 960 ? "my-4" : "mb-4";
    let pt4 = window.innerHeight > 960 ? "my-4" : "pt-4";
    const oY = window.innerHeight > 960 ? "" : "o-y-scroll";
    let faX: any = window.innerHeight > 960 ? "5x" : "3x";
    const classSpinnerMobile = "mx-auto my-auto text-center";
    const classSpinner1 = "text-center";
    const classSpinner2 = "text-center pt-5";

    if (transfer.isUploadFinished && isDetailOpen)
      this.setState(
        {
          ...this.state,
          isDetailOpen: false,
        },
        () => (isDetailOpen = false)
      );

    if (window.innerWidth <= 375 || isTablet) {
      mpB = "my-0";
      faX = "2x";
    }
    const HeaderProgress = () => (
      <header
        className={`header-transfer fv_layout_current_header fv_layout_current_header_deposit d-flex ${
          transfer.isLocked ? "flex-row-reverse" : "flex-row"
        }`}
      >
        {!!file.length &&
          !transfer.isLocked &&
          !transfer.isUploadFinished &&
          input}

        {!!file.length && !transfer.isUploadFinished && (
          <div
            className={
              "w-25 d-flex fv_layout_current_header_right fv_layout_current_header_right_deposit my-auto"
            }
          >
            <div
              onClick={this.handleClick}
              className={
                "my-auto hover-pointer fv_layout_current_header_size_wrapper fv_layout_current_header_size_wrapper_deposit d-inline-flex"
              }
            >
              <span className={"lh-1"}>{convertSize(transfer.totalSize)}</span>
            </div>
            <div
              className={"my-auto fv_layout_current_header_arrow_wrapper"}
              style={{ zIndex: 1000 }}
            >
              <span
                onClick={this.handleClick}
                className={`icon-chevron-right float-right hover-pointer ${
                  this.state.isDetailOpen ? "show" : "hide"
                }`}
              ></span>
            </div>
          </div>
        )}
      </header>
    );

    const FinishedHeader = () => (
      <MDBCardHeader
        className={`finished text-center fv_layout_finished_header fv_layout_finished_header_deposit py-0 py-lg-2 py-xl-3 ${
          isTablet && "px-5"
        }`}
      >
        {!isMobile && (
          <MDBIcon
            size={faX}
            icon={"check-circle"}
            className={"green-text align"}
          />
        )}
        <MDBCardTitle
          className={`font-weight-bold centered ${isMobile ? "d-flex" : ""}`}
        >
          {isMobile && (
            <MDBIcon
              size={faX}
              icon={"check-circle"}
              className={"green-text align p-2"}
            />
          )}
          <span className={"my-auto "}>{i18n._("upload_finished_title")}</span>
        </MDBCardTitle>
      </MDBCardHeader>
    );

    const FinishedBody = ({ error }: any) => (
      <MDBCard
        className={
          "h-100 fv_layout_finished_card fv_layout_finished_card_deposit"
        }
      >
        <FinishedHeader />
        <MDBCardBody className={`text-center ${oY}`}>
          <div className={`${mpB} my-1 mbox`}>
            <small>
              {i18n._("transfer_sharing_indication")}
              <span className="triangle" />
            </small>
          </div>

          <ActionInputComponent
            customClass={`outline px-0 mx-0 text-center hover-pointer fv-uploader-layout fv-uploader-layout-deposit fv_uploader_layout_deposit`}
            mailTo={{
              subject: i18n._("link_copy_subject", [
                i18n._("fv_title"),
                config.ihmUrl,
              ]),
              body: i18n
                ._("link_copy_message", [
                  i18n._("fv_title"),
                  convertDate(
                    new Date(this.props.transfer.expiration_date),
                    i18n.getLang(),
                    true
                  ),
                  encodeURIComponent(parseCurly(this.props.transfer.link)),
                ])
                .split("<br />")
                .join("%0D%0A")
                .split("<br />")
                .join("%0D%0A"),
              enabled: true,
            }}
            value={parseCurly(this.props.transfer.link)}
          />

          <MDBCardText className={mpB}>
            <MDBIcon icon={"map-marker-alt"} className={"green-text mx-1"} />
            {i18n._("upload_finished_stockage_label")}
          </MDBCardText>

          <MDBCardText className={mb4}>
            {i18n._("transfer_expiration_label", this.getTtlDate())}
          </MDBCardText>

          <MDBCardText className={pt4}>
            <MDBIcon icon={"seedling"} className={"green-text mx-1"} />
            {i18n._("upload_finished_consumption_label")}
          </MDBCardText>

          <p className={"h5 centered"} style={{ color: "green" }}>
            {fixeVal(transfer.consumption.gCO2)} gCO2
          </p>
        </MDBCardBody>
        <MDBCardFooter className={"text-center"}>
          <MDBCardText>
            <a
              href={"#resend"}
              role={"button"}
              className={"nav-link"}
              onClick={this.handleResend}
            >
              {i18n._("upload_resend_label")}
            </a>
          </MDBCardText>
        </MDBCardFooter>
      </MDBCard>
    );

    const FinishedErrorBody = ({ error }: any) => (
      <MDBCard
        className={
          "h-100 fv_layout_finished_card fv_layout_finished_card_deposit"
        }
      >
        <MDBCardHeader
          className={`finished text-center fv_layout_finished_header fv_layout_finished_header_deposit py-0 py-lg-2 py-xl-3 ${
            isTablet && "px-5"
          }`}
        >
          {!isMobile && (
            <MDBIcon
              size={faX}
              icon={"times-circle"}
              className={"text-danger align"}
            />
          )}
          <MDBCardTitle
            className={`font-weight-bold centered ${
              isMobile ? "d-flex" : ""
            } text-danger`}
          >
            {isMobile && (
              <MDBIcon
                size={faX}
                icon={"times-circle"}
                className={"text-danger align p-2"}
              />
            )}
            <a href={"#upload-error"} className={"my-auto "}>
              {i18n._("upload_error_title")}
            </a>
          </MDBCardTitle>
        </MDBCardHeader>

        <MDBCardBody className={`text-center ${oY} py-5`}>
          <p
            className={"text-dark"}
            dangerouslySetInnerHTML={{ __html: i18n._("upload_error_text") }}
          ></p>
        </MDBCardBody>

        <MDBCardFooter className={"text-center"}>
          <MDBCardText>
            <a
              href={"#resend"}
              role={"button"}
              className={"nav-link"}
              onClick={this.handleResend}
            >
              {i18n._("upload_resend_label")}
            </a>
          </MDBCardText>
        </MDBCardFooter>
      </MDBCard>
    );

    return (
      <MDBContainer
        className={`fv_layout_container layout-container fv-layout-container-deposit fv_layout_container_deposit ${
          this.state.isDetailOpen ? "m-0" : ""
        }`}
      >
        {this.state.successCopyLink && (
          <MDBNotification
            show
            fade
            message={i18n._("upload_finished_copied_label")}
            autohide={1000}
            titleClassName={"hidden d-none"}
            style={{
              position: "fixed",
              bottom: "10px",
              left: "10px",
              zIndex: 9999,
            }}
          />
        )}

        <MDBRow
          className={`${
            !this.props.file.length || !this.state.isDetailOpen
              ? "w-100"
              : "w-200"
          }`}
        >
          <div {...dropzoneProps}>
            {!file.length && input}
            {!this.props.app.APP_ERROR &&
              !!file.length &&
              !transfer.isUploadFinished && <HeaderProgress />}

            {!!this.props.file.length &&
              !this.props.transfer.isLocked &&
              !transfer.isUploadFinished && (
                <UploaderFormComponent
                  maximum_size={this.props?.maximum_size}
                  submitCb={this.props.submitCb}
                />
              )}

            {!!this.props.file.length &&
              !this.props.transfer.isLocked &&
              !transfer.isUploadFinished &&
              submitButton}
            {!this.props.app.APP_ERROR &&
              !!this.props.file.length &&
              this.props.transfer.isLocked &&
              !transfer.isUploadFinished && (
                <MDBCol size={"12"} className={"text-center "}>
                  <LoaderComponent
                    type={"Spinner"}
                    percent={Math.round(this.getPercent())}
                  />
                </MDBCol>
              )}
            {!this.props.app.APP_ERROR &&
              !!this.props.file.length &&
              this.props.transfer.isLocked &&
              !transfer.isUploadFinished && (
                <MDBCol
                  size={"12"}
                  className={isMobile ? classSpinnerMobile : classSpinner1}
                >
                  {!this.props.transfer.isUploadFinished && (
                    <p className={"text-center mb-1"}>
                      {`${
                        this.props.transfer.loaded
                          ? convertSize(this.props.transfer.loaded)
                          : ""
                      } ${
                        this.props.transfer.loaded
                          ? i18n._("upload_finished_speed_label")
                          : ""
                      } ${this.props.transfer.loaded ? this.getSpeed() : ""}`}
                    </p>
                  )}
                  <p className={"text-center"}>
                    {!this.props.transfer.isUploadFinished
                      ? `${
                          this.props.transfer.loaded
                            ? i18n._("upload_finished_about_label")
                            : ""
                        } ${
                          this.props.transfer.loaded
                            ? this.getRemainingTime()
                            : ""
                        }`
                      : `${convertSize(this.props.transfer.totalSize)} ${i18n._(
                          "upload_finished_about_label"
                        )} ${convertTime(this.props.transfer.totalTime)}`}
                  </p>
                </MDBCol>
              )}
            {!this.props.app.APP_ERROR &&
              !!this.props.file.length &&
              this.props.transfer.isLocked &&
              !this.props.transfer.isUploadFinished && (
                <MDBCol
                  size={"12"}
                  className={isMobile ? classSpinnerMobile : classSpinner2}
                >
                  <MDBLink to={"#"} onClick={this.cancelTransfer}>
                    {i18n._("cancel_label")}
                  </MDBLink>
                </MDBCol>
              )}

            {this.props.app.APP_ERROR && (
              <FinishedErrorBody error={this.props.app.APP_ERROR} />
            )}
            {this.props.transfer.isUploadFinished && <FinishedBody />}
          </div>

          <MDBCol
            lg={"6"}
            md={isTablet ? "5" : "12"}
            className={`preview-wrapper ${
              !!this.props.file.length && this.state.isDetailOpen
                ? "show"
                : "hide d-none"
            }`}
          >
            <header className={"header-files absolute text-lg-center"}>
              <span
                className={"col-lg-12 col-md-8 text-lg-center text-md-left"}
              >
                {i18n._("files_details_label")}
              </span>
              <span
                className={
                  "d-lg-none d-md-inline-flex hover-pointer text-right float-right"
                }
                onClick={this.handleClick}
              >
                <MDBIcon icon={"times"} />
              </span>
            </header>

            <div className={`${this.state.isDetailOpen ? "" : "d-none"}`}>
              {previews}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

const parseCurly = (str: string) =>
  str.split("[").join("_5B_").split("]").join("_5D_");

const mapStateToProps = (state: any) => ({
  app: state.app,
  transfer: state.transfer.transfers,
  file: state.file,
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
