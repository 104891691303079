import React from "react";
import "./Activity.css";

import { connect } from "react-redux";

import { i18n } from "../../../../utils/";
import transferApi from "../../../../Api/transferApi";
import convertSize, { convertGCO2 } from "../../../../utils/convertSize";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBLink,
  MDBRow,
} from "mdbreact";
import { userApi } from "../../../../Api";

interface Props {
  dispatch: Function;
  user: any;
  title: string;
  changeTab?: any;
}

interface State {
  currentConsumption: {
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  currentTeamConsumption: {
    sizeIn0: number;
    kWh: number;
    kms: number;
    gCO2: number;
  };
  isLoading: boolean;
  fullConsumption: {
    sizeIn0: number;
    gCO2: number;
  };
  totalAttachmentsSaved: string;
  savedByYearGCO2: string;
  savedPUERatio: string;
  savedConsumption: {
    activity_transfered_label: number;
    activity_saved_by_pue_label: number;
    activity_saved_by_year_label: number;
    activity_saved_label: number;
    activity_compensated_label: number;
  };
}

interface ItemProps {
  size:
    | "3x"
    | "lg"
    | "1x"
    | "2x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x"
    | undefined;
  iconName: string;
  value: number | string;
  label: string;
  suffix?: string;
}

const waterRatio: number = 1.8;

// export function TeamConsumptionComponent({team_consumption}: any) {
//   return (
//     <MDBContainer>
//       <MDBRow>
//         <MDBCol size={'6'} />
//         <MDBCol size={'6'} />
//       </MDBRow>
//     </MDBContainer>
//   );
// }

class ActivityComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      savedConsumption: {
        activity_transfered_label: 0,
        activity_saved_by_pue_label: 0,
        activity_saved_by_year_label: 0,
        activity_saved_label: 0,
        activity_compensated_label: 0,
      },
      fullConsumption: {
        sizeIn0: 0,
        gCO2: 0,
      },
      currentConsumption: {
        sizeIn0: 0,
        kWh: 0,
        kms: 0,
        gCO2: 0,
      },
      currentTeamConsumption: {
        sizeIn0: 0,
        kWh: 0,
        kms: 0,
        gCO2: 0,
      },
      isLoading: false,
      totalAttachmentsSaved: "",
      savedByYearGCO2: "",
      savedPUERatio: "",
    };
  }

  componentDidMount = async () => {
    setTimeout(async () => {
      await this.getUploadHistory();
    }, 500);
  };

  getUploadHistory = async () => {
    this.setState({ isLoading: true });
    let history: any[] = await transferApi.getAllUserTransfer(
      this.props.user.id
    );

    const userActivity = await userApi.getUserActivity(this.props.user.id);

    this.setState({
      ...this.state,
      savedConsumption: userActivity,
    });

    const totalAttachmentsSaved = convertGCO2(
      (() => {
        return history?.filter(({ consumption, size }) => {
          const x = 30 * Math.pow(1024, 2);
          return Number(consumption?.size) > x || Number(size) > x;
        })?.length;
      })() * 46
    );

    const minTime = history?.reduce(
      (min, { created_at }) => (!min || created_at < min ? created_at : min),
      0
    );

    const days =
      Date.now() / 1000 / 60 / 60 / 24 -
      new Date(minTime).getTime() / 1000 / 60 / 60 / 24;

    const ratioPerYear = 365 / days;

    const concurrentConsumedTime = history?.length * 24 * 365;
    const fvConsumedTime = history?.reduce(
      (acc, cur) => (acc += cur?.consumption?.duration || 24 * 365),
      0
    );

    const currentDate = new Date();
    const startDate = new Date(
      currentDate.setFullYear(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      )
    ).getTime();

    const fullConsumption = history.reduce(
      (acc, cur) => ({
        sizeIn0:
          acc?.sizeIn0 +
          (cur?.consumption?.size ||
            (() => {
              if (cur.size) return Number(cur.size);
              if (cur.transfer_size) return Number(cur.transfer_size);
              return 0;
            })()),
        gCO2: acc?.gCO2 + (cur?.consumption?.gCO2 || 0),
      }),
      { sizeIn0: 0, gCO2: 0 }
    );

    const fvKWHConsumed =
      (fullConsumption.sizeIn0 / 1024 / 1024 / 1024 / 1024) * fvConsumedTime;
    const concurrentKWHConsumed =
      (fullConsumption.sizeIn0 / 1024 / 1024 / 1024 / 1024) *
      concurrentConsumedTime;
    //@ts-ignore
    const getC02Ratio = () => window?.connexionInfos?.CO2Ratio;
    const savedByYearGCO2 = convertGCO2(
      (concurrentKWHConsumed - fvKWHConsumed) * getC02Ratio() * ratioPerYear
    );

    history = history.filter(
      (item: any) => startDate < new Date(item.date_transfer).getTime()
    );

    let teamHistory: any[] = history.filter(
      (transfer: any) => transfer.user_id !== this.props.user.id
    );
    history = history.filter(
      (transfer: any) => transfer.user_id === this.props.user.id
    );
    let { currentConsumption, currentTeamConsumption } = this.state;

    history.forEach((line: any) => {
      if (
        line?.consumption?.kWh ||
        line?.consumption?.gCO2 ||
        line?.consumption?.kms ||
        line?.consumption?.size
      ) {
        currentConsumption.kWh += line?.consumption?.kWh || 0;
        currentConsumption.gCO2 += line?.consumption?.gCO2 || 0;
        currentConsumption.kms += line?.consumption?.kms || 0;
        currentConsumption.sizeIn0 +=
          line?.consumption?.size || parseInt(line?.transfer_size);
      }
    });

    teamHistory.forEach((line: any) => {
      if (
        line?.consumption?.kWh ||
        line?.consumption?.gCO2 ||
        line?.consumption?.kms ||
        line?.consumption?.size
      ) {
        currentTeamConsumption.kWh += line?.consumption?.kWh || 0;
        currentTeamConsumption.gCO2 += line?.consumption?.gCO2 || 0;
        currentTeamConsumption.kms += line?.consumption?.kms || 0;
        currentTeamConsumption.sizeIn0 +=
          line?.consumption?.size || parseInt(line?.transfer_size);
      }
    });

    this.setState({
      fullConsumption,
      currentConsumption,
      currentTeamConsumption,
      isLoading: false,
      totalAttachmentsSaved,
      savedByYearGCO2,
    });
  };

  handleCSVExport = async () => {
    const report = await userApi.getCSVConsumptionReport(
      "csv",
      this.props.user.id
    );
    this.handleReportDownload(report);
  };

  handleReportDownload = (reportCSV: ArrayBuffer) => {
    const reportDate = new Date();
    const DOMid = `filevert-consommation_${reportDate.getDate()}-${
      reportDate.getMonth() + 1
    }-${reportDate.getFullYear()}`;

    let link: any = document.getElementById(DOMid);

    if (link) return link.click();

    const url = window.URL.createObjectURL(new Blob([reportCSV]));

    link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("id", DOMid);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", `${DOMid}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  render = () => {
    const { currentConsumption, currentTeamConsumption } = this.state;
    const { user, title } = this.props;

    const { current_uploaded_size, limit_total } = this.props.user;
    const percentUsage = (current_uploaded_size * 100) / limit_total;

    const teamActivities: ItemProps[] = [
      {
        size: "1x",
        iconName: "cloud-upload-alt",
        value: convertSize(currentTeamConsumption.sizeIn0).split(" ")[0],
        label: "weight",
        suffix: convertSize(currentTeamConsumption.sizeIn0).split(" ")[1],
      },
      {
        size: "1x",
        iconName: "bolt",
        value: currentTeamConsumption.kWh.toFixed(2),
        label: "electricity",
      },
      {
        size: "1x",
        iconName: "seedling",
        value: currentTeamConsumption.gCO2.toFixed(2),
        label: "carbon",
      },
      {
        size: "1x",
        iconName: "tint",
        value: (currentTeamConsumption.kWh * waterRatio).toFixed(2),
        label: "L",
      },
      {
        size: "1x",
        iconName: "car",
        value: currentTeamConsumption.kms.toFixed(2),
        label: "km",
      },
    ];

    const activities: ItemProps[] = [
      {
        size: "3x",
        iconName: "cloud-upload-alt",
        value: convertSize(currentConsumption.sizeIn0).split(" ")[0],
        label: "weight",
        suffix: convertSize(currentConsumption.sizeIn0).split(" ")[1],
      },
      {
        size: "3x",
        iconName: "bolt",
        value: currentConsumption.kWh.toFixed(2),
        label: "electricity",
      },
      {
        size: "3x",
        iconName: "seedling",
        value: currentConsumption.gCO2.toFixed(2),
        label: "carbon",
      },
      {
        size: "3x",
        iconName: "tint",
        value: (currentConsumption.kWh * waterRatio).toFixed(2),
        label: "L",
      },
      {
        size: "3x",
        iconName: "car",
        value: currentConsumption.kms.toFixed(2),
        label: "km",
      },
    ];
    return (
      <MDBContainer className={"pr-0"}>
        <MDBRow className={"border-bottom"}>
          <MDBCol
            lg={"8"}
            md={"12"}
            className={
              "my-auto text-left tab-title-wrapper fv_tab_title_wrapper"
            }
          >
            <div
              className={
                "float-left font-weight-bold fv_activity_compensation_wrapper"
              }
            >
              <h3
                className={"d-block"}
                dangerouslySetInnerHTML={{
                  __html: i18n._("activity_transfered_label", [
                    convertSize(
                      this.state.savedConsumption.activity_transfered_label
                    ),
                  ]),
                }}
              ></h3>

              {/* Différence entre PUE 1.59 classique et 1.31 FileVert */}
              <span
                className={"d-block"}
                dangerouslySetInnerHTML={{
                  __html: i18n._("activity_saved_by_pue_label", [
                    convertGCO2(
                      this.state.savedConsumption.activity_saved_by_pue_label
                    ),
                  ]),
                }}
              ></span>
              {/* Différence par année entre le temps de stockage et celui de FileVert */}
              <span
                className={"d-block"}
                dangerouslySetInnerHTML={{
                  __html: i18n._("activity_saved_by_year_label", [
                    convertGCO2(
                      this.state.savedConsumption.activity_saved_by_year_label
                    ),
                  ]),
                }}
              ></span>
              {/* Calcul de la page offre pour l'organisation (Pièce jointe) */}
              <span
                className={"d-block"}
                dangerouslySetInnerHTML={{
                  __html: i18n._("activity_saved_label", [
                    convertGCO2(
                      this.state.savedConsumption.activity_saved_label
                    ),
                  ]),
                }}
              ></span>
              {/* Consommation totale de l'organisation qui a été compensé (achat de titre droit à polluer) */}
              <span
                className={"d-block"}
                dangerouslySetInnerHTML={{
                  __html: i18n._("activity_compensated_label", [
                    convertGCO2(
                      this.state.savedConsumption.activity_compensated_label
                    ),
                  ]),
                }}
              ></span>
            </div>
          </MDBCol>
          {this.props.user?.permissions?.consumption_export?.value &&
            !user.referer_id && (
              <MDBCol lg={"4"} md={"12"} className={"my-auto text-left"}>
                <MDBBtn
                  color={"default"}
                  onClick={this.handleCSVExport}
                  className={"text-nowrap"}
                >
                  <h5 className={"text-left font-weight-bold"}>
                    {i18n._("export_report_label")}
                  </h5>
                </MDBBtn>
              </MDBCol>
            )}
        </MDBRow>
        <MDBRow>
          <MDBCol size={"12"}>{title}</MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBRow
            className={"w-100 bg-light-grey my-5 py-5 justify-content-around"}
          >
            {activities.map((el: ItemProps, k: number) => (
              <MDBCol key={k} xs={"12"} md={"2"} className={"no-padding"}>
                <ActivityItem item={el} />
              </MDBCol>
            ))}
          </MDBRow>

          {user.role > 1 &&
            !user.referer_id &&
            !!user?.permissions?.team_size?.value && (
              <MDBContainer>
                <MDBRow>
                  <MDBCol size={"12"}>
                    <h5 className={"text-left font-weight-bold"}>
                      {i18n._("team_tracking_label")}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  className={
                    "text-center bg-light-grey my-5 py-5 justify-content-around"
                  }
                >
                  {teamActivities.map((el: ItemProps, k: number) => (
                    <MDBCol key={k} xs={"12"} md={"2"}>
                      <ActivityItem mod={"compact"} item={el} />
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBContainer>
            )}
        </MDBRow>

        {/* <MDBRow>
          <MDBCol size={"12"}>
            <h5 className={"text-left font-weight-bold"}>
              {i18n._("activity_active_transfer_label")}{" "}
              {convertSize(current_uploaded_size)} / {convertSize(limit_total)}
            </h5>
          </MDBCol>
        </MDBRow>
        <MDBRow
          className={
            "row text-center bg-light-grey my-5 py-5 justify-content-around"
          }
        >
          <MDBCol lg={"10"} sm={"8"} className={"flex-column"}>
            <div className={"py-1 px-5"}>
              <progress className={"w-100"} value={percentUsage / 10} />
            </div>
          </MDBCol>
          <MDBCol lg={"2"} sm={"4"}>
            <div style={{ alignSelf: "center" }}>
              <MDBLink
                to={"/profil?tab=2"}
                onClick={(e: any) => this.props.changeTab(e, 2)}
              >
                {i18n._("activity_manage_transfer_label")}
              </MDBLink>
            </div>
          </MDBCol>
        </MDBRow> */}

        {/*<TeamConsumptionComponent team_consumption={currentConsumption} />*/}
      </MDBContainer>
    );
  };
}

const ActivityItem = ({ item, mod }: { item: ItemProps; mod?: string }) => {
  const { iconName, value, label, suffix, size } = item;
  return "compact" === mod ? (
    <div
      className={
        "consuptionItem text-center d-inline-block fv_consumption_item_container"
      }
    >
      <span className={"consuptionItem img text-default d-inline-flex"}>
        <MDBIcon className={"text-default"} size={size} icon={iconName} />
      </span>
      &nbsp;
      <span
        className={
          "text-default text-center font-weight-bold text-bold d-inline-flex"
        }
      >
        {value}
        &nbsp;
        {!suffix && (
          <strong>{i18n._(`tab_activity_${label?.toLowerCase()}_unit`)}</strong>
        )}
        {suffix && <strong>{suffix}</strong>}{" "}
      </span>
    </div>
  ) : (
    /*<div className={'consuptionItem text-center d-inline-block fv_consumption_item_container'}>

        <span className={'consuptionItem img text-default'}>
        <MDBIcon size={size} icon={iconName} className={'text-default'}></MDBIcon>
        </span>

        <span className={'text-default text-center font-weight-bold text-bold'}>
          {value}
          <hr className={'consuptionItem separator text-default bg-white my-1 d-none d-lg-flex'}/>
        </span>

        <span className={'consuptionItem label text-default'}>
          {label}
        </span>
      </div>*/

    <div className={"activityItem container-fluid no-padding"}>
      <div className={"container"}>
        <span className={"activityItem img"}>
          <MDBIcon size={size} icon={iconName}></MDBIcon>
        </span>
        <span className={"activityItem value"}>{value}</span>
      </div>

      <hr className={"activityItem separator"} />

      <div className={"container"}>
        <span className={"activityItem label"}>
          {!suffix && (
            <strong>
              {i18n._(`tab_activity_${label?.toLowerCase()}_unit`)}
            </strong>
          )}
          {suffix && <strong>{suffix}</strong>}
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: i18n._(`tab_activity_${label?.toLowerCase()}_label`),
            }}
          />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(ActivityComponent);
