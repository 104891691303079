export const gradients = {
  clair: [
    { className: "vertige", text: "Forêt", id: "0-0" },
    { className: "deepsea", text: "Ozone", id: "0-1" },
    { className: "veryblue", text: "Océan", id: "0-2" },
    { className: "rouge", text: "Aube", id: "0-3" },
    { className: "test", text: "Nuit", id: "0-4" },
    { className: "sublime", text: "Nébuleuse", id: "0-5" },
  ],
  sombre: [
    { className: "bb", text: "Air", id: "1-0" },
    { className: "rb", text: "Ciel", id: "1-1" },
    { className: "ppp", text: "Lune", id: "1-2" },
    { className: "aa", text: "Aurore", id: "1-3" },
    { className: "orange", text: "Jour", id: "1-4" },
    { className: "am", text: "Voie lactée", id: "1-5" },
  ],
};
