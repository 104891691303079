import { formatDate, i18n, translateHeaders } from "../../utils";
import React, { useState } from "react";
import { userApi } from "../../Api";
import * as userAction from "../../Store/Actions/userAction";
import { TableListComponent, MDBSwitch } from "./TableListComponent";
import { subscriptionsCells } from "../../Constants";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from "mdbreact";
import CustomOfferScreen from "../../Screens/CustomOffer/CustomOfferScreen";

const cancelSubscription = async (sId: string, dispatch: any, user_id: any) => {
  if (!window.confirm(i18n._("confirm_unsubscription_label"))) return;

  const updatedUser = await userApi.cancelSubscription(sId, user_id);

  if (updatedUser && !updatedUser.error) {
    dispatch && dispatch(userAction.loginUser(updatedUser));
  }
};
const reactivateSubscription = async (
  sId: string,
  dispatch: any,
  user_id: any
) => {
  if (!window.confirm(i18n._("confirm_reactivate_subscription_label"))) return;

  const updatedUser = await userApi.reactivateSubscription(sId, user_id);

  if (updatedUser && !updatedUser.error) {
    dispatch && dispatch(userAction.loginUser(updatedUser));
  }
};
const getSubPl = (sub: any, updateCb: any, dispatch: any, user_id: any) => ({
  subscription_cell_name: sub?.name,
  subscription_cell_number: sub?.id,
  subscription_cell_users: sub?.qty - 1 || 1,
  subscription_cell_date: i18n._("invoices_date_text", [
    sub?.current_period_start
      ? formatDate(new Date(sub?.current_period_start * 1000))
      : "N/A",
    sub?.current_period_end
      ? formatDate(new Date(sub?.current_period_end * 1000))
      : "N/A",
  ]),
  subscription_cell_status: sub?.isActive ? sub?.status : "-",
  subscription_cell_update: (
    <>
      <a
        href={"#update-subscription"}
        className={`${sub?.isActive ? "hover-pointer" : "disabled"}`}
        role={"button"}
        onClick={sub?.isActive ? updateCb : undefined}
      >
        {i18n._("btn_modify_label")}
      </a>
    </>
  ),
  subscription_cell_action: (
    <MDBSwitch
      checked={sub?.isActive}
      onChange={() => {
        if (sub?.isActive) cancelSubscription(sub?.id, dispatch, user_id);
        else reactivateSubscription(sub?.id, dispatch, user_id);
      }}
    />
  ),
});

export function TableSubscriptionsComponent({
  subscriptions,
  dispatch,
  user_id,
}: {
  subscriptions: any;
  dispatch: any;
  user_id: any;
}) {
  const [state, setState] = useState({
    modalOpen: false,
    page: 1,
    rowsPerPage: 5,
  });

  const [orderStatus, setOrderStatus]: [{ updated: boolean }, any] = useState({
    updated: false,
  });

  return (
    <>
      <TableListComponent
        headers={translateHeaders(subscriptionsCells)}
        elements={[subscriptions[0]]
          // .filter(({ isActive }: { isActive?: boolean }) => isActive)
          .map((sub: any) =>
            getSubPl(
              sub,
              () => setState({ ...state, modalOpen: !state.modalOpen }),
              dispatch,
              user_id
            )
          )}
      />
      <MDBModal
        contentClassName={"modal-unstyled fv_modal_unstyled"}
        size={"md"}
        isOpen={state.modalOpen}
        toggle={() => setState({ ...state, modalOpen: !state.modalOpen })}
      >
        <MDBModalBody>
          {state.modalOpen && !orderStatus.updated && (
            <CustomOfferScreen minimal cb={setOrderStatus} />
          )}
          {state.modalOpen && orderStatus.updated && <UpdatedComponent />}
        </MDBModalBody>
      </MDBModal>
    </>
  );
}

const UpdatedComponent = function () {
  const statusKey = "SUCCESS";
  const [waitfor, setWaitfor]: any = useState(5);

  if (waitfor) setTimeout(() => setWaitfor(waitfor - 1), 1000);
  if (waitfor === 0) {
    document.location.reload();
    return null;
  }

  return (
    <MDBRow>
      <MDBCol size={"12"}>
        <MDBCard
          style={{ margin: "auto" }}
          className={`card-wrapper mx-auto m-5 fv_ordercard`}
        >
          <MDBCardHeader
            className={`font-weight-bold justify-content-center d-flex centered text-center text-green`}
          >
            <MDBIcon
              size={"3x"}
              icon={"check-circle"}
              className={"green-text align"}
            />
            <span className={"p-3"}>
              {i18n._(`CARD_HEADER.ORDER_${statusKey}.LABEL`)}
            </span>
          </MDBCardHeader>
          <MDBCardBody
            className={"p-5 d-flex flex-column justify-content-center"}
            style={{ minHeight: 400 }}
          >
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.INTRO`)}</p>
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.CONTENT`, waitfor)}</p>
            <p>{i18n._(`CARD_BODY.ORDER_${statusKey}.OUTRO`)}</p>
          </MDBCardBody>
          <MDBCardFooter className={"text-center"}>
            <MDBBtn
              onClick={() => {
                return document.location.reload();
              }}
            >
              {i18n._(`BUTTONS.RELOAD.LABEL`)}
            </MDBBtn>
          </MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};
