import React from "react";
import "./ConsumptionItem.css";
import { i18n } from "../../../../utils";

const waterRatio: number = 1.8;
function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const ConsumptionItem = function ({
  iconName,
  label,
  value,
  getUnit,
  consumption,
  type,
  isPreview,
  isOpen,
  forceIOS,
}: any) {
  let previewKey = "WEIGHT";

  switch (label) {
    case "Km":
      previewKey = "KM";
      value = consumption["kms"];
      break;

    case "gCO2":
      previewKey = "CARBON";
      value = consumption["gCO2"];
      break;

    case "kWh":
      previewKey = "ELECTRICITY";
      value = consumption["kWh"];
      break;
    case "L":
      previewKey = "L";
      value = consumption["kWh"] * waterRatio;
      break;

    default:
      break;
  }

  if (isPreview) {
    return (
      <div
        className={`consuptionItem text-center d-inline-bloc footer-preview-item fv_footer_preview_item fv_consumption_item_container`}
      >
        <span className={"consuptionItem img text-green"}>
          <i className={`${isOpen && "text-green"} ${iconName}`}></i>
        </span>

        {isOpen && (
          <>
            <p className={"exp text-center text-white pt-3"}>
              {i18n._(`FOOTER_CONSO_EXPLAINATION.${previewKey}.LABEL`)}
            </p>

            <p className={"exp text-center"}>
              {i18n._(`FOOTER_CONSO_EXPLAINATION.${previewKey}.TEXT`)}
            </p>
          </>
        )}
      </div>
    );
  }

  // prettier-ignore
  //@ts-ignore
  return (type !== 'download') ? (
    <div className={'consuptionItem text-center d-inline-block fv_consumption_item_container'}>

        <span className={'consuptionItem img text-white'}>
          <i className={iconName}></i>
        </span>

      <span className={'text-white text-center font-weight-bold text-bold'}>
          {getUnit(value)}
        <hr className={'consuptionItem separator text-white bg-white my-1 d-none d-lg-flex'}/>
        </span>

      <span className={'consuptionItem label text-white'}>
          {label}
        </span>
    </div>
  ) : (
    <div className={'consuptionItem text-center d-inline-block fv_consumption_item_container'}>

        <span className={'consuptionItem img text-white d-inline-flex'}>
          <i className={iconName}></i>
        </span>
      &nbsp;

      { (!isIOS() || forceIOS) &&
      <span className={'text-white text-center font-weight-bold text-bold d-inline-flex'}>
            {` ${getUnit(value)} `}
        {label}
          </span>
      }
    </div>
  );
};

export default ConsumptionItem;
