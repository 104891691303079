import React from "react";
import "./Brand.css";

import { images } from "../../../../assets";
import { Link } from "react-router-dom";
import { i18n } from "../../../../utils";
import { connect } from "react-redux";
import { fileAction, transferAction } from "../../../../Store";

const mapDispatchToProps = (dispatch: any) => ({
  resetTransfer: (fLen: any) => {
    dispatch(transferAction.purgeInfos());
    dispatch(fileAction.purgeFile());
    if (document.location.pathname === "/") document.location.reload();
  },
});

const mapStateToProps = (state: any) => ({ file: state.file });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function ({ mode, resetTransfer, file, forcePath }: any) {
  return (
    <div className={"p-0 m-0"}>
      {!!forcePath ? (
        <a
          href={"/"}
          onClick={() => resetTransfer(file.length)}
          title={i18n._("fv_slogan")}
        >
          <img
            className={"brand logo"}
            src={mode === "light" ? images.LogoLight : images.Logo}
            alt={i18n._("fv_logo_alt")}
          />
        </a>
      ) : (
        <Link
          to={"/"}
          onClick={() => resetTransfer(file.length)}
          title={i18n._("fv_slogan")}
        >
          <img
            className={"brand logo"}
            src={mode === "light" ? images.LogoLight : images.Logo}
            alt={i18n._("fv_logo_alt")}
          />
        </Link>
      )}
    </div>
  );
});
