import React, { useContext } from "react";
import { TransferContext } from "../../../Contexts";
import { convertSize } from "../../../utils";
import { ActionInputComponent } from "../../Button";
import { config } from "../../../config";

export function UploaderStandaloneFullLayout({ files }: any) {
  const transfer = useContext(TransferContext);

  return (
    <div className="fv-uploader-standalone fv_uploader_standalone">
      {files &&
        files.map((f: any, i: number) => (
          <div
            className="text-dark"
            style={{ margin: 10, color: "#696969" }}
            key={`file_stal_${i}`}
          >
            <span>{f.name}</span>
            {/* <div>{convertSize(transfer.loaded)}</div> */}

            {!!transfer.transfer_link && (
              <ActionInputComponent
                navigateTo={{ enabled: true }}
                value={`${config.ihmUrl}/collab/${transfer.transfer_link}`}
              />
            )}
          </div>
        ))}
    </div>
  );
}
