import React from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { i18n } from "../../../utils";

export interface IInputPasswordComponentProps {
  name: string;
  error?: string;
  label?: string;
  defaultValue?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  onChangeCb?: any;
}

export function InputPasswordComponent({
  label,
  error,
  name,
  defaultValue,
  maxLength,
  minLength,
  required,
  onChangeCb,
}: IInputPasswordComponentProps) {
  return (
    <FormGroup
      onBlur={(event: any) => {
        event.currentTarget.classList.add("was-validated");
        const [password, confirmationPassword]: any = Object.values(
          event.currentTarget.querySelectorAll('input[type="password"]')
        );
        confirmationPassword.setCustomValidity(
          password.value !== confirmationPassword.value
            ? i18n._("password_missmatch_label")
            : ""
        );

        if (onChangeCb) onChangeCb(password.value, confirmationPassword.value);
      }}
      onChange={(event: any) => {
        const [password, confirmationPassword]: any = Object.values(
          event.currentTarget.querySelectorAll('input[type="password"]')
        );
        event.currentTarget.classList.remove("was-validated");
        if (onChangeCb) onChangeCb(password.value, confirmationPassword.value);
      }}
    >
      <Form.Label>
        {label}{" "}
        {required && <span className={"text-danger fv_required"}>*</span>}
      </Form.Label>
      <FormControl
        id={`${name}`}
        name={name}
        type={"password"}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
      />
      {error && (
        <Form.Control.Feedback
          className="font-weight-bold"
          type="invalid"
          role="alert"
        >
          {error}
        </Form.Control.Feedback>
      )}

      <Form.Label>
        {i18n._("INPUTS.CONFIRM_PASSWORD.LABEL")}{" "}
        {required && <span className={"text-danger fv_required"}>*</span>}
      </Form.Label>
      <FormControl
        name={`confirm_${name}`}
        type={"password"}
        minLength={minLength}
        maxLength={maxLength}
        defaultValue={defaultValue}
        required={required}
      />

      <Form.Control.Feedback
        className="font-weight-bold"
        type="invalid"
        role="alert"
      >
        {i18n._("password_missmatch_label")}
      </Form.Control.Feedback>
    </FormGroup>
  );
}
