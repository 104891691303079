import { IFileWithMeta } from "react-dropzone-uploader/";

export const SET_FILE = "SET_FILE";
export const PURGE = "PURGE";

export function setFile(value: IFileWithMeta[]) {
  return { type: SET_FILE, value };
}

export function purgeFile(cb?: any) {
  return { type: PURGE, value: [], cb };
}
