import React, { useEffect, useRef, useState } from "react";
import "./HeaderV2.css";
import { NavLink, withRouter } from "react-router-dom";
import { i18n, isMobile } from "../../utils";
import Brand from "./Brand/Brand";
import { BurgerMenu, FooterComponent, LangComponent } from "..";
import { LoginButtonV2 } from "../Header/Components";
import { connect } from "react-redux";
import { gradients } from "../../Constants";
import { appAction, userAction } from "../../Store";
import { MDBIcon } from "mdbreact";

function getSerializedSlogan(str: string): string {
  return (str = str.replace(/{{(\w+)}}/g, function (e, t) {
    return '<img alt="Drapeau français" src="/flag.png" />';
  }));
}

function getThemeClass(id: string) {
  if (id === "custom-0" || id === "custom-1") return id;

  const themeClass: any =
    "0" === id[0]
      ? gradients.clair.find((gradient) => gradient.id === id)
      : gradients.sombre.find((gradient) => gradient.id === id);

  return themeClass?.className || "";
}

function getClassName(app: any) {
  const className = !app[appAction.SHOW_CUSTOM_HEADER]
    ? "normal"
    : getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

  return className;
}

function getClassId(app: any) {
  const classId = !app[appAction.SHOW_CUSTOM_HEADER]
    ? ""
    : app[appAction.SHOW_CUSTOM_HEADER];

  return classId;
}

function HeaderV2Component({
  isLogged,
  dispatch,
  app,
  location,
}: { isLogged?: boolean; dispatch: any; app: any } & any) {
  const [headerOpened, setHeaderOpened] = useState<boolean>(false);
  const [bgMenuOpen, setBgMenuOpen] = useState<boolean>(false);
  const [loginOpen, setLoginOpen] = useState(false);

  const themeClass = getClassName(app),
    themeId = getClassId(app);

  useEffect(() => {
    setHeaderOpened("normal" === themeClass);
  }, [app, location]);

  if (app[appAction.HIDE_HEADER]) return null;

  let btnClassName = "btn-outline-default",
    textColorName = "text-default",
    isLight = isMobile,
    isWhite = isMobile;

  if (themeId && themeId.length) {
    isLight = themeId[0] === "0" || themeId === "custom-0";
    isWhite = (() => {
      return (
        gradients.clair.some(({ id }) => themeId === id) ||
        themeId === "custom-0"
      );
    })();
    btnClassName = isWhite ? "btn-outline-white" : "btn-outline-black";
    textColorName = isWhite ? "text-white" : "text-black";
  }

  if (app[appAction.HIDE_HEADER]) return null;

  const isThemeLight = app[appAction.SHOW_LIGHT_HEADER];
  const themeColor = isThemeLight ? "dark" : "white";

  const headerClass = isThemeLight
    ? `default-header-2 bg-white w-100 position-fixed fv_light_header fv_light_header_screen_${
        document.location?.pathname?.split("/")[1] || "home"
      }`
    : `fv-header fv_header default-header w-100 position-fixed ${themeClass}-header pt-0 fv_${themeClass}_header fv_header_theme_${
        isLight ? "clair" : "sombre"
      } theme-${isLight ? "clair" : "sombre"} ${isMobile ? "py-0" : ""} ${
        app.SAVE_PASSWORD || app.RESET_PASSWORD ? "no-hover" : ""
      } fv_header_screen_${
        document.location?.pathname?.split("/")[1] || "home"
      }`;

  return (
    <>
      <header
        className={`${headerClass} ${
          (headerOpened || loginOpen) && "open no-hover"
        } fv-header-v2 fv_header_v2`}
        onMouseLeave={() =>
          setHeaderOpened((curState: boolean) =>
            (!bgMenuOpen && !loginOpen) ? false : curState
          )
        }
      >
        <div style={{ display: "flex", flex: 2 }}>
          <Brand mode={isThemeLight && "light"} />
          {!isMobile && (
            <h1
              className={`header slogan text-${themeColor} small-text`}
              dangerouslySetInnerHTML={{
                __html: getSerializedSlogan(i18n._("fv_slogan")),
              }}
            />
          )}
        </div>

        <div
          style={{
            gap: "1em",
            width: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <ul
            style={{ justifyContent: "space-around", gap: "2em" }}
            className={"fv-navbar fv_navbar"}
          >
            <li className={"fv-navbar-item d-none d-lg-flex"}>
              <NavLink
                className={`text-${themeColor} fv-header-v2-item fv_header_v2_item`}
                to={"/offers"}
              >
                {i18n._("link_subscribe_price_label")}
              </NavLink>
            </li>

            <li className={"fv-navbar-item d-none d-lg-flex"}>
              <a
                className={`text-${themeColor} fv-header-v2-item fv_header_v2_item`}
                href={`/solutions/${i18n.langCode}`}
              >
                {i18n._("solution_link_label")}
              </a>
            </li>

            <li className={"fv-navbar-item d-none d-lg-flex"}>
              <NavLink
                className={`text-${themeColor} fv-header-v2-item fv_header_v2_item`}
                to={"/collab"}
              >
                {i18n._("collab_link_label")}
              </NavLink>
            </li>

            <li
              className={
                "fv-navbar-item nav-item my-auto ml-4 fv-header-v2-item fv_header_v2_item"
              }
            >
              {loginOpen && (
                <LoginButtonV2
                  onModalChangeCB={setLoginOpen}
                  defaultOpen
                  mod={isThemeLight ? "light" : "normal"}
                  iconOnly
                />
              )}
              {!loginOpen && (
                <LoginButtonV2
                  onModalChangeCB={setLoginOpen}
                  mod={isThemeLight ? "light" : "normal"}
                  iconOnly
                />
              )}

              {isLogged && false && (
                <NavLink
                  className={`text-${themeColor} fv-header-v2-item fv_header_v2_item`}
                  to={"/profil"}
                >
                  <svg
                    fill={!isThemeLight ? "#FFF" : "#000"}
                    width={30}
                    height={30}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    id="account"
                  >
                    <path d="M25 1C11.767 1 1 11.767 1 25c0 7.091 3.094 13.472 8 17.869v.017l.348.3c.061.053.128.097.19.149a24.496 24.496 0 0 0 3.189 2.279c.085.051.172.099.257.148.557.324 1.126.629 1.71.908l.018.008a23.838 23.838 0 0 0 3.915 1.456l.075.021c.641.175 1.293.322 1.954.443l.185.033a24.17 24.17 0 0 0 1.939.262c.075.007.15.011.224.017.659.055 1.323.09 1.996.09s1.337-.035 1.996-.09c.075-.006.15-.01.224-.017.655-.06 1.301-.15 1.939-.262l.185-.033a23.451 23.451 0 0 0 1.954-.443l.075-.021a23.838 23.838 0 0 0 3.915-1.456l.018-.008a24.261 24.261 0 0 0 1.71-.908c.086-.05.172-.097.257-.148a24.123 24.123 0 0 0 1.487-.968c.124-.087.248-.174.371-.264.456-.334.9-.683 1.331-1.047.062-.052.129-.096.19-.149l.348-.3v-.017c4.906-4.398 8-10.778 8-17.869C49 11.767 38.233 1 25 1zm0 24c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3 2c6.065 0 11 4.935 11 11v3.958c-.042.035-.086.067-.128.102-.395.321-.8.626-1.214.918-.092.065-.182.132-.274.195-.447.305-.906.591-1.373.862l-.257.148a21.799 21.799 0 0 1-6.871 2.468l-.171.031a22.27 22.27 0 0 1-1.715.225c-.079.007-.159.012-.239.018-.583.045-1.169.075-1.758.075s-1.175-.03-1.758-.077l-.239-.018a21.789 21.789 0 0 1-1.886-.256 22.013 22.013 0 0 1-5.212-1.626l-.161-.073a21.799 21.799 0 0 1-1.755-.917c-.467-.27-.926-.557-1.373-.862-.093-.063-.183-.13-.274-.195a21.826 21.826 0 0 1-1.214-.918c-.042-.034-.086-.067-.128-.102V38c0-6.065 4.935-11 11-11h6zm13 13.076V38c0-6.271-4.464-11.519-10.38-12.735A9.996 9.996 0 0 0 35 17c0-5.514-4.486-10-10-10s-10 4.486-10 10a9.996 9.996 0 0 0 4.38 8.265C13.464 26.481 9 31.729 9 38v2.076C5.284 36.135 3 30.831 3 25 3 12.869 12.869 3 25 3s22 9.869 22 22c0 5.831-2.284 11.135-6 15.076z"></path>
                  </svg>
                </NavLink>
              )}

              {!isLogged && false && (
                <NavLink
                  className={`text-${themeColor} fv-header-v2-item fv_header_v2_item`}
                  to={"#"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 49 49"
                    fill="none"
                  >
                    <path
                      d="M24.9642 3.9239C17.6668 3.90476 11.2451 7.68025 7.57492 13.3842C7.41223 13.6378 7.59406 13.9727 7.89553 13.9727H11.2595C11.4892 13.9727 11.7045 13.8722 11.8481 13.6952C12.183 13.2885 12.5419 12.8961 12.9199 12.5228C14.4799 10.9677 16.2935 9.74265 18.3128 8.89089C20.3991 8.01042 22.6195 7.56062 24.9115 7.56062C27.2036 7.56062 29.4239 8.00564 31.5103 8.89089C33.5296 9.74265 35.3432 10.9677 36.9031 12.5228C38.4631 14.078 39.6833 15.8916 40.5399 17.9061C41.4251 19.9925 41.8701 22.208 41.8701 24.5001C41.8701 26.7922 41.4203 29.0077 40.5399 31.094C39.6881 33.1086 38.4631 34.9221 36.9031 36.4773C35.3432 38.0325 33.5296 39.2575 31.5103 40.1093C29.4223 40.9912 27.1781 41.4436 24.9115 41.4395C22.6195 41.4395 20.3991 40.9897 18.3128 40.1093C16.2974 39.2584 14.4661 38.025 12.9199 36.4773C12.5419 36.0993 12.1878 35.7069 11.8481 35.305C11.7045 35.1279 11.4844 35.0274 11.2595 35.0274H7.89553C7.59406 35.0274 7.40744 35.3624 7.57492 35.616C11.2404 41.3055 17.6333 45.0762 24.9115 45.0762C36.2141 45.0762 45.392 35.9797 45.5069 24.7106C45.6217 13.2597 36.4294 3.95261 24.9642 3.9239ZM18.9023 29.8594V26.2227H3.87695C3.66641 26.2227 3.49414 26.0505 3.49414 25.8399V23.1602C3.49414 22.9497 3.66641 22.7774 3.87695 22.7774H18.9023V19.1407C18.9023 18.8201 19.2756 18.6383 19.5244 18.8392L26.3146 24.1986C26.3603 24.2344 26.3973 24.2802 26.4228 24.3324C26.4482 24.3846 26.4614 24.442 26.4614 24.5001C26.4614 24.5582 26.4482 24.6155 26.4228 24.6677C26.3973 24.72 26.3603 24.7657 26.3146 24.8015L19.5244 30.1609C19.2756 30.3571 18.9023 30.1801 18.9023 29.8594Z"
                      fill="#F8F8F8"
                    />
                  </svg>
                </NavLink>
              )}
            </li>
          </ul>

          <BurgerMenu onTrigger={setBgMenuOpen} light={isThemeLight} backdrop>
            <div
              style={{ position: "absolute", left: 15, top: 15 }}
              className="fv-header-v2-burger fv_header_v2_burger"
            >
              <LangComponent
                textClass={`text---${themeColor}`}
                dispatch={dispatch}
              />
            </div>
            <div
              className="w-100 d-flex text-center flex-column"
              style={{ bottom: 0, gap: 2 }}
            >
              <span
                className="fv-header-v2-burger-group-item fv_header_v2_burger_group_item"
                onClick={(evt) => {
                  if (!isLogged) setLoginOpen(() => true);
                  setBgMenuOpen(() => false);
                }}
              >
                <LoginButtonV2 labelOnly />
              </span>
            </div>

            <hr />

            <div className="p-3 d-flex flex-column gap-2 fv-header-v2-burger-group fv_header_v2_burger_group group-1 group_1">
              <NavLink
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                to={"/offers"}
              >
                {i18n._("link_subscribe_price_label")}
              </NavLink>
              <a
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                href={`/solutions/${i18n.langCode}`}
              >
                {i18n._("solution_link_label")}
              </a>
            </div>

            <hr />

            <div className="p-3 d-flex flex-column gap-2 fv-header-v2-burger-group fv_header_v2_burger_group group-2 group_2">
              <NavLink
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                to={"/collab"}
              >
                {i18n._("collab_link_label")}
              </NavLink>
            </div>

            <hr />

            <div className="p-3 d-flex flex-column gap-2 fv-header-v2-burger-group fv_header_v2_burger_group group-3 group_3">
              <NavLink
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                to={"/impact"}
              >
                {i18n._("impact_link_label")}
              </NavLink>
              <a
                hrefLang={i18n.langCode}
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                href={`/solutions/${i18n.langCode}/rse`}
              >
                {i18n._("engagement_link_label")}
              </a>
              <a
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                rel={"noreferrer"}
                href={i18n._("footer_guide_link_url")}
                target={"_blank"}
              >
                {i18n._("footer_guide_link_label")}
              </a>
              <a
                className={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
                href={"https://blog.filevert.fr"}
              >
                {i18n._("blog_link_label")}
              </a>
            </div>

            <hr />

            <div
              className="fv-header-v2-burger-group fv_header_v2_burger_group group-4 group_4"
              style={{
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FooterComponent
                hideIcon
                contactOnly
                legales
                contactClassName={`text---${themeColor} fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
              >
                {i18n._("modal_contact_title")}
              </FooterComponent>
            </div>

            <a
              className={`w-100 position-absolute fv_burger_menu_logout_wrapper text---${themeColor} ${
                !isLogged && "d-none"
              } fv-header-v2-burger-group-item fv_header_v2_burger_group_item`}
              style={{ bottom: 10 }}
            >
              <span
                onClick={() => {
                  dispatch(userAction.logoutUser({}));
                }}
                className="mr-2"
              >
                {i18n._("logout_label")}
              </span>
              <MDBIcon
                title={i18n._("logout_label")}
                className={`hover-pointer text-default`}
                size={"1x"}
                icon={"sign-out-alt"}
                onClick={() => {
                  dispatch(userAction.logoutUser({}));
                }}
              />
            </a>
          </BurgerMenu>
        </div>
      </header>

      {"normal" !== themeClass && (
        <div
          onMouseOver={() => setHeaderOpened(true)}
          className={`fv-header-opener text-center w-100 ${
            headerOpened ? "active" : "inactive"
          }`}
          style={{ position: "absolute", background: "transparent", zIndex: 1 }}
        >
          <MDBIcon
            icon={"angle-down"}
            size={"2x"}
            className={
              "text-white arrow-header hover-pointer zi100 position-relative"
            }
          ></MDBIcon>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user.user,
  transfer: state.transfer.transfers,
  file: state.file,
});

export const HeaderV2 = connect(mapStateToProps)(withRouter(HeaderV2Component));
