import React from "react";
import "./BannerItem.css";

import { MDBCol } from "mdbreact";

import { i18n } from "../../../../utils/";

interface Item {
  faClassName: string;
  i18nKey: string;
}

interface Props {
  item: Item;
}

interface State {}

class BannerItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { faClassName, i18nKey } = this.props.item;

    return (
      <MDBCol
        size={"4"}
        lg={"2"}
        className={"mb-lg-0 mb-5 text-center fv_banner_item"}
      >
        <i className={faClassName}></i>
        <h5 className={"bold text-white mb-4"}>{i18n._(i18nKey)}</h5>
      </MDBCol>
    );
  };
}

export default BannerItemComponent;
