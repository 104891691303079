import React, { useContext, useState } from "react";
import "./UploaderStandalone.css";
import { UploaderStandaloneFullLayout } from "./Layout";
import { TransferContext } from "../../Contexts";
import { InputTextComponent } from "../Form/Input";
import { MDBBtn, MDBInput } from "mdbreact";
import { BtnComponent, LoaderComponent } from "..";
import { i18n } from "../../utils";

const UploaderStandalone = ({
  children,
  multiple,
  onFiles,
  onSubmit,
  accept,
  transfer_name,
}: any) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [transferName, setTransferName] = useState(transfer_name || "");
  const [selectedFiles, setSelectedFiles]: any = useState([]);
  const transfer = useContext(TransferContext);
  const [uploading, setUploading] = useState(false);

  const onFilesHandler = (files: any) => {
    if (onFiles) onFiles(Object.values(files));
    setSelectedFiles(Object.values(files));
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover")
      return setDragActive(true);
    if (e.type === "dragleave") setDragActive(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (!e.dataTransfer.files || !e.dataTransfer.files[0])
      return console.log("No file choose");

    onFilesHandler(e.dataTransfer.files);
  };
  const handleChange = (e: any) => {
    e.preventDefault();

    if (!e.target || !e.target.files || !e.target.files[0])
      return console.log("No file choose");

    onFilesHandler(e.target.files);
  };

  if (selectedFiles.length) {
    return (
      <div className={`fv-uploader-standalone-wrapper full`}>
        <MDBInput
          readOnly={uploading}
          outline
          type={"text"}
          value={transferName}
          onChange={(event: any) => setTransferName(event?.target?.value)}
          label={i18n._("collab_form.transfer.label")}
        />

        {uploading && (
          <div className="w-100 mt-4 fv-standalone-progress-wrapper fv_standalone_progress_wrapper">
            <div className="position-relative">
              <div
                className={`position-absolute w-100 ${
                  transfer?.loaded && transfer?.loaded < selectedFiles[0]?.size
                    ? "hidden"
                    : ""
                }`}
              >
                <LoaderComponent size="small" />
              </div>
              {uploading && transfer?.loaded < selectedFiles[0]?.size && (
                <progress
                  className="w-100 fv-standalone-progress fv_standalone_progress"
                  value={transfer?.loaded}
                  max={selectedFiles[0]?.size}
                />
              )}
            </div>
          </div>
        )}

        {!uploading && (
          <>
            <UploaderStandaloneFullLayout files={selectedFiles} />
          </>
        )}

        <div className="d-flex justify-content-center m-1">
          {!uploading && !transfer.loaded && !transfer?.transfer_link && (
            <MDBBtn
              className={"waves-effect waves-light w-100"}
              color={"default"}
              onClick={() => {
                setUploading(true);
                onSubmit({
                  transfer_name: transferName || selectedFiles[0].name,
                });
              }}
            >
              {i18n._("collab_form.transfer.submit")}
            </MDBBtn>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`fv-uploader-standalone-wrapper empty ${
        dragActive && "fv-drag-active"
      }`}
    >
      {!!children && children}
      <form
        className={"fv-fullsize-element fv-form-file-upload"}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          hidden
          type="file"
          accept={accept}
          className={"fv-fullsize-element fv-form-input-file-upload"}
          id="input-file-upload"
          multiple={multiple || false}
          onChange={handleChange}
        />

        <label
          htmlFor="input-file-upload"
          className={`fv-fullsize-element fv-form-file-upload-label`}
        />

        {dragActive && (
          <div
            className={"fv-fullsize-element"}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </form>
    </div>
  );
};

export default UploaderStandalone;
