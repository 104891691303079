import React, { useState } from "react";
import "./ActionInputComponent.css";
import { MDBNotification } from "mdbreact";
import { i18n } from "../../utils";

const resetStateTimeout = 1500;
const actionTimeout = 500;

export interface IActionInputComponentProps {
  value: string;
  inputProps?: any;
  navigateTo?: {
    enabled: boolean;
    cb?: any;
  };
  mailTo?: {
    subject?: any;
    body?: any;
    enabled: boolean;
    cb?: any;
  };
  onCopy?: (copied: boolean | any) => void;
  customClass?: string;
  explanation_label?: string;
}

export function ActionInputComponent({
  value,
  navigateTo,
  mailTo,
  onCopy,
  inputProps,
  customClass,
  explanation_label,
}: IActionInputComponentProps) {
  const [copied, setCopied] = useState(false);
  const [gone, setGone] = useState(false);
  const [sent, setSent] = useState(false);
  const inputId = `input_to_copy_${Date.now()}`;

  let paddingRight = 5;
  // if (mailTo?.enabled) paddingRight += 30;
  // if (navigateTo?.enabled) paddingRight += 30;

  // iconClassName =
  //   iconClassName ||
  //   `fa fa-copy fa-solid ${!copied ? "hover-pointer" : "text-green"}`;

  const handleCopy = (evt: any) => {
    const dataDom = evt.currentTarget; //document.getElementById(inputId);
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    document.execCommand("copy");
    setCopied(true);
    if (onCopy)
      try {
        onCopy(true);
      } catch (e) {
        onCopy(e);
      }
    window.getSelection()?.removeAllRanges();
    setTimeout(() => {
      try {
        dataDom.blur();
        setCopied(false);
      } catch (e) {}
    }, resetStateTimeout);
  };
  const handleMailTo = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    const mailToLink = document.createElement("a"),
      subject = mailTo?.subject || "",
      body = mailTo?.body || "";

    mailToLink.setAttribute("target", "_blank");
    mailToLink.href = `mailto:?subject=${subject}&body=${body}`;

    setSent(true);
    setTimeout(() => {
      mailToLink.click();
      if (mailTo?.cb) mailTo.cb();
    }, actionTimeout);

    setTimeout(() => {
      try {
        setSent(false);
      } catch (e) {}
    }, resetStateTimeout);
  };
  const handleNavigateTo = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();

    setGone(true);
    setTimeout(() => {
      window.open(value, "_blank")?.focus();
      if (navigateTo?.cb) navigateTo.cb();
    }, actionTimeout);

    setTimeout(() => {
      try {
        setGone(false);
      } catch (e) {}
    }, resetStateTimeout);
  };

  return (
    <>
      {!!explanation_label && (
        <span
          className={"text-white fv-input-action-label fv_input_action_label"}
        >
          {explanation_label}
        </span>
      )}

      <span
        className={`fv-input-action-wrapper d-flex ${
          customClass || ""
        } fv_input_action_wrapper`}
      >
        <input
          style={{ paddingRight }}
          id={inputId}
          readOnly
          className={`fv-input-action hover-pointer ${
            customClass || ""
          } fv_input_action ${copied && "copied"}`}
          onFocus={handleCopy}
          {...(inputProps || {})}
          value={value}
        ></input>
        <label
          className={`fv-input-action-label d-flex hover-pointer ${
            customClass || ""
          } fv_input_action_label`}
          htmlFor={inputId}
        >
          {mailTo?.enabled && (
            <i
              onClick={handleMailTo}
              className={`fas fa-paper-plane fa-lg fv-icon-suffix ${
                sent && "sent"
              } ${customClass || ""}`}
            />
          )}
          {navigateTo?.enabled && (
            <i
              onClick={handleNavigateTo}
              className={`fas fa-external-link-alt fa-lg fv-icon-suffix ${
                gone && "gone"
              } ${customClass || ""}`}
            />
          )}
        </label>

        {/*<input id={inputId} value={value} style={{opacity: 0, position: 'fixed', top: "-100%", left: "-100%"}}/>*/}
      </span>
      {copied && (
        <MDBNotification
          show
          fade
          message={i18n._("upload_finished_copied_label")}
          autohide={1000}
          titleClassName={"hidden d-none"}
          style={{
            position: "fixed",
            bottom: "10px",
            left: "10px",
            zIndex: 9999,
          }}
        />
      )}
    </>
  );

  // return (
  //   <>
  //     <i className={iconClassName} onClick={onClick}>
  //       <input readOnly className={"data-off"} value={dataToCopy} />
  //
  //       {copied && <div>{i18n._("btn_copy_copied_label")}</div>}
  //     </i>
  //   </>
  // );
}
