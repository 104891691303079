import { fileAction } from "../Actions";
import { AnyAction } from "redux";
import { IFileWithMeta } from "react-dropzone-uploader/";

const initialState: IFileWithMeta[] = [];

export default function uploadReducer(state: any, action: AnyAction) {
  let nextState;

  switch (action.type) {
    case fileAction.SET_FILE:
      nextState = action.value;
      break;

    case fileAction.PURGE:
      let nTr = state.length;
      state?.forEach &&
        state.forEach(({ remove }: any) => {
          setTimeout(() => {
            remove && remove();
            --nTr === 0 && action.cb && action.cb();
          });
        });

      nextState = [];
      break;

    default:
      nextState = state;
      break;
  }

  return nextState || initialState;
}

export { fileAction };
