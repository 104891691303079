import React from "react";

export interface ISwitchButtonComponentProps {
  defaultChecked?: boolean;
  onSwitch?: (checked: boolean) => void;
  id: string;
  label: string;

  [rest: string]: any;
}

export function SwitchButtonComponent({
  children,
  defaultChecked,
  onSwitch,
  label,
  id,
  ...rest
}: ISwitchButtonComponentProps) {
  return (
    <>
      <div className="custom-control custom-switch">
        <input
          onChange={({ currentTarget: { checked } }) =>
            onSwitch && onSwitch(checked)
          }
          type="checkbox"
          className="custom-control-input hover-pointer"
          id={id}
          checked={rest.checked || false}
          value={rest.checked || false}
          {...rest}
        />
        <label
          className="custom-control-label hover-pointer fv-custom-label fv_custom_label"
          htmlFor={id}
        >
          {label}
          {!!rest?.infos && (
            <span
              className={
                "bg-black text-white fv-offer-tooltip fv_offer_tooltip"
              }
            >
              {rest?.infos}
            </span>
          )}
        </label>
      </div>
      {children}
    </>
  );
}
