import React, { useState } from "react";
import { i18n } from "../../utils";

export interface ICopyButtonComponentProps {
  iconClassName?: string;
  dataToCopy: string;
  onCopy?: (copied: boolean) => void;
  label?: string;
}

export function CopyButtonComponent({
  iconClassName,
  dataToCopy,
  onCopy,
  label,
}: ICopyButtonComponentProps) {
  const inputId = `${Date.now()}`;
  const [copied, setCopied] = useState(false);

  iconClassName = iconClassName || `fa fa-copy fa-solid`;

  iconClassName = `${iconClassName} ${
    !copied ? "hover-pointer" : "text-green"
  }`;

  const onClick = (evt: any) => {
    const dataDom = document.getElementById(inputId);
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    if (document.execCommand("copy")) {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  return (
    <>
      <i className={iconClassName} onClick={onClick}>
        <input
          id={inputId}
          readOnly
          className={"data-off"}
          value={dataToCopy}
        />
        <span style={{ paddingLeft: 5 }}> {label}</span>

        {copied && <div>{i18n._("btn_copy_copied_label")}</div>}
      </i>
    </>
  );
}
