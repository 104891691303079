import { Go } from "./sizeInO";

export interface IPermission {
  label: string;
  value: boolean | number;
  min?: number;
  max?: number;
  options?: number[];
  optional?: boolean;
}

export interface IDefaultPermissions {
  transfer_notification: IPermission;
  transfer_authentication: IPermission;
  transfer_tracking: IPermission;
  consumption_export: IPermission;
  team_size: IPermission;
  customization: IPermission;
  outlook_addin: IPermission;
  upload_size: IPermission;
  upload_ttl: IPermission;

  [rest: string]: IPermission;
}

export const defaultPermissions: IDefaultPermissions = {
  studio: {
    value: false,
    label: "studio",
  },
  transfer_tracking: {
    label: "transfer_tracking",
    value: false,
  },
  transfer_notification: {
    label: "transfer_notification",
    value: false,
  },
  transfer_authentication: {
    label: "transfer_authentication",
    value: false,
  },
  consumption_export: {
    label: "consumption_export",
    value: false,
  },
  team_size: {
    // Different (Valeur numérique si oui)
    label: "team_size",
    value: 0,
    min: 0,
    max: 100,
    options: [0, 5, 10, 20, 30, 50, 100],
    optional: true,
  },
  customization: {
    label: "customization",
    value: false,
  },
  outlook_addin: {
    label: "outlook_addin",
    value: false,
  },

  upload_size: {
    // Different (Valeur numérique uniquement)
    label: "upload_size",
    value: 2 * Go,
    min: 2 * Go,
    max: 200 * Go,
    options: [2, 5, 10, 20, 50, 100, 200].map((n) => n * Go),
  },
  upload_ttl: {
    // Different (Valeur numérique uniquement)
    label: "upload_ttl",
    value: 2,
    min: 2,
    max: 15,
    options: [2, 3, 7, 15],
  },
};

export const defaultOffer: IDefaultPermissions = {
  studio: {
    value: false,
    label: "studio",
  },
  transfer_notification: {
    label: "transfer_notification",
    value: true,
  },
  transfer_authentication: {
    label: "transfer_authentication",
    value: true,
  },
  transfer_tracking: {
    label: "transfer_tracking",
    value: true,
  },
  consumption_export: {
    label: "consumption_export",
    value: true,
  },
  team_size: {
    // Different (Valeur numérique si oui)
    label: "team_size",
    value: false,
    min: 0,
    max: 100,
    options: [0, 5, 10, 20, 30, 50, 100],
    optional: true,
  },
  customization: {
    label: "customization",
    value: false,
  },
  outlook_addin: {
    label: "outlook_addin",
    value: false,
  },

  upload_size: {
    // Different (Valeur numérique uniquement)
    label: "upload_size",
    value: 20 * Go,
    min: 2 * Go,
    max: 200 * Go,
    options: [2, 5, 10, 20, 50, 100, 200].map((n) => n * Go),
  },
  upload_ttl: {
    // Different (Valeur numérique uniquement)
    label: "upload_ttl",
    value: 7,
    min: 2,
    max: 15,
    options: [2, 3, 7, 15],
  },
};
