import axios, { AxiosResponse } from "axios";

const offerApi = {
  async getOffers(): Promise<AxiosResponse<any>> {
    const offers = await axios.get(`/stripe/offer`);

    return offers.data;
  },

  async getTotalConsumption(): Promise<AxiosResponse<any>> {
    const { data } = await axios.get(`/transfer/consumption`);
    return data || 0;
  },

  async createCheckoutSession(payload: any, referer_id?: string | null) {
    try {
      const { data } = await axios.post("/stripe/session", payload, {
        params: { referer_id },
      });
      return data || payload;
    } catch (e) {
      return e;
    }
  },

  async confirmCheckoutSession(status: string, session_id: string) {
    try {
      const { data } = await axios.get(`/stripe/order/${status}/${session_id}`);
      return data;
    } catch (e) {
      return e;
    }
  },

  async confirmCheckoutSessionForOldUser(status: string, session_id: string) {
    try {
      const { data } = await axios.get(
        `/stripe/order/old/${status}/${session_id}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },

  async createCheckoutSessionForUser(payload: any) {
    try {
      const { data } = await axios.put("/stripe/session", payload);
      return data || payload;
    } catch (e) {
      return e;
    }
  },

  async createCustomer(formValues: any): Promise<AxiosResponse<any> | any> {
    try {
      const { data, error }: any = await axios.post(
        `/stripe/customer`,
        formValues
      );
      return data || error;
    } catch (error: any) {
      if (!error?.response?.data) return error;

      switch (error.response.data.code) {
        case "UNAVAILABLE_EMAIL":
          return {
            error: { field: "email", reason: "unavailable" },
          };
        case "INVALID_TVA_NUMBER":
          return {
            error: { field: "vat_number", reason: "invalid" },
          };
        default:
          return {
            error: { field: "unknown", reason: "unknown" },
          };
      }
    }
  },
};

export default offerApi;
