import React, { useEffect, useRef, useState } from "react";
import playIconImg from "../../../assets/images/playIcon.png";
import gobackIconImg from "../../../assets/images/goback-icon.png";
import goForwardIconImg from "../../../assets/images/go-forward-icon.png";
import muteIconImg from "../../../assets/images/mute-icon.png";
import soundOnImg from "../../../assets/images/sound-on.png";
import expandIconImg from "../../../assets/images/expand-icon.png";
import "./Timeline.css";

export type ITimeline = {
  markers?: any[];
  playerRef?: any;
  playing?: boolean;
  setPlaying?: any;
  duration: number;
  currentTime: number;
  fullScreen?: boolean;
  setFullScreen?: any;
  refreshed: any;
  loadedBuffer?: number;
  zoomedComment?: number;
  setZoomedComment?: any;
  type?: "audio" | "video" | "image";
  volume?: any;
  setVolume?: any;
  setCurrentTime?: any;
  isReplacing?: boolean;
  setIsReplacing?: any;
};
export function Timeline({
  type,
  setZoomedComment,
  zoomedComment,
  fullScreen,
  setFullScreen,
  volume,
  setVolume,
  markers,
  playerRef,
  setPlaying,
  playing,
  duration = 0,
  setCurrentTime,
  currentTime = 0,
  refreshed,
  loadedBuffer,
  setIsReplacing,
  isReplacing,
}: ITimeline) {
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [displayedMarkers, setDisplayedMarkers] = useState<any[]>([]);
  const progressRef = useRef<any>(null);
  const [{ last, first }, setFirstOrLast] = useState({
    first: true,
    last: false,
    skip: true,
  });
  const [timeInfo, setTimeInfo] = useState<any>({
    show: false,
    time: null,
    left: null,
    last: null,
  });
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const printMarkers = (markers: any) => {
    setDisplayedMarkers(
      markers?.reduce((acc: any, cur: any) => {
        const markerTimeFound = acc.find(({ time }: any) => time === cur.time);
        if (markerTimeFound) {
          markerTimeFound.count += 1;
          return acc;
        }
        return [...acc, { ...cur, count: 1 }];
      }, [])
    );
  };

  useEffect(() => {
    printMarkers(markers as any);
  }, [markers]);

  useEffect(() => {
    printMarkers([]);

    setTimeout(() => {
      printMarkers(markers);
    }, 400);
  }, [refreshed]);

  useEffect(() => {
    const { time } = markers?.find(({ id }) => zoomedComment === id) || {
      time: 0,
    };
    if (typeof time === "number" && playerRef.current) {
      playerRef.current.seekTo(time, "seconds");
      setPlaying(false);
    }
  }, [zoomedComment]);

  const handleSeek = (e: any) => {
    try {
      if (playerRef.current) {
        setPlaying(false);
        playerRef.current.seekTo(e.target.value / 1_000, "seconds");
      }
    } catch (e) {
      console.error("Unable to seek");
    }
  };

  const handleSeekFromKeyboard = (value: any) => {
    try {
      if (playerRef.current) {
        // setPlaying(false);
        playerRef.current.seekTo(value, "seconds");
      }
    } catch (e) {
      console.error("Unable to seek");
    }
  };

  const handleVolume = () => {
    setIsMuted(!volume);
  };
  useEffect(handleVolume, [volume]);

  useEffect(() => {
    const onkeydown = (evt: any) => {
      try {
        if (
          ["INPUT", "TEXTAREA"]?.includes(evt?.currentTarget?.activeElement?.tagName)
          || ["INPUT", "TEXTAREA"]?.includes(evt?.target?.tagName)
          || ["INPUT", "TEXTAREA"]?.includes(evt?.srcElement?.tagName)
        ) {
          return evt;
        }

        const { key, code, altKey, shiftKey } = evt;

        if (key === "Enter" && altKey) setFullScreen((fS: boolean) => !fS);
        if (key === "r" || key === "R") setIsReplacing((iR: boolean) => !iR);
        if (key === " " || code === "Space") setPlaying(() => !playing);
        if ([key, code].includes("ArrowLeft")) {
          setCurrentTime((cT: number) => {
            handleSeekFromKeyboard(cT - 1);
            return cT - 1;
          });
          // if (!shiftKey) {} else {seekOnComment("prev")}
        }
        if ([key, code].includes("ArrowRight")) {
          setCurrentTime((cT: number) => {
            handleSeekFromKeyboard(cT + 1);
            return cT + 1;
          });
          // if (!shiftKey) {} else {seekOnComment("next")}
        }
      } catch (e) {}
    };

    window.addEventListener("keydown", onkeydown);

    return () => {
      window.removeEventListener("keydown", onkeydown);
    };
  }, [playing]);

  const seekOnComment = (dir: "next" | "prev") => {
    if (!markers?.length) return null;

    let newMarker = null;
    const currentMarker = markers?.find(({ id }) => id === zoomedComment);

    if (dir === "next") {
      newMarker = markers
        ?.sort((m1, m2) => (m1?.time > m2?.time ? 1 : -1))
        ?.find(({ time }) => time > currentTime);
    }
    if (dir === "prev") {
      newMarker = markers
        ?.sort((m1, m2) => (m1?.time > m2?.time ? -1 : 1))
        ?.find(({ time }) => time < currentTime);
    }

    // if (!newMarker) newMarker = markers?.find(({ id }) => id === zoomedComment);

    const currentMarkerIndex = markers?.indexOf(currentMarker);
    const marker = newMarker; //markers[currentMarkerIndex + (dir === "next" ? 1 : -1)];

    if (marker || (dir === "prev" && currentTime > 0)) {
      setPlaying(false);
      playerRef.current.seekTo(marker?.time || 0, "seconds");
      setFirstOrLast(() => ({
        first: dir === "prev" && currentMarkerIndex < 0,
        last: dir === "next" && currentMarkerIndex >= markers?.length - 1,
        skip: true,
      }));
      if (setZoomedComment) setZoomedComment(marker?.id);
    }
  };

  useEffect(() => {
    const nextMarkers = markers?.find(({ time }) => time > currentTime);
    const prevMarkers = markers?.find(({ time }) => time < currentTime);

    setFirstOrLast(({ first, last, skip }) => ({
      first: skip ? first : !prevMarkers || !currentTime,
      last: skip ? last : !nextMarkers,
      skip: false,
    }));
  }, [currentTime]);

  const handleHover = (e: any) => {
    try {
      const progressBar = e.target;
      const mousePosition =
        e.clientX - progressBar.getBoundingClientRect().left;

      const left = mousePosition + 70;
      const targetTimeInPercent = mousePosition / progressBar.offsetWidth;

      setTimeInfo({
        left,
        time: duration * targetTimeInPercent,
      });
    } catch (e) {
      console.error("Unable to seek");
    }
  };

  const getLeft = (time: number) => {
    const pos = (time * 100) / duration;
    return pos < 100 ? pos : 99.4;
  };

  if (isResizing) return null;

  return (
    <>
      <div
        style={{
          borderTop: "10px solid black",
          position: "relative",
          zIndex: 200,
        }}
      />
      <div
        onClick={(e) => {
          const progressBar = progressRef.current;
          const clickPosition =
            e.clientX - progressBar.getBoundingClientRect().left;

          if (playerRef.current) {
            setPlaying(false);
            playerRef.current.seekTo(
              (clickPosition / progressBar.offsetWidth) * duration,
              "seconds"
            );
          }
        }}
        className={"media-timeline-wrapper"}
        style={{
          top: (progressRef?.current?.offsetParent?.offsetTop || 0) + 5,
          left: progressRef?.current?.offsetLeft,
          position: "absolute",
          width: progressRef?.current?.clientWidth,
          zIndex: 2000,
        }}
      >
        {displayedMarkers.map(({ time, onClick }: any) => (
          <div
            onMouseMove={(e) => {
              setTimeInfo({ ...timeInfo, ...(timeInfo?.last || {}) });
            }}
            onClick={onClick}
            key={`marker--${time}`}
            id={`marker--${time}`}
            className={"fv-media-timeline-marker"}
            style={{ left: `${getLeft(time)}%` }}
          >
            <span
              className={"fv-media-timeline-marker-counter"}
              style={{ display: "none" }}
            >
              {/*{count}*/}
            </span>
          </div>
        ))}
      </div>

      <div
        onMouseLeave={() => {
          setTimeInfo({ last: timeInfo });
        }}
        style={{
          width: "auto",
          zIndex: 1000,
          background: "#141414",
          padding: "12px",
          position: "relative",
          paddingBottom: "2px",
          paddingTop: "2px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {playing ? (
          <span
            style={{
              height: 12,
              width: 8,
              padding: 3,
              borderRight: "2px solid #EEE",
              borderLeft: "2px solid #EEE",
              fontSize: 14,
              marginRight: "8px",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => setPlaying(false)}
          />
        ) : (
          <img
            onClick={() => setPlaying(true)}
            src={playIconImg}
            alt="check"
            style={{
              cursor: "pointer",
              paddingRight: "4px",
              height: "16px",
            }}
          />
        )}
        <img
          src={gobackIconImg}
          alt="go-back"
          onClick={!first ? () => seekOnComment("prev") : undefined}
          style={{
            cursor: !first ? "pointer" : undefined,
            paddingRight: "4px",
            height: "16px",
            opacity: first ? 0.5 : 1,
          }}
        />
        <img
          onClick={!last ? () => seekOnComment("next") : undefined}
          src={goForwardIconImg}
          alt="go-forward"
          style={{
            cursor: !last ? "pointer" : undefined,
            paddingRight: "4px",
            height: "16px",
            opacity: last ? 0.5 : 1,
          }}
        />

        <input
          type="range"
          className="timeline"
          onChange={handleSeek}
          id={"player-timeline"}
          onMouseMove={handleHover}
          max={duration * 1_000}
          value={currentTime * 1_000}
          style={{
            height: 14,
            width: "100%",
            background: `linear-gradient(to right, 
              var(--tl-color) ${(currentTime * 100) / duration}%, 
              rgba(8, 202, 141, .3) ${(currentTime * 100) / duration}% ${
              ((loadedBuffer || 0) * 100) / duration
            }%,
              transparent ${
                ((loadedBuffer || currentTime) * 100) / duration
              }% 100%)`,
            cursor: "pointer",
            marginRight: 4,
          }}
          ref={progressRef}
          // onClick={handleSeek}
        />
        {!!timeInfo.time && (
          <span
            className={"hover-time-info"}
            style={{
              width: 70,
              left: timeInfo?.left - 35,
              padding: "5px 10px",
              position: "absolute",
              background: "#111",
              borderRadius: 5,
              border: "1px solid #CCC",
              bottom: 20,
              textAlign: "center",
              color: "#FFF",
              zIndex: 3000,
            }}
          >
            <small style={{ width: "100%" }}>{printTime(timeInfo.time)}</small>
          </span>
        )}

        {isMuted ? (
          <img
            onClick={() => setVolume(1)}
            src={muteIconImg}
            alt="mute"
            style={{
              cursor: "pointer",
              paddingRight: "4px",
              height: "16px",
            }}
          />
        ) : (
          <img
            onClick={() => setVolume(0)}
            src={soundOnImg}
            alt="unmute"
            style={{
              cursor: "pointer",
              paddingRight: "4px",
              height: "16px",
            }}
          />
        )}
        {type !== "audio" && (
          <img
            onClick={() => {
              setFullScreen(() => !fullScreen);
            }}
            src={expandIconImg}
            alt="expand"
            style={{
              cursor: "pointer",
              paddingRight: "4px",
              height: "16px",
            }}
          />
        )}
      </div>

      <div
        style={{
          padding: "0 15px",
          textAlign: "right",
          zIndex: 2000,
        }}
      >
        <small style={{ color: "#FFF", textAlign: "right" }}>
          {printTime(currentTime)} / {printTime(duration)}
        </small>
      </div>
    </>
  );
}

function printTime(time: number) {
  let hour = 0,
    min = Math.floor(time / 60),
    sec = Math.round(time % 60);

  if (min >= 60) {
    hour = Math.floor(min / 60);
    min = Math.round(min % 60);
  }

  return `${hour ? hour.toString().padStart(2, "0") + ":" : ""}${min
    .toString()
    .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
}
