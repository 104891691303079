import React from "react";
import "./Band.css";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import { Link } from "react-router-dom";
import { i18n } from "../../utils/";

interface Props {
  text: string;
  imageSrc: string;
}

interface State {}

class BandComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { text, imageSrc } = this.props;

    return (
      <MDBContainer
        fluid
        middle={"true"}
        className="py-5 project-area cloudy-gradient no-margin no-padding fv_band"
        id="top"
        tabIndex={-1}
      >
        <MDBContainer className="text-center text-lg-left dark-grey-text">
          <MDBRow className={"px-md-2 mx-md-2 px-lg-3 mx-lg-3 px-xl-4 mx-xl-4"}>
            <MDBCol
              xs={"12"}
              sm={"6"}
              md={"6"}
              lg={"3"}
              xl={"3"}
              className="mb-lg-0 mb-4"
            >
              <img
                alt={"pic-band"}
                className={"img-fluid fv_image_band"}
                src={imageSrc}
              />
            </MDBCol>

            <MDBCol
              xs={"12"}
              sm={"6"}
              md={"6"}
              lg={"7"}
              xl={"7"}
              className="my-auto mb-2"
            >
              <h3 className="text-center band-title px-lg-4 px-xl-5">{text}</h3>
            </MDBCol>

            <MDBCol
              xs={"12"}
              sm={"12"}
              md={"12"}
              lg={"2"}
              xl={"2"}
              className="text-center pricing my-auto d-flex d-lg-block"
            >
              <Link
                to={"/offers"}
                type="button"
                className="btn btn-primary text-uppercase waves-effect waves-light  mx-auto cta-button float-right"
              >
                {i18n._(`btn_register_label`)}
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    );
  };
}

export default BandComponent;
