import { assetsApi } from "../Api";
import { i18n } from "./index";

const defaultLang = "en_EN";

class I18n {
  strings: any;
  lang: string;
  langChoices: string[];
  isReady: boolean = false;

  get langCode() {
    return this.lang.substring(0, 2);
  }

  constructor() {
    this.lang = defaultLang;
    this.langChoices = [];
    this.init().then(() => (this.isReady = true));
  }

  // _ = (string: string): string => this.strings[string];

  _(key: string, args?: string | string[] | any): string | any {
    if (!key) return "";

    if (!this.strings || !this.lang || !this.strings[this.lang]) {
      return "";
    }

    if (typeof args === "string" || typeof args === "number") args = [args];

    let result: string | any = "";

    if (!key.includes(".")) {
      result = this.strings[this.lang][key];
    } else {
      key.split(".").forEach((kPark: string) => {
        try {
          if (kPark?.includes("[")) {
            const kPartIndex = parseInt(kPark.split("[")[1]);
            kPark = kPark.split("[")[0];
            result =
              result[kPark][kPartIndex] ||
              this.strings[this.lang][kPark][kPartIndex];
            return;
          }
          result = result[kPark] || this.strings[this.lang][kPark];
        } catch (e) {}
      });
    }

    if (result) {
      if (args) {
        result = result.replace(/{(\d+)}/g, function (match: any, number: any) {
          return typeof args[number] !== "undefined" ? args[number] : "";
        });
      }
      return result;
    } else {
      console.warn("Missing key in translation file : ", key);
      return "";
    }
  }

  init = async () => {
    //TODO: Mettre en cache les strings
    let forceLang: boolean = false;
    try {
      const now = new Date();
      const ttl = 1000 * 3600 * 24;

      let apiInfos: any = localStorage.getItem("apiInfos");

      if (apiInfos) apiInfos = JSON.parse(apiInfos);
      //TODO: Remove hack for cache in the futur
      else apiInfos = { hasToUpdate: true };

      let stringsFromLocalStorage = localStorage.getItem("strings");
      let strings;

      if (stringsFromLocalStorage) {
        stringsFromLocalStorage = JSON.parse(stringsFromLocalStorage);
        // prettier-ignore
        //@ts-ignore
        if (stringsFromLocalStorage && stringsFromLocalStorage.value && stringsFromLocalStorage.ttl > now) {
          // prettier-ignore
  //@ts-ignore
          strings = stringsFromLocalStorage.value;
        }
      }

      strings =
        strings && (!apiInfos || !apiInfos?.hasToUpdate)
          ? strings
          : await assetsApi.getStrings();
      this.strings = strings;

      this.langChoices = Object.keys(this.strings).sort((l1, l2) =>
        this.strings[l1].order < this.strings[l2].order ? -1 : 1
      );

      let userLang = navigator.language;
      userLang = userLang
        ? `${userLang.split("-")[0]}_${userLang.split("-")[0].toUpperCase()}`
        : defaultLang;

      const urlSearchParams = new URLSearchParams(document.location.search);
      const urlLang = urlSearchParams.get("hl");

      if (urlLang) {
        userLang = `${urlLang.toLowerCase()}_${urlLang.toUpperCase()}`;
        forceLang = Object.keys(this.strings).includes(userLang);
      }

      const rootStorage = localStorage.getItem("strings");
      if (rootStorage) {
        const appStorage = JSON.parse(rootStorage).app;

        if (appStorage && !forceLang) {
          userLang = JSON.parse(appStorage).LANG || userLang;
        }
      }

      this.lang =
        localStorage.getItem("lang") ||
        Object.keys(this.strings).includes(userLang)
          ? userLang
          : defaultLang;

      localStorage.setItem(
        "strings",
        JSON.stringify({ ttl: now.getTime() + ttl, value: strings })
      );
      this.initHTMLSEOValues();

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  initRSS = async () => {
    if (i18n.isReady) {
      const now = new Date();
      const ttl = 1000 * 3600 * 24;
      return await assetsApi
        .getRSS(this.lang)
        .then((rss: any) =>
          localStorage.setItem(
            "rss",
            JSON.stringify({ ttl: now.getTime() + ttl, value: rss })
          )
        );
    } else {
      setTimeout(this.initRSS, 500);
    }
  };

  getLang = () => this.lang;
  getLangChoices = () => this.langChoices;

  changeLang = (lang: string) => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    this.lang = lang;
    urlSearchParams.set("hl", lang.substr(0, 2));
    document.location.search = urlSearchParams.toString();
    localStorage.setItem("lang", this.lang);
  };

  initHTMLSEOValues = () => {
    const htmlLang = this.lang.split("_")[0];
    const htmlDOM = document.getElementsByTagName("html")[0];
    const titleDOM: any = document.querySelector("title");
    const descriptionDOM: any = document.querySelector(
      'meta[name="description"]'
    );

    htmlDOM.setAttribute("xml:lang", htmlLang);
    htmlDOM.setAttribute("lang", htmlLang);

    titleDOM.setAttribute("xml:lang", htmlLang);
    titleDOM.setAttribute("lang", htmlLang);
    titleDOM.setAttribute("hreflang", htmlLang);

    descriptionDOM.setAttribute("xml:lang", htmlLang);
    descriptionDOM.setAttribute("lang", htmlLang);
    descriptionDOM.setAttribute("hreflang", htmlLang);
    descriptionDOM.setAttribute(
      "content",
      i18n._("fv_website_meta_description")
    );

    titleDOM.innerText = i18n._("fv_website_meta_title");
  };
}

const _t = new I18n();

export default _t;
