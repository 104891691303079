import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import stores from "./Store";

import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";

import Main from "./Main";

import * as serviceWorker from "./serviceWorker";
import {
  MDBBtn,
  MDBCardBody,
  MDBCardFooter,
  MDBModal,
  MDBModalBody,
} from "mdbreact";
import { i18n } from "./utils";
import { images } from "./assets";

const AppRooting = () => {
  return (
    <Provider store={stores.store}>
      <PersistGate loading={null} persistor={stores.persistor}>
        <Main />
        <CookiesComponent />
      </PersistGate>
    </Provider>
  );
};

const CookiesComponent = () => {
  const legalsPages = ["/legal-terms", "/privacy", "/cgu"];
  const cookiesPermissionsFromLocalStorage =
    localStorage.getItem("cookies-permission");
  const [state, setState] = useState({
    cookiesPermissions: cookiesPermissionsFromLocalStorage,
    isReady: false,
    isOpen:
      !legalsPages.includes(document.location.pathname) &&
      (!cookiesPermissionsFromLocalStorage ||
        "denied" === cookiesPermissionsFromLocalStorage),
  });

  const updateState = () => {
    setTimeout(() => {
      if (!i18n.isReady) return updateState();

      return setState({ ...state, isReady: true });
    }, 250);
  };
  const handleCookiesAcceptation = () => {
    localStorage.setItem("cookies-permission", "granted");
    setState({ ...state, cookiesPermissions: "granted", isOpen: false });
  };
  let { isReady, isOpen } = state;

  if (!isReady) {
    updateState();
    return null;
  }
  let reasons: string[] | any = i18n._("modal_cookies_reasons");
  if ("modal_cookies_reasons" === reasons) reasons = undefined;

  return (
    <MDBModal centered isOpen={isOpen} className={"max-w-400"}>
      <MDBModalBody>
        <MDBCardBody>
          <div>
            <h4 className={"mb-4 text-center font-weight-bold"}>
              {i18n._("modal_cookies_title")}
            </h4>
          </div>
          <div className={"d-flex justify-content-center mb-3"}>
            <div
              className={
                "card-circle d-flex justify-content-center align-items-center"
              }
            >
              <img src={images.LogoBahaus} width={80} alt={"Logo Bahaus"} />
            </div>
          </div>

          <h5 className={"mb-4 text-center"}>
            {i18n._("modal_cookies_intro")}
          </h5>

          <div className={"mb-4 text-muted"}>
            <ul>
              {reasons &&
                reasons.map((str: string, i: number) => (
                  <li key={`reasons_${i}`}>{str}</li>
                ))}
            </ul>
          </div>

          <div>
            <p
              className={"small text-muted"}
              dangerouslySetInnerHTML={{
                __html: i18n._("modal_cookies_outro"),
              }}
            />
          </div>
        </MDBCardBody>

        <MDBCardFooter className={"bg-white text-center"}>
          <MDBBtn
            className={"mx-auto"}
            color={"default"}
            onClick={handleCookiesAcceptation}
          >
            {i18n._("btn_accept_label")}
          </MDBBtn>
        </MDBCardFooter>
      </MDBModalBody>
    </MDBModal>
  );
};

ReactDOM.render(AppRooting(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
