import React, { ReactElement } from "react";
import "./Subscription.css";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

import { formatDate, i18n, userCanInviteMember } from "../../../../utils/";
import { connect } from "react-redux";

import { invoiceApi, userApi } from "../../../../Api";
import {
  MDBAlert,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBNotification,
  MDBRow,
} from "mdbreact";
import * as userAction from "../../../../Store/Actions/userAction";
import LoaderComponent from "../../../Loader/Loader";
import { isMobile } from "../../../../utils";
import {
  TableMembersComponent,
  TableSubscriptionsComponent,
} from "../../../TableList";

interface Props {
  dispatch: Function;
  user: any;
  title: string;
}

interface State {
  invoices: any[];
  customer: any;
  members: any[];
  referer: any;
  error: { code: string; message: string } | null;
  rowsPerPage: number;
  page: number;
  isLoading: boolean;
  modalOpen: boolean;
  successCopyLink: boolean;
}

class SubscriptionComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
      invoices: [],
      customer: null,
      members: [],
      referer: {},
      error: null,
      rowsPerPage: 5,
      page: 0,
      isLoading: true,
      successCopyLink: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.user.referer_id) {
      const referer = await userApi.getReferer(this.props.user.id);
      return this.setState({ ...this.state, referer, isLoading: false });
    }

    if (!this.props?.user?.subscriptions?.length) return;

    const { members_id, id } = this.props.user;
    const invoices = await invoiceApi.getInvoices(id);
    const customer = await invoiceApi.getCustomer(id);

    const members =
      members_id && members_id.length ? await userApi.getTeamMembers(id) : [];
    this.setState({
      invoices: invoices ? invoices : [],
      customer,
      members: members ? members : [],
      isLoading: false,
    });
  };

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { members_id } = this.props.user;

    try {
      if (
        JSON.stringify(prevProps?.user?.subscriptions) !==
        JSON.stringify(prevState?.customer?.subscriptions?.data)
      ) {
        return await this.componentDidMount();
      }
    } catch (e) {}

    if (prevProps.user.members !== this.props.user.members) {
      const members =
        members_id && members_id.length
          ? await userApi.getTeamMembers(this.props.user.id)
          : [];
      this.setState({ ...this.state, members });
    }
  }

  deleteMembers = async (members: any[]) => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(i18n._("member_deletion_confirm_label", members.length)))
      return;

    const result = await userApi.deleteMembers(
      this.props?.user?.id,
      members.map(({ id }: any) => id)
    );

    if (result && !result.error)
      return this.props.dispatch(userAction.loginUser(result));

    return result.error;
  };

  handleManageBilling = async () => {
    const result = await userApi.manageBilling(this.props.user);
    if (result.url) return window.open(result.url);
  };

  handleManageBilling2 = async () => {
    //customer/update
    const result = await userApi.manageBilling(this.props.user);
    if (result.url) return window.open(`${result.url}/customer/update`);
  };

  renderTableBody = (invoices: any[]) => {
    if (!invoices) {
      return (
        <TableBody className={"w-100 h-100 position-absolute bg-transparent"}>
          <TableRow
            className={
              "text-center w-100 h-100 position-absolute pb-5 bg-transparent"
            }
          >
            <TableCell
              className={"text-center w-100 h-100 position-absolute pb-3"}
            >
              <LoaderComponent size={"small"} />
              <p>{i18n._("loading_label")}</p>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (!invoices.length) {
      return (
        <TableBody className={"w-100 h-100 position-absolute bg-transparent"}>
          <TableRow
            className={
              "text-center w-100 h-100 position-absolute pb-5 bg-transparent"
            }
          >
            <TableCell
              className={"text-center w-100 h-100 position-absolute pb-3"}
            >
              <p>{i18n._("no_invoices_label")}</p>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {invoices.map((inv: any, k: number) => (
          <TableRow key={k}>
            <TableCell className={"text-center"}>
              {inv.lines.data.map((line: any, k: number) => (
                <p key={k}>{line.description}</p>
              ))}
            </TableCell>
            <TableCell className={"text-center"}>
              {i18n._("invoices_date_text", [
                formatDate(new Date(inv.period_start * 1000)),
                formatDate(new Date(inv.period_end * 1000)),
              ])}
            </TableCell>
            <TableCell className={"text-center"}>
              {(inv.total / 100).toFixed(2)} €
            </TableCell>
            <TableCell className={"text-center"}>
              <a
                rel={"noreferrer"}
                target={"_blank"}
                href={inv.hosted_invoice_url}
                className={"grey-text"}
              >
                <MDBIcon icon={"eye"} size={"2x"} />
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  handleLinkCopy = (evt: any) => {
    const dataDom = document.getElementById(evt.target.id);
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    if (document.execCommand("copy")) {
      this.setState({ ...this.state, successCopyLink: true }, () =>
        setTimeout(
          () => this.setState({ ...this.state, successCopyLink: false }),
          1500
        )
      );
    }
  };

  async handleResetInviteLink() {
    if (window.confirm(i18n._("BUTTONS.RESET_INVITE_LINK.CONFIRMATION_TEXT"))) {
      const updatedUser = await userApi.resetInviteInviteLink(this.props.user);
      this.props.dispatch(userAction.loginUser(updatedUser));
    }
  }

  render = () => {
    const { user } = this.props;
    const team_size = user?.permissions?.team_size?.value;
    const {
      invoices,
      customer,
      referer,
      members,
      rowsPerPage,
      page,
      isLoading,
      successCopyLink,
    } = this.state;

    const hasPendingSubscription = customer?.subscriptions?.data?.some(
      (x: any) => x.cancel_at_period_end
    );
    const invoicesCell = [
      i18n._("invoices_cell_subscription_label"),
      i18n._("invoices_cell_period_label"),
      i18n._("invoices_cell_amount_label"),
      i18n._("invoices_cell_link_label"),
    ];

    members.map(
      (member: any) => (member.isSelected = member.isSelected || false)
    );

    if (user.role > 1 && user.referer_id) {
      return (
        <MDBContainer className={"text-center mx-auto my-auto"}>
          {!!isLoading && (
            <div className={"overlay"}>
              <LoaderComponent size={"big"} />
            </div>
          )}
          <MDBRow>
            <MDBCol
              lg={"8"}
              md={"12"}
              className={"text-center tab-title-wrapper fv_tab_title_wrapper"}
            >
              <MDBCardTitle className={"card-title font-weight-bold"}>
                FileVert
              </MDBCardTitle>
            </MDBCol>
            <MDBCol size={"12"}>{i18n._("team_leader_label")}</MDBCol>

            <MDBCol size={"12"}>{referer.email}</MDBCol>

            <MDBCol size={"12"}>
              {referer.first_name} {referer.last_name}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }

    return (
      <MDBContainer>
        <MDBRow className={"border-bottom"}>
          <MDBCol
            lg={"6"}
            md={"12"}
            className={"text-left tab-title-wrapper fv_tab_title_wrapper"}
          >
            <h3 className={"font-weight-bold mb-0"}>
              FileVert{" "}
              {user.role > 1 &&
              !user.referer_id &&
              user?.permissions?.team_size?.value
                ? " Groupe"
                : ""}
            </h3>
            {!!userCanInviteMember(user) && (
              <p>
                {members?.length} / {team_size} {i18n._("user_label")}
              </p>
            )}
          </MDBCol>
          <MDBCol size={"6"} className={"d-flex flex-column"}>
            {!!userCanInviteMember(user) && (
              <MDBInput
                readOnly
                id={"invitation-link-to-copy"}
                onFocus={(evt: any) => {
                  this.handleLinkCopy(evt);
                  evt.target.blur();
                  evt.preventDefault();
                }}
                className={"pr-5 hover-pointer"}
                label={i18n._("BUTTONS.INVITE_LINK.LABEL")}
                outline
                value={this.props.user.invitation_link}
              />
            )}

            {!!successCopyLink && (
              <MDBNotification
                show
                fade
                message={i18n._("upload_finished_copied_label")}
                autohide={1000}
                titleClassName={"hidden d-none"}
                style={{
                  position: "fixed",
                  bottom: "10px",
                  left: "10px",
                  zIndex: 9999,
                }}
              />
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow></MDBRow>
        <MDBRow>
          <MDBCol className={"mb-5"} size={"12"}>
            {user.permissions?.team_size?.value && !user.referer_id && (
              <>
                <TableMembersComponent members={members} user_id={user.id} />
              </>
            )}
          </MDBCol>

          {!!(user.role && customer && !user.referer_id) && (
            // RENDER USER MDB TABLE
            <>
              {!!hasPendingSubscription && (
                <MDBAlert className={"w-100"} color={"primary"} dismiss>
                  {i18n._(
                    "MODAL_HEADER.OFFER_COMPARISON.AVAILABILITY.NEXT_INVOICE.LABEL"
                  )}
                </MDBAlert>
              )}
              <MDBCol size={"12"}>
                <TableSubscriptionsComponent
                  subscriptions={
                    customer?.subscriptions?.data ||
                    customer?.subscriptions ||
                    []
                  }
                  dispatch={this.props.dispatch}
                  user_id={user.id}
                />
              </MDBCol>
            </>
          )}

          <MDBRow className={"w-100 mt-5"}>
            <MDBCol size={"12"}>
              <Table
                //@ts-ignore
                padding={"dense"}
                className={"table-responsive-md table-striped text-center"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding={"checkbox"}
                      style={{ border: "none" }}
                      className={"pl-0 position-absolute"}
                    >
                      <h5
                        className={`text-big text-left pl-3 pt-${
                          isMobile ? 2 : 4
                        }`}
                      >
                        {i18n._("invoices_table_title")}
                      </h5>

                      <a
                        className={"pl-3"}
                        href={"#manage-billing"}
                        onClick={this.handleManageBilling}
                      >
                        {i18n._("user_manage_billing_label")}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {invoicesCell.map((invLabel: string, k: number) => (
                      <TableCell key={k} className={"text-center"}>
                        {invLabel}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {invoices &&
                  !!invoices.length &&
                  this.renderTableBody(
                    invoices.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  )}
              </Table>
            </MDBCol>
            <MDBCol size={"12"}>
              <TableCustomPagination
                elements={invoices}
                setParentState={this.setState}
                state={this.state}
              />
            </MDBCol>
          </MDBRow>
        </MDBRow>
      </MDBContainer>
    );
  };
}

const TableCustomPagination = ({
  elements,
  setParentState,
  state,
}: {
  elements: any[];
  setParentState: Function;
  state: any;
}): ReactElement | null => {
  const handleChangePage = (event: unknown, page: number): void => {
    setParentState({ ...state, page });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    try {
      setParentState({
        ...state,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100, 250];
  const { page, rowsPerPage } = state;
  return (
    <TablePagination
      labelRowsPerPage={"Nombre de lignes"}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={elements.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(SubscriptionComponent);
