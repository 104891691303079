export const ACCEPT_DEPOSIT = "ACCEPT_DEPOSIT";

export function acceptDeposit(value: {
  transfer_id: string;
  accepted: boolean;
  uploader_email: string;
}) {
  return {
    type: ACCEPT_DEPOSIT,
    value,
  };
}
