import React from "react";
import "./LoginButton.css";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBBtn,
  MDBAlert,
  MDBIcon,
  MDBLink,
  MDBModalHeader,
} from "mdbreact";

import { connect } from "react-redux";

import { userApi } from "../../../../Api";

import { appAction, userAction } from "../../../../Store";

import { Link } from "react-router-dom";
import { i18n } from "../../../../utils";
import { LangComponent } from "../../../index";
import { isMobile, isTablet } from "react-device-detect";

interface Props {
  dispatch: Function;
  user: any;
  mod?: "light" | "normal";
  app: any;
  btnClassName?: string;
  textColorName?: string;
  labelOnly?: boolean;
  iconOnly?: boolean;
  onModalChangeCB?: (isOpen: boolean) => void;
}

interface State {
  email: string;
  password: string;
  isModalOpen: boolean;
  error: any | null;
}

class LoginButtonComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      isModalOpen: false,
    };
  }

  handleChange = (evt: any) => {
    return this.setState({
      ...this.state,
      [evt.target.name]: evt.target.value,
    });
  };

  handleReset = () => {
    this.setState({ ...this.state, isModalOpen: false }, () =>
      this.props.dispatch(appAction.resetPassword(true))
    );
  };

  handleSubmit = async (force?: boolean) => {
    const { email, password } = this.state;
    const { user, error } = await userApi.auth({ email, password }, force);

    if (user && !error) {
      this.props.dispatch(userAction.loginUser(user));

      if (this.props.user && this.props.user.status) {
        this.closeModal();
        return document.location.reload();
      }
    }

    this.setState({ error });
  };

  closeModal = (): void => {
    if (this.props.onModalChangeCB) this.props.onModalChangeCB(false);
    this.setState({ isModalOpen: false, error: null });
    // prettier-ignore
    //@ts-ignore
    try { document.getElementById('fv-header').classList.remove('no-hover'); }
    catch (e) {}
  };

  openModal = (): void => {
    if (this.props.onModalChangeCB) this.props.onModalChangeCB(true);
    // prettier-ignore
    //@ts-ignore
    try { document.getElementById('fv-header').classList.add('no-hover'); }
    catch (e) {}
    this.setState({ isModalOpen: true, error: null });
  };

  renderLoginButon = (outline: boolean) =>
    !this.props.user || !this.props.user.status ? (
      <MDBBtn
        id={"fv-header-login-btn"}
        outline={outline}
        color={"default"}
        onClick={this.openModal}
        className={"btn-sm waves-effect bouton-space bg-transparent"}
      >
        {i18n._("login_label")}
      </MDBBtn>
    ) : (
      <span className={`my-auto`}>
        <Link
          to={"/profil"}
          className={`${
            outline
              ? this.props.btnClassName || "btn-outline-default"
              : "btn-outline-default"
          } btn-sm waves-effect bouton-space bg-transparent mr-2 d-block d-md-inline-flex `}
        >
          {i18n._("account_label")}
        </Link>

        <Link
          to={"#"}
          className={`${
            outline
              ? this.props.btnClassName || "btn-outline-default"
              : "btn-outline-default"
          } btn-sm waves-effect bouton-space bg-transparent mr-2 mt-3 mb-0 d-md-flex d-lg-none `}
          onClick={() => {
            this.props.dispatch(userAction.logoutUser({}));
          }}
        >
          <span className={"mr-2"}>{i18n._("logout_label")}</span>

          <MDBIcon
            title={i18n._("logout_label")}
            className={`float-right d-md-inline-flex d-lg-none hover-pointer dezoom ${
              this.props.textColorName || "text-default"
            }`}
            size={"2x"}
            icon={"sign-out-alt"}
            onClick={() => {
              this.props.dispatch(userAction.logoutUser({}));
            }}
          />
        </Link>
        <MDBIcon
          title={i18n._("logout_label")}
          className={`float-right d-none d-lg-block hover-pointer ${
            this.props.textColorName || "text-default"
          }`}
          size={"2x"}
          icon={"sign-out-alt"}
          onClick={() => {
            this.props.dispatch(userAction.logoutUser({}));
          }}
        />
      </span>
    );

  renderLoginButonMobile = (outline: boolean) =>
    !this.props.user || !this.props.user.status ? (
      <MDBLink
        id={"fv-header-login-btn"}
        to={"#"}
        color={"default"}
        onClick={this.openModal}
        className={"btn-sm waves-effect bouton-space bg-transparent"}
      >
        {i18n._("login_label")}
      </MDBLink>
    ) : (
      <>
        <MDBLink to={"/profil"} className={`bg-transparent d-block text-white`}>
          {i18n._("account_label")}
        </MDBLink>
        <MDBLink
          to={"/#"}
          className={`d-block text-white`}
          onClick={() => {
            this.props.dispatch(userAction.logoutUser({}));
          }}
        >
          <span className={"mr-2"}>{i18n._("logout_label")}</span>
        </MDBLink>
      </>
    );

  render = () => {
    // prettier-ignore
    const { mod } = this.props;

    const outline = mod !== "light";
    const textClass = mod === "light" ? "text-black" : "text-white";

    return (
      <div className={`${!isMobile && "d-inline-flex"}`}>
        {!isMobile
          ? this.renderLoginButon(outline)
          : this.renderLoginButonMobile(false)}

        {(!isMobile || isTablet) && (
          <LangComponent textClass={textClass} dispatch={this.props.dispatch} />
        )}

        <MDBModal
          className={"fv_login_modal"}
          centered
          isOpen={this.state.isModalOpen}
          toggle={this.closeModal}
        >
          <MDBModalBody>
            <MDBContainer
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className={"header-login"}
            >
              <MDBRow className="nav nav-tabs md-tabs tabs-2 w-100 header-login-row">
                <MDBCol
                  size={"6"}
                  className="nav-item waves-effect waves-light p-0 m-0"
                >
                  <span
                    className="nav-link active text-center"
                    data-toggle="tab"
                  >
                    <MDBIcon icon={"user"} className="mr-1" />
                    {i18n._("login_label")}
                  </span>
                </MDBCol>

                <MDBCol
                  size={"6"}
                  className="nav-item waves-effect waves-light text-white p-0 m-0 text-white"
                >
                  <Link
                    style={{ float: "right" }}
                    className="nav-link text-white text-center bg-default"
                    to={"/offers"}
                    onClick={this.closeModal}
                  >
                    <MDBIcon icon={"user-plus"} className="mr-1" />
                    {i18n._("register_label")}
                  </Link>
                </MDBCol>

                {/*
                <MDBIcon onClick={this.closeModal} icon={'times'} size={'1x'} className={'text-white position-absolute hover-pointer'} style={{right: 0, top: 0}}/>
*/}
              </MDBRow>
            </MDBContainer>

            <MDBContainer className={"mt-5"}>
              {this.state?.error?.code &&
                this.state.error.code === "TOO_MANY_LOGIN" && (
                  <MDBModal isOpen centered>
                    <MDBModalHeader>
                      <p className={"text-center"}>
                        {i18n._("TOO_MANY_LOGIN_HEADER_TEXT")}
                      </p>
                    </MDBModalHeader>
                    <MDBModalBody
                      onClick={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <p className={"py-4 text-center"}>
                        {i18n._("TOO_MANY_LOGIN_BODY_TEXT")}
                      </p>
                    </MDBModalBody>
                    <MDBModalFooter style={{ justifyContent: "center" }}>
                      <MDBRow>
                        <MDBCol
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                          size={"12"}
                        >
                          <a
                            href={"#login"}
                            onClick={() =>
                              this.setState({ ...this.state, error: null })
                            }
                          >
                            {i18n._("TOO_MANY_LOGIN_CANCEL_TEXT")}
                          </a>
                          <MDBBtn
                            onClick={() => {
                              this.handleSubmit(true);
                            }}
                          >
                            {i18n._("TOO_MANY_LOGIN_CONFIRM_TEXT")}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBModalFooter>
                  </MDBModal>
                )}

              {this.state.error && this.state.error?.code !== "TOO_MANY_LOGIN" && (
                <MDBRow className={"w-100 my-3"}>
                  <MDBCol size={"12"}>
                    <MDBAlert
                      color={"danger"}
                      dismiss={true}
                      onClose={() => this.setState({ error: null })}
                    >
                      {i18n._("invalid_credentials")}
                    </MDBAlert>
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow className={"w-100 my-3"}>
                <MDBCol
                  size={"12"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <MDBInput
                    outline
                    className={"px-2 text-left"}
                    validate
                    name={"email"}
                    type={"email"}
                    value={this.state.email}
                    onChange={this.handleChange}
                    label={i18n._("form_login_email_label")}
                    icon="envelope"
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_login_email_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_login_email_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow className={"w-100 my-3"}>
                <MDBCol
                  size={"12"}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <MDBInput
                    outline
                    className={"px-2 text-left"}
                    validate
                    error={this.state.error || ""}
                    name={"password"}
                    type={"password"}
                    value={this.state.password}
                    onChange={this.handleChange}
                    label={i18n._("form_login_password_label")}
                    icon="lock"
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_login_password_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_login_password_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow center className={"w-100"}>
                <MDBCol size={"12"} className={"text-center"}>
                  <MDBBtn
                    className={"waves-effect waves-light"}
                    color={"default"}
                    onClick={() => this.handleSubmit()}
                  >
                    {i18n._("login_label")}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBContainer fluid>
              <MDBRow className={"w-100"} end>
                <MDBCol size={"12"} className={"mx-auto text-center"}>
                  <Link to={"#"} onClick={this.handleReset}>
                    {i18n._("forgotten_password_label")}
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  app: state.app,
});

export default connect(mapStateToProps)(LoginButtonComponent);
