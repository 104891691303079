import axios, { AxiosResponse } from "axios";

interface ContactRequest {
  email: string;
  subject: string;
  message: string;
}

const path: string = "contact";

const contactApi = {
  async sendContactRequest(contactRequest: ContactRequest) {
    try {
      let contactResponse: AxiosResponse & any = await axios.post(
        `${path}`,
        contactRequest
      );

      if (contactResponse.error) {
        contactResponse = { data: { error: "INVALID_REQUEST" } };
      }

      return contactResponse && contactResponse.data
        ? contactResponse.data
        : null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default contactApi;
