const convertDate = (
  date: Date | string,
  lang: string = "fr_FR",
  withTime: boolean = false
): string => {
  if (typeof date === "string") date = new Date(date);
  let d: string | number = date.getDate();
  let m: string | number = date.getMonth();
  let y: string | number = date.getFullYear();
  let time: string = "";

  if (withTime) {
    time =
      lang === "fr_FR"
        ? ` à ${date.toLocaleTimeString()}`
        : ` , ${date.toLocaleTimeString()}`;
  }

  d = d < 10 ? "0" + d : d;
  m = m + 1 < 10 ? "0" + (m + 1) : m + 1;

  return lang === "fr_FR" ? `${d}-${m}-${y}${time}` : `${m}/${d}/${y}${time}`;
};

export default convertDate;
