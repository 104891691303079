import React, { useEffect, useState } from "react";
import { BurgerMenuButton } from "..";
import "./BurgerMenu.css";
import { withRouter } from "react-router-dom";

export const BurgerMenu = withRouter(
  ({ children, light, onTrigger, backdrop, ...rest }: any) => {
    const [opened, setOpened] = useState(false);

    useEffect(() => {
      if (rest.location.pathname === "/contact" || rest.location.hash === "#") {
        return;
      }

      setOpened(() => false);
      onTrigger(false);
    }, [rest.location]);

    useEffect(() => {
      if (onTrigger) onTrigger(opened);
    }, [opened]);

    return (
      <div>
        {backdrop && (
          <div
            className={`fv-burger-menu-backdrop fv_burger_menu_backdrop ${
              opened ? "open" : "close"
            }`}
            onClick={() => setOpened(false)}
          />
        )}

        <BurgerMenuButton
          opened={opened}
          setOpened={setOpened}
          light={light}
          onClick={setOpened}
          backdrop={true}
        />
        <div
          className={`fv-burger-menu-wrapper ${light && "light"} ${
            opened && "opened"
          } fv_burger_menu_wrapper`}
        >
          {children}
        </div>
      </div>
    );
  }
);
