import { Context, createContext } from "react";

interface ITransferContext {
  loaded: number;
  consumption: {
    kms: number;
    sizeInO: number;
    kWh: number;
    gCO2: number;
  };
  [key: string]: any;
}

export const DEFAULT_TRANSFERCONTEXT_VALUE: ITransferContext = {
  loaded: 0,
  consumption: {
    kms: 0,
    sizeInO: 0,
    kWh: 0,
    gCO2: 0,
  },
};

export const TransferContext: Context<ITransferContext> = createContext(
  DEFAULT_TRANSFERCONTEXT_VALUE
);
