import CryptoJS from "crypto-js";

export function decrypt(hash: string, pass: string) {
  const iv = CryptoJS.enc.Base64.parse(pass);
  let result: string = hash;
  try {
    result = CryptoJS.RC4Drop.decrypt(hash, iv, { iv }).toString(
      CryptoJS.enc.Utf8
    );
  } catch (e) {
    try {
      result = CryptoJS.RC4Drop.decrypt(unescape(hash), iv, { iv }).toString(
        CryptoJS.enc.Utf8
      );
    } catch (e) {
      console.warn("Decryption failed");
    }
  } finally {
    return unescape(result);
  }
}

export function encrypt(str: string, pass: string): string {
  const iv = CryptoJS.enc.Base64.parse(pass);
  let result: string = "";
  result = CryptoJS.RC4Drop.encrypt(escape(str), iv, { iv }).toString();
  return result;
}

const cryptoUtils = {
  encrypt,
  decrypt,
};

export default cryptoUtils;

// prettier-ignore
//@ts-ignore
window.c = {encrypt, decrypt, CryptoJS}
