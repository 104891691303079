export const SET_INFOS = "SET_INFOS";
export const SET_PREVIEWS = "SET_PREVIEWS";
export const LOCK_TRANSFER = "LOCK_TRANSFER";

export const PURGE = "PURGE";

export function setInfos(value: any) {
  return { type: SET_INFOS, value };
}

export function lockTransfer(value: any) {
  return { type: LOCK_TRANSFER, value };
}

export function purgeInfos(value: any = {}) {
  return { type: PURGE, value };
}
