import React, { useState } from "react";
import { DepositFormComponent } from "../../../Form";
import { getDepositFormConfig } from "../../../../Services";
import { i18n, isMobile } from "../../../../utils";

export function DepositBoxComponent({
  onDepositFormSubmit,
  user_email,
  isPro,
}: any) {
  const [uploaderEmail, setUploaderEmail] = useState(user_email);
  const [accepted, setAccepted] = useState(false);

  if (accepted) console.log("CGU has been accepted");

  return (
    <>
      <div
        style={{
          zIndex: 99999,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,.3)",
        }}
      ></div>

      <div
        style={{
          position: "fixed",
          zIndex: 99999,
          minHeight: 400,
          minWidth: isMobile ? undefined : 400,
          height: 400,
          width: isMobile ? "90%" : undefined,
          left: isMobile ? "5%" : undefined,
          top: isMobile ? "10%" : "calc(50% - 200px)",
        }}
        className={"card text-center fv-card-form-deposit"}
      >
        <div
          className={
            "modal-header card-header text-center fv-card-form-deposit"
          }
          style={{ color: "#FFF" }}
        >
          <h2>{i18n._("fv_deposit_cgu_card_header")}</h2>
        </div>

        <div className={"card-body text-center fv-card-form-deposit"}>
          <div>
            <DepositFormComponent
              isPro={isPro}
              onSubmit={(values: any) => {
                setAccepted(values?.cgu);
                setUploaderEmail(values?.email);
                if (values?.cgu && values?.email)
                  setTimeout(
                    () =>
                      onDepositFormSubmit({
                        uploader_email: values?.email,
                        accepted: values?.cgu,
                      }),
                    250
                  );
              }}
              {...getDepositFormConfig(uploaderEmail || "")}
            />
          </div>
        </div>

        <div className={"card-footer text-center fv-card-form-deposit"}>
          <span>{i18n._("fv_deposit_cgu_card_footer")}</span>
        </div>
      </div>
    </>
  );
}
