import i18n from "./i18n";
import convertSize, {
  convertSizeInGo,
  convertSizeInMo,
  convertSizeInKo,
} from "./convertSize";
import convertTime from "./convertTime";
import getPrices from "./getPrices";
import calcConsumption from "./calcConsumption";
import removeEmojis from "./removeEmojis";
import { encrypt, decrypt } from "./crypto";
import convertDate from "./convertDate";
import matomo from "./matomo";
import { config } from "../config";
const wrapStr = (
  str: string = "",
  maxLength: number = 50,
  offset: number = 3,
  glue: string = "."
) => {
  return str?.length > maxLength
    ? `${str.substr(0, maxLength - offset)}${glue.repeat(offset)}`
    : str;
};

export const removeHTMLTags = (htmlString: any) => {
  const elDom = document.createElement("div");
  elDom.innerHTML = htmlString;
  return elDom.innerText;
};

const fixeVal = (v: number = 0, l: number = 2, force: boolean = false) => {
  let result = "";
  v = v ? v : 0;
  result = v.toFixed(l);

  if ("0.00" === result && !force) result = "< 0.01";

  return result;
};

export {
  i18n,
  convertSize,
  convertSizeInGo,
  convertSizeInMo,
  convertSizeInKo,
  convertTime,
  getPrices,
  calcConsumption,
  removeEmojis,
  encrypt,
  decrypt,
  wrapStr,
  convertDate,
  matomo,
  fixeVal,
};

export * from "./compareShallowObject";
export * from "./getPredefinedPermissions";
export * from "./getClosestNumber";
export * from "./isOfferDifferent";
export * from "./tablelist";

export function onClickSubmit(evt: any): void {
  const id = typeof evt === "string" ? evt : evt.target.getAttribute("for");
  if (!id) return;

  const submitBtn: any = document
    .getElementById(id)
    ?.querySelector('input[type="submit"]');
  submitBtn && submitBtn.click && submitBtn.click();
}

export function isValidEmail(str: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
}

export const formatDate = (date: Date) =>
  `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

/***
 * @param role (Destructured from user)
 * @param referer_id (Destructured from user)
 * @param permissions (Destructured from user)
 * @return boolean
 */
export const userCanInviteMember = ({ role, referer_id, permissions }: any) =>
  role > 1 && !referer_id && permissions?.team_size?.value;

export function getCurrentUserPriceByPeriod(
  userPeriod: "year" | "month",
  currentPeriod: "year" | "month",
  price?: number
) {
  if (!price) return 0;

  if (userPeriod === currentPeriod) {
    return Math.ceil(price * 100) / 100;
  }

  return userPeriod === "year"
    ? Math.ceil((price / 12) * 100) / 100
    : Math.ceil(price * 100) / 100;
}

export const getFinalPrice = (period: "year" | "month", price: number) =>
  period === "year"
    ? Math.ceil(price * 12 * 100) / 100
    : Math.ceil(price * 100) / 100;

export * from "./convertGCO2toKM";
export * from "./hashLinkScroll";

export * from "./customDom";

/**
 * Return the QR Code Link image link
 * @param linkTo
 * @param size
 */
export function getQRCodeLink(linkTo: string, size: number = 600) {
  return `${config.apiUrl}/v2/qrcode?linkTo=${encodeURIComponent(
    linkTo
  )}&size=${size}`;
  // return `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${linkTo}`
}

export * from "./device";
export * from "./eventDomUtils";
