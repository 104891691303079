import React from "react";
import "./Registerform.css";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { MDBBtn, MDBCol, MDBInput, MDBContainer, MDBRow } from "mdbreact";
import { i18n } from "../../utils";
import TooltipComponent from "../Tooltip/Tooltip";
import { userApi } from "../../Api";

interface Props {
  cb: Function;
  role: number;
  user?: any;
  period?: string;
  prices?: any;
}

interface State {
  form: {
    role: number;
    lastName: string;
    firstName: string;
    organization: string;
    job: string;
    tva_number: string;
    email: string;
    country: string;
    city: string;
    street: string;
    zipCode: string;
    password: string;
    confirmPassword: string;
    nb_users: number;
    cgu: boolean;
  };
  isValid: boolean;
}

const qtyOptions = [1, 3, 5, 10, 20, 30];

class RegisterformComponent extends React.Component<Props, State & any> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      form: {
        lastName: "",
        firstName: "",
        organization: "",
        job: "",
        tva_number: "",
        email: "",
        country: "",
        city: "",
        street: "",
        zipCode: "",
        password: "",
        confirmPassword: "",
        role: this.props.role,
        cgu: false,
        nb_users: 1,
      },
      isValid: false,
    };
  }

  async handleChange(evt: any) {
    let isValid = true;
    let { form } = this.state;
    const passwordField = document.querySelector('input[name="password"]');
    const confirmPassField = document.querySelector(
      'input[name="confirmPassword"]'
    );
    const value =
      evt.target.name === "cgu" ? evt.target.checked : evt.target.value;

    form[evt.target.name] = value;

    if (!this.props.user || !this.props.user.status) {
      for (let k in form) {
        // if (
        //   (2 > this.props.role && !!~proKeys.indexOf(k)) ||
        //   !!~optionalKeys.indexOf(k)
        // )
        //   /*NO CHANGE*/ isValid = isValid;
        // else
        if (!form[k]) {
          isValid = false;
          break;
        }
      }
      if (
        form.password &&
        form.confirmPassword &&
        confirmPassField &&
        passwordField
      ) {
        if (
          !form.password.match(
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z|a-z]).*$/
          )
        ) {
          // prettier-ignore
          //@ts-ignore
          passwordField.setCustomValidity('invalid');
          passwordField.classList.add("is-invalid");
          passwordField.classList.remove("is-valid");
        } else {
          // prettier-ignore
          //@ts-ignore
          passwordField.setCustomValidity('');
          passwordField.classList.remove("is-invalid");
          passwordField.classList.add("is-valid");
        }

        if (form.password !== form.confirmPassword) {
          // prettier-ignore
          //@ts-ignore
          confirmPassField.setCustomValidity('invalid');
          confirmPassField.classList.add("is-invalid");
          confirmPassField.classList.remove("is-valid");
        } else {
          // prettier-ignore
          //@ts-ignore
          confirmPassField.setCustomValidity('');
          confirmPassField.classList.remove("is-invalid");
          confirmPassField.classList.add("is-valid");
        }
      }
    }

    this.setState({ isValid, form });
  }

  handleSubmit = async (evt: Event & any) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.className += " was-validated";

    let errors = [];
    let { form, isValid } = this.state;

    const isEmailAvailable = await userApi.checkEmail(form.email);
    const emailField = document.querySelector('input[name="email"]');

    if ((!isEmailAvailable || isEmailAvailable.error) && emailField) {
      isValid = false;
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('invalid');
      emailField.classList.add("is-invalid");
      emailField.classList.remove("is-valid");
    } else if (emailField) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('');
      emailField.classList.remove("is-invalid");
      emailField.classList.add("is-valid");
    }

    if (!form.cgu) errors.push("cgu");

    for (let k in form)
      if (
        !form[k] &&
        (!this.props.user || !this.props.user.status || k === "cgu")
      )
        errors.push(k);

    if (!this.props.user || !this.props.user.status)
      if (form.password !== form.confirmPassword)
        errors.push("confirmPassword");

    const submitField = document.querySelector('button[type="submit"]');

    if (!isValid && submitField) {
      // prettier-ignore
      //@ts-ignore
      submitField.setCustomValidity('invalid');
      submitField.classList.add("is-invalid");
      submitField.classList.remove("is-valid");
      return false;
    } else if (submitField) {
      // prettier-ignore
      //@ts-ignore
      submitField.setCustomValidity('');
      submitField.classList.add("is-valid");
      submitField.classList.remove("is-invalid");
    }

    if (isValid) this.props.cb(form);
  };

  render = () => {
    const { role, prices, user, period } = this.props;
    let {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      job,
      organization,
      tva_number,
      cgu,
      city,
      country,
      street,
      zipCode,
      nb_users,
    } = this.state.form;

    return (
      <MDBContainer className={"fv_register_form_container"}>
        <MDBRow>
          <MDBCol size={"12"} className={"px-0"}>
            <form
              noValidate
              className={
                "container text-left register-form needs-validation fv_register_form_form"
              }
              onSubmit={this.handleSubmit}
            >
              <hr className={"mt-3"} />
              {(!user || !user.status) && (
                <MDBRow className={"form-group m-inside"}>
                  <MDBCol size={"6"}>
                    <MDBInput
                      required
                      outline
                      label={i18n._("form_profil_first_name_label")}
                      onChange={this.handleChange}
                      type={"text"}
                      autoComplete={"given-name"}
                      name={"firstName"}
                      value={firstName}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_first_name_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_first_name_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>

                  <MDBCol size={"6"}>
                    <MDBInput
                      required
                      outline
                      label={i18n._("form_profil_last_name_label")}
                      onChange={this.handleChange}
                      type={"text"}
                      autoComplete={"family-name"}
                      name={"lastName"}
                      value={lastName}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_last_name_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_last_name_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>

                  <MDBCol size={"12"}>
                    <MDBInput
                      outline
                      required
                      label={i18n._("form_profil_email_label")}
                      onChange={this.handleChange}
                      type={"email"}
                      autoComplete={"email"}
                      name={"email"}
                      value={email}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_email_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_email_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              )}
              {(!user || !user.status) && (
                <MDBRow className={"form-group m-inside"}>
                  <MDBCol size={"12"}>
                    <MDBInput
                      required
                      outline
                      label={i18n._("form_profil_password_label")}
                      onChange={this.handleChange}
                      type={"password"}
                      name={"password"}
                      value={password}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_password_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_password_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol size={"12"}>
                    <MDBInput
                      required
                      outline
                      label={i18n._("form_profil_password_confirmation_label")}
                      onChange={this.handleChange}
                      type={"password"}
                      name={"confirmPassword"}
                      value={confirmPassword}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_password_confirmation_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_password_confirmation_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              )}

              {1 < role && (!user || !user.status) && (
                <MDBRow className={"form-group m-inside"}>
                  <MDBCol size={"6"}>
                    <MDBInput
                      required
                      outline
                      label={i18n._("form_profil_organization_label")}
                      onChange={this.handleChange}
                      type={"text"}
                      autoComplete={"organization"}
                      name={"organization"}
                      value={organization}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_organization_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_organization_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol size={"6"}>
                    <MDBInput
                      outline
                      label={i18n._("form_profil_job_label")}
                      onChange={this.handleChange}
                      type={"text"}
                      autoComplete={"job"}
                      name={"job"}
                      value={job}
                    >
                      <div className="invalid-feedback">
                        {i18n._("form_profil_job_invalid")}
                      </div>
                      <div className="valid-feedback">
                        {i18n._("form_profil_job_valid")}
                      </div>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              )}

              {(!user || !user.status) && (
                <MDBRow className={"form-group m-inside mb-0"}>
                  <address className={"col-12 mb-0"}>
                    <MDBRow className={"form-group"}>
                      <MDBCol size={"12"}>
                        <MDBInput
                          outline
                          maxLength={20}
                          required
                          label={i18n._("form_profil_tva_number_label")}
                          onChange={this.handleChange}
                          type={"text"}
                          name={"tva_number"}
                          value={tva_number}
                        >
                          <div className="invalid-feedback">
                            {i18n._("form_profil_tva_number_invalid")}
                          </div>
                          <div className="valid-feedback">
                            {i18n._("form_profil_tva_number_valid")}
                          </div>
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className={"form-group"}>
                      <MDBCol size={"12"}>
                        <MDBInput
                          required
                          outline
                          label={i18n._("form_profil_address_street_label")}
                          onChange={this.handleChange}
                          type={"street_number"}
                          autoComplete={"address-line1"}
                          name={"street"}
                          value={street}
                        >
                          <div className="invalid-feedback">
                            {i18n._("form_profil_address_street_invalid")}
                          </div>
                          <div className="valid-feedback">
                            {i18n._("form_profil_address_street_valid")}
                          </div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol size={"5"}>
                        <MDBInput
                          required
                          outline
                          label={i18n._("form_profil_address_zipcode_label")}
                          onChange={this.handleChange}
                          type={"postal-code"}
                          autoComplete={"postal-code"}
                          name={"zipCode"}
                          value={zipCode}
                        >
                          <div className="invalid-feedback">
                            {i18n._("form_profil_address_zipcode_invalid")}
                          </div>
                          <div className="valid-feedback">
                            {i18n._("form_profil_address_zipcode_valid")}
                          </div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol size={"7"}>
                        <MDBInput
                          required
                          outline
                          label={i18n._("form_profil_address_city_label")}
                          onChange={this.handleChange}
                          type={"city"}
                          autoComplete={"address-level2"}
                          name={"city"}
                          value={city}
                        >
                          <div className="invalid-feedback">
                            {i18n._("form_profil_address_city_invalid")}
                          </div>
                          <div className="valid-feedback">
                            {i18n._("form_profil_address_city_valid")}
                          </div>
                        </MDBInput>
                      </MDBCol>
                      <MDBCol size={"5"}>
                        <MDBInput
                          required
                          outline
                          label={i18n._("form_profil_address_country_label")}
                          onChange={this.handleChange}
                          type={"country"}
                          autoComplete={"country-name"}
                          name={"country"}
                          value={country}
                        >
                          <div className="invalid-feedback">
                            {i18n._("form_profil_address_country_invalid")}
                          </div>
                          <div className="valid-feedback">
                            {i18n._("form_profil_address_country_valid")}
                          </div>
                        </MDBInput>
                      </MDBCol>
                    </MDBRow>
                  </address>
                </MDBRow>
              )}

              {/* KEEP ONLY BELOW IF USER IS LOGGED IN */}
              {role > 1 && (
                <MDBRow className={"form-group m-inside my-0"}>
                  <MDBCol size={"12"}>
                    {prices && (
                      <SelectTiers
                        value={nb_users}
                        disabled={!user || !user.status}
                        period={period}
                        prices={prices}
                        onChange={this.handleChange}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow
                className={"form-group m-inside my-0 fv_cgu_input_wrapper"}
              >
                <MDBCol size={"12"}>
                  <MDBInput
                    className={
                      "no-outline no-box-shadow hover-pointer fv_cgu_input"
                    }
                    required
                    id="checkbox4"
                    label={i18n._("accept_cgu_label")}
                    onChange={this.handleChange}
                    type={"checkbox"}
                    name={"cgu"}
                    checked={cgu}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className={"form-group m-inside"}>
                <MDBCol size={"12"} className={"text-center"}>
                  <MDBBtn
                    onClick={this.handleSubmit}
                    type={"submit"}
                    color={"default"}
                  >
                    {i18n._("btn_valid_label")}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

//
const renderOption = (up_to: number, price: number): string =>
  up_to > 1
    ? i18n._("more_users_quantity_label", [up_to, price.toFixed(2)])
    : i18n._("unique_user_label");

const SelectTiers = ({ onChange, period, prices, disabled, value }: any) => {
  let price: number;

  return (
    <div>
      <Select
        className={"fv_pro_select"}
        disabled={disabled}
        name={"nb_users"}
        onChange={onChange}
        value={value}
      >
        {qtyOptions.map((el: any, i: number) => {
          price = (prices.year / 12) * el;
          return el ? (
            <MenuItem key={i} value={el}>
              {" "}
              {renderOption(el, price)}{" "}
            </MenuItem>
          ) : null;
        })}
      </Select>
      {disabled && <TooltipComponent text={i18n._("pro_team_explaination")} />}
    </div>
  );
};

export default RegisterformComponent;
