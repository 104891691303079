import React from "react";
import "./Collab.css";

import { HistoryComponent } from "../Shared";

import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { i18n } from "../../../../utils";

interface Props {
  title: string;
}
interface State {}

class CollabComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { title } = this.props;

    return (
      <MDBContainer fluid>
        <MDBRow className={"border-bottom"}>
          <MDBCol
            lg={"8"}
            md={"12"}
            className={"my-auto tab-title-wrapper fv_tab_title_wrapper"}
          >
            <h3 className={"float-left font-weight-bold"}>
              {" "}
              {i18n._("profil_collab_title")}{" "}
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size={"12"} className={"my-auto"}>
            <HistoryComponent type={"collab"} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

export default CollabComponent;
