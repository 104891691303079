import { InputItem } from "../../Class/Input/InputItem";

export function getDepositFormConfig(defaultValue: string = "") {
  return {
    email: new InputItem({
      type: "email",
      required: true,
      defaultValue,
      errors: {
        missing: "INPUTS.EMAIL.ERRORS.MISSING",
        invalid: "INPUTS.EMAIL.ERRORS.INVALID",
        unavailable: "INPUTS.EMAIL.ERRORS.UNAVAILABLE",
      },
      name: "email",
      label: "INPUTS.EMAIL.LABEL",
    }),
  };
}
