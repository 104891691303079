import { defaultPermissions, Go, offerLabelsAndColors } from "../Constants";

export function getPredefinedPermissions(
  type = offerLabelsAndColors[2].label,
  {
    transfer_notification,
    transfer_authentication,
    transfer_tracking,
    consumption_export,
    team_size,
    customization,
    outlook_addin,
    upload_size,
    upload_ttl,
    studio,
  }: any = defaultPermissions
) {
  if (type === offerLabelsAndColors[0].label) return defaultPermissions;
  return {
    studio: {
      ...studio,
      value: type === offerLabelsAndColors[3].label,
    },
    transfer_notification: {
      ...transfer_notification,
      value: true,
    },
    transfer_authentication: {
      ...transfer_authentication,
      value: true,
    },
    transfer_tracking: {
      ...transfer_tracking,
      value: true,
    },
    consumption_export: {
      ...consumption_export,
      value: true,
    },
    team_size: {
      ...team_size,
      value: type === offerLabelsAndColors[2].label ? 0 : false,
    },
    customization: {
      ...customization,
      value:
        type === offerLabelsAndColors[2].label ||
        type === offerLabelsAndColors[3].label,
    },
    outlook_addin: {
      ...outlook_addin,
      value: type === offerLabelsAndColors[2].label,
    },
    upload_size: {
      ...upload_size,
      value: 20 * Go,
    },
    upload_ttl: {
      ...upload_ttl,
      value:
        type === offerLabelsAndColors[2].label ||
        type === offerLabelsAndColors[3].label
          ? 15
          : 7,
    },
  };
}
