import React from "react";
import "./Account.css";

import { connect } from "react-redux";

import { MDBCol, MDBContainer, MDBNotification, MDBRow } from "mdbreact";

import { InformationComponent, SpaceComponent } from "./Components";

import { i18n, userCanInviteMember } from "../../../../utils/";
import { ActionInputComponent, BtnComponent } from "../../../index";
import { userApi } from "../../../../Api";
import * as userAction from "../../../../Store/Actions/userAction";
import { ApiKeyComponent } from "./Components/ApiKeyComponent";

interface Props {
  dispatch: Function;
  user: any;
  title: string;
}

interface State {
  step: number;
  isLoading: boolean;
  hasChanged: boolean;
  isDisabled: boolean;
  isSuccess: boolean;
  user: any;
  error: any;
  successCopyLink: boolean;
}

class AccountComponent extends React.Component<Props, State> {
  tab: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      step: 1,
      isLoading: false,
      hasChanged: true,
      isDisabled: true,
      isSuccess: false,
      user: undefined,
      error: null,
      successCopyLink: false,
    };
  }

  handleChangeInfo = (userInfo: any) => {
    this.setState({
      ...this.state,
      hasChanged: true,
      user: { ...this.state.user, ...userInfo },
    });
  };

  handleLinkCopy = (evt: any) => {
    const dataDom = document.getElementById(evt.target.id);
    const selection = window.getSelection();

    if (!dataDom || !selection) return;

    // prettier-ignore
    //@ts-ignore
    dataDom.select();

    if (document.execCommand("copy")) {
      this.setState({ ...this.state, successCopyLink: true }, () =>
        setTimeout(
          () => this.setState({ ...this.state, successCopyLink: false }),
          1500
        )
      );
    }
  };

  handleChangeSpace = (
    userConfig: any,
    userLogo: any,
    key: string = "logo"
  ) => {
    if (userConfig === "RESET") {
      return this.setState({
        hasChanged: true,
        user: {
          ...this.state.user,
          config: {
            theme: "",
            url: "",
            website: "",
            rss_feed: "",
            title: "",
            message: "",
            logo: false,
            background: false,
          },
          logo: false,
          background: false,
        },
      });
    }
    if (
      key === "background" &&
      userConfig?.theme &&
      userLogo &&
      !userConfig?.theme?.includes("custom")
    )
      userConfig.theme = `custom-${userConfig.theme?.split("-")[1] || 0}`;

    if (userConfig && (userLogo || userLogo === false))
      return this.setState({
        ...this.state,
        hasChanged: true,
        user: {
          ...this.state.user,
          config: { ...userConfig },
          [key]: { ...userLogo },
        },
      });
    else if (userLogo || userLogo === false) {
      const config = this.state.user?.config
        ? { ...this.state.user.config, [key]: { ...userLogo } }
        : { [key]: { ...userLogo } };

      return this.setState({
        ...this.state,
        hasChanged: true,
        user: { ...this.state.user, config },
      });
    } else if (userConfig)
      return this.setState({
        ...this.state,
        hasChanged: true,
        user: { ...this.state.user, config: { ...userConfig } },
      });
  };

  handleSubmit = async () => {
    try {
      const { step, user } = this.state;
      const currentUserConfig = this.props.user?.config;

      if (step === 1)
        return this.setState({ ...this.state, step: 2, hasChanged: false });

      this.setState({ ...this.state, isLoading: true });
      let userUp: any = {};

      Object.keys(user).forEach((k: string) => {
        if (k === "config" && user[k]) {
          return Object.keys(user[k]).forEach((k2: string) => {
            if (k2 === "url") {
              k2 = "website";
            }
            if (
              user[k][k2] !== null &&
              user[k][k2] !== currentUserConfig[k2] &&
              !(!user[k][k2] && !currentUserConfig[k2])
            ) {
              userUp[k] = userUp[k] || {};
              userUp[k][k2] = user[k][k2];
            }
          });
        }
        if (user[k] !== null) {
          userUp[k] = user[k];
        }
      });

      const updatedUser = await userApi.update(userUp, this.props.user.id);
      this.props.dispatch(userAction.loginUser(updatedUser));
      this.setState({
        ...this.state,
        isLoading: false,
        isDisabled: true,
        hasChanged: true,
        step: 1,
      });
    } catch (error: any) {
      if (error.code)
        this.setState({
          ...this.state,
          error: { [error.code]: error },
          isLoading: false,
        });
    }
  };

  render = () => {
    const { isLoading, hasChanged, isSuccess, step, user } = this.state;
    const { title } = this.props;

    const isCustomizable = this.props.user?.permissions?.customization?.value;

    return (
      <MDBContainer>
        <MDBRow className={"border-bottom"}>
          <MDBCol
            lg={"8"}
            md={"12"}
            className={"my-auto tab-title-wrapper fv_tab_title_wrapper"}
          >
            {this.props.user?.permissions?.outlook_addin?.value && (
              <div
                className={"position-absolute fv_outlook_link_div"}
                style={{ top: 50 }}
              >
                <a
                  rel={"noreferrer"}
                  className={"mx-0 fv_outlook_link"}
                  target={"_blank"}
                  href={i18n._("outlook_download_link")}
                >
                  {i18n._("outlook_download_text")}
                </a>
              </div>
            )}
            <h3 className={"float-left font-weight-bold "}>{title}</h3>
          </MDBCol>
          <MDBCol lg={"4"} md={"12"} className={"my-auto"}>
            <BtnComponent
              outline={false}
              background={"default"}
              color={"default"}
              className={"waves-effect waves-light text-uppercase float-right"}
              onClick={this.handleSubmit}
              text={
                step === 1
                  ? i18n._("btn_modify_label")
                  : i18n._("btn_save_label")
              }
              isLoading={isLoading}
              hasChanged={hasChanged}
              isDisabled={
                (step !== 1 && !hasChanged) || (user && user?.isError)
              }
              isSuccess={isSuccess}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className={"mx-auto"}>
          <MDBCol md={"6"} sm={"12"} className={"mx-auto"}>
            <InformationComponent
              error={this.state.error}
              isDisabled={step === 1}
              handleChangeInfo={this.handleChangeInfo}
            />

            {!!(
              userCanInviteMember(this.props.user) &&
              !this.props.user?.referer_id
            ) && (
              <MDBContainer className={"mx-auto"}>
                <MDBRow className={"mx-auto mt-5"}>
                  <MDBCol size={"12"} className={"pb-0"}>
                    <>
                      <h5
                        className={
                          "text-left my-5 font-weight-bold  profil-infos-panel fv_profil_infos_panel"
                        }
                      >
                        {i18n._("BUTTONS.AFFILIATE_LINK.LABEL")}
                      </h5>

                      <ActionInputComponent
                        customClass={
                          "pr-5 hover-pointer outline mt-4 account-tab"
                        }
                        value={this.props.user.affiliate_link}
                      />
                    </>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )}
          </MDBCol>

          {isCustomizable && (
            <MDBCol md={"6"} sm={"12"}>
              <SpaceComponent
                isDisabled={step === 1 || this.props.user.role < 0 /*HACK RPI*/}
                handleChangeSpace={this.handleChangeSpace}
              />
            </MDBCol>
          )}

          {canUseApiKey(this.props.user) && (
            <div className={"col-12"}>
              <ApiKeyComponent
                dispatch={this.props.dispatch}
                user={this.props.user}
              />
            </div>
          )}
        </MDBRow>

        {this.state.successCopyLink && (
          <MDBNotification
            show
            fade
            message={i18n._("upload_finished_copied_label")}
            autohide={1000}
            titleClassName={"hidden d-none"}
            style={{
              position: "fixed",
              bottom: "10px",
              left: "10px",
              zIndex: 9999,
            }}
          />
        )}
      </MDBContainer>
    );
  };
}

function canUseApiKey({ permissions, role, referer_id }: any) {
  return permissions.team_size.value && role > 1 && !referer_id;
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(AccountComponent);
