import { MDBBtn, MDBIcon } from "mdbreact";
import LoaderComponent from "./Loader/Loader";
import React from "react";

const BTNComponent = ({
  isLoading,
  isDisabled,
  hasChanged,
  text,
  onClick,
  className,
  color,
  isSuccess,
  props,
  background = "transparent",
  outline = true,
}: any) => (
  <MDBBtn
    {...props}
    outline={outline}
    background={background}
    disabled={isDisabled}
    color={
      !hasChanged && !isDisabled && !isLoading && !isSuccess ? "danger" : color
    }
    className={className}
    onClick={onClick}
  >
    {isLoading && <LoaderComponent size={"small"} />}
    {isSuccess && <MDBIcon size={"1x"} icon={"check"} className={"w-100"} />}
    {!hasChanged && !isDisabled && !isLoading && !isSuccess && (
      <MDBIcon size={"1x"} icon={"times"} className={"red-text w-100"} />
    )}
    {!isLoading && !isSuccess && text}
  </MDBBtn>
);

export default BTNComponent;
