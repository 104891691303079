import React from "react";
import "./Footer.css";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBBtn,
  MDBAlert,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";

import { connect } from "react-redux";

import { ConsumptionItemComponent } from "./Components";
import { Link } from "react-router-dom";

import { convertSize, i18n } from "../../utils";
import { appAction } from "../../Store/Actions";
import { isAndroid, isMobile, isTablet } from "react-device-detect";
import { contactApi } from "../../Api";
import { IconScrollComponent } from "../IconScrollComponent";
import { gradients } from "../../Constants";

interface Item {
  iconName: string;
  label: string;
  getUnit: Function;
}

interface CounterProps {
  dispatch: Function;
  transfer: any;
  type?: "upload" | "download" | undefined;
  app?: any;
  isPreview?: boolean;
  forceIOS?: boolean;
}

interface State {
  icons: Item[];
  showPreview?: boolean;
}
const { version } = require("../../../package.json");

const fixeVal = (v: number = 0, l: number = 2, force: boolean = false) => {
  let result = "";
  v = v ? v : 0;
  if (v?.toFixed) {
    result = v.toFixed(l);
  } else {
    console.log("Error, v is not a number: ", v);
  }

  if ("0.00" === result && !force) result = "< 0.01";

  return result;
};

class FooterCounterComponent extends React.Component<CounterProps, State> {
  get isPreview() {
    if (this.props.isPreview) return true;
    return (
      !isMobile &&
      !this.props.transfer.consumption &&
      this.props.type?.toLowerCase() !== "download"
    );
  }
  constructor(props: CounterProps) {
    super(props);
    const { loaded } = this.props.transfer;

    if (this.isPreview) {
      document.body.onwheel = (evt) => {
        if (evt.deltaY > 0 && this.state.showPreview) return;
        if (evt.deltaY < 0 && !this.state.showPreview) return;

        if (evt.deltaY > 0)
          return this.setState({ ...this.state, showPreview: true });
        if (evt.deltaY < 0)
          return this.setState({ ...this.state, showPreview: false });
      };
    }

    this.state = {
      showPreview: false,
      icons: [
        {
          iconName: "fa fa-cloud-upload-alt",
          label: convertSize(loaded).split(" ")[1],
          getUnit: (val: number): string => convertSize(val || 0).split(" ")[0],
        },
        {
          iconName: "fa fa-bolt",
          label: "kWh",
          getUnit: (val: number): string => fixeVal(val, 2, this.isForce0()),
        },
        {
          iconName: "fa fa-seedling",
          label: "gCO2",
          getUnit: (val: number): string => fixeVal(val, 2, this.isForce0()),
        },
        {
          iconName: "fa fa-tint",
          label: "L",
          getUnit: (val: number): string => fixeVal(val, 2, this.isForce0()),
        },
        {
          iconName: "fa fa-car",
          label: "Km",
          getUnit: (val: number): string => fixeVal(val, 2, this.isForce0()),
        },
      ],
    };
  }

  getThemeClass(id: string) {
    if (id === "custom-0" || id === "custom-1") return id;

    const themeClass: any =
      "0" === id[0]
        ? gradients.clair.find((gradient) => gradient.id === id)
        : gradients.sombre.find((gradient) => gradient.id === id);

    return themeClass?.className || "";
  }
  isForce2 = (): boolean =>
    "download" === this.props?.type && !this.props.transfer?.loaded;
  isForce0 = (): boolean =>
    (isMobile && !this.props.transfer?.loaded) ||
    ("download" === this.props?.type && !this.props.transfer?.loaded);

  getClassName = () => {
    const { app } = this.props;

    const className = !app[appAction.SHOW_CUSTOM_HEADER]
      ? "normal"
      : this.getThemeClass(app[appAction.SHOW_CUSTOM_HEADER]);

    return className;
  };

  getClassId = () => {
    const { app } = this.props;

    const classId = !app[appAction.SHOW_CUSTOM_HEADER]
      ? ""
      : app[appAction.SHOW_CUSTOM_HEADER];

    return classId;
  };

  renderItem = (item: Item, k: number) => (
    <ConsumptionItemComponent
      forceIOS={this.props?.forceIOS}
      type={this.props.type}
      key={k}
      iconName={item.iconName}
      value={this.props.transfer.loaded}
      consumption={
        this.props.transfer.consumption || { kWh: 0, kms: 0, gCO2: 0 }
      }
      label={
        k === 0
          ? convertSize(this.props.transfer.loaded).split(" ")[1]
          : item.label
      }
      getUnit={item.getUnit}
      isPreview={this.isPreview}
      isOpen={this.state.showPreview}
    />
  );

  render = () => {
    const themeClass = this.getClassName();
    const mobileClass = !isMobile || isTablet ? "" : "mobile";

    if (this.props.transfer.purging) return null;
    return (
      <MDBFooter
        className={`position-fixed w-100 ${mobileClass} fv_footer_${themeClass}_footer ${
          this.isForce0() ? "dl-fade" : ""
        } ${isMobile && isAndroid && !this.props?.forceIOS && "android"}`}
      >
        <MDBContainer
          fluid
          className={`footer-fade ${
            this.props.transfer.loaded ||
            this.props.type === "download" ||
            (this.isPreview && this.state.showPreview)
              ? "show"
              : "hide"
          } ${
            this.isPreview && "conso-explained fv_conso_explained"
          } fv_footer_${themeClass}_container`}
        >
          <div className={"position-absolute backdrop"}></div>
          {this.isPreview && (
            <MDBRow className={"position-absolute w-100"} style={{ bottom: 0 }}>
              <MDBCol
                className={!this.state.showPreview ? "hide" : "fade"}
                size={"12"}
              >
                <IconScrollComponent />
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow className={"justify-content-around"}>
            {this.state.icons.map((el, i) => (
              <MDBCol size={"2"} className={"text-center"} key={i}>
                {this.renderItem(el, i)}
              </MDBCol>
            ))}
          </MDBRow>

          <span className={`fv-version text-white fv_version`}>v{version}</span>
        </MDBContainer>
      </MDBFooter>
    );
  };
}

interface FooterState {
  isOpen: boolean;
  contact_email: string;
  contact_subject: string;
  contact_message: string;
  success: boolean;
  error: string[];
  isSubmitted: boolean;
  hideIcon?: boolean;
  legales?: boolean;
}

export class FooterComponent extends React.Component<any, FooterState> {
  defaultNavbarStyle: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
      contact_email: "",
      contact_subject: "",
      contact_message: "",
      success: false,
      error: [],
      isSubmitted: false,
    };
  }

  componentDidMount() {
    if (document.location.hash === "#contact") {
      this.openModal();
    }
  }

  handleChange = (evt: any) => {
    let { contact_email, contact_message, contact_subject } = this.state;
    const value = evt.target.value;
    let error: string[] = [];

    const emailField = document.querySelector('input[name="contact_email"]');
    const subjectField = document.querySelector(
      'input[name="contact_subject"]'
    );
    const messageField = document.querySelector(
      'textarea[name="contact_message"]'
    );

    if (
      evt.target.name === "contact_email" &&
      emailField &&
      !contact_email.length &&
      !value.length
    ) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('invalid');
      emailField.classList.add("is-invalid");
      emailField.classList.remove("is-valid");
      error.push("contact_email");
      return this.setState({ ...this.state, error, [evt.target.name]: value });
    } else if (evt.target.name === "contact_email" && emailField) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('');
      emailField.classList.add("is-valid");
      emailField.classList.remove("is-invalid");
    }
    if (
      evt.target.name === "contact_subject" &&
      subjectField &&
      !contact_subject.length &&
      !value.length
    ) {
      // prettier-ignore
      //@ts-ignore
      subjectField.setCustomValidity('invalid');
      subjectField.classList.add("is-invalid");
      subjectField.classList.remove("is-valid");
      error.push("contact_subject");
      return this.setState({ ...this.state, error, [evt.target.name]: value });
    } else if (evt.target.name === "contact_subject" && subjectField) {
      // prettier-ignore
      //@ts-ignore
      subjectField.setCustomValidity('');
      subjectField.classList.add("is-valid");
      subjectField.classList.remove("is-invalid");
    }
    if (
      evt.target.name === "contact_message" &&
      messageField &&
      !contact_message.length &&
      !value.length
    ) {
      // prettier-ignore
      //@ts-ignore
      messageField.setCustomValidity('invalid');
      messageField.classList.add("is-invalid");
      messageField.classList.remove("is-valid");
      error.push("contact_message");
      this.setState({ ...this.state, error, [evt.target.name]: value });
    } else if (evt.target.name === "contact_message" && messageField) {
      // prettier-ignore
      //@ts-ignore
      messageField.setCustomValidity('');
      messageField.classList.add("is-valid");
      messageField.classList.remove("is-invalid");
    }

    this.setState({ ...this.state, error, [evt.target.name]: value });
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openModalFromHeader = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    document.querySelector("#fv-header")?.classList.add("no-hover");
    this.forceNavbar(true);
    this.setState({ isOpen: true });
  };
  closeModalFromHeader = () => {
    document.querySelector("#fv-header")?.classList.remove("no-hover");
    this.forceNavbar(false);
    this.setState({ isOpen: false });
  };

  forceNavbar = (open: boolean) => {
    const navbar: any = document.querySelector("#navbar-normal");
    if (!navbar || !navbar.style) return;

    if (open) {
      this.defaultNavbarStyle = navbar.style;
      navbar.style.display = "block";
      navbar.style.height = "100%";
      navbar.style.width = "100%";
      navbar.style.zIndex = 100000;
    } else {
      navbar.style = this.defaultNavbarStyle;
      navbar.style.display = undefined;
      navbar.style.height = undefined;
      navbar.style.width = undefined;
      navbar.style.zIndex = undefined;
    }
  };

  handleSubmit = async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.className += " was-validated";

    let { contact_email, contact_subject, contact_message, error } = this.state;

    const emailField = document.querySelector('input[name="contact_email"]');
    const subjectField = document.querySelector(
      'input[name="contact_subject"]'
    );
    const messageField = document.querySelector(
      'textarea[name="contact_message"]'
    );

    if (emailField && !contact_email.length) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('invalid');
      emailField.classList.add("is-invalid");
      emailField.classList.remove("is-valid");
      error.push("contact_email");
    } else if (emailField) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('');
      emailField.classList.add("is-valid");
      emailField.classList.remove("is-invalid");
    }
    if (subjectField && !contact_subject.length) {
      // prettier-ignore
      //@ts-ignore
      subjectField.setCustomValidity('invalid');
      subjectField.classList.add("is-invalid");
      subjectField.classList.remove("is-valid");
      error.push("contact_subject");
    } else if (subjectField) {
      // prettier-ignore
      //@ts-ignore
      subjectField.setCustomValidity('');
      subjectField.classList.add("is-valid");
      subjectField.classList.remove("is-invalid");
    }
    if (messageField && !contact_message.length) {
      // prettier-ignore
      //@ts-ignore
      messageField.setCustomValidity('invalid');
      messageField.classList.add("is-invalid");
      messageField.classList.remove("is-valid");
      error.push("contact_message");
    } else if (messageField) {
      // prettier-ignore
      //@ts-ignore
      messageField.setCustomValidity('');
      messageField.classList.add("is-valid");
      messageField.classList.remove("is-invalid");
    }

    if (error && error.length) return false;

    const result = await contactApi.sendContactRequest({
      email: contact_email,
      subject: contact_subject,
      message: contact_message,
    });

    if (result && result.success)
      return this.setState(
        {
          success: true,
          contact_subject: "",
          contact_email: "",
          contact_message: "",
          error: [],
          isSubmitted: true,
        },
        () =>
          setTimeout(() => {
            this.setState({ success: false, isSubmitted: false });
          }, 2000)
      );
  };

  render = () => {
    const {
      isOpen,
      success,
      isSubmitted,
      contact_subject,
      contact_message,
      contact_email,
    } = this.state;

    if (this.props.contactOnly) {
      return (
        <div>
          <MDBModal
            className={"contact-modal text-black"}
            isOpen={isOpen}
            backdropStyles={{ zIndex: 10000 }}
            toggle={this.closeModalFromHeader}
          >
            <MDBModalHeader
              className={"text-dark"}
              toggle={this.closeModalFromHeader}
            >
              {" "}
              {i18n._("modal_contact_title")}{" "}
            </MDBModalHeader>
            <MDBModalBody>
              {isSubmitted && (
                <MDBAlert color={success ? "success" : "danger"}>
                  {i18n._(
                    success ? "form_contact_success" : "form_contact_error"
                  )}
                </MDBAlert>
              )}

              <form onSubmit={this.handleSubmit}>
                <MDBInput
                  value={contact_email}
                  onChange={this.handleChange}
                  outline
                  type={"email"}
                  label={i18n._("form_contact_email_label")}
                  name={"contact_email"}
                >
                  <div className="invalid-feedback">
                    {i18n._("form_contact_email_invalid")}
                  </div>
                  <div className="valid-feedback">
                    {i18n._("form_contact_email_valid")}
                  </div>
                </MDBInput>
                <MDBInput
                  value={contact_subject}
                  onChange={this.handleChange}
                  outline
                  type={"text"}
                  label={i18n._("form_contact_subject_label")}
                  name={"contact_subject"}
                >
                  <div className="invalid-feedback">
                    {i18n._("form_contact_subject_invalid")}
                  </div>
                  <div className="valid-feedback">
                    {i18n._("form_contact_subject_valid")}
                  </div>
                </MDBInput>
                <MDBInput
                  value={contact_message}
                  onChange={this.handleChange}
                  outline
                  type={"textarea"}
                  label={i18n._("form_contact_message_label")}
                  name={"contact_message"}
                  rows={10}
                >
                  <div className="invalid-feedback">
                    {i18n._("form_contact_message_invalid")}
                  </div>
                  <div className="valid-feedback">
                    {i18n._("form_contact_message_valid")}
                  </div>
                </MDBInput>

                <MDBRow className={"form-group"}>
                  <MDBCol size={"12"}>
                    <MDBBtn
                      name={"contact_submit"}
                      type={"submit"}
                      className={"mx-auto text-center"}
                      color={"default"}
                    >
                      {i18n._("btn_valid_label")}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
            </MDBModalBody>
          </MDBModal>

          {this.props?.legales && (
            <>
              <ul className="list-unstyled">
                <li>
                  <Link to={"/legal-terms"}>
                    {i18n._("footer_basics_legales_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/cgu"}>
                    {i18n._("footer_basics_cgu_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy"}>
                    {i18n._("footer_basics_privacy_link_label")}
                  </Link>
                </li>
              </ul>
            </>
          )}

          <MDBNavLink
            id={"footer-contact-btn"}
            to={"#contact"}
            onMouseDown={this.openModalFromHeader}
            onClick={this.openModalFromHeader}
            className={this.props.contactClassName}
          >
            {!this?.props?.hideIcon && (
              <MDBIcon icon={"envelope"} size={"1x"} far />
            )}
            {!!this.props?.children && this.props.children}
            {/* {i18n._("modal_contact_title")} */}
          </MDBNavLink>
        </div>
      );
    }

    return (
      <footer
        className={
          "page-footer font-small teal accent-5 text-white fv_footer_light_footer pt-1"
        }
      >
        <MDBModal className={"contact-modal text-black"} isOpen={isOpen}>
          <MDBModalHeader className={"text-dark"} toggle={this.closeModal}>
            {" "}
            {i18n._("modal_contact_title")}{" "}
          </MDBModalHeader>
          <MDBModalBody>
            {isSubmitted && (
              <MDBAlert color={success ? "success" : "danger"}>
                {i18n._(
                  success ? "form_contact_success" : "form_contact_error"
                )}
              </MDBAlert>
            )}

            <form onSubmit={this.handleSubmit}>
              <MDBInput
                value={contact_email}
                onChange={this.handleChange}
                outline
                type={"email"}
                label={i18n._("form_contact_email_label")}
                name={"contact_email"}
              >
                <div className="invalid-feedback">
                  {i18n._("form_contact_email_invalid")}
                </div>
                <div className="valid-feedback">
                  {i18n._("form_contact_email_valid")}
                </div>
              </MDBInput>
              <MDBInput
                value={contact_subject}
                onChange={this.handleChange}
                outline
                type={"text"}
                label={i18n._("form_contact_subject_label")}
                name={"contact_subject"}
              >
                <div className="invalid-feedback">
                  {i18n._("form_contact_subject_invalid")}
                </div>
                <div className="valid-feedback">
                  {i18n._("form_contact_subject_valid")}
                </div>
              </MDBInput>
              <MDBInput
                value={contact_message}
                onChange={this.handleChange}
                outline
                type={"textarea"}
                label={i18n._("form_contact_message_label")}
                name={"contact_message"}
                rows={10}
              >
                <div className="invalid-feedback">
                  {i18n._("form_contact_message_invalid")}
                </div>
                <div className="valid-feedback">
                  {i18n._("form_contact_message_valid")}
                </div>
              </MDBInput>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBBtn
                    name={"contact_submit"}
                    type={"submit"}
                    className={"mx-auto text-center"}
                    color={"default"}
                  >
                    {i18n._("btn_valid_label")}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBModalBody>
        </MDBModal>

        <div
          className={
            "container text-center text-md-left fv_footer_light_container"
          }
        >
          <div className="row">
            <div className="col-md-3 mx-auto my-2 text-center">
              <h5 className="bold text-uppercase mt-3 mb-4 white-text">
                {i18n._("footer_services_title")}
              </h5>

              <ul className="list-unstyled">
                <li>
                  <Link to={"/"}>
                    {i18n._("footer_services_transfer_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/offers"}>
                    {i18n._("footer_services_offers_link_label")}
                  </Link>
                </li>
                <li>
                  <a
                    rel={"noreferrer"}
                    href={i18n._("footer_services_blog_link_url")}
                    target={"_blank"}
                  >
                    {i18n._("footer_services_blog_link_label")}
                  </a>
                </li>
                <li>
                  <a
                    rel={"noreferrer"}
                    href={i18n._("footer_guide_link_url")}
                    target={"_blank"}
                  >
                    {i18n._("footer_guide_link_label")}
                  </a>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-3 mx-auto my-2 text-center">
              <h5 className="bold text-uppercase mt-3 mb-4 white-text">
                {i18n._("footer_discover_title")}
              </h5>

              <ul className="list-unstyled">
                <li>
                  <Link to={"/impact"}>
                    {i18n._("footer_discover_about_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/impact#project_2"}>
                    {i18n._("footer_discover_engagements_link_label")}
                  </Link>
                </li>

                <li>
                  <Link to={"/impact/pro"}>
                    {i18n._("footer_discover_pro_link_label")}
                  </Link>
                </li>

                <li>
                  <a
                    rel={"noreferrer"}
                    href={i18n._("footer_partners_link_url")}
                    target={"_blank"}
                  >
                    {i18n._("footer_partners_link_label")}
                  </a>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-3 mx-auto my-2 text-center">
              <h5 className="bold text-uppercase mt-3 mb-4 white-text">
                {i18n._("footer_basics_title")}
              </h5>

              <ul className="list-unstyled">
                <li>
                  <Link to={"/legal-terms"}>
                    {i18n._("footer_basics_legales_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/cgu"}>
                    {i18n._("footer_basics_cgu_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"/privacy"}>
                    {i18n._("footer_basics_privacy_link_label")}
                  </Link>
                </li>
                <li>
                  <Link to={"#contact"} onClick={this.openModal}>
                    {i18n._("footer_basics_contact_link_label")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center py-3">
          {i18n._("copyright_date")}
          <Link to={"#"}>{i18n._("copyright_label")}</Link>
        </div>
      </footer>
    );
  };
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});
export default connect(mapStateToProps)(FooterCounterComponent);
