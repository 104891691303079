import React from "react";
import "./LoginButtonV2.css";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBBtn,
  MDBAlert,
  MDBIcon,
  MDBModalHeader,
} from "mdbreact";

import { connect } from "react-redux";

import { userApi } from "../../../../Api";

import { appAction, userAction } from "../../../../Store";

import { Link, NavLink } from "react-router-dom";
import { i18n } from "../../../../utils";
import { isMobile } from "react-device-detect";
import { InputCheckboxComponent } from "../../../Form/Input";

interface Props {
  dispatch: Function;
  user: any;
  mod?: "light" | "normal";
  app: any;
  btnClassName?: string;
  textColorName?: string;
  labelOnly?: boolean;
  iconOnly?: boolean;
  logoutOnly?: boolean;
  defaultOpen?: boolean;
  skip?: boolean;
  onModalChangeCB?: (isOpen: boolean) => void;
}

interface State {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  passwordConfirmation: string;
  cgu: boolean;
  isModalOpen: boolean;
  isRegistering: boolean;
  isWaitingConfirmation: boolean;
  error: any | null;
}

class LoginButtonV2Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isRegistering: false,
      isWaitingConfirmation: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      passwordConfirmation: "",
      cgu: false,
      error: null,
      isModalOpen: props?.defaultOpen || false,
    };
  }

  handleChange = (evt: any) => {
    return this.setState({
      ...this.state,
      [evt.target.name]:
        evt.target.type === "checkbox" ? evt.target.checked : evt.target.value,
    });
  };

  handleReset = () => {
    this.setState({ ...this.state, isModalOpen: false }, () =>
      this.props.dispatch(appAction.resetPassword(true))
    );
  };

  handleSubmit = async (force?: boolean) => {
    try {
      const { email, password } = this.state;
      const { user, error } = await userApi.auth({ email, password }, force);

      if (user && !error) {
        this.props.dispatch(userAction.loginUser(user));

        if (this.props.user && this.props.user.status) {
          this.closeModal();
          return document.location.reload();
        }
      }

      this.setState({ error });
    } catch (e) {}
  };

  handleSubmitRegister = async () => {
    const { email, password, passwordConfirmation, firstName, lastName, cgu } =
      this.state;
    let invalid: any = [];

    if (!email) invalid.push("email");
    if (!password) invalid.push("password");
    if (!passwordConfirmation || passwordConfirmation !== password)
      invalid.push("password_confirmation");
    if (!firstName) invalid.push("first_name");
    if (!lastName) invalid.push("last_name");
    if (!cgu) invalid.push("footer_basics_cgu_link_label");

    if (invalid?.length) {
      return this.setState({
        ...this.state,
        error: invalid?.map(
          (k: string) =>
            /* i18n._(`form_profil_${k}_invalid`) || */ i18n._(
              `form_profil_${k}_label`
            ) || i18n._(`${k}`)
        ),
      });
    }

    const { success, waitingConfirmation, error } =
      await userApi.simpleRegister({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        cgu,
      });

    if (success && !error) {
      return this.setState({ ...this.state, isWaitingConfirmation: true });
    }

    this.setState({ error });
  };

  closeModal = (): void => {
    if (this.props.onModalChangeCB) this.props.onModalChangeCB(false);
    this.setState({ isModalOpen: false, error: null });
    // prettier-ignore
    //@ts-ignore
    try { document.getElementById('fv-header').classList.remove('no-hover'); }
    catch (e) {}
  };

  openModal = (): void => {
    if (this.props.onModalChangeCB) this.props.onModalChangeCB(true);
    // prettier-ignore
    //@ts-ignore
    try { document.getElementById('fv-header').classList.add('no-hover'); }
    catch (e) {}
    this.setState({ isModalOpen: true, error: null });
  };

  renderLoginButon = (outline: boolean) => {
    const color = this.props.mod === "light" ? "dark" : "white";
    const fill = this.props.mod === "light" ? "#111" : "#F8F8F8";
    return !this.props.user || !this.props.user.status ? (
      this.props.iconOnly ? (
        <NavLink
          className={`text-${color}`}
          id={"fv-header-login-btn"}
          to={"#"}
          onClick={this.openModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 49 49"
            fill="none"
          >
            <path
              d="M24.9642 3.9239C17.6668 3.90476 11.2451 7.68025 7.57492 13.3842C7.41223 13.6378 7.59406 13.9727 7.89553 13.9727H11.2595C11.4892 13.9727 11.7045 13.8722 11.8481 13.6952C12.183 13.2885 12.5419 12.8961 12.9199 12.5228C14.4799 10.9677 16.2935 9.74265 18.3128 8.89089C20.3991 8.01042 22.6195 7.56062 24.9115 7.56062C27.2036 7.56062 29.4239 8.00564 31.5103 8.89089C33.5296 9.74265 35.3432 10.9677 36.9031 12.5228C38.4631 14.078 39.6833 15.8916 40.5399 17.9061C41.4251 19.9925 41.8701 22.208 41.8701 24.5001C41.8701 26.7922 41.4203 29.0077 40.5399 31.094C39.6881 33.1086 38.4631 34.9221 36.9031 36.4773C35.3432 38.0325 33.5296 39.2575 31.5103 40.1093C29.4223 40.9912 27.1781 41.4436 24.9115 41.4395C22.6195 41.4395 20.3991 40.9897 18.3128 40.1093C16.2974 39.2584 14.4661 38.025 12.9199 36.4773C12.5419 36.0993 12.1878 35.7069 11.8481 35.305C11.7045 35.1279 11.4844 35.0274 11.2595 35.0274H7.89553C7.59406 35.0274 7.40744 35.3624 7.57492 35.616C11.2404 41.3055 17.6333 45.0762 24.9115 45.0762C36.2141 45.0762 45.392 35.9797 45.5069 24.7106C45.6217 13.2597 36.4294 3.95261 24.9642 3.9239ZM18.9023 29.8594V26.2227H3.87695C3.66641 26.2227 3.49414 26.0505 3.49414 25.8399V23.1602C3.49414 22.9497 3.66641 22.7774 3.87695 22.7774H18.9023V19.1407C18.9023 18.8201 19.2756 18.6383 19.5244 18.8392L26.3146 24.1986C26.3603 24.2344 26.3973 24.2802 26.4228 24.3324C26.4482 24.3846 26.4614 24.442 26.4614 24.5001C26.4614 24.5582 26.4482 24.6155 26.4228 24.6677C26.3973 24.72 26.3603 24.7657 26.3146 24.8015L19.5244 30.1609C19.2756 30.3571 18.9023 30.1801 18.9023 29.8594Z"
              fill={fill}
            />
          </svg>
        </NavLink>
      ) : (
        <NavLink
          className={`text-${color}`}
          id={"fv-header-login-btn"}
          to={"#"}
          onClick={this.openModal}
        >
          {i18n._("login_label")}
        </NavLink>
      )
    ) : this.props?.iconOnly ? (
      <NavLink className={`text-${color}`} to={"/profil"}>
        <svg
          fill={fill}
          width={30}
          height={30}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          id="account"
        >
          <path d="M25 1C11.767 1 1 11.767 1 25c0 7.091 3.094 13.472 8 17.869v.017l.348.3c.061.053.128.097.19.149a24.496 24.496 0 0 0 3.189 2.279c.085.051.172.099.257.148.557.324 1.126.629 1.71.908l.018.008a23.838 23.838 0 0 0 3.915 1.456l.075.021c.641.175 1.293.322 1.954.443l.185.033a24.17 24.17 0 0 0 1.939.262c.075.007.15.011.224.017.659.055 1.323.09 1.996.09s1.337-.035 1.996-.09c.075-.006.15-.01.224-.017.655-.06 1.301-.15 1.939-.262l.185-.033a23.451 23.451 0 0 0 1.954-.443l.075-.021a23.838 23.838 0 0 0 3.915-1.456l.018-.008a24.261 24.261 0 0 0 1.71-.908c.086-.05.172-.097.257-.148a24.123 24.123 0 0 0 1.487-.968c.124-.087.248-.174.371-.264.456-.334.9-.683 1.331-1.047.062-.052.129-.096.19-.149l.348-.3v-.017c4.906-4.398 8-10.778 8-17.869C49 11.767 38.233 1 25 1zm0 24c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3 2c6.065 0 11 4.935 11 11v3.958c-.042.035-.086.067-.128.102-.395.321-.8.626-1.214.918-.092.065-.182.132-.274.195-.447.305-.906.591-1.373.862l-.257.148a21.799 21.799 0 0 1-6.871 2.468l-.171.031a22.27 22.27 0 0 1-1.715.225c-.079.007-.159.012-.239.018-.583.045-1.169.075-1.758.075s-1.175-.03-1.758-.077l-.239-.018a21.789 21.789 0 0 1-1.886-.256 22.013 22.013 0 0 1-5.212-1.626l-.161-.073a21.799 21.799 0 0 1-1.755-.917c-.467-.27-.926-.557-1.373-.862-.093-.063-.183-.13-.274-.195a21.826 21.826 0 0 1-1.214-.918c-.042-.034-.086-.067-.128-.102V38c0-6.065 4.935-11 11-11h6zm13 13.076V38c0-6.271-4.464-11.519-10.38-12.735A9.996 9.996 0 0 0 35 17c0-5.514-4.486-10-10-10s-10 4.486-10 10a9.996 9.996 0 0 0 4.38 8.265C13.464 26.481 9 31.729 9 38v2.076C5.284 36.135 3 30.831 3 25 3 12.869 12.869 3 25 3s22 9.869 22 22c0 5.831-2.284 11.135-6 15.076z"></path>
        </svg>
      </NavLink>
    ) : (
      <NavLink to={"/profil"} className={`text-${color}`}>
        {i18n._("account_label")}
      </NavLink>
    );
  };

  render = () => {
    // prettier-ignore
    const { mod } = this.props;

    const outline = mod !== "light";
    const textClass = mod === "light" ? "text-black" : "text-white";

    return (
      <div className={`${!isMobile && "d-inline-flex"}`}>
        {this.renderLoginButon(outline)}

        <MDBModal
          className={"fv_login_modal"}
          centered
          isOpen={
            this.state.isModalOpen ||
            (this.props?.skip &&
              !this.props.app.RESET_PASSWORD &&
              !this.props.user?.id)
          }
          toggle={this.props?.skip ? () => null : this.closeModal}
        >
          <MDBModalBody>
            <MDBContainer
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className={"header-login"}
            >
              <MDBRow className="nav nav-tabs md-tabs tabs-2 w-100 header-login-row">
                <MDBCol
                  size={"6"}
                  className="nav-item waves-effect waves-light p-0 m-0"
                >
                  <span
                    className="nav-link active text-center"
                    data-toggle="tab"
                  >
                    <MDBIcon icon={"user"} className="mr-1" />
                    {i18n._("login_label")}
                  </span>
                </MDBCol>

                <MDBCol
                  size={!this.props?.skip ? "6" : "12"}
                  className="nav-item waves-effect waves-light text-white p-0 m-0 text-white"
                >
                  {!this.props?.skip && (
                    <Link
                      style={{ float: "right" }}
                      className="nav-link text-white text-center bg-default"
                      to={"/offers"}
                      onClick={this.closeModal}
                    >
                      <MDBIcon icon={"user-plus"} className="mr-1" />
                      {i18n._("register_label")}
                    </Link>
                  )}
                  {this.props?.skip &&
                    (!this.state.isRegistering ? (
                      <Link
                        style={{ float: "right" }}
                        className="nav-link text-white text-center bg-default"
                        to={"#register"}
                        onClick={() =>
                          this.setState({ ...this.state, isRegistering: true })
                        }
                      >
                        <MDBIcon icon={"user-plus"} className="mr-1" />
                        {i18n._("register_label")}
                      </Link>
                    ) : (
                      <Link
                        style={{ float: "right" }}
                        className="nav-link text-white text-center bg-default"
                        to={"#login"}
                        onClick={() =>
                          this.setState({ ...this.state, isRegistering: false })
                        }
                      >
                        <MDBIcon icon={"user"} className="mr-1" />
                        {i18n._("login_label")}
                      </Link>
                    ))}
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className={"mt-5"}>
              {this.state?.error?.code &&
                this.state.error.code === "TOO_MANY_LOGIN" && (
                  <MDBModal isOpen centered>
                    <MDBModalHeader>
                      <p className={"text-center"}>
                        {i18n._("TOO_MANY_LOGIN_HEADER_TEXT")}
                      </p>
                    </MDBModalHeader>
                    <MDBModalBody
                      onClick={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <p className={"py-4 text-center"}>
                        {i18n._("TOO_MANY_LOGIN_BODY_TEXT")}
                      </p>
                    </MDBModalBody>
                    <MDBModalFooter style={{ justifyContent: "center" }}>
                      <MDBRow>
                        <MDBCol
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                          size={"12"}
                        >
                          <a
                            href={"#login"}
                            onClick={() =>
                              this.setState({ ...this.state, error: null })
                            }
                          >
                            {i18n._("TOO_MANY_LOGIN_CANCEL_TEXT")}
                          </a>
                          <MDBBtn
                            onClick={() => {
                              this.handleSubmit(true);
                            }}
                          >
                            {i18n._("TOO_MANY_LOGIN_CONFIRM_TEXT")}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBModalFooter>
                  </MDBModal>
                )}

              {!this.state.isRegistering &&
                this.state.error &&
                this.state.error?.code &&
                this.state.error?.code !== "TOO_MANY_LOGIN" && (
                  <MDBRow className={"w-100 my-3"}>
                    <MDBCol size={"12"}>
                      <MDBAlert
                        color={"danger"}
                        dismiss={true}
                        onClose={() => this.setState({ error: null })}
                      >
                        {i18n._("invalid_credentials")}
                      </MDBAlert>
                    </MDBCol>
                  </MDBRow>
                )}

              {!this.state.error &&
                this.props?.skip &&
                !this.state.isRegistering && (
                  <MDBAlert className="text-center" color="warning">
                    {i18n._("collab_form.login.warning")}
                  </MDBAlert>
                )}

              {this.state.isRegistering && this.state?.error && (
                <MDBAlert className="text-center" color="danger">
                  {this.state.error?.length &&
                    (this.state.error?.join ? (
                      <p
                        color="text-danger"
                        dangerouslySetInnerHTML={{
                          __html: this.state.error?.join("<br />"),
                        }}
                      ></p>
                    ) : (
                      `${this.state.error}`
                    ))}
                </MDBAlert>
              )}

              {/* <LOGIN FORM HERE> */}
              {!this.state.isRegistering && (
                <SimpleLoginForm
                  {...{
                    state: this.state,
                    handleChange: this.handleChange,
                  }}
                />
              )}
              {/* </LOGIN FORM HERE> */}

              {/* <REGISTER FORM HERE> */}
              {this.state.isRegistering && !this.state.isWaitingConfirmation && (
                <SimpleRegisterForm
                  {...{
                    state: this.state,
                    handleChange: this.handleChange,
                  }}
                />
              )}
              {/* </REGISTER FORM HERE> */}

              {this.state.isRegistering && this.state.isWaitingConfirmation && (
                <div className="text-success">
                  {i18n._("invitation_mail_confirmation_footer")}
                  <br />
                  {i18n._("invitation_mail_confirmation_body", [
                    this.state.email,
                  ])}
                  <br />
                </div>
              )}

              {!this.state.isWaitingConfirmation && (
                <MDBRow center className={"w-100"}>
                  <MDBCol size={"12"} className={"text-center"}>
                    <MDBBtn
                      className={"waves-effect waves-light"}
                      color={"default"}
                      onClick={() =>
                        this.state.isRegistering
                          ? this.handleSubmitRegister()
                          : this.handleSubmit()
                      }
                    >
                      {i18n._(
                        this.state.isRegistering
                          ? "register_label"
                          : "login_label"
                      )}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBContainer fluid>
              <MDBRow className={"w-100"} end>
                <MDBCol size={"12"} className={"mx-auto text-center"}>
                  <Link to={"#"} onClick={this.handleReset}>
                    {i18n._("forgotten_password_label")}
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  };
}

function SimpleLoginForm({ state, handleChange }: any) {
  return (
    <>
      {/* <LOGIN FORM HERE> */}
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            name={"email"}
            type={"email"}
            value={state.email}
            onChange={handleChange}
            label={i18n._("form_login_email_label")}
            icon="envelope"
          >
            <div className="invalid-feedback">
              {i18n._("form_login_email_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_login_email_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            error={state.error || ""}
            name={"password"}
            type={"password"}
            value={state.password}
            onChange={handleChange}
            label={i18n._("form_login_password_label")}
            icon="lock"
          >
            <div className="invalid-feedback">
              {i18n._("form_login_password_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_login_password_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      {/* </LOGIN FORM HERE> */}
    </>
  );
}

function SimpleRegisterForm({ state, handleChange }: any) {
  return (
    <>
      {/* <REGISTER FORM HERE> */}
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            name={"email"}
            type={"email"}
            value={state.email}
            onChange={handleChange}
            label={i18n._("form_login_email_label")}
            icon="envelope"
          >
            <div className="invalid-feedback">
              {i18n._("form_login_email_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_login_email_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            error={state.error || ""}
            name={"password"}
            type={"password"}
            value={state.password}
            onChange={handleChange}
            label={i18n._("form_login_password_label")}
            icon="lock"
          >
            <div className="invalid-feedback">
              {i18n._("form_login_password_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_login_password_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            error={state.error || ""}
            name={"passwordConfirmation"}
            type={"password"}
            value={state.passwordConfirmation}
            onChange={handleChange}
            label={i18n._("form_profil_password_confirmation_label")}
            icon="lock"
          >
            <div className="invalid-feedback">
              {i18n._("form_profil_password_confirmation_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_profil_password_confirmation_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            error={state.error || ""}
            name={"firstName"}
            type={"text"}
            value={state.firstName}
            onChange={handleChange}
            label={i18n._("form_profil_first_name_label")}
            icon="user-tie"
          >
            <div className="invalid-feedback">
              {i18n._("form_profil_first_name_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_profil_first_name_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>
      <MDBRow className={"w-100 my-3"}>
        <MDBCol
          size={"12"}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <MDBInput
            outline
            className={"px-2 text-left"}
            validate
            error={state.error || ""}
            name={"lastName"}
            type={"text"}
            value={state.lastName}
            onChange={handleChange}
            label={i18n._("form_profil_last_name_label")}
            icon="user-tie"
          >
            <div className="invalid-feedback">
              {i18n._("form_profil_last_name_invalid")}
            </div>
            <div className="valid-feedback">
              {i18n._("form_profil_last_name_valid")}
            </div>
          </MDBInput>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"} className="text-dark">
          <InputCheckboxComponent
            id={"cgu_input"}
            label={i18n._("accept_cgu_member_label")}
            name={"cgu"}
            onChange={handleChange}
            defaultChecked={false}
            required
          />
        </MDBCol>
      </MDBRow>

      {/* </REGISTER FORM HERE> */}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
  app: state.app,
});

export default connect(mapStateToProps)(LoginButtonV2Component);
