import React, { FormEvent } from "react";
import { Form, FormControl } from "react-bootstrap";
import { InputCheckboxComponent, InputEmailComponent } from "./Input";
import { i18n } from "../../utils";
import { MDBCol, MDBRow } from "mdbreact";

export function DepositFormComponent({ onSubmit, ...rest }: any) {
  const { email } = rest;

  return (
    <Form
      onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        evt.currentTarget.classList.add("was-validated");
        let errors: any = Object.values(
          evt.currentTarget.querySelectorAll("input")
        ).some((e) => !e.checkValidity || !e.checkValidity());
        if (!errors && onSubmit)
          errors = await onSubmit(
            Object.values(evt.currentTarget.querySelectorAll("input")).reduce(
              (acc, cur) =>
                cur && cur.name && cur.value && cur.name !== "confirm_password"
                  ? {
                      ...acc,
                      [cur.name]:
                        cur.type === "checkbox" ? cur.checked : cur.value,
                    }
                  : acc,
              {}
            )
          );

        if (errors && errors.field && !errors.ok) {
          switch (errors.field) {
            case "email":
              email.setCurrentError(errors.reason);
              break;
            default:
              break;
          }
        }
      }}
      id={"deposit_form"}
    >
      <MDBRow>
        <MDBCol style={{ maxHeight: 150, overflow: "hidden" }} size={"12"}>
          <InputEmailComponent inputObject={email} />
          <InputCheckboxComponent
            id={"cgu_input"}
            label={i18n._(
              rest?.isPro
                ? "accept_cgu_deposit_pro_label"
                : "accept_cgu_deposit_free_label"
            )}
            name={"cgu"}
            defaultChecked={false}
            required
          />
        </MDBCol>
        {/*<MDBCol size={"12"} style={{height: 50, overflow: "hidden"}}>*/}
        {/*</MDBCol>*/}
      </MDBRow>
      <FormControl
        className={"btn-default btn Ripple-parent mx-auto text-center"}
        value={"Valider"}
        type={"submit"}
      />
    </Form>
  );
}
