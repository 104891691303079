export function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== "") {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

/*
 *   Usage: A extraire dans les props du component visé:
 *   <MDBNavLink
 *       link
 *       to={'/about/#scope'}
 *       {...hashLinkScrollProps}
 *       className={'link light text-uppercase font-weight-bold'}>
 *       {i18n._('link_discover_label')}
 *    </MDBNavLink>
 *
 * */
export const hashLinkScrollProps = {
  onClick: (evt: any) => {
    if (evt?.currentTarget?.hash) {
      setTimeout(hashLinkScroll, 10);
    }
  },
  onTouchEnd: (evt: any) => {
    if (evt?.currentTarget?.hash) {
      setTimeout(hashLinkScroll, 10);
    }
  },
};
