export const CURRENT_PRICE = "CURRENT_PRICE";
export const CURRENT_OFFER = "CURRENT_OFFER";
export const CURRENT_PERIOD = "CURRENT_PERIOD";

export function setCurrentPrice(value: number) {
  return { type: CURRENT_PRICE, value };
}

export function setCurrentPeriod(value: "month" | "year") {
  return { type: CURRENT_PERIOD, value };
}

export function setCurrentOffer(value: any) {
  return { type: CURRENT_OFFER, value };
}
