import { ReactPlayerProps } from "react-player";
import ReactPlayer from "react-player/lazy";
import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "./Components/Timeline";
import { LoaderComponent } from "../index";

function openFullscreen(elem: any) {
  try {
    elem.setAttribute("controlsList", "nodownload");

    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem?.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  } catch (e) {}
}

function closeFullscreen() {
  // try {
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //     //@ts-ignore
  //   } else if (document?.webkitExitFullscreen) { /* Safari */
  //     //@ts-ignore
  //     document.webkitExitFullscreen();
  //     //@ts-ignore
  //   } else if (document?.msExitFullscreen) { /* IE11 */
  //     //@ts-ignore
  //     document.msExitFullscreen();
  //   }
  // } catch(e) {}
}

export function FVVideoPlayer({
  setZoomedComment,
  zoomedComment,
  children,
  comments,
  onTimeChange,
  onReadyCB,
  type,
  ratio,
  hidden,
  isPlaying,
  setIsPlaying,
  isReplacing,
  setIsReplacing,
  ...props
}: ReactPlayerProps) {
  const [ready, setReady] = useState<boolean>(false);
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false);
  const [child, setChild] = useState(children);
  const playerRef = useRef<ReactPlayer & any>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [playing, setPlaying] = useState<boolean>(isPlaying);
  const [refreshed, setRefreshed] = useState<any>(Date.now());
  const [volume, setVolume] = useState<number>(1);

  useEffect(() => {
    if (isPlaying !== playing) setPlaying(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying !== playing) setIsPlaying(playing);
  }, [playing]);

  useEffect(() => {
    setChild(children);
  }, [children]);

  useEffect(() => {
    setChild(children);
    setRefreshed(null);
    setTimeout(() => setRefreshed(() => Date.now()), 400);
  }, [props?.width]);

  useEffect(() => {
    setMarkers(getMarkers(comments));
  }, [comments]);

  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime]: any = useState<number>();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  // ?
  // const marginLeft = `${(100 - (100 / ratio)) / 2}%`, width = `${100 / ratio}%`;

  const handleFullScreen = (cb?: any) => {
    const elem: any = document.querySelector(
      `#fv-video-player > ${type === "image" ? "img" : type}`
    );
    if (!fullScreen) {
      openFullscreen(elem);
      elem.onfullscreenchange = () => {
        setFullScreen(false);
        elem.onfullscreenchange = null;
        setRefreshed(() => Date.now());
      };
    }
    setFullScreen(() => !fullScreen);
  };

  useEffect(() => {
    if (!fullScreen) {
      setReady(false);
      setTimeout(() => setReady(true), 250);
    }
    setRefreshed(Date.now());
  }, [fullScreen]);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 0,
        display: hidden ? "none" : undefined,
      }}
    >
      {ready && refreshed && child}

      {/* {loadingBuffer && (
        <div
          style={{
            zIndex: 1000,
            position: "absolute",
            left: 0,
            top: 0,
            background: "radial-gradient(rgba(0,0,0, .2), transparent)",
            width: "100%",
            height: "calc(100% - 60px)",
            display: "flex",
          }}
        >
          <div
            style={{
              margin: "auto",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <LoaderComponent size="small" />
          </div>
        </div>
      )} */}

      {/* {!ready && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            console.log("Be patient please ...");
            return false;
          }}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            background: "rgba(0,0,0, .6)",
            width: "100%",
            height: "100%",
            display: "flex",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              margin: "auto",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <LoaderComponent size={"small"} />
          </div>
        </div>
      )} */}

      {type !== "image" && (
        <>
          <ReactPlayer
            {...props}
            onProgress={({ playedSeconds }: any) => {
              if (onTimeChange) onTimeChange(playedSeconds);
              setCurrentTime(playedSeconds);
            }}
            onReady={() => {
              if (onReadyCB) onReadyCB();
              setReady(() => true);
            }}
            onBuffer={() => setLoadingBuffer(true)}
            onBufferEnd={() => setLoadingBuffer(false)}
            onDuration={setDuration}
            playing={playing}
            ref={playerRef}
            volume={volume}
            controls={fullScreen}
            controlsList={"nodownload"}
            onPause={() => playing && setPlaying(false)}
            onPlay={() => !playing && setPlaying(true)}
            onError={(error) => {
              console.log(error, playerRef.current.target);
              setReady(true);
              setTimeout(() => {
                setRefreshed(Date.now());
              }, 250);
            }}
            // onClickPreview={console.log}
            // previewTabIndex={1}
          />
          <div>
            <Timeline
              {...{
                isReplacing,
                setIsReplacing,
                setVolume,
                volume,
                setZoomedComment,
                zoomedComment,
                loadedBuffer: playerRef?.current?.getSecondsLoaded(),
                refreshed,
                playing,
                setPlaying,
                duration,
                currentTime,
                setCurrentTime,
                type,
                fullScreen,
                setFullScreen: handleFullScreen,
              }}
              markers={markers}
              playerRef={playerRef}
            />
          </div>
        </>
      )}

      {type === "image" && (
        <div
          id="fv-video-player"
          className={`d-flex justify-content-center ${props?.className}`}
          data-name={props?.name}
          style={{
            textAlign: "center",
            width: props?.width,
            height: props?.height,
          }}
        >
          <img
            style={{
              display: "flex",
              margin: "auto",
              maxWidth: props?.width,
              maxHeight: props?.height,
            }}
            onLoad={() => {
              setReady(true);
              if (onReadyCB) onReadyCB();
            }}
            src={props?.url as any}
          />
        </div>
      )}
    </div>
  );
}

function getMarkers(comments: any) {
  return comments
    ?.filter(
      ({ status, time }: any) => status !== "close" && typeof time === "number"
    )
    ?.sort((c1: any, c2: any) =>
      typeof c1.time === "number" &&
      typeof c2.time === "number" &&
      c1.time < c2.time
        ? -1
        : 1
    )
    ?.map(({ id, content, username, time }: any) => ({
      id,
      content,
      username,
      time,
    }));
}
