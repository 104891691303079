import { createStore, combineReducers } from "redux";

import {
  userReducer,
  userAction,
  appReducer,
  appAction,
  fileReducer,
  fileAction,
  transferReducer,
  transferAction,
  offerReducer,
  offerAction,
  depositReducer,
  depositAction,
} from "./Reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "fv",
  storage,
  whitelist: [],
};

const depositPersistConfig = {
  key: "fv-deposit",
  storage,
  whitelist: ["deposits"],
};

const transferPersistConfig = {
  key: "fv-transfer",
  storage,
  whitelist: ["transfers"],
};

const userPersistConfig = {
  key: "fv-user",
  storage,
  whitelist: ["user", "token"],
};

const reducers = persistReducer(
  persistConfig,
  combineReducers({
    offer: offerReducer,
    user: persistReducer(userPersistConfig, userReducer),
    app: appReducer,
    file: fileReducer,
    // transfer: transferReducer,
    transfer: persistReducer(transferPersistConfig, transferReducer),
    deposit: persistReducer(depositPersistConfig, depositReducer),
  })
);

const store = createStore(reducers);
const persistor = persistStore(store);

export {
  userAction,
  appAction,
  fileAction,
  transferAction,
  offerAction,
  depositAction,
};

const reduxStore = {
  store,
  persistor,
};

export default reduxStore;
