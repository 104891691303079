export function getAbsolutePosInPercent(e: any) {
  const { offsetHeight, offsetWidth } = e.currentTarget;
  const { left, top } = e.target.getBoundingClientRect();

  const pX = e.clientX - left - 8;
  const pY = e.clientY - top - 8;

  return {
    x: ((offsetWidth - (offsetWidth - pX)) * 100) / offsetWidth,
    y: ((offsetHeight - (offsetHeight - pY)) * 100) / offsetHeight,
  };
}
