import React, { ReactElement } from "react";
import "./Tooltip.css";

import { MDBTooltip } from "mdbreact";

const TooltipComponent = ({
  text,
  placement,
  className,
}: {
  text: string;
  placement?: "top" | "left" | "bottom" | "right" | undefined;
  className?: string;
}): ReactElement | null => {
  if (!className) className = text.length ? "pl-2" : "empty pl-2";

  if (!placement) placement = "top";

  return (
    <MDBTooltip domElement placement={placement}>
      <i className={`fas fa-info-circle 1x ${className}`} />
      <span>{text}</span>
    </MDBTooltip>
  );
};

export default TooltipComponent;
