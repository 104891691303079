import React from "react";
import { offerLabelsAndColors } from "../../Constants";
import {
  getFinalPrice,
  getPredefinedPermissions,
  i18n,
  isOfferDifferent,
} from "../../utils";

import "./CustomOfferStyle.css";

export function CustomOfferCardHeaderComponent({
  setCurrentOffer,
  currentOffer,
  price,
  currentPeriod,
}: any) {
  const isFree = !price;
  let type = 1;

  if (
    !isOfferDifferent([
      getPredefinedPermissions(offerLabelsAndColors[1].label),
      currentOffer,
    ])
  )
    type = 1;
  if (
    !isOfferDifferent([
      getPredefinedPermissions(offerLabelsAndColors[2].label),
      currentOffer,
    ])
  )
    type = 2;
  if (
    !isOfferDifferent([
      getPredefinedPermissions(offerLabelsAndColors[3].label),
      currentOffer,
    ])
  )
    type = 3;

  return (
    <>
      <div className={"d-flex justify-content-around py-3"}>
        {offerLabelsAndColors.map(
          ({ label, bg_color, color, slogan }, index) => (
            <div key={`offerLabelsAndColors_${index}`}>
              <h3
                style={{
                  backgroundColor:
                    type === index ? i18n._(bg_color) : undefined,
                  color: i18n._(color),
                }}
                onClick={() => setCurrentOffer(getPredefinedPermissions(label))}
                className={`hover-pointer text-uppercase px-3 py-1 mx-2 offer-header-item fv_header_item`}
              >
                {i18n._(
                  (!index && type !== index) ||
                    (type === index && !price && !type)
                    ? "offer_free_label"
                    : label
                )}
              </h3>
            </div>
          )
        )}
      </div>

      <div className={"d-flex flex-column justify-content-center"}>
        <h6
          className={"text-center pricing card-price mb-3"}
          style={{
            fontSize: "3rem",
            margin: 0,
            fontWeight: 600,
            color: "#666",
            letterSpacing: "-.03em",
          }}
        >
          {price}€
          {!isFree && (
            <small className={"mini"}>
              {i18n._(`offer_monthlyPricePeriod_label`)}
            </small>
          )}
          <br />
          <small
            className={`mini ${
              (isFree || currentPeriod === "month") && "d-none"
            }`}
          >
            {/*{i18n._('offer_price_period_info', [currentPeriod, price])}*/}
            {i18n._(`offer_per_${currentPeriod}_label`)}
            &nbsp;
            {getFinalPrice(currentPeriod, price)} € (HT)
            {/*{currentPeriod}*/}
            {/*({i18n._('pricing_ht_prefix')} HT {i18n._('offer_monthlyPricePeriod_label')})*/}
          </small>
        </h6>
        <p className={"text-center"}>
          {i18n._(
            isFree ? "offer_free_slogan" : offerLabelsAndColors[type].slogan
          )}
        </p>
      </div>
    </>
  );
}
