import { transferAction } from "../Actions";
import { AnyAction } from "redux";

const initialState: any = {
  transfers: {},
};

export default function transferReducer(state: any, action: AnyAction) {
  let nextState;

  switch (action.type) {
    case transferAction.SET_INFOS:
      nextState = {
        transfers: {
          ...state.transfers,
          ...action.value,
        },
      };
      break;

    case transferAction.LOCK_TRANSFER:
      nextState = {
        transfers: {
          ...state.transfers,
          ...action.value,
          isLocked: action.value,
        },
      };
      break;
    case transferAction.PURGE:
      nextState = initialState;
      break;

    default:
      nextState = state;
      break;
  }

  return nextState || initialState;
}

export { transferAction };
