import { i18n, translateHeaders } from "../../utils";
import React, { useEffect, useState } from "react";
import { TableListComponent, MDBSwitch } from "./TableListComponent";
import { membersTableHeadersCells } from "../../Constants";
import { MDBIcon } from "mdbreact";
import { userApi } from "../../Api";

const getMembersPl = (members: any[], user_id: number, updateCB: any) =>
  members
    .filter(({ deleted_at }) => !deleted_at)
    .map(({ id, member_name, email, status }) => ({
      members_cell_name: member_name,
      members_cell_email: email,
      members_cell_status: i18n._(
        status === 1
          ? "member_status_active"
          : status
          ? "member_status_deleted"
          : "member_status_inactive"
      ),
      members_cell_action: (
        <div style={{ justifyContent: "center" }}>
          <MDBSwitch
            disabled={status < 1}
            checked={status === 1}
            onChange={() => userApi.deleteMembers(user_id, [id])}
          />
        </div>
      ),
      members_cell_delete: (
        <div style={{ justifyContent: "center" }}>
          <MDBIcon
            className={"hover-pointer text-danger text-center"}
            size={"1x"}
            icon={"trash"}
            disabled={status > 1}
            color={"danger"}
            role={"link"}
            onClick={async () => {
              const updatedMembers = await userApi.deleteMembers(
                user_id,
                [id],
                true
              );
              if (updateCB && updatedMembers) {
                try {
                  updateCB(updatedMembers);
                } catch (e) {}
              }
            }}
          />
        </div>
      ),
      // members_cell_action: <MDBIcon
      //   icon={'trash'}
      //   className={(status === 1) ? 'hover-pointer' : 'disabled'}
      //   disabled={status !== 1}
      //   onClick={status === 1 ? console.log : undefined}
      // />,
    }));

export function TableMembersComponent({
  members,
  user_id,
}: {
  members: any;
  user_id: number;
}) {
  const [membersList, setMembersList] = useState(members);

  useEffect(() => {
    setMembersList(members);
  }, [members]);

  return (
    <>
      <TableListComponent
        headers={translateHeaders(membersTableHeadersCells)}
        elements={getMembersPl(membersList, user_id, setMembersList)}
      />
    </>
  );
}
