import BandFeature1 from "./bandfeature_1.svg";
import BandFeature2 from "./bandfeature_2.svg";
import BandFeature3 from "./bandfeature_3.svg";
import BandFeature4 from "./bandfeature_4.svg";
//import BandFeature5 from './bandfeature_5.svg';
import BandFeature6 from "./bandfeature_6.svg";
import BandFeature7 from "./bandfeature_7.svg";
import BandFeature8 from "./bandfeature_8.svg";
import BandFeature9 from "./bandfeature_9.svg";
import BandFeature10 from "./bandfeature_10.svg";
import BandFeature11 from "./bandfeature_11.svg";
import BandFeature12 from "./bandfeature_12.svg";

const businessImages = {
  BandFeature1,
  BandFeature2,
  BandFeature3,
  BandFeature4,
  //BandFeature5,
  BandFeature6,
  BandFeature7,
  BandFeature8,
  BandFeature9,
  BandFeature10,
  BandFeature11,
  BandFeature12,
};

export default businessImages;
