import { defaultPermissions } from "../Constants";

export function isOfferDifferent(offers: any[]) {
  offers = offers.filter((offer) => offer);
  if (offers?.length < 2) return true;

  return offers.some((offer, k) =>
    Object.keys(defaultPermissions).some(
      (perm, i) =>
        (!!k && offer[perm]?.value !== offers[0][perm]?.value) ||
        (!k &&
          offers[1] &&
          offers[1][perm] &&
          offer[perm]?.value !== offers[1][perm]?.value)
    )
  );
}

export const isDefaultOffer = (offer: any) =>
  !Object.keys(offer).some(
    (offerKey: string) =>
      defaultPermissions[offerKey]?.value !== offer[offerKey]?.value
  );
