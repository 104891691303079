import React, { useState } from "react";
import { i18n } from "../../../../../utils";
import {
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact";
import { userApi } from "../../../../../Api";
import * as userAction from "../../../../../Store/Actions/userAction";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
//@ts-ignore
import SyntaxHighlighter from "react-syntax-highlighter";
//@ts-ignore
import { solarizedLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { isMobile } from "../../../../../utils";

const ITransferToDisplay = `
  interface ITransfer {
    transfer_id: string;
    transfer_name: string;
    transfer_expiration: Date | null;
    bucket_id: string;
    transfer_size: number;
    transfer_status: number;
    uploader_ip: string|null;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date | null;
    transfer_type: number;
    transfer_recipients: string[];
    transfer_sender: string;
    transfer_password: string|null;
    transfer_message: string|null;
    transfer_mail_auth: boolean;
    for_team: boolean;
    auto_remove: boolean;
    transfer_provider: string;
    consumption: {
      duration: number;
      gCO2: number;
      kWh: number;
      kms: number;
      size: number;
    };
  }
`;

const availableApis = [
  {
    method: "GET",
    path: "/api/v2/user/transfers",
    url: `${document.location.origin}/api/v2/user/transfers?key=<YOUR_API_KEY>`,
    description: "api_key_gettransfers_description",
    response: `
      ${ITransferToDisplay}
    `,
  },
];

export function ApiKeyComponent({ user, dispatch }: any) {
  const [apiKeyValue, setApiKeyValue]: any = useState(
    user?.api_key?.api_key || ""
  );
  const [updating, setUpdating]: any = useState(false);
  const [open, setOpen] = useState(false);

  const updateApiKey = async (action: "create" | "regenerate" | "revoke") => {
    try {
      setUpdating(true);
      const response = await userApi.updateUserApiKey(user, action);
      setTimeout(() => {
        dispatch(userAction.loginUser(response));
        setApiKeyValue(response?.user?.api_key?.api_key || "");
        setUpdating(false);
      }, 0);
    } catch (e) {
      setUpdating(false);
    }
  };

  return (
    <>
      <div className={"row my-3"}>
        <div className={"col-12 fv-profil-wrapper"}>
          <>
            <h5
              className={
                "text-left my-5 font-weight-bold  profil-infos-panel fv_profil_infos_panel"
              }
            >
              {i18n._("api_key_title")}
            </h5>

            <div className={"row"}>
              <div
                className={`col-${
                  isMobile ? 12 : 10
                } fv-apikey-input-wrapper fv_apikey_input_wrapper ${
                  isMobile && "mobile"
                }`}
              >
                <MDBInput
                  id={"apikey-to-copy"}
                  className={`${!isMobile && "pr-5"} hover-pointer`}
                  outline
                  value={apiKeyValue}
                  placeholder={i18n._("api_key_placeholder")}
                >
                  {!isMobile && (
                    <div
                      className={"fv-apikey-btn-wrapper fv_apikey_btn_wrapper"}
                    >
                      {apiKeyValue ? (
                        <>
                          <button
                            disabled={updating}
                            onClick={async () => updateApiKey("regenerate")}
                            className={"fv-apikey-btn fv_apikey_btn"}
                          >
                            {i18n._("api_key_regenerate")}
                          </button>
                          <button
                            disabled={updating}
                            onClick={async () => updateApiKey("revoke")}
                            className={"fv-apikey-btn fv_apikey_btn"}
                          >
                            {i18n._("api_key_revoke")}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            disabled={updating}
                            onClick={async () => updateApiKey("create")}
                            className={"fv-apikey-btn fv_apikey_btn"}
                          >
                            {i18n._("api_key_add")}
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </MDBInput>

                {isMobile && (
                  <div
                    className={`fv-apikey-btn-wrapper${
                      isMobile ? " mobile" : ""
                    } fv_apikey_btn_wrapper${isMobile ? " mobile" : ""}`}
                  >
                    {apiKeyValue ? (
                      <>
                        <button
                          disabled={updating}
                          onClick={async () => updateApiKey("regenerate")}
                          className={"fv-apikey-btn fv_apikey_btn"}
                        >
                          {i18n._("api_key_regenerate")}
                        </button>
                        <button
                          disabled={updating}
                          onClick={async () => updateApiKey("revoke")}
                          className={"fv-apikey-btn fv_apikey_btn"}
                        >
                          {i18n._("api_key_revoke")}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          disabled={updating}
                          onClick={async () => updateApiKey("create")}
                          className={"fv-apikey-btn fv_apikey_btn"}
                        >
                          {i18n._("api_key_add")}
                        </button>
                      </>
                    )}
                  </div>
                )}
                <span className={"text-danger font-italic"}>
                  <small>{i18n._("api_key_expiration_warning")}</small>
                </span>
              </div>
              <div
                className={`col-${
                  isMobile ? "12 text-center my-3" : 2
                } fv-apikey-link-wrapper fv_apikey_link_wrapper`}
              >
                <a
                  href={i18n._("api_key_link_url")}
                  onClick={() => setOpen(!open)}
                >
                  {i18n._("api_key_link_label")}
                </a>
              </div>
            </div>
          </>
        </div>
      </div>

      <MDBModal
        className={`fv-api-modal${isMobile ? " mobile" : ""}`}
        isOpen={open}
        toggle={() => setOpen(false)}
      >
        <MDBModalHeader toggle={() => setOpen(false)}>
          {i18n._("api_key_modal_title")}
        </MDBModalHeader>
        <MDBModalBody>
          {availableApis.map((apiItem, k) => (
            <ExpansionPanel
              key={`api_${k}`}
              id={`api_${k + 1}`}
              className={"my-0"}
            >
              <ExpansionPanelSummary
                expandIcon={<MDBIcon icon={"angle-down"} size={"1x"} />}
              >
                <Typography>{`${apiItem.method} - ${apiItem.path}?key=<YOUR_API_KEY>`}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={"container container-fluid"}>
                  <div className={"row mt-2"}>
                    <div className={"col-2"}>
                      {i18n._("api_key_method_label")}
                    </div>
                    <div className={"col-10"}>{apiItem.method}</div>
                  </div>
                  <hr />
                  <div className={"row mt-2"}>
                    <div className={"col-2"}>{i18n._("api_key_url_label")}</div>
                    <div className={"col-10"}>
                      {apiItem.url.replace(
                        "<YOUR_API_KEY>",
                        apiKeyValue || "<YOUR_API_KEY>"
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className={"row mt-2"}>
                    <div className={"col-2"}>
                      {i18n._("api_key_description_label")}
                    </div>
                    <div className={"col-10"}>
                      {i18n._(apiItem.description)}
                    </div>
                  </div>
                  <hr />
                  <div className={"row"}>
                    <div className={"col-12"}>
                      <SyntaxHighlighter
                        language="typescript"
                        style={solarizedLight}
                      >
                        {ITransferToDisplay}
                      </SyntaxHighlighter>
                    </div>
                  </div>
                </div>
                {/*<div className={"container container-fluid"}>*/}
                {/*  <div className={"row mt-2"}>*/}
                {/*    <div className={"col-2"}>*/}
                {/*      Méthode:*/}
                {/*    </div>*/}
                {/*    <div className={"col-10"}>*/}
                {/*      {apiItem.method}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <hr />*/}
                {/*  <div className={"row mt-2"}>*/}
                {/*    <div className={"col-2"}>*/}
                {/*      URL:*/}
                {/*    </div>*/}
                {/*    <div className={"col-10"}>*/}
                {/*      {apiItem.url.replace("<YOUR_API_KEY>", apiKeyValue || "<YOUR_API_KEY>")}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <hr />*/}
                {/*  <div className={"row mt-2"}>*/}
                {/*    <div className={"col-2"}>*/}
                {/*      Description:*/}
                {/*    </div>*/}
                {/*    <div className={"col-10"}>*/}
                {/*      {apiItem.description}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <hr />*/}

                {/*  <div className={"row mt-2"}>*/}
                {/*    <div className={"col-2"}>*/}
                {/*      Réponse:*/}
                {/*    </div>*/}
                {/*    <div className={"col-10"}>*/}
                {/*      {JSON.stringify(apiItem.response)}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </MDBModalBody>
        <MDBModalFooter>FOOTER</MDBModalFooter>
      </MDBModal>
    </>
  );
}
