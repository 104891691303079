import React, { useEffect, useState } from "react";
import { offerApi } from "../../Api";
import { convertGCO2toWaterL, i18n } from "../../utils";
import LoaderComponent from "../Loader/Loader";

export function CustomOfferCO2SavedComponent() {
  const [savedCO2, setSavedCO2]: [any, any] = useState(null);
  const [CO2Unit, setCO2Unit]: [any, any] = useState("");
  const [ready, setReady]: any = useState(false);
  const [fullConsumption, setFullConsumption]: any = useState(null);

  const setConvertedCO2 = (consumption: any) => {
    let KgCO2 = consumption.activity_saved_by_year_label / 1000;
    const isTon = !(KgCO2 < 1000);
    KgCO2 = !isTon ? KgCO2 : Math.round((KgCO2 / 1000) * 10) / 10;
    setFullConsumption(consumption);
    setCO2Unit(isTon ? "t" : "Kg");
    setSavedCO2(KgCO2.toFixed(2));
  };

  useEffect(() => {
    offerApi.getTotalConsumption().then(setConvertedCO2);
  }, []);

  if (
    !ready &&
    fullConsumption !== null &&
    CO2Unit !== "" &&
    savedCO2 !== null
  ) {
    setTimeout(() => setReady(true), 1250);
  }

  if (!ready)
    return (
      <div className={"numbre text-white fv_custom_offer_saved_gco2"}>
        <LoaderComponent color={"light"} size={"small"} type={"Dot"} />
      </div>
    );

  return (
    <>
      <div>
        <div className={"numbre text-white fv_custom_offer_saved_gco2"}>
          <small
            className={`t1 ${savedCO2 ? "" : "d-flex justify-content-center"}`}
          >
            {(savedCO2 || savedCO2 === 0) && `${savedCO2} `}

            {CO2Unit && (
              <span className="kg pl-1">
                {CO2Unit}
                {i18n._("custom_offer_co2_saved_unit")}
              </span>
            )}
          </small>
          <br />
          <span className="t2">{i18n._("custom_offer_co2_saved_label")}</span>
          <br />
          <span
            className="t2"
            dangerouslySetInnerHTML={{
              __html: i18n._(
                "custom_offer_co2_saved_equivalence",
                convertGCO2toWaterL(
                  fullConsumption?.activity_saved_by_year_label || 0,
                  0
                )
              ),
            }}
          />
          <br />
          <hr />
        </div>
      </div>
    </>
  );
}
