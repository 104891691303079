import React, { useState } from "react";
import {
  ActionInputComponent,
  LoaderComponent,
  QRCodeComponent,
} from "../../../index";
import { transferApi } from "../../../../Api";
import { i18n, isMobile } from "../../../../utils";

export function DepositCreateComponent({ visible }: { visible?: boolean }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [deposit, setDeposit]: any = useState();
  const [loading, setLoading] = useState(false);

  const createDeposit = () => {
    setModalOpen(true);
    setLoading(true);
    transferApi
      .createNewDeposit()
      .then((deposit) => {
        if (!isModalOpen) setModalOpen(true);
        setDeposit(deposit);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  if (!visible) return null;

  return (
    <>
      <button
        className={
          "mx-auto d-block btn btn-default text-white fv-create-deposit-link fv_create_deposit_link"
        }
        disabled={isModalOpen}
        style={{ zIndex: !isModalOpen ? 99999 : -1, position: "relative" }}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          createDeposit();
          return false;
        }}
      >
        {i18n._("fv_btn_create_deposit_cta")}
      </button>

      <div
        className={`fv-create-deposit-backdrop fv_create_deposit_backdrop ${
          !isModalOpen && "d-none"
        }`}
      />

      <div
        style={{ maxHeight: isMobile ? 300 : undefined }}
        className={`card text-center fv-wrapper-create-deposit ${
          !isModalOpen && "d-none"
        }`}
      >
        <div
          className={
            "text-center text-white modal-header card-header fv-card-create-deposit-header"
          }
        >
          {i18n._("fv_creation_deposit_card_header")}

          <button
            type="button"
            onClick={() => {
              setModalOpen(false);
              setDeposit();
            }}
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className={"card-body fv-card-create-deposit-body"}>
          {loading ? (
            <>
              {i18n._("fv_creation_deposit_card_body_loading")}
              <LoaderComponent size={"small"} type={"Dot"} />
            </>
          ) : (
            <div className={`row ${isMobile && "h-100"}`}>
              <div
                className={
                  "col-md-12 col-lg-8 my-auto fv-deposit-copylink-wrapper"
                }
              >
                <div>
                  <div className={"text-left"}>
                    <span>{i18n._("fv_creation_deposit_card_link_label")}</span>
                  </div>
                  <ActionInputComponent
                    customClass={"create-deposit-form"}
                    value={deposit?.deposit_link}
                    onCopy={console.log}
                    navigateTo={{
                      enabled: true,
                    }}
                    mailTo={{
                      enabled: true,
                      subject: i18n._(
                        "fv_creation_deposit_card_mailto_subject"
                      ),
                      body: i18n._("fv_creation_deposit_card_mailto_body", [
                        encodeURIComponent(deposit?.deposit_link),
                      ]),
                    }}
                  />

                  <a
                    rel={"noreferrer"}
                    target={"_blank"}
                    href={i18n._("fv_deposit_know_link_url")}
                    className={
                      "more-link-hub fv-know-more-deposit fv_know_more_deposit"
                    }
                  >
                    {i18n._("fv_deposit_know_link_label")}
                  </a>
                </div>
              </div>
              <div
                className={
                  "col-md-12 col-lg-4 my-auto fv-deposit-qr-code-wrapper fv_deposit_qr_code_wrapper"
                }
              >
                {deposit?.deposit_link && (
                  <QRCodeComponent
                    imgClass={"fv-deposit"}
                    link={deposit?.deposit_link}
                    size={100}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
