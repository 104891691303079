import React from "react";
import { i18n, matomo } from "../utils";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
const ReactTextRotator = require("react-text-rotator").default;
const { _paq } = matomo;

class AnnonceComponent extends React.Component<any, any> {
  // tracker: any;

  mounted: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      mounted: false,
      annonce: null,
      rssFeed: [],
      currentIndex: 0,
      isLoading: this.props.isUploading,
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    this.init();
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  init() {
    i18n.initRSS().then(() => {
      const rssFeed = JSON.parse(localStorage.getItem("rss") || "[]");

      if (this.mounted)
        this.setState(
          {
            ...this.state,
            mounted: true,
            annonce:
              rssFeed.value?.annonces[
                this.getRandomIndex(rssFeed.value.annonces.length)
              ].node,
            rssFeed: JSON.parse(localStorage.getItem("rss") || ""),
            currentIndex: this.getRandomIndex(rssFeed.length),
            isLoading: false,
          },
          () => {
            matomo.trackAnnonce("annonce-to-track")("link-to-track");
          }
        );
    });
  }

  getRandomIndex = (nb: number) => Math.floor(Math.random() * nb);

  handleClick = (url: string) => {
    _paq.push(["trackLink", url, "link"]);
    window.open(url || "/offers", "_blank");
  };

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const { isUploading, btnClassName } = this.props;
    const { annonce, isLoading } = this.state;

    const content: any = i18n._("messages") || [];

    if (isLoading || !content || !content.map) return null;

    content.forEach((message: any) => {
      message.animation = "fade";
      message.className = `message-text-rotator text-white ${message.className}`;
    });

    if (!isUploading && content.length > 0) {
      return (
        // @ts-ignore
        <ReactTextRotator
          transitionTime={250}
          content={content}
          time={10000}
          startDelay={0}
        />
      );
    }

    if (!isUploading && !content.length) return null;

    if (!annonce) return null;

    if (annonce?.body)
      annonce.body = annonce?.body?.split("<script").join("<noscript");

    return (
      <div
        id={"annonce-to-track"}
        data-content-target={annonce?.url}
        data-content-piece={`${annonce?.id} - ${annonce?.publisher}: "${annonce?.title}"`}
        data-content-name={`Annonce ${this.props.typeAnnonce || "Uploader"}`}
        className={"fv_default_message_wrapper_wrapper"}
      >
        {this.props.typeAnnonce === "Download" && (
          <h2 className={"text-white fv_default_message_title"}>
            {i18n._("DOWNLOAD_SCREEN_TITLE")}
          </h2>
        )}
        <h3
          className={`text-white fv_default_message_subtitle ${
            this.props.typeAnnonce === "Download" &&
            "fv_default_message_subtitle_download"
          }`}
        >
          {annonce.title}
        </h3>
        <p
          className={"text-white fv_default_message_body"}
          dangerouslySetInnerHTML={{ __html: annonce.body }}
        />
        <p className={"text-white small fv_default_message_phrase"}>
          {annonce.Phrase}
        </p>
        <MDBBtn
          id={"link-to-track"}
          data-content-target={annonce?.url}
          data-content-piece={`${annonce?.id} - ${annonce?.publisher}: "${annonce?.title}"`}
          data-content-name={`Annonce ${this.props.typeAnnonce || "Uploader"}`}
          data-track-content
          onClick={() => this.handleClick(annonce?.url)}
          className={`btn ${
            btnClassName || "btn-outline-default"
          } waves-effect waves-light my-4 ml-0 text-center text-white fv_default_message_link`}
        >
          {i18n._(
            annonce?.publisher !== "FileVert" && annonce?.url
              ? "know_more_label"
              : "know_more_v2_label"
          )}
        </MDBBtn>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(AnnonceComponent);
