import "./Information.css";
import React from "react";
import { connect } from "react-redux";

import {
  MDBAlert,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";

import { i18n } from "../../../../../../utils";
import { appAction } from "../../../../../../Store";
import { userApi } from "../../../../../../Api";

interface User {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  organization: string;
  job?: string;
  tva_number?: string;
  config: any;
}

interface Props {
  dispatch: Function;
  handleChangeInfo: Function;
  user: any;
  isDisabled: boolean;
  error?: any;
}

interface State {
  user: User & any;
  hasChanged: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isEmailLocked: boolean;
  isSuccess: boolean;
  isModalOpen: boolean;
  isEmailAlertOpen: boolean;
  emailError?: boolean;
}

class InformationComponent extends React.Component<Props, State> {
  passwordForEmail: string;
  emailInputRef: any;

  constructor(props: Props) {
    super(props);
    this.passwordForEmail = "";
    const {
      first_name,
      last_name,
      email,
      organization,
      job,
      tva_number,
      config,
    } = this.props.user;

    this.state = {
      user: {
        first_name: first_name || "",
        last_name: last_name || "",
        email: email || "",
        organization: organization || "",
        job: job || "",
        tva_number: tva_number || "",
        password: "********",
        config: config,
      },
      hasChanged: false,
      isLoading: false,
      isDisabled: true,
      isEmailLocked: true,
      isSuccess: false,
      isModalOpen: false,
      isEmailAlertOpen: false,
    };
  }

  handleChange = async (evt: any) => {
    const email = evt.target.value;

    this.setState(
      {
        ...this.state,
        hasChanged: true,
        isDisabled: false,
        isSuccess: false,
        user: { ...this.state.user, [evt.target.name]: email },
      },
      this.handleSubmit
    );
  };

  handleSubmit = async () => {
    const protectedKey = ["password"];
    let updatedInfos: any = {};

    Object.keys(this.state.user).forEach((key: string, i: number) => {
      if (
        ~protectedKey.indexOf(key) ||
        (key === "logo" &&
          JSON.stringify(this.state.user[key]) === this.props.user[key])
      )
        return;
      if (
        !this.props.user[key] ||
        this.props.user[key] !== this.state.user[key]
      )
        updatedInfos[key] = this.state.user[key];
    });

    if (this.state.user.email && !this.state.isEmailLocked) {
      const isEmailAvailable = await userApi.checkEmail(this.state.user.email);

      if (
        !isEmailAvailable ||
        isEmailAvailable !== true ||
        (isEmailAvailable?.status && isEmailAvailable?.status !== 200)
      )
        return this.setState({
          ...this.state,
          isLoading: false,
          emailError: true,
          user: { ...this.state.user, isError: true },
        });
      else
        this.setState({
          ...this.state,
          isLoading: false,
          emailError: false,
          user: { ...this.state.user, isError: false },
        });
    }
    this.props.handleChangeInfo(updatedInfos);
  };

  changePassword = () => this.props.dispatch(appAction.savePassword(true));

  openModal = () => this.setState({ ...this.state, isModalOpen: true });
  closeModal = () =>
    this.setState({
      ...this.state,
      isModalOpen: false,
      isEmailAlertOpen: false,
      isEmailLocked: true,
    });

  unlockEmailField = async () => {
    const { user, error } = await userApi.auth({
      email: this.props.user.email,
      password: this.passwordForEmail,
    });

    if (!error && user && user.token) {
      this.passwordForEmail = "";
      return this.setState(
        {
          ...this.state,
          isEmailLocked: false,
          isEmailAlertOpen: false,
          isModalOpen: false,
        },
        () => {
          this.emailInputRef &&
            this.emailInputRef.setFocus &&
            this.emailInputRef.setFocus();
        }
      );
    }

    return this.setState({ ...this.state, isEmailAlertOpen: true });
  };

  /*
    componentWillUnmount(): void {
      if (this.state.hasChanged || !this.props.isDisabled)
        window.confirm(i18n._('profil_exit_wo_save'))
    }
  */

  render() {
    const { isDisabled } = this.props;
    const { isEmailLocked, isModalOpen, isEmailAlertOpen } = this.state;

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol size={"12"}>
            <h5
              className={
                "text-left my-5 font-weight-bold profil-infos-panel fv_profil_infos_panel"
              }
            >
              {i18n._("account_information_title")}
            </h5>

            <MDBModal isOpen={isModalOpen} toggle={this.closeModal}>
              <MDBModalHeader>
                {i18n._("form_profil_password_label")}
              </MDBModalHeader>
              <MDBModalBody>
                {isEmailAlertOpen && (
                  <MDBAlert color={"danger"} dismiss>
                    {i18n._("invalid_credentials")}
                  </MDBAlert>
                )}
                <MDBInput
                  outline
                  label={i18n._("form_profil_password_label")}
                  type={"password"}
                  onChange={(evt: any) =>
                    (this.passwordForEmail = evt.target.value)
                  }
                >
                  <div className="invalid-feedback">
                    {i18n._("form_profil_email_invalid")}
                  </div>
                  <div className="valid-feedback">
                    {i18n._("form_profil_email_valid")}
                  </div>
                </MDBInput>
              </MDBModalBody>
              <MDBModalFooter className={"text-center"}>
                <MDBBtn className={"mx-auto"} onClick={this.unlockEmailField}>
                  {i18n._("btn_valid_label")}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>

            <MDBContainer className={"pl-0"}>
              <MDBRow className={"form-group"}>
                <MDBCol size={"6"}>
                  <MDBInput
                    maxLength={45}
                    disabled={isDisabled}
                    outline
                    className={"w-100 float-left"}
                    label={i18n._("form_profil_first_name_label")}
                    onChange={this.handleChange}
                    type={"text"}
                    autoComplete={"given-name"}
                    name={"first_name"}
                    value={this.state.user.first_name}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_first_name_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_first_name_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>

                <MDBCol size={"6"}>
                  <MDBInput
                    maxLength={45}
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_last_name_label")}
                    className={"w-100 float-right"}
                    onChange={this.handleChange}
                    type={"text"}
                    autoComplete={"family-name"}
                    name={"last_name"}
                    value={this.state.user.last_name}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_last_name_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_last_name_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    maxLength={45}
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_organization_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"text"}
                    autoComplete={"organization"}
                    name={"organization"}
                    value={this.state.user.organization}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_organization_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_organization_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    maxLength={45}
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_job_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"text"}
                    autoComplete={"job"}
                    name={"job"}
                    value={this.state.user.job}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_job_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_job_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    disabled={isDisabled}
                    outline
                    maxLength={20}
                    label={i18n._("form_profil_tva_number_label")}
                    className={"float-left w-100"}
                    onChange={this.handleChange}
                    type={"text"}
                    name={"tva_number"}
                    value={this.state.user?.tva_number}
                  >
                    <div
                      className={`invalid-feedback ${
                        this.props?.error?.INVALID_TVA_NUMBER && "d-block"
                      }`}
                    >
                      {i18n._("form_profil_tva_number_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_tva_number_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBInput
                    icon={"edit"}
                    ref={(input) => {
                      this.emailInputRef = input;
                    }}
                    iconClass={`edit-email ${
                      isDisabled ? "disabled" : "hover-pointer"
                    }`}
                    iconSize={"2x"}
                    onIconClick={isDisabled ? undefined : this.openModal}
                    disabled={isDisabled}
                    outline
                    label={i18n._("form_profil_email_label")}
                    className={"float-left w-100 mx-0"}
                    multiple={false}
                    onChange={!isEmailLocked ? this.handleChange : undefined}
                    onFocus={isEmailLocked ? this.openModal : undefined}
                    type={"email"}
                    autoComplete={"email"}
                    name={"email"}
                    value={this.state.user.email}
                  >
                    {this.state.emailError && !isEmailLocked && (
                      <div className={"text-danger"}>
                        {i18n._("form_profil_email_unavailable")}
                      </div>
                    )}

                    <div className="invalid-feedback">
                      {i18n._("form_profil_email_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_email_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className={"form-group"}>
                <MDBCol size={"12"}>
                  <MDBBtn
                    color={"default"}
                    className={"text-center btn-sm"}
                    disabled={isDisabled}
                    onClick={this.changePassword}
                  >
                    {i18n._("password_update_label")}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(InformationComponent);
