import { InputItem } from "../../Class/Input/InputItem";

export function getInvitationFormConfig() {
  return {
    first_name: new InputItem({
      type: "text",
      required: true,
      minLength: 2,
      errors: {
        missing: "INPUTS.FIRST_NAME.ERRORS.MISSING",
        invalid: "INPUTS.FIRST_NAME.ERRORS.INVALID",
      },
      name: "first_name",
      label: "INPUTS.FIRST_NAME.LABEL",
    }),
    last_name: new InputItem({
      type: "text",
      minLength: 2,
      required: true,
      errors: {
        missing: "INPUTS.LAST_NAME.ERRORS.MISSING",
        invalid: "INPUTS.LAST_NAME.ERRORS.INVALID",
      },
      name: "last_name",
      label: "INPUTS.LAST_NAME.LABEL",
    }),
    email: new InputItem({
      type: "email",
      required: true,
      errors: {
        missing: "INPUTS.EMAIL.ERRORS.MISSING",
        invalid: "INPUTS.EMAIL.ERRORS.INVALID",
        unavailable: "INPUTS.EMAIL.ERRORS.UNAVAILABLE",
      },
      name: "email",
      label: "INPUTS.EMAIL.LABEL",
    }),
    organization: new InputItem({
      required: false,
      type: "text",
      disabled: true,
      errors: {
        invalid: "INPUTS.ORGANIZATION.ERRORS.INVALID",
      },
      name: "organization",
      label: "INPUTS.ORGANIZATION.LABEL",
    }),
    job: new InputItem({
      required: false,
      type: "text",
      errors: {
        invalid: "INPUTS.JOB.ERRORS.INVALID",
      },
      name: "job",
      label: "INPUTS.JOB.LABEL",
    }),
    password: new InputItem({
      type: "password",
      required: true,
      minLength: 8,
      errors: {
        invalid: "INPUTS.PASSWORD.ERRORS.INVALID",
        missing: "INPUTS.PASSWORD.ERRORS.MISSING",
        missmatch: "INPUTS.PASSWORD.ERRORS.MISSMATCH",
      },
      name: "password",
      label: "INPUTS.PASSWORD.LABEL",
    }),
    // cgu_input: {
    //
    // },
  };
}
