import axios, { AxiosError, AxiosResponse } from "axios";

interface Credentials {
  email: string;
  password: string;
}

function handleError(e: AxiosError | any) {
  if (e?.response?.data) return { error: e?.response?.data };
  return Promise.reject(e);
}

const userApi = {
  async updateUserApiKey(
    { id }: { id: number },
    action: "create" | "regenerate" | "revoke"
  ) {
    try {
      const result = await axios.get(`/user/${id}/api_key`, {
        params: {
          action,
        },
      });
      return result.data;
    } catch (error: any) {
      return error?.response?.data || error;
    }
  },

  async confirmUserFromInvitationToken(token: string) {
    try {
      const result = await axios.get(`/user/invitation_confirmation/${token}`);
      return result.data;
    } catch (error: any) {
      return error?.response?.data || error;
    }
  },

  async createUserFromInvitation(id: number, payload: any) {
    try {
      const response = await axios.post(`/user/${id}/member`, payload, {
        headers: { "x-invitation-link": document.location.href },
      });
      return response.data;
    } catch (error: any) {
      if (!error?.response?.data) return error;

      switch (error.response.data.code) {
        case "UNAVAILABLE_EMAIL":
          return {
            error: { field: "email", reason: "unavailable" },
          };
        default:
          return {
            error: { field: "unknown", reason: "unknown" },
          };
      }
    }
  },

  async getInvitationByLink() {
    try {
      const response = await axios.get(`/user/invitation_request`, {
        headers: { "x-invitation-link": document.location.href },
      });
      return response.data;
    } catch (e: any) {
      const error = e?.response?.data || e;
      return Promise.reject(error);
    }
  },

  async resetInviteInviteLink({ id, invitation_link }: any) {
    try {
      const response = await axios.patch(`/user/${id}/invitation_link`, {
        invitation_link,
      });
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  },

  async manageBilling({ id }: any): Promise<any> {
    try {
      const response = await axios.put(`/user/${id}/manage-billing`);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async checkEmail(email: string): Promise<any> {
    try {
      let result: AxiosResponse & any = await axios.get(`/user/check/${email}`);

      if (result.error) return result;

      return result && result.data ? result.data : null;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getConnexionInfos(): Promise<any> {
    const connexionInfos = await axios.get("/ip");

    return connexionInfos?.data;
  },

  async getReferer(user_id: number): Promise<any> {
    try {
      let user: AxiosResponse & any = await axios.get(
        `/user/${user_id}/referer`
      );

      if (user.error)
        user = { error: { code: "INVALID_USER", message: "invalid user" } };

      return user && user.data ? user.data : null;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getUserActivity(user_id: number): Promise<any> {
    try {
      const { data } = await axios.get(`/user/${user_id}/activity`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async auth(credentials: Credentials, confirm?: boolean): Promise<any> {
    try {
      const user: AxiosResponse & any = await axios.post(
        `/user/auth`,
        credentials,
        {
          params: {
            confirm,
          },
        }
      );

      if (user.error) {
        return { error: user.error };
      }

      if (user.data && user.data.user) {
        user.data.user.user_id = user.data.user.id;
      }
      return user && user.data
        ? { user: user.data, error: null }
        : { user: null, error: null };
    } catch (e) {
      return handleError(e);
    }
  },

  async simpleRegister(userInfos: any): Promise<any> {
    try {
      const response: AxiosResponse & any = await axios.post(
        `/user/register`,
        userInfos
      );

      if (response.error) {
        return { error: response.error };
      }

      if (response.data) {
        return response.data;
      }
    } catch (e) {
      return handleError(e);
    }
  },

  async sendResetLink(email: any): Promise<AxiosResponse & any> {
    try {
      const user = await axios.post(`/user/reset-password`, email);
      return user.data;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async checkResetToken(token: any, email?: string): Promise<AxiosResponse & any> {
    try {
      const user = await axios.get(`/user/confirm/${token}?email=${email}`);
      return user.data;
    } catch (e: any) {
      return (
        e?.response?.data || {
          error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
        }
      );
    }
  },

  async update(
    user?: any,
    user_id?: number,
    config?: any
  ): Promise<AxiosResponse & any> {
    try {
      let params: any = {};
      if (user?.tva_number) {
        // const updatedTva = await axios.put(`/user/${user_id}/tva`, {
        //   tva_number: user?.tva_number,
        // });
        delete user.tva_number;
      }
      const updatedUser = await axios.put(
        `/user/${user_id}`,
        { user, config },
        params
      );
      return updatedUser && updatedUser.data ? updatedUser.data : null;
    } catch (e: any) {
      return Promise.reject(e?.response?.data || e);
    }
  },

  async getTeamMembers(user_id: string): Promise<AxiosResponse & any> {
    if (!user_id) return false;
    try {
      const members = await axios.get(`/user/${user_id}/member`);
      return members.data;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async inviteMember(
    user_id: number,
    member: string
  ): Promise<AxiosResponse & any> {
    if (!member || !member.length) return null;

    try {
      const result = await axios.post(`/user/${user_id}/member`, { member });
      return result.data;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async deleteMembers(
    user_id: number,
    members: any[],
    force?: boolean
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.put(`/user/${user_id}/member/delete`, {
        members,
        force,
      });
      return result.data?.user?.members || null;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async getCSVConsumptionReport(
    format: string = "xlsx",
    user_id: number
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios({
        method: "GET",
        url: `/user/${user_id}/consumption/export?format=${format}`,
        responseType: "arraybuffer",
      });
      return result.data || null;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async cancelSubscription(
    sId: string,
    user_id?: any
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.delete(`/user/${user_id}/subscription/${sId}`);
      return result.data || null;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async reactivateSubscription(
    sId: string,
    user_id?: any
  ): Promise<AxiosResponse & any> {
    try {
      const result = await axios.patch(`/user/${user_id}/subscription/${sId}`);
      return result.data || null;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },

  async userVerification() {
    try {
      const result = await axios.get(`/user/relog`);
      return result.data || null;
    } catch (e) {
      console.log(e);
      return {
        error: { code: "FATAL_ERROR", message: "Une erreur est survenue" },
      };
    }
  },
};

export default userApi;
