import React from "react";
import "./Loader.css";
import { isMobile } from "../../utils";

interface Props {
  type?: "Dot" | "Spinner" | "network_error";
  percent?: number;
  size?: "small" | "big";
  text?: string;
  color?: "dark" | "light";
  height?: string | number;
}

interface State {}

const getOffset = (value: number) => 1010 - 5.01 * value;
const height: number = window.innerHeight >= 812 ? 170 : 130;
const width: number = window.innerHeight >= 812 ? 170 : 130;

const Spinner = ({ percent }: any) => (
  <div className={"m-auto"} style={{ width, height }}>
    <div className={"spinner-wrapper"}>
      <div>
        <div>
          <span className={`spinner-label ${isMobile && "mobile"}`}>
            {percent}
            <span>%</span>
          </span>
        </div>
        <svg
          height={`${height}`}
          width={`${width}`}
          className={"spinner-circle spinning"}
          shapeRendering={"geometricPrecision"}
          viewBox={`0 0 ${height} ${width}`}
        >
          <linearGradient
            id={"linearColors"}
            x1={"0"}
            y1={"0"}
            x2={"1"}
            y2={"1"}
          >
            <stop offset={"0%"} stopColor={"#2096ff"} />
            <stop offset={"100%"} stopColor={"#05ffa3"} />
          </linearGradient>
          <circle
            className={"spinner-bg"}
            r={`${height / 2 - 5}`}
            cx={`${height / 2}`}
            cy={`${width / 2}`}
            fill={"transparent"}
          />
          <circle
            className={"spinner-fg"}
            stroke={"url(#linearColors)"}
            strokeDashoffset={getOffset(percent)}
            r={`${height / 2 - 5}`}
            cx={`${height / 2}`}
            cy={`${width / 2}`}
            fill={"transparent"}
          />
        </svg>
      </div>
    </div>
  </div>
);

const LoaderBig = ({ text, color }: any) => (
  <div
    style={{
      position: "fixed",
      height: "100%",
      width: "100%",
      textAlign: "center",
      paddingTop: "50vh",
    }}
  >
    <div className={"root-loader"}>
      <span className={color === "light" ? "bg-white" : undefined} />
      <span className={color === "light" ? "bg-white" : undefined} />
      <span className={color === "light" ? "bg-white" : undefined} />
    </div>
    <p>{text}</p>
  </div>
);

const LoaderNetwork = ({ text }: any) => (
  <div
    style={{
      position: "fixed",
      height: "100%",
      width: "100%",
      textAlign: "center",
      paddingTop: "50vh",
    }}
  >
    <div className={"root-loader"}>
      <span className={"bg-white"} />
      <span className={"bg-white"} />
      <span className={"bg-white"} />
    </div>
    <p className={"text-white"}>{text}</p>
  </div>
);

const LoaderSmall = ({ text, color, height }: any) => (
  <div className={"container"} style={{ height }}>
    <div className={"row"}>
      <div className={"col-12 text-center"}>
        <div className={"root-loader"}>
          <span className={color === "light" ? "bg-white" : undefined} />
          <span className={color === "light" ? "bg-white" : undefined} />
          <span className={color === "light" ? "bg-white" : undefined} />
        </div>
        <p>{text}</p>
      </div>
    </div>
  </div>
);

class LoaderComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { text, type, percent, color } = this.props;

    if (type === "Spinner") {
      return <Spinner percent={percent} text={text} />;
    }

    if (this.props.size === "small")
      return (
        <LoaderSmall height={this.props.height} color={color} text={text} />
      );

    if (this.props.type === "network_error")
      return <LoaderNetwork text={text} />;

    return <LoaderBig color={color} text={text} />;
  };
}

export default LoaderComponent;
