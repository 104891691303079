import React, { FormEvent, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import {
  InputCheckboxComponent,
  InputEmailComponent,
  InputPasswordComponent,
  InputTextComponent,
} from "./Input";
import { i18n, onClickSubmit } from "../../utils";
import { MDBBtn, MDBCol, MDBModalBody, MDBModalFooter, MDBRow } from "mdbreact";
import LoaderComponent from "../Loader/Loader";

export function CustomOfferFormComponent({ onSubmit, ...rest }: any) {
  const [ctaDisabled, setCtaDisabled] = useState(true);
  const [pwdValid, setPwdValid] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const {
    first_name,
    last_name,
    email,
    organization,
    job,
    vat_number,
    password,
  } = rest;

  const updateSubmitBtnState = (pwd?: any, confirmPwd?: any) => {
    if (pwd && confirmPwd) {
      setPwdValid(pwd?.length && pwd === confirmPwd);
    }
    if (!pwdValid) return !ctaDisabled && setCtaDisabled(true);
    if (
      !first_name?.currentError &&
      last_name?.currentError &&
      email?.currentError
    )
      return;

    setCtaDisabled(false);
  };

  return (
    <>
      <MDBModalBody>
        <Form
          onBlur={updateSubmitBtnState}
          onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            evt.currentTarget.classList.add("was-validated");
            let errors: any = Object.values(
              evt.currentTarget.querySelectorAll("input")
            ).some((e) => !e.checkValidity || !e.checkValidity());
            if (!errors && onSubmit) setSubmiting(true);
            errors = await onSubmit(
              Object.values(evt.currentTarget.querySelectorAll("input")).reduce(
                (acc, cur) =>
                  cur &&
                  cur.name &&
                  cur.value &&
                  cur.name !== "confirm_password"
                    ? {
                        ...acc,
                        [cur.name]:
                          cur.type === "checkbox" ? cur.checked : cur.value,
                      }
                    : acc,
                {}
              )
            );

            if (errors && errors.field) {
              setSubmiting(false);

              switch (errors.field) {
                case "email":
                  email.setCurrentError(errors.reason);
                  break;
                case "vat_number":
                  vat_number.setCurrentError(errors.reason);
                  break;
                default:
                  break;
              }
            }
          }}
          id={"custom_offer_form"}
        >
          <MDBRow>
            <MDBCol size={"6"}>
              <InputTextComponent inputObject={first_name} />
            </MDBCol>
            <MDBCol size={"6"}>
              <InputTextComponent inputObject={last_name} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputEmailComponent inputObject={email} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputTextComponent inputObject={organization} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputTextComponent inputObject={job} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputTextComponent inputObject={vat_number} />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputPasswordComponent
                onChangeCb={updateSubmitBtnState}
                {...password.getRawInput()}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size={"12"}>
              <InputCheckboxComponent
                id={"cgu_input"}
                label={i18n._("accept_cgu_label")}
                name={"cgu"}
                defaultChecked={false}
                required
              />
            </MDBCol>
          </MDBRow>

          <FormControl disabled={ctaDisabled} hidden type={"submit"} />
        </Form>
      </MDBModalBody>

      <MDBModalFooter className={"justify-content-center"}>
        <MDBBtn
          disabled={ctaDisabled || submiting}
          htmlFor={"custom_offer_form"}
          type={"submit"}
          onClick={onClickSubmit}
        >
          {!submiting ? (
            i18n._("btn_valid_label")
          ) : (
            <LoaderComponent
              type={"Dot"}
              color={"light"}
              size={"small"}
              height={"15px"}
            />
          )}
        </MDBBtn>
      </MDBModalFooter>
    </>
  );
}
