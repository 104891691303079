import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { config } from "../config";

import transferApi from "./transferApi";
import offerApi from "./offerApi";
import userApi from "./userApi";
import invoiceApi from "./invoiceApi";
import assetsApi from "./assetsApi";
import contactApi from "./contactApi";
import { i18n } from "../utils";

// type Window =  Window & typeof globalThis
// @ts-ignore
const appVersion = require("../../package.json")?.version;
// import { version as appVersion } from "../../package.json";

const baseUrl = `${config.apiUrl}/v2/`;
const defaultResolution = 1366 * 768;
const resolution = window.screen
  ? window.screen.height * window.screen.width
  : defaultResolution;

axios.defaults.baseURL = baseUrl;
axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

function requestInterceptor(config: AxiosRequestConfig) {
  const lang: string = i18n.getLang(),
    langChoices: string[] = i18n.getLangChoices();

  config.headers["X-Screen-Resolution"] = resolution;
  // prettier-ignore
  //@ts-ignore
  config.headers['X-Connexion-Type'] = window?.connexionInfos?.connexion || 'WIFI';
  config.headers["X-Lang"] = lang;
  config.headers["X-IHM-Version"] = appVersion;
  config.headers["Accept-Language"] = langChoices.join(", ");
  //@ts-ignore
  if (window.connexionInfos?.ip)
    //@ts-ignore
    config.headers["x-client-ip"] = window.connexionInfos?.ip;
  // config.headers['Content-Encoding'] = 'gzip';
  const DeviceUUID = require("device-uuid").DeviceUUID();
  config.headers["X-Device-Id"] = DeviceUUID.get()
    //.split('99db').join('11db'); // Connected
    .split("99db")
    .join("22db"); // Will overwrite other connexions

  config.headers["X-Device-Infos"] = `${DeviceUUID.parse().os};${
    DeviceUUID.parse().platform
  };${DeviceUUID.parse().browser}`;

  // prettier-ignore
  //@ts-ignore
  config.headers["X-Country"] = window?.connexionInfos?.country;
  // prettier-ignore
  //@ts-ignore
  config.headers["X-Country-Code"] = window?.connexionInfos?.countryCode;

  try {
    const storeString: any = localStorage.getItem("persist:fv-user");
    if (!storeString) {
      return config;
    }

    const { token } = JSON.parse(storeString);
    if (token) {
      if (JSON.parse(token)) {
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      } else {
        delete config.headers.Authorization;
      }
    }
    return config;
  } catch (e) {}
  return config;
}

function responseSuccessInterceptor(response: AxiosResponse<any>) {
  const hasToUpdate = response.headers["x-has-to-update"],
    apiVersion = response.headers["x-api-version"];
  const currentApiInfos = localStorage.getItem("apiInfos");
  const apiInfos = JSON.stringify({ hasToUpdate, apiVersion });

  if (response.headers["x-content-length"]) {
    response.headers["content-length"] = response.headers["x-content-length"];
  }

  if (currentApiInfos && currentApiInfos !== apiInfos) {
    localStorage.removeItem("apiInfos");
    localStorage.setItem("apiInfos", apiInfos);
  }
  return response;
}

function responseErrorInterceptor(error: AxiosError) {
  if (
    error &&
    error.response &&
    !error.response?.data?.code &&
    error.response.status > 400 &&
    error.response.status < 500 &&
    error.response.status !== 404 &&
    error.response.status !== 409
  ) {
    return Promise.resolve({
      error: { code: "UNAUTHORIZED", message: "Ressource non autorisée" },
    });
  } else {
    return Promise.reject(error);
  }
}

const getStripe = async (id: string, customerInfo: any) => {
  const sId = await axios.post(
    `order/${id}/${customerInfo.nb_users}`,
    customerInfo
  );

  return sId;
};

export {
  offerApi,
  transferApi,
  getStripe,
  userApi,
  invoiceApi,
  assetsApi,
  contactApi,
};
