import React, { ReactElement, useState } from "react";
import { appAction, userAction } from "../Store/Actions";
import {
  MDBAlert,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import { i18n } from "../utils";
import { userApi } from "../Api";

interface State {
  isSubmitted: boolean;
  resetError: null | string;
  email: string;
  error: null | string;
  password: string;
  confirm_password: string;
}
const PasswordModalsComponent: ({
  app,
  userFromParent,
  dispatch,
}: {
  app: any;
  userFromParent?: any;
  dispatch: Function;
}) => ReactElement | null = ({ app, dispatch, userFromParent }) => {
  const [state, setState]: [State, any] = useState({
    isSubmitted: false,
    resetError: null,
    email: "",
    error: null,
    password: "",
    confirm_password: "",
  });

  const closeResetModal = () => dispatch(appAction.resetPassword(false));
  const handleChange = (evt: any) =>
    setState({ ...state, [evt.target.name]: evt.target.value });

  const handleNewPassword = async () => {
    const { password, confirm_password } = state;

    if (confirm_password !== password)
      return setState({ ...state, error: i18n._("password_missmatch_label") });

    const user = await userApi.update(
      { password, token: "", status: 1 },
      userFromParent?.id
    );

    if (!user || user.error) return;

    dispatch(appAction.savePassword(false));
    dispatch(userAction.loginUser(user));
    //document.location.href = '/profil';
  };

  const handleResetLink = async () => {
    const { email } = state;
    const user = await userApi.sendResetLink({ email });

    if (user.error)
      return setState({
        ...state,
        isSubmitted: true,
        resetError: user.error.message,
      });

    return setState({ ...state, isSubmitted: true, resetError: null });
  };

  if (app.RESET_PASSWORD) {
    return (
      <MDBModal
        centered
        className={"fv_profil_reset_modal"}
        isOpen={app.RESET_PASSWORD}
        toggle={() => dispatch(appAction.resetPassword(false))}
      >
        <MDBModalHeader toggle={closeResetModal}>
          {i18n._("forgotten_password_label")}
        </MDBModalHeader>

        <MDBModalBody>
          <MDBRow className={"w-100 my-3"}>
            <MDBCol size={"12"}>
              {state.isSubmitted && (
                <MDBAlert
                  color={state.resetError ? "success" : "success"}
                  dismiss={true}
                >
                  {i18n._("reset_link_sent_label")}
                </MDBAlert>
              )}
            </MDBCol>

            <MDBCol size={"12"} className={"text-left"}>
              <MDBInput
                outline
                className={"px-2 text-left"}
                validate
                name={"email"}
                type={"email"}
                value={state.email}
                onChange={handleChange}
                label={i18n._("form_login_email_label")}
                icon="envelope"
              >
                <div
                  className="inv
                    alid-feedback"
                >
                  {i18n._("form_login_email_invalid")}
                </div>
                <div className="valid-feedback">
                  {i18n._("form_login_email_valid")}
                </div>
              </MDBInput>
            </MDBCol>
            <MDBCol size={"12"} className={"text-center"}>
              <MDBBtn
                className={"waves-effect waves-light"}
                color={"default"}
                onClick={handleResetLink}
              >
                {i18n._("reset_password_label")}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    );
  }

  if (app.SAVE_PASSWORD) {
    return (
      <MDBModal
        className={"fv_profil_save_password_modal"}
        isOpen={app.SAVE_PASSWORD}
      >
        <MDBModalHeader toggle={() => dispatch(appAction.savePassword(false))}>
          {i18n._("new_password_label")}
        </MDBModalHeader>

        <MDBModalBody>
          <MDBRow className={"w-100 mx-auto"}>
            <MDBCol size={"12"}>
              {state.isSubmitted && (
                <MDBAlert
                  color={state.resetError ? "danger" : "success"}
                  dismiss={true}
                >
                  {state.error ? state.error : i18n._("password_success_saved")}
                </MDBAlert>
              )}
            </MDBCol>

            <MDBCol size={"12"}>
              <MDBRow className={"w-100 mx-auto"}>
                <MDBCol size={"12"} className={"text-left"}>
                  <MDBInput
                    outline
                    className={"px-2 text-left"}
                    validate
                    error={state.error || ""}
                    name={"password"}
                    type={"password"}
                    value={state.password}
                    onChange={handleChange}
                    label={i18n._("form_login_password_label")}
                    icon="lock"
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_login_password_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_login_password_valid")}
                    </div>
                  </MDBInput>

                  <MDBInput
                    outline
                    className={"px-2 text-left"}
                    validate
                    error={state.error || ""}
                    name={"confirm_password"}
                    type={"password"}
                    value={state.confirm_password}
                    onChange={handleChange}
                    label={i18n._("form_profil_password_confirmation_label")}
                    icon="lock"
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_login_password_label")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_login_password_label")}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol size={"12"} className={"text-center"}>
                  <MDBBtn color={"default"} onClick={handleNewPassword}>
                    {i18n._("password_update_label")}
                    {/*{i18n._('login_label')}*/}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    );
  }

  return null;
};

export default PasswordModalsComponent;
