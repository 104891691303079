import React from "react";
import "./Receive.css";

import { HistoryComponent } from "../Shared";
import {
  MDBAlert,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdbreact";
import { i18n } from "../../../../utils";
import { transferApi } from "../../../../Api";
import { connect } from "react-redux";
import { ActionInputComponent } from "../../../Button";
import { QRCodeComponent } from "../../../QRCodeComponent";

interface Props {
  title: string;
  user?: any;
}
interface State {
  success?: boolean;
  error?: any;
  transfer_recipients?: string;
  transfer_name?: string;
  isLoading?: boolean;
}

class ReceiveComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transfer_recipients: "",
      transfer_name: "",
      success: false,
      error: null,
      isLoading: false,
    };
  }

  handleChange = (evt: React.ChangeEvent<HTMLInputElement> & any) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    return this.setState({ [evt.target.name]: value });
  };

  handleNewReceive = async () => {
    this.setState({ ...this.state, isLoading: true });
    const { transfer_name, transfer_recipients } = this.state;
    const transfer = await transferApi.createNewReceive({
      transfer_name,
      transfer_recipients: [transfer_recipients],
    });

    if (transfer.error)
      this.setState({
        ...this.state,
        success: false,
        error: transfer.error,
        isLoading: false,
      });

    this.setState({
      error: null,
      success: true,
      transfer_recipients: "",
      transfer_name: "",
      isLoading: false,
    });
  };

  handleSubmit = async (evt: Event & any) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.className += " was-validated";

    let errors = [];
    const { transfer_recipients } = this.state;

    if (!transfer_recipients || !transfer_recipients.length)
      errors.push("transfer_recipients");

    const emailField = document.querySelector('input[type="email"]');

    if (!!errors.length && emailField) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('invalid');
      emailField.classList.add("is-invalid");
      emailField.classList.remove("is-valid");
      return false;
    } else if (emailField) {
      // prettier-ignore
      //@ts-ignore
      emailField.setCustomValidity('');
      emailField.classList.add("is-valid");
      emailField.classList.remove("is-invalid");
    }

    await this.handleNewReceive();
  };

  render = () => {
    const { error, success, isLoading, transfer_recipients } = this.state;
    const { title, user } = this.props;

    return (
      <MDBContainer fluid>
        <MDBRow className={"border-bottom"}>
          <MDBCol
            lg={"8"}
            md={"12"}
            className={"my-auto tab-title-wrapper fv_tab_title_wrapper"}
          >
            <h3 className={"float-left font-weight-bold"}>{title}</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol
            size={"12"}
            className={"my-auto  fv-receipt-tab-wrapper fv_receipt_tab_wrapper"}
          >
            <form onSubmit={this.handleSubmit}>
              <MDBRow className={`py-2`}>
                <MDBCol
                  className={`align-self-center fv-receipt-tab-copylink-wrapper fv_receipt_tab_copylink_wrapper`}
                  size={"8"}
                >
                  <ActionInputComponent
                    customClass={"receive"}
                    onCopy={() => {
                      console.log("Link copied");
                    }}
                    mailTo={{
                      subject: i18n._(
                        "fv_creation_deposit_card_mailto_subject"
                      ),
                      body: i18n._("fv_creation_deposit_card_mailto_body", [
                        encodeURIComponent(user?.deposit_link),
                      ]),
                      enabled: true,
                      cb: () => {
                        console.log("MailTo opened");
                      },
                    }}
                    navigateTo={{
                      enabled: true,
                      cb: () => {
                        console.log("Link opened");
                      },
                    }}
                    value={user?.deposit_link}
                  />
                </MDBCol>
                <MDBCol
                  className={`text-center fv-receipt-tab-qrcode-wrapper fv_receipt_tab_qrcode_wrapper`}
                  size={"4"}
                >
                  <QRCodeComponent link={user?.deposit_link} size={50} />
                </MDBCol>
              </MDBRow>

              {(success || error) && (
                <MDBAlert color={error ? "danger" : "success"} dismiss={!error}>
                  {i18n._("receive_success_label")}
                </MDBAlert>
              )}

              <MDBRow
                className={
                  "form-group fv-receipt-tab-transfer-form fv_receipt_tab_transfer_form"
                }
              >
                <MDBCol
                  md={"12"}
                  lg={"4"}
                  className={
                    "h-100 fv-receipt-tab-transfer-form-recipients-input fv_receipt_tab_transfer_form_recipients_input"
                  }
                >
                  <MDBInput
                    disabled={isLoading}
                    className={"my-0"}
                    type={"email"}
                    validate
                    outline
                    label={i18n._("form_upload_transfer_email_label")}
                    onChange={this.handleChange}
                    name={"transfer_recipients"}
                    value={this.state.transfer_recipients}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_email_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_email_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>
                <MDBCol
                  md={"12"}
                  lg={"4"}
                  className={
                    "h-100 fv-receipt-tab-transfer-form-transfername-input fv_receipt_tab_transfer_form_transfername_input"
                  }
                >
                  <MDBInput
                    disabled={isLoading}
                    className={"my-0"}
                    type={"text"}
                    validate
                    outline
                    label={i18n._("form_upload_transfer_name_label")}
                    onChange={this.handleChange}
                    name={"transfer_name"}
                    value={this.state.transfer_name}
                  >
                    <div className="invalid-feedback">
                      {i18n._("form_profil_email_invalid")}
                    </div>
                    <div className="valid-feedback">
                      {i18n._("form_profil_email_valid")}
                    </div>
                  </MDBInput>
                </MDBCol>

                <MDBCol
                  className={
                    "my-auto text-center fv-receipt-tab-transfer-form-submit-btn fv_receipt_tab_transfer_form_submit_btn"
                  }
                  md={"12"}
                  lg={"4"}
                >
                  <MDBBtn
                    disabled={isLoading || !transfer_recipients?.length}
                    type={"submit"}
                    color={"default"}
                    className={
                      "waves-effect waves-light text-uppercase text-center"
                    }
                    size={"sm"}
                  >
                    <MDBIcon icon={"plus"} className={"mr-1 hover-pointer"} />
                    <span className={"float-right d-flex"}>
                      {i18n._("new_receive_label")}
                    </span>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
          <MDBCol size={"12"}>
            {!isLoading && <HistoryComponent type={"receive"} />}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(ReceiveComponent);
