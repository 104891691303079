import React from "react";
import "./UploaderForm.css";
import { connect } from "react-redux";
import { MDBBtn, MDBCol, MDBLink, MDBRow } from "mdbreact";

import { Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { convertSize, i18n } from "../../../../utils";
import { appAction, transferAction } from "../../../../Store/Reducers";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { transferApi } from "../../../../Api";
import { Go } from "../../../../Constants";

interface Props {
  dispatch: Function;
  transfer: any;
  user: any;
  file: any;
  match?: any;
  submitCb: any;
  app: any;
  user_upload_size: {
    per_transfer_limit: number;
    total_transfer_limit: number;
  };
  current_uploaded_size: number;
  maximum_size?: number;
}

interface TransferInfosModel {
  id?: string;
  transfer_type?: number;
}

interface State {
  transferInfos: TransferInfosModel;
  isFilesLoading: boolean;
  isPasswordModalOpen: boolean;
}

const defaultLimitInGo: number = Go * 2;
const hackTransferType = Number(window.location.pathname.startsWith("/u/"));

class UploaderFormComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPasswordModalOpen: false,
      transferInfos: {},
      isFilesLoading: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload() {
    const response = window.confirm("You will cancel your transfer ?");
    console.log(response);
    alert("OK");
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);

    const { user } = this.props;

    if (user) {
      const { config } = user;

      if (!config || !config.message || !config.title) return;
    }
  }

  handleRestart = (file: IFileWithMeta): void => {
    file.restart();
  };

  isValidLimit = () => {
    const {
      user_upload_size: { per_transfer_limit },
      transfer: { totalSize },
    } = this.props;

    if (this.props?.transfer?.totalSize && this.props?.maximum_size)
      return this.props?.transfer?.totalSize <= this.props?.maximum_size;

    if (
      this.props?.transfer?.totalSize &&
      (this.props?.maximum_size || this.props?.transfer?.maximum_size) &&
      this.props?.transfer?.totalSize >
        (this.props?.maximum_size || this.props?.transfer?.maximum_size)
    ) {
      return false;
    }

    return per_transfer_limit >= totalSize;

    // return (per_transfer_limit >= totalSize
    //   &&    (current_uploaded_size + totalSize) <= total_transfer_limit
    // )
  };
  handleCustomSubmit = async () => {
    if (!this.isValidLimit()) return;
    this.props.dispatch(transferAction.lockTransfer(true));
    const startTime = Date.now();
    const progress = {
      last: {
        timestamp: 0,
        loaded: 0,
      },
      current: {
        timestamp: 0,
        loaded: 0,
      },
    };
    this.props.dispatch(
      transferAction.setInfos({
        startTime,
        progress,
      })
    );

    const transfer = await transferApi.createTransfer({
      transfer_id: this.props.transfer.id,
    });
    return this.props.submitCb(transfer);
  };

  onChangeTab = (current: number) => {
    this.props.dispatch(appAction.setUserUploadFavoriteMethod(current));
  };

  render = () => {
    const { transfer } = this.props;
    const isValidLimit = this.isValidLimit();

    if (transfer.transfer_type || transfer.type || hackTransferType)
      return (
        <MDBRow
          style={{ display: this.state.isFilesLoading ? "none" : "" }}
          className={"h-75"}
        >
          <MDBCol size={"12"} className={"my-auto"}>
            <MDBBtn
              disabled={!isValidLimit}
              onClick={this.handleCustomSubmit}
              className={
                "btn btn-block Ripple-parent aqua-gradient waves-effect waves-light py-2 mx-auto"
              }
            >
              {i18n._("btn_transfer_label")}
            </MDBBtn>

            {!isValidLimit && (
              <>
                <span className={"text-center text-danger"}>
                  {convertSize(
                    this.props?.transfer?.maximum_size ||
                      this.props.user_upload_size.per_transfer_limit
                  )}
                  /{i18n._("transfer_label")} max.
                </span>
              </>
            )}
          </MDBCol>
        </MDBRow>
      );

    return (
      <Tabs
        onSelect={this.onChangeTab}
        defaultIndex={
          this.props?.app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0
        }
      >
        {!!this.props.user?.role && (
          <MDBLink
            className={"text-center"}
            to={i18n._("BUTTONS.MORE_FEATURES.URL")}
          >
            <span className="lnr lnr-rocket"></span>
            {i18n._("BUTTONS.MORE_FEATURES.LABEL")}
          </MDBLink>
        )}
        <MDBRow style={{ display: this.state.isFilesLoading ? "none" : "" }}>
          <MDBCol size={"12"}>
            <MDBBtn
              disabled={!!transfer.isLocked || !isValidLimit}
              onClick={this.handleCustomSubmit}
              className={
                "btn btn-block Ripple-parent aqua-gradient waves-effect waves-light py-2 mx-auto"
              }
            >
              {i18n._("btn_transfer_label")}
            </MDBBtn>

            {!isValidLimit && (
              <>
                <span className={"text-center text-danger"}>
                  {convertSize(
                    this.props?.transfer?.maximum_size ||
                      this.props.user_upload_size.per_transfer_limit
                  )}
                  /{i18n._("transfer_label")} max.
                </span>
              </>
            )}
          </MDBCol>
        </MDBRow>
      </Tabs>
    );
  };
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user.user,
  user_upload_size: {
    per_transfer_limit:
      state?.user?.user?.permissions?.upload_size?.value || defaultLimitInGo,
    total_transfer_limit: state?.user?.user?.limit_total || defaultLimitInGo,
  },
  current_uploaded_size:
    state?.user?.user?.current_uploaded_size || defaultLimitInGo,
  transfer: state.transfer.transfers,
  file: state.file,
});
export default connect(mapStateToProps)(UploaderFormComponent);
