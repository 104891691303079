import React, { ReactElement, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";

export interface ITableHeader {
  key: string;
  label: string;
}

export const MDBSwitch = ({ checked, onChange, disabled }: any) => (
  <div
    className={`custom-control custom-switch ${
      !disabled ? "hover-pointer" : "hover-disabled"
    }`}
    onClick={!disabled ? onChange : undefined}
  >
    <input
      disabled={disabled}
      onChange={() => null}
      checked={checked}
      type="checkbox"
      className={`custom-control-input ${
        !disabled ? "hover-pointer" : "hover-disabled"
      }`}
    />
    <label
      className={`custom-control-label ${
        !disabled ? "hover-pointer" : "hover-disabled"
      }`}
      htmlFor="customSwitches"
    />
  </div>
);

export function TableListComponent({
  headers,
  elements,
}: {
  headers: ITableHeader[];
  elements?: any;
}) {
  const [state, setState] = useState({ page: 0, rowsPerPage: 5 });
  const count = elements.length;
  return (
    <>
      <Table
        //@ts-ignore
        padding={"dense"}
        className={"table-responsive-md table-striped text-center"}
      >
        <TableHead>
          <TableRow>
            {headers.map(({ label, key }, n: number) => (
              <TableCell key={`t_header_cell_${key}_${n}`}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...elements]
            .splice(state.page * state.rowsPerPage, state.rowsPerPage)
            .map((item: any, n: number) => (
              <TableRow key={`t_body_row_${n}`}>
                {headers.map(({ key }) => {
                  // console.log(key, item[key])
                  return (
                    <TableCell key={`t_body_cell_${key}_${n}`}>
                      {item[key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TableCustomPagination
        count={count}
        setParentState={setState}
        state={state}
      />
    </>
  );
}

export const TableCustomPagination = ({
  count,
  setParentState,
  state,
}: {
  count: number;
  setParentState: any;
  state: any;
}): ReactElement | any => {
  const handleChangePage = (event: unknown, page: number): void => {
    setParentState({ ...state, page });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    try {
      setParentState({
        ...state,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100, 250];
  const { page, rowsPerPage } = state;

  return (
    <TablePagination
      labelRowsPerPage={"Nombre de lignes"}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};
