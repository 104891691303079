/**
 * @description Get closest number in an array
 * @param n
 * @type number
 * @param arr
 * @type number[]
 */
export function getClosestNumber(n: number, arr: number[]) {
  return arr.reduce((a, b) => {
    const aDiff = Math.abs(a - n),
      bDiff = Math.abs(b - n);

    return aDiff === bDiff ? (a > b ? a : b) : bDiff < aDiff ? b : a;
  });
}
