import axios, { AxiosResponse } from "axios";
import { images } from "../assets";

const prefix = "assets";
const defaultLang = "fr_FR";

const assetsApi = {
  async getBackground(): Promise<AxiosResponse & any> {
    const time = Date.now();
    try {
      const backgroudResponse = await axios.get(
        `${prefix}/background?d=${time}`,
        {
          timeout: 10000,
        }
      );
      return `${backgroudResponse.data}?d=${time}`;
    } catch (e) {
      return images.Slide2;
    }
  },

  async getRSS(lang: string = "fr_FR"): Promise<AxiosResponse & any> {
    try {
      const rss = await axios.get(`${prefix}/rss`, {
        timeout: 10000,
        params: { lang },
      });

      if (rss.data) {
        rss.data.annonces.forEach((annonce: any, k: number) => {
          const keys = Object.keys(annonce);
          keys.forEach((anK: any) => {
            const convK: string = anK.toLowerCase();
            annonce[convK] = annonce[convK] || annonce[anK];
          });
          if (!annonce?.node.id && !annonce?.node.publisher) {
            annonce.node.id = k;
            annonce.node.publisher = `Partenaire n° ${k}`;
          }
        });
      }

      return rss.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getLegale(type: string, lang: string): Promise<AxiosResponse & any> {
    try {
      const legaleContent = await axios.get(`legale/${type}/${lang}`, {
        timeout: 10000,
      });
      return legaleContent.data;
    } catch (e) {
      if ((e as any)?.response?.status === 404) {
        try {
          const legaleContent = await axios.get(
            `legale/${type}/${defaultLang}`,
            {
              timeout: 10000,
            }
          );
          return legaleContent.data;
        } catch (e) {
          return Promise.reject(e);
        }
      }

      return Promise.reject(e);
    }
  },

  async getStrings(): Promise<AxiosResponse & any> {
    try {
      const strings = await axios.get(`${prefix}/locales`, { timeout: 10000 });
      return strings.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getCustomCss(): Promise<any> {
    try {
      const customCss = await axios.get(`${prefix}/custom-css`, {
        timeout: 10000,
      });
      return customCss.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default assetsApi;
