import React, { FormEvent } from "react";
import { Form, FormControl } from "react-bootstrap";
import {
  InputCheckboxComponent,
  InputEmailComponent,
  InputPasswordComponent,
  InputTextComponent,
} from "./Input";
import { i18n } from "../../utils";
import { MDBCol, MDBRow } from "mdbreact";

export function InvitationFormComponent({ onSubmit, ...rest }: any) {
  const { first_name, last_name, email, organization, job, password } = rest;
  return (
    <Form
      onSubmit={async (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        evt.currentTarget.classList.add("was-validated");
        let errors: any = Object.values(
          evt.currentTarget.querySelectorAll("input")
        ).some((e) => !e.checkValidity || !e.checkValidity());
        if (!errors && onSubmit)
          errors = await onSubmit(
            Object.values(evt.currentTarget.querySelectorAll("input")).reduce(
              (acc, cur) =>
                cur && cur.name && cur.value && cur.name !== "confirm_password"
                  ? {
                      ...acc,
                      [cur.name]:
                        cur.type === "checkbox" ? cur.checked : cur.value,
                    }
                  : acc,
              {}
            )
          );

        if (errors && errors.field && !errors.ok) {
          switch (errors.field) {
            case "email":
              email.setCurrentError(errors.reason);
              break;
            default:
              break;
          }
        }
      }}
      id={"invitation_form"}
    >
      <MDBRow>
        <MDBCol size={"6"}>
          <InputTextComponent inputObject={first_name} />
        </MDBCol>
        <MDBCol size={"6"}>
          <InputTextComponent inputObject={last_name} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"}>
          <InputEmailComponent inputObject={email} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"}>
          <InputTextComponent inputObject={organization} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"}>
          <InputTextComponent inputObject={job} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"}>
          <InputPasswordComponent {...password.getRawInput()} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size={"12"}>
          <InputCheckboxComponent
            id={"cgu_input"}
            label={i18n._("accept_cgu_member_label")}
            name={"cgu"}
            defaultChecked={false}
            required
          />
        </MDBCol>
      </MDBRow>

      <FormControl hidden type={"submit"} />
    </Form>
  );
}
